import React, {useState, useEffect} from "react";
import { Container,Row,Col } from "react-bootstrap"
import { Link } from 'react-router-dom';
import api from "../../../helpers/api";
const Tracking =()=>{
    const order_id = localStorage.getItem('order_id');
    const [siteSettings, setSiteSettings] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0)
        localStorage.setItem('cart', JSON.stringify([]));
        localStorage.setItem('totalQuantity', 0);
    }, []);
    useEffect(() => {
        api.get(`/get-site-settings`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setSiteSettings(res.data.data)
        });
    }, []);
    return(
        <div className="order-tracking">
            <Container>
                <Row>
                    <Col>
                    <div className="heading-order-tracking">
                        <h4 className="mb-4">Thank You For Choosing Happy Shopping </h4>
                        <span className="mb-4">{"Your order ID is : " + order_id}</span>
                        <br/>
                    </div>
                    <div className="tracking-button">
                    <Link to={`/`}>
                      <button className="log-btn-tracking">Continue Shopping</button>
                      </Link>
                    </div>
                    
                    </Col>
                </Row>
            </Container>
        </div>

    )
}
export default Tracking;
import React, {useState, useEffect} from "react";
import { Container,Row,Col } from "react-bootstrap";
import { SpinnerCircularFixed} from 'spinners-react';
import api from "../../../../helpers/api";
const Homebanner =(props)=>{
    const siteSettings = props.siteSettings;
    const [Banner, setBanner] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        api.get(`/fetch_banners`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh',
            }
          }).then(res => {
            setBanner(res.data.data[0])
            setIsLoading(false)
        });

    }, []);
    return(
       <div className="banner-margin" id="banner">
            <Container>

                    {
                        isLoading && props.isLoading ?
                        <Row>
                            <div className="text-center pt-4 mb-4">
                                {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                                <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                            </div>
                        </Row> :
                        <Row>
                            <Col>
                                <div className="banner-cont justify-content-sm-between" style={{ background: `url(${siteSettings.banner_image})`}}>
                                    <div className="text-center">
                                        <h1>{Banner.title}</h1>
                                        <span>{Banner.description}</span>
                                        <a href="/catalog"><button className="btn" >{Banner.button_text}</button></a>
                                    </div>
                                    <div className="r-side text-center">
                                        <span>{Banner.small_description}</span>
                                        <h2 className="m-2">{Banner.small_heading}</h2>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    }
                    {/* <Row>
                        <Col xxl={4} lg={6}>
                            <div className="discount d-flex">
                                <div>
                                    <span>30% Off</span>
                                    <h3>Computers</h3>
                                    <button className="btn">SHOP NOW</button>
                                </div>
                                <div className="image">
                                    <img className="img-fluid" src={monitor} alt=""/>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={4} lg={6}>
                            <div className="discount1 d-flex">
                                <div>
                                    <span>20% Off</span>
                                    <h3>Mobile Phones</h3>
                                    <button className="btn">SHOP NOW</button>
                                </div>
                                <div className="image"><img className="img-fluid" src={mobile} alt=""/></div>
                            </div>
                        </Col>
                        <Col xxl={4} lg={6}>
                            <div className="discount2 d-flex">
                                <div>
                                    <span>25% Off</span>
                                    <h3>Accessories</h3>
                                    <button className="btn">SHOP NOW</button>
                                </div>
                                <div className="image">
                                    <img className="img-fluid" src={router} alt=""/>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                </Container>
       </div>

    )
}
export default Homebanner;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "lottie-react";
import { Navbar, Nav, NavDropdown, Image, Dropdown } from "react-bootstrap";
import searchlottie from "../../../../assets/PetStore/Lottie/search.json";
import headersearch from "../../../../assets/PetStore/images/header-search.svg";
import logo from "../../../../assets/PetStore/images/pet-logo.png";
import cartlottie from "../../../../assets/PetStore/Lottie/cart.json";
import heart from "../../../../assets/PetStore/images/heart-icon.svg";
import {
    faAngleDoubleDown,
    faBars,
    faChevronDown,
    faChevronRight,
    faTruck,
    faUser,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import api from "../../../helpers/api";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Header = (props) => {
    let query = useQuery();
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    var link = window.location.href.split("/");
    // wishlist items counter
    const wishlist_items = JSON.parse(localStorage.getItem("wishlist"));
    const WishlistCount = wishlist_items ? wishlist_items.length : "0";
    // cart items counter
    const cart_items = JSON.parse(localStorage.getItem("cart"));

    const totalQuantity = cart_items
        ? cart_items.reduce((total, m) => total + m.quantity, 0)
        : "0";

    // Logout Handler
    const user = localStorage.getItem("isLoggedIn");
    const customer = JSON.parse(localStorage.getItem("userData"));
    const onLogoutHandler = (e) => {
        e.preventDefault();
        localStorage.removeItem("userData");
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("isLoggedIn");
        window.location.href = "/";
    };
    // get product types & site settings handler
    const [siteSettings, setSiteSettings] = useState([]);
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        api.get(`/get-product-type`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCategories(res.data.data);
            });
        setIsLoading(true);
        api.get(`/get-site-settings`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setSiteSettings(res.data.data);
                setIsLoading(false);
            });
    }, []);
    // Load more categories when arrows clicked on dropdown
    const [displayedCategories, setDisplayedCategories] = useState(3);
    const loadMoreCategories = () => {
        setDisplayedCategories(displayedCategories + 3);
    };
    // Search Handler
    const [isActive, setIsActive] = useState(false);
    const [search_item, setSearchItem] = useState(query.get("term"));

    const handleClick = (event) => {
        setIsActive((current) => !current);
    };

    useEffect(() => {
        if (search_item != null) {
            setIsActive(true);
        }
    }, []);

    const [HomeSearch, setHomeSearch] = useState("");

    const handleChange = (e) => {
        setHomeSearch(e.target.value);
    };

    const handleSearch = async (e) => {
        e.preventDefault();

        if (HomeSearch) {
            navigate(`/catalog?term=${HomeSearch}`);
        }
    };

    return (
        <header className="header">
            <Navbar expand="lg p-0">
                <div className="container-width container-fluid position-relative">
                    <Navbar.Brand href="/">
                        <div className="logo">
                            {isLoading ? (
                                <Image src={logo} />
                            ) : (
                                <Image src={siteSettings.logo} />
                            )}
                        </div>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="navbarScroll">
                        <FontAwesomeIcon
                            icon={faBars}
                            className="navbar-toggler-icon toggler-icon"
                        />
                    </Navbar.Toggle>
                    <Navbar.Collapse
                        id="navbarScroll"
                        className="collapse-wrapper-mobile"
                    >
                        <Nav className="header-list my-2 my-lg-0 d-flex justify-content-end align-lg-items-center align-items-start">
                            <div>
                                <a className="item-pointer nav-link p-0">
                                    <a
                                        className="nav-link d-flex align-items-center me-3"
                                    >
                                        Categories
                                        <FontAwesomeIcon
                                            className="ms-2"
                                            icon={faChevronDown}
                                        />
                                    </a>

                                    <div
                                        className="dropdown-menu-custom dropdown-menu-list p-0"
                                        role="menu"
                                        aria-labelledby="dropdownMenu"
                                    >
                                        <ul className="dropdown-menu-custom">
                                            {categories
                                                ?.slice(0, displayedCategories)
                                                .map((item, index) => (
                                                    <li className="submenu-li">
                                                        <div className="d-flex w-100 justify-content-between align-items-center submenu-li-x">
                                                            <a
                                                                href={`/catalog?type=${item.name}`}
                                                            >
                                                                {item?.name}
                                                            </a>
                                                            {item
                                                                ?.sub_categories
                                                                ?.length >
                                                                0 && (
                                                                <a className="text-black">
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faChevronRight
                                                                        }
                                                                    />
                                                                </a>
                                                            )}
                                                        </div>
                                                        {item?.sub_categories
                                                            ?.length > 0 && (
                                                            <div
                                                                role="menu"
                                                                aria-labelledby="dropdownMenu"
                                                            >
                                                                <ul className="p-0">
                                                                    <li className="dropdown-submenu-custom multi-sub-menu">
                                                                        <ul className="p-0">
                                                                            {item?.sub_categories.map(
                                                                                (
                                                                                    row,
                                                                                    index
                                                                                ) => (
                                                                                    <li className="px-2 py-1">
                                                                                        <a
                                                                                            href={`/catalog?type=${row.name}`}
                                                                                        >
                                                                                            {
                                                                                                row?.name
                                                                                            }
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </li>
                                                ))}
                                            {displayedCategories <
                                                categories?.length && (
                                                <li className="submenu-li">
                                                    <a
                                                    style={{cursor:"pointer"}}
                                                        className="d-flex justify-content-center pt-2"
                                                        onClick={
                                                            loadMoreCategories
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            className="text-black"
                                                            icon={
                                                                faAngleDoubleDown
                                                            }
                                                        />
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </a>
                            </div>
                            {/* <NavDropdown title="Our Stores" id="navbarScrollingDropdowntwo" className={query.has('type') ? "header-top-cata menu-active-navdrop" : "header-top-cata"}>
                                {categories.map((item, index) => (
                                <NavDropdown.Item className={res == `catalog?type=${item.name}` && "menu-active-navdrop" } href={`/catalog?type=${item.name}`}>{item.name}</NavDropdown.Item>
                                ))}
                            </NavDropdown> */}

                            <Nav.Link
                                href="/catalog"
                                className={
                                    link[3] == `catalog` &&
                                    "menu-active-navdrop"
                                }
                            >
                                Catalog
                            </Nav.Link>
                            <Nav.Link
                                href="/cms/about-us"
                                className={
                                    link[4] == `about-us` &&
                                    "menu-active-navdrop"
                                }
                            >
                                About Us
                            </Nav.Link>
                            <Nav.Link
                                href="/faq"
                                className={
                                    link[3] == `faq` && "menu-active-navdrop"
                                }
                            >
                                Faqs
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <div className="header-track-order d-flex align-items-center">
                        <Link to="/track-order">
                            <div className="header-truck">
                                <FontAwesomeIcon icon={faTruck} />
                            </div>
                        </Link>
                        <Link to="/cart">
                            <div className="header-icon-color me-lg-3 me-2">
                                <div className="cart position-relative d-inline-block">
                                    <div className="cart-header">
                                        <Lottie
                                            animationData={cartlottie}
                                            loop={true}
                                        />
                                    </div>
                                    <span>{totalQuantity}</span>
                                </div>
                            </div>
                        </Link>
                        <div className="bottom-header-heart position-relative">
                            <Link to="/wishlist">
                                <div className="bottom-header-img position-relative">
                                    <img src={heart} alt="" />
                                    <span>
                                        {props.lengthWishlist
                                            ? props.lengthWishlist
                                            : WishlistCount}
                                    </span>
                                </div>
                            </Link>
                        </div>
                        <div className="header-icons">
                            {isActive == false && (
                                <Lottie
                                    animationData={searchlottie}
                                    loop={true}
                                    onClick={handleClick}
                                />
                            )}
                            <div className="header-cross">
                                {isActive == true && (
                                    <FontAwesomeIcon
                                        className="cross-header"
                                        icon={faXmark}
                                        onClick={handleClick}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="lottie-icons d-flex align-items-center">
                        {/* <div className='header-icons'>
                            <Lottie animationData={searchlottie} loop={true} />
                        </div> */}
                        {/* <div className="header-icons">
                           <FontAwesomeIcon className="user-icon" icon={faUser} />
                        </div> */}
                        {!user && (
                            <Link className="nav-link-header-log" to="/login">
                                <div className="header-bg-color">
                                    <FontAwesomeIcon
                                        className="user-icon"
                                        icon={faUser}
                                    />
                                </div>
                            </Link>
                        )}
                        {user && (
                            <div className="d-flex align-items-center">
                                <div className="user-profile">
                                    <Dropdown className="d-flex ">
                                        <div className="me-2">
                                            {/* <img className="img-fluid" src={profile} style={{width: "38px"}}/> */}
                                        </div>
                                        <Dropdown.Toggle
                                            variant="success"
                                            id="dropdown-basic-top-change"
                                        >
                                            <span className="text-flow">
                                                {customer.first_name
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    customer.first_name.slice(
                                                        1
                                                    )}
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="header-down">
                                            <Dropdown.Item href="/myaccount">
                                                My Account
                                            </Dropdown.Item>
                                            <Dropdown.Item href="/myorder">
                                                My Orders
                                            </Dropdown.Item>
                                            <Dropdown.Item href="/wishlist">
                                                My Wishlist
                                            </Dropdown.Item>
                                            <NavDropdown.Item
                                                href="#"
                                                onClick={onLogoutHandler}
                                            >
                                                Logout
                                            </NavDropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Navbar>
            <div className="custome-container">
                {isActive == true && (
                    <form
                        className="header-form d-flex align-items-center"
                        onSubmit={handleSearch}
                    >
                        <input
                            className={isActive ? "w100" : ""}
                            type="text"
                            placeholder="Search"
                            onChange={
                                location.pathname == "/catalog"
                                    ? props.handleChange
                                    : handleChange
                            }
                            value={props.search_catalog}
                        />
                        {location.pathname != "/catalog" && (
                            <button
                                className="header-search-button"
                                type="submit"
                            >
                                <img src={headersearch} />
                            </button>
                        )}
                    </form>
                )}
            </div>
        </header>
    );
};

export default Header;

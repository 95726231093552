import React, { useState, useEffect } from "react";
import { Nav, Navbar, Image, NavDropdown, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import loging from "../../../../assets/FlowerShop/images/user.svg";
import shopping from "../../../../assets/FlowerShop/images/shopping.svg";
import logo from "../../../../assets/FlowerShop/images/Logo_Flower.svg";
import heart from "../../../../assets/FlowerShop/images/heart-icon.svg";
import {
    faAngleDoubleDown,
    faChevronRight,
    faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../helpers/api";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Header = (props) => {
    let query = useQuery();
    const navigate = useNavigate();
    // cart item Quantity
    const cart_items = JSON.parse(localStorage.getItem("cart"));
    const totalQuantity = cart_items
        ? cart_items.reduce((total, m) => total + m.quantity, 0)
        : "0";
    const customer = JSON.parse(localStorage.getItem("userData"));
    const user = localStorage.getItem("isLoggedIn");
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const wishlist_items = JSON.parse(localStorage.getItem("wishlist"));
    const WishlistCount = wishlist_items ? wishlist_items.length : "0";
    // Site Settings (Logo)
    const [siteSettings, setSiteSettings] = useState([]);

    useEffect(() => {
        api.get(`/get-product-type`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCategories(res.data.data);
            });
        setIsLoading(true);
        api.get(`/get-site-settings`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setSiteSettings(res.data.data);
                setIsLoading(false);
            });
    }, []);
    const onLogoutHandler = (e) => {
        e.preventDefault();
        localStorage.removeItem("userData");
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("isLoggedIn");
        toast.success("You logout successfully");
        navigate("/");
    };
    var link = window.location.href.split("/");
    const [displayedCategories, setDisplayedCategories] = useState(3);
    // Load more categories when the "Load More" button is clicked
    const loadMoreCategories = () => {
        setDisplayedCategories(displayedCategories + 3);
    };

    return (
        <header className="header">
            <Navbar expand="lg">
                <div className="container-fluid container-width">
                    {isLoading ? (
                        <Navbar.Brand href="/" className="me-0">
                            <div className="logo">
                                <Image src={logo} fluid />
                            </div>
                        </Navbar.Brand>
                    ) : (
                        <Navbar.Brand href="/" className="me-0">
                            <div className="logo">
                                <Image src={siteSettings.logo} fluid />
                            </div>
                        </Navbar.Brand>
                    )}
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="header-list my-2 my-lg-0 d-flex align-items-center justify-content-center">
                            <Nav.Link
                                href="/"
                                className={link[3] == "" && "menu-active-nav"}
                            >
                                Home
                            </Nav.Link>
                            {/* <Nav.Link href="#action4">Categories</Nav.Link> */}
                            {/* <NavDropdown
                                title="Categories"
                                id="basic-nav-dropdown"
                                className={
                                    query.has("type")
                                        ? "menu-active-nav header-top-cata"
                                        : "header-top-cata"
                                }
                            >
                                {categories.map((item, index) => (
                                    <NavDropdown.Item
                                        className={
                                            link[3] ==
                                                `catalog?type=${item.name}` &&
                                            "menu-active-navdrop"
                                        }
                                        href={`/catalog?type=${item.name}`}
                                    >
                                        {item.name}
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown> */}
                            <a className="item-pointer nav-link">
                                <a
                                    className={`dropdown-toggle ${
                                        query.has("type")
                                            ? "menu-active-navdrop"
                                            : ""
                                    }`}
                                >
                                    Categories
                                </a>
                                <div
                                    className="dropdown-menu-custom dropdown-menu-list p-0"
                                    role="menu"
                                    aria-labelledby="dropdownMenu"
                                >
                                    <ul className="dropdown-menu-custom">
                                        {categories
                                            ?.slice(0, displayedCategories)
                                            .map((item, index) => (
                                                <li
                                                    className="submenu-li"
                                                    key={item.id}
                                                >
                                                    <div className="d-flex w-100 justify-content-between align-items-center submenu-li-x">
                                                        <a
                                                            className={`${
                                                                query.get("type") == item.name
                                                                    ? "menu-active-navdrop"
                                                                    : ""
                                                            }`}
                                                            href={`/catalog?type=${item.name}`}
                                                        >
                                                            {item?.name}
                                                        </a>
                                                        {item?.sub_categories
                                                            ?.length > 0 && (
                                                            <a className="text-black">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faChevronRight
                                                                    }
                                                                />
                                                            </a>
                                                        )}
                                                    </div>
                                                    {item?.sub_categories
                                                        ?.length > 0 && (
                                                        <div
                                                            role="menu"
                                                            aria-labelledby="dropdownMenu"
                                                        >
                                                            <ul className="p-0">
                                                                <li className="dropdown-submenu-custom multi-sub-menu">
                                                                    <ul className="p-0">
                                                                        {item?.sub_categories.map(
                                                                            (
                                                                                row,
                                                                                index
                                                                            ) => (
                                                                                <li
                                                                                    className="px-2 py-1"
                                                                                    key={
                                                                                        row.id
                                                                                    }
                                                                                >
                                                                                    <a
                                                                                        href={`/catalog?type=${row.name}`}
                                                                                    >
                                                                                        {
                                                                                            row?.name
                                                                                        }
                                                                                    </a>
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )}
                                                </li>
                                            ))}
                                        {displayedCategories <
                                            categories?.length && (
                                            <li className="submenu-li">
                                                <a
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    className="d-flex justify-content-center pt-2"
                                                    onClick={loadMoreCategories}
                                                >
                                                    <FontAwesomeIcon
                                                        className="text-black"
                                                        icon={faAngleDoubleDown}
                                                    />
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </a>
                            <Nav.Link
                                href="/catalog"
                                className={
                                    link[3] == "catalog" && "menu-active-nav"
                                }
                            >
                                Catalog
                            </Nav.Link>
                            <Nav.Link
                                href="/cms/about-us"
                                className={
                                    link[4] == "about-us" && "menu-active-nav"
                                }
                            >
                                About Us
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/faq"
                                className={
                                    link[3] == "faq" && "menu-active-nav"
                                }
                            >
                                FAQs
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Link className="me-3" to={"/cart"}>
                        <div className="header-icons d-flex justify-content-between align-items-center">
                            <div className="top-header-items d-flex justify-content-between align-items-center">
                                <Link className="me-3" to="/track-order">
                                    <FontAwesomeIcon
                                        className="header-truck me-3"
                                        icon={faTruck}
                                    />
                                </Link>
                                <div className="shopping-cart-image me-3 position-ralative">
                                    <Image src={shopping} fluid />
                                    <span className="d-flex align-items-center justify-content-center">
                                        {totalQuantity}
                                    </span>
                                </div>
                                <div className="bottom-header-heart me-3 position-raltive">
                                    <Link
                                        className="bottom-header-img"
                                        to="/wishlist"
                                    >
                                        <img
                                            className="img-fluid"
                                            src={heart}
                                            alt=""
                                        />
                                    </Link>
                                    <span>
                                        {props.lengthWishlist
                                            ? props.lengthWishlist
                                            : WishlistCount}
                                    </span>
                                </div>
                                {!user && (
                                    <Link to="/login">
                                        {/* <FontAwesomeIcon className="header-user-icon" icon={faUser} /> */}
                                        <div className="header-flower-user ms-lg-3 ms-0">
                                            <img
                                                className="img-fluid"
                                                src={loging}
                                                alt="Jewelry"
                                            ></img>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </Link>
                    {user && (
                        <div className="user-profile">
                            <Dropdown className="d-flex ">
                                <div className="me-2"></div>
                                <Dropdown.Toggle
                                    variant="none"
                                    className="login-header"
                                    id="dropdown-basic-top-change"
                                >
                                    <span className="text-flow">
                                        {customer.first_name
                                            .charAt(0)
                                            .toUpperCase() +
                                            customer.first_name.slice(1)}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="header-down">
                                    <Dropdown.Item href="/myaccount">
                                        My Account
                                    </Dropdown.Item>
                                    <Dropdown.Item href="/myorder">
                                        My Orders
                                    </Dropdown.Item>
                                    <Dropdown.Item href="/wishlist">
                                        My Wishlist
                                    </Dropdown.Item>
                                    <NavDropdown.Item
                                        href="#"
                                        onClick={onLogoutHandler}
                                    >
                                        Logout
                                    </NavDropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}
                </div>
            </Navbar>
        </header>
    );
};

export default Header;

import React, { useState, useEffect } from "react";
import useValidator from "../../../hooks/useValidator";
import { Container, Row, Col, InputGroup, Form, Image } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import eyeicon from "../../../../assets/HappyShopping/images/eye-icon.svg";
import eyeslash from "../../../../assets/HappyShopping/images/eye-slash-icon.svg";
import api from "../../../helpers/api";
import "react-toastify/dist/ReactToastify.css";
function ShowErrorMessage(props) {
  const messages = props.message;
  const listItems = Object.keys(messages).map((key) => {
    return <li>{props.message[key]}</li>;
  });
  return <ul>{listItems}</ul>;
}
const ResetForm = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [password_confirmation, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [validator, showValidationMessage] = useValidator();
  const [errorMsg, setErrorMsg] = useState();
  useEffect(() => {
    if (localStorage.jwt_token) {
      window.location.href = "/";
    }
  });
  const submitHandler = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      await api
        .post(
          `/reset-password`,
          {
            password,
            password_confirmation,
            token
          },
          {
            headers: {
              "POS-API-KEY": "jd54elkrtlh"
            }
          }
        )
        .then((res) => {
          if (res.data.status == 0) {
            if (typeof res.data.message === "object") {
              setErrorMsg(<ShowErrorMessage message={res.data.message} />);
            } else {
              toast.error(res.data.message);
            }
          } else {
            setErrorMsg("");
            setConfirmPassword("");
            setPassword("");
            navigate("/login");
            toast.success(res.data.message);
          }
        })
        .catch((res) => {
          toast.error("Something went wrong");
        });
    } else {
      showValidationMessage(true);
    }
  };
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [ConfirmpasswordType, setConfirmPasswordType] = useState("password");
  const toggleConfirmPassword = () => {
    if (ConfirmpasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };
  return (
    <div className="resetfrom-track">
      <div id="login-data">
        <Container>
          <Row>
            <Col>
              <div class="d-flex justify-content-center form-outside">
                <div class="login-form text-center ">
                  {errorMsg && (
                    <div className="alert alert-danger alert-dismissible">
                      {errorMsg}
                    </div>
                  )}
                  <Form method="post" onSubmit={submitHandler}>
                    <span class="head">Reset Password</span>
                    <div class="email-inp">
                      <InputGroup className="mb-3 position-relative">
                        <Form.Control
                          placeholder="Password"
                          aria-label="Password"
                          aria-describedby="basic-addon1"
                          type={passwordType}
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                        <div
                          className="login-eye-icon"
                          onClick={togglePassword}
                        >
                          {passwordType === "password" ? (
                            <Image className="slash-eye-icon" src={eyeslash} />
                          ) : (
                            <Image src={eyeicon} />
                          )}
                        </div>
                      </InputGroup>
                    </div>
                    <div class="pass-inp">
                      <InputGroup className="mb-3 position-relative">
                        <Form.Control
                          placeholder="Confirmed Password"
                          aria-label="Confirmed Password"
                          aria-describedby="basic-addon1"
                          type={ConfirmpasswordType}
                          value={password_confirmation}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                        />
                        <div
                          className="login-eye-icon"
                          onClick={toggleConfirmPassword}
                        >
                          {ConfirmpasswordType === "password" ? (
                            <Image className="slash-eye-icon" src={eyeslash} />
                          ) : (
                            <Image src={eyeicon} />
                          )}
                        </div>
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <Form.Control
                          type="hidden"
                          value={token}
                          onChange={(e) => {
                            setConfirmPassword(token);
                          }}
                        />
                      </InputGroup>
                    </div>
                    <button class="log-btn-tracking">Reset</button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default ResetForm;

import React, { useState, useEffect } from "react";
import TopHeader from "./../../sections/TopHeader/TopHeader";
import MiddleHeader from "./../../sections/MiddleHeader/MiddleHeader";
import BottomHeader from "./../../sections/BottomHeader/BottomHeader";
import Account from "./account";
import MiddleFooter from "./../../sections/MiddleFooter/Middlefooter";
import BottomFooter from "./../../sections/BottomFooter/BottomFooter";
import TopFooter from "./../../sections/TopFooter/TopFooter";
import SalonHeader from "../../../HairBeauty/BottomHeader/BottomHeader";
import SalonTopFooter from "../../../HairBeauty/TopFooter/TopFooter";
import SalonMiddleFooter from "../../../HairBeauty/MiddleFooter/Middlefooter";
import SalonBottomFooter from "../../../HairBeauty/BottomFooter/BottomFooter";
import KidMidHeader from "../../../BearKid/MiddleHeader/MiddleHeader";
import KidBotomHeader from "../../../BearKid/BottomHeader/BottomHeader";
import KidTopFooter from "../../../BearKid/TopFooter/TopFooter";
import KidMiddleFooter from "../../../BearKid/MiddleFooter/Middlefooter";
import KidBottomFooter from "../../../BearKid/BottomFooter/BottomFooter";
import api from "../../../helpers/api";
import { toast } from "react-toastify";
function ShowErrorMessage(props) {
    const messages = props.message;
    const listItems = Object.keys(messages).map((key) => {
        return <li className="sign-form-li">{props.message[key]}</li>;
    });
    return <ul>{listItems}</ul>;
}
function MyAccount() {
    const [showContent, setShowContent] = useState(0);
    const NewThemeID = JSON.parse(localStorage.getItem("ThemeID"));
    const [themeId, setThemeId] = useState(NewThemeID);
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setThemeId(res.data.data.store.theme_id);
                localStorage.setItem("ThemeID", res.data.data.store.theme_id);
            });
    }, []);
    useEffect(() => {
        const TotalQty = JSON.parse(localStorage.getItem("totalQuantity"));
        setShowContent(TotalQty);
    }, []);
    // customer profile info
    const customer = JSON.parse(localStorage.getItem("userData"));
    const [first_name, setFirstName] = useState(customer?.first_name);
    const [last_name, setLastName] = useState(customer?.last_name);
    const [email, setEmail] = useState(customer?.email);
    const [phone, setPhone] = useState(customer?.phone);
    const [gender, setGender] = useState(customer?.gender);
    const [showPersonalProfileModal, setShowPersonalProfileModal] =
        useState(false);
    const [profileName, setProfileName] = useState();
    const [errorMsg, setErrorMsg] = useState(); // missing form details Or Invalid case
    const handleProfileShow = () => setShowPersonalProfileModal(true);
    // close modal
    const handleClose = (event) => {
        event.preventDefault();
        setShowPersonalProfileModal(false);
    };

    const submitProfileInfo = async (e) => {
        e.preventDefault();
        await api.post(
                `/add-profile?user_id=${customer.user_id}`,
                {
                    first_name,
                    last_name,
                    email,
                    gender,
                    phone,
                },
                {
                    headers: {
                        "POS-API-KEY": "jd54elkrtlh",
                    },
                }
            )
            .then(({ data }) => {
                if (data.status == 0) {
                    if (typeof data.message === "object") {
                        setShowPersonalProfileModal(true);
                        setErrorMsg(
                            <ShowErrorMessage message={data.message} />
                        );
                    } else {
                        setErrorMsg(data.message);
                    }
                } else {
                    setErrorMsg("");
                    setShowPersonalProfileModal(false);
                    localStorage.setItem("userData", JSON.stringify(data.data));
                    setProfileName(first_name);
                    toast.success(data.message);
                }
            })
            .catch(({ data }) => {
                toast.error("something went wrong");
            });
    };

    return (
        <>
            {themeId == 7 && <TopHeader />}
            {themeId == 7 && (
                <MiddleHeader
                    showContent={showContent}
                    profileName={profileName}
                />
            )}
            {themeId == 7 && <BottomHeader />}
            {themeId == 5 && <SalonHeader />}
            {themeId == 6 && <KidMidHeader showContent={showContent} />}
            {themeId == 6 && <KidBotomHeader />}
            <Account
                customer={customer}
                errorMsg={errorMsg}
                handleProfileShow={handleProfileShow}
                handleClose={handleClose}
                submitProfileInfo={submitProfileInfo}
                showPersonalProfileModal={showPersonalProfileModal}
                first_name={first_name}
                onFirstNameChange={setFirstName}
                last_name={last_name}
                onLastNameChange={setLastName}
                email={email}
                onEmailChange={setEmail}
                phone={phone}
                onPhoneChange={setPhone}
                gender={gender}
                onGenderChange={setGender}
            />
            {themeId == 7 && <TopFooter />}
            {themeId == 7 && <MiddleFooter />}
            {themeId == 7 && <BottomFooter />}
            {themeId == 5 && <SalonTopFooter />}
            {themeId == 5 && <SalonMiddleFooter />}
            {themeId == 5 && <SalonBottomFooter />}
            {themeId == 6 && <KidTopFooter />}
            {themeId == 6 && <KidMiddleFooter />}
            {themeId == 6 && <KidBottomFooter />}
        </>
    );
}
export default MyAccount;

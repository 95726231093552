import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import api from "../../../helpers/api";
const Testimonials = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    useEffect(() => {
        setIsLoading(true)
        api.get(`/get-testimonials`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setTestimonials(res.data.data)
            setIsLoading(false)
        });
    }, []);
    const lengthTestimonial = testimonials.length > 3 ? 3 : testimonials.length;

    var test = {
        // centerMode: true,
        centerPadding: '60px',
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: lengthTestimonial,
        slidesToScroll: 1,
        prevArrow:  <FontAwesomeIcon icon={faArrowLeft} className="arrow-left"/>,
        nextArrow:  <FontAwesomeIcon icon={faArrowRight} className="arrow-right"/>,
        responsive: [            
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 2,
                centerPadding: '40px',
                slidesToScroll: 1,
                infinite: true,
                dots:false
              }
            },
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  centerPadding: '30px',
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false
                }
              },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                centerPadding: '20px',
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
                breakpoint: 750,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 2
                }
              },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                centerPadding: '5px',
                slidesToScroll: 1
              }
            }
          ]






    };
    return (
        <div className='testimonials'>
            <div className='section-heading text-center'>
                <h3>Testimonials</h3>
            </div>
            <div className='container-width'>
                <div className='test-slider'>
                    <div className='text-slider-heading mb-5'>
                        <h2>What Our Customer Says?</h2>
                    </div>
                    <Slider {...test} className="testimonials-slider">
                        {
                            testimonials.map((row, key)=>(
                                <div className='test-item' key={row?.id}>
                                    <div className='test-item-content'>
                                        <p>{row.description}</p>
                                        <h5>{row.title}</h5>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Testimonials;

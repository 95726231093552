import axios from 'axios';

// Get Current Store Theme Details
// var current_url = 'https://shoplove.quix365.com/';

var current_url = window.location.href;
var current_store = current_url?.split('/')
var link2 = current_store[2]?.split('.');
var store = link2[0];  

const instance = axios.create({
  baseURL: 'https://'+ store + '.quix365.com/api', // Replace with your API base URL
});

export default instance;
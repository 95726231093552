import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
const PaymentMethod = () => {
  const cart_items = JSON.parse(localStorage.getItem("cart"));
  return (
    <div id="payment">
      <Container>
        <Row>
          <div className="set-width">
            <div className="d-lg-flex">
              <div className="cont1">
                <div className="nav " id="nav-tab" role="tablist"></div>
                <div>
                  <div
                    className="tab-pane fade show active"
                    id="pills-payment"
                    role="tabpanel"
                    aria-labelledby="pills-payment-tab"
                  >
                    <div className="cont1-inside">
                      <ul>
                        <li className="d-flex">
                          <label for="#">Contact</label>
                          <span className="d-flex">
                            Your Name (yourname@email.com)
                          </span>
                          <Link className="active-change text-end" to="/">
                            Change
                          </Link>
                        </li>
                        <li className="d-flex">
                          <label for="#">Ship to</label>
                          <span className="d-flex">
                            Your complete address with postal code
                          </span>
                          <Link className="active-change text-end" to="/">
                            Change
                          </Link>
                        </li>
                        <li className="d-flex">
                          <label for="#">Method</label>
                          <span className="d-flex">Standard 80$</span>
                        </li>
                      </ul>
                      <div className="heading">
                        <h2>Payments</h2>
                        <span className="active-paragraph">
                          All transactions are secure and encrypted.
                        </span>
                      </div>
                      <div className="radio-border">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label lab-color"
                            for="flexRadioDefault1"
                          >
                            Credit Card / Debit Card / Net Banking
                          </label>
                        </div>
                        <div className="form-check border-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Cash on Delivery (COD)
                          </label>
                        </div>
                      </div>
                      <div className="heading">
                        <h2>Billing Address</h2>
                        <span className="active-paragraph">
                          Select the address that matches your card or payment
                          method.
                        </span>
                      </div>
                      <div className="radio-border">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label lab-color"
                            for="flexRadioDefault1"
                          >
                            Use a different billing address
                          </label>
                        </div>
                        <div className="form-check border-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Same as shipping address
                          </label>
                        </div>
                      </div>
                    </div>
                    <button className="endbtn">Order Complete</button>
                    <button className="endbtn">Return to shipping</button>
                  </div>
                </div>
              </div>
              <div className="cont2">
                <div className="cont2-inside">
                  {cart_items != "" ? (
                    cart_items.map((row, key) => (
                      <div className="d-flex cont2-adj">
                        <div className="img-cont">
                          <img className="w-100 h-100" src={row.image} alt="" />
                        </div>
                        <span className="p-width">{row.product_name}</span>
                        <span className="text-end price">{row.price}</span>
                      </div>
                    ))
                  ) : (
                    <p> No Product Found</p>
                  )}
                  <div className="sect1">
                    <ul className="list-unstyled mb-0">
                      <li>
                        <label for="#">Subtotal</label>
                        <span className="price">
                          {cart_items.reduce(
                            (prev, cur) => cur.price * cur.quantity + prev,
                            0
                          )}
                        </span>
                      </li>
                      <li className="mb-0">
                        <label for="#">Shipping</label>
                        <span className="color">Calculated at next step</span>
                      </li>
                    </ul>
                    <ul className="list-unstyled border-0 mb-0 pb-0">
                      <li className="mb-0 ">
                        <label for="#">Total</label>
                        <span className="price2">
                          <span className="usd">USD</span>
                          {cart_items.reduce(
                            (prev, cur) => cur.price * cur.quantity + prev,
                            0
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default PaymentMethod;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faStar} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
const SliderProducts = (props) => {
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : "Rs";
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
        nextArrow: <FontAwesomeIcon icon={faArrowRight} />,
        responsive: [
            {
              breakpoint: 1399,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
              }
            },
            {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false,
                }
              },
            
            {
                breakpoint: 950,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false,
                }
              },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    
	
    };
    function StarRating({count, value, 
        inactiveColor='#ddd',
        size=24,
        activeColor='#f00', onChange}) {
    
      // short trick 
      const stars = Array.from({length: count}, () => '🟊')
  
      return (
        <div>
          {stars.map((s, index) => {
            let style = inactiveColor;
            if (index < value) {
              style=activeColor;
            }
            return (
                <FontAwesomeIcon icon={faStar} style={{color: style, width:size, height:size, fontSize: size, marginLeft: "-6px"}}/>
            )
          })}
        </div>
      )
    }
    return (
        <Slider className="prod-slider" {...settings}>
            {
            props.products.map((row, index) => (
            <div className="slider-cont">
                <Link className="product-text" to={`/shop/${row.id}`}>
                <div className="leather-jacket">
                      <img src={row.image} alt=""/>
                </div>
                </Link>
                  { row.display_name !== null ?
                    <div className="discount-tag">
                        <span>{row.display_name}</span>
                    </div>
                    :
                    ''
                  }
                  <div className="heart-icon" key={index} data-id={row.id} onClick={() => { props.addToWishlist(row.id); }}>
                  {
                    (JSON.stringify(wishlist_items.find((item) => item.id === row.id))) ?
                    <svg xmlns="http://www.w3.org/2000/svg" className="top-product-svg" width="20" height="20"
                    viewBox="0 0 27 23">
                    <path id="Icon_feather-heart" data-name="Icon feather-heart"
                        d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                        transform="translate(-0.823 -2.997)" fill="none"
                        stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="3" />
                    </svg>
                    : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                    viewBox="0 0 27 23">
                    <path id="Icon_feather-heart" data-name="Icon feather-heart"
                        d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                        transform="translate(-0.823 -2.997)" fill="none"
                        stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="3" />
                    </svg>
                  }
                  </div>
                <Link className="product-text" to={`/shop/${row.id}`}>
                <div className="">
                    <div>
                    {/* <Tooltip title={row.product_name}>
                          <h3>{row.product_name}</h3>
                        </Tooltip> */}
                        <h3 className="heading-trend-slider mb-3" title={row.product_name}>{row.product_name}</h3>
                        <div className="starRating mb-3">
                        <StarRating 
                        count={5}
                        size={20}
                        value={row.product_reviews}
                        activeColor ={'#FEBE37'}
                        inactiveColor={'#ddd'}/>  
                         </div>
                         <div className="slider-descrip">
                          <p>
                            {row.description}
                          </p>
                    </div>
                        <div className="d-flex justify-content-between  align-items-center trend-slider-price">
                            {
                                row.discount_offer_id != null ?
                                <div className="slid-price">
                                  <span className="d-price"><del>{currency +" "+row.price}</del> | </span>
                                  <span className="r-price">{currency +" "+(row.price - row.discounted_amount)}</span>
                                </div>
                                :
                                <span>{currency +" "+row.price}</span>
                            }
                            <button className="slider-button">
                                <span>Add to Cart</span>
                            </button>
                        </div>
                    </div>
                </div>
                </Link>
            </div>
            ))}
        </Slider>
    )
}
export default SliderProducts;

import React from "react";
import { Row } from "react-bootstrap";
import { SpinnerCircularFixed} from 'spinners-react';
import SliderProducts from './../SliderComponents/SliderProducts';
import NonSliderProducts from './../SliderComponents/NonSliderProducts';
const NewArrival = (props) => {
    const newArrivalProducts= props.newArrivalProducts;
    const addToWishlist = props.addToWishlist;
    const wishlist = props.wishlist;
    const isLoading = props.isLoading;
    return (
        <div className='new-arrival container-width'>
            <div className='section-heading d-flex align-items-center justify-content-between'>
                <h4>New Arrival</h4>
                {/* <a href='/' className='view'>View all</a> */}
            </div>
                {isLoading ? 
                <Row>
                    <div className="text-center pt-4 mb-4">
                        {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                        <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                    </div>
                </Row>
                :
                    newArrivalProducts.length > 0 ?
                        newArrivalProducts.length > 4 ?
                        <SliderProducts products = {newArrivalProducts} addToWishlist={addToWishlist} wishlist={wishlist}/>
                        :
                        <NonSliderProducts products = {newArrivalProducts} addToWishlist={addToWishlist} wishlist={wishlist}/>
                    : <p className="category-data d-flex justify-content-center">No Product found in this Category</p>
                        
                }
        </div>
    )
}

export default NewArrival

import React, {useState, useEffect} from "react";
import Header from "../Header/Header";
import Middlefooter from "../Middlefooter/Middlefooter";
import Addcart from "./Addcart";
import Bread from "./Bread";
import PetStoreHeader from "../../PetStore/HomePage/Header/Header";
import NewUpdates from '../../PetStore/HomePage/NewUpdates/NewUpdates';
import PetStoreFooter from '../../PetStore/HomePage/Footer/Footer';
import JewelleryHeader from '../../Jewellery/Header/Header'
import JewelleryTopFooter from '../../Jewellery/Home-Page/Subscriber/Subscriber'
import JewelleryFooter from '../../Jewellery/Footer/Footer'
import ShopHeader from "../../ShopLove/Header/Header";
import ShopBottomFooter from "../../ShopLove/BottomFooter";
import FlowerHeader from "../../FlowerShop/HomePage/Header/Header";
import FlowerFooter from "../../FlowerShop/HomePage/Footer/Footer";
import FlowerMidFooter from "../../FlowerShop/HomePage/MiddleFooter/MiddleFooter";
import CafeHeader from "../../CafeFood/Header/Header";
import CafeFooter from "../../CafeFood/BottomFooter";
import { ToastContainer, toast } from 'react-toastify';
import api from "../../helpers/api";
const Cart =()=>{
    const [showContent, setShowContent] = useState(0);

    const cart_items = JSON.parse(localStorage.getItem('cart'));

    const [cart, setCart] = useState(cart_items);

    if(cart == null)
    {
        setCart([])
    }
    const [newQuantity, setNewQuantity] = useState(0);
    const NewThemeID = JSON.parse(localStorage.getItem('ThemeID'));
    const [themeId, setThemeId] = useState(NewThemeID?NewThemeID:[]);
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setThemeId(res.data.data.store.theme_id)
            // localStorage.setItem('ThemeID', res.data.data.store.theme_id);
        });
    }, []);
    useEffect(() => {
      window.scrollTo(0, 0)
        localStorage.setItem('cart', JSON.stringify(cart));
        const TotalQty = cart.reduce((total,m) => total + m.quantity,0); 
        setNewQuantity(cart.quantity);
        setShowContent(TotalQty);
        localStorage.setItem('totalQuantity', JSON.stringify(TotalQty));
    }, [cart])
    
    const IncreaseChange = (id) => {
        const check_index = cart.find(item => item.variant_id != "" ? item.variant_id=== id : item.prooduct_id=== id);
        
        if(check_index?.productStock > 0)
        {
          if (check_index !== -1) {
            const newList = cart.map((item) => {
                if (item.variant_id != "" ? item.variant_id=== id : item.prooduct_id=== id) {
                  const updatedItem = {
                    ...item,
                    quantity: item.quantity + 1,
                    productStock: item.productStock - 1
                  };
          
                  return updatedItem;
                }
          
                return item;
            });
              setCart(newList);
          }  
        }
        else
        {
          toast.error("You cannot add more items of this product in cart. Available stock is full", {
            autoClose: 1000
        });
        }
         
    };
    
    const DecreaseChange = (id) => {

      const check_index = cart.find(item => item.variant_id != "" ? item.variant_id=== id : item.prooduct_id=== id);
      if (check_index !== -1) {
        const newList = cart.map((item) => {
            if (item.variant_id != "" ? item.variant_id=== id : item.prooduct_id=== id) {
              if(item.quantity > 1)
              {
                const updatedItem = {
                  ...item,
                  quantity: item.quantity - 1,
                  productStock: item.productStock + 1
                };
        
                return updatedItem;
              }
              
            }
      
            return item;
        });
      
          setCart(newList);
      }   
    };

    const RemoveCart = (variant_id,product_id) => {
      const items = variant_id == "" ? cart.filter((item) => item.prooduct_id !== product_id) : cart.filter((item) => item.variant_id !== variant_id)
      localStorage.setItem("cart", JSON.stringify(items));
      if (items.length === 0) {
        localStorage.removeItem("item");
      }
      setCart(items);
      toast.error("Item is removed from Cart", {
          autoClose: 1000
      });
    };
    return(
        <div className="wrapper">
          <ToastContainer/>
            {themeId == 1 && <FlowerHeader showContent={showContent}/>}
            {themeId == 2  && <ShopHeader showContent={showContent} themeId={themeId}/>}
            {themeId == 3 && <Header showContent={showContent}/>}
            {themeId == 4 && <CafeHeader showContent={showContent}/>}
            {themeId == 8 && <PetStoreHeader showContent={showContent}/>}
            {themeId == 9 && <JewelleryHeader showContent={showContent}/>}
            <Bread breadTitle="Your Shopping Cart"/>
            <Addcart themeId={themeId} newQuantity={newQuantity} cart_items={cart_items} cart={cart} IncreaseChange={IncreaseChange} DecreaseChange={DecreaseChange} RemoveCart={RemoveCart}/>
            {themeId == 1 && <FlowerFooter/>}
            {themeId == 1 && <FlowerMidFooter/>}
            {themeId == 2 && <ShopBottomFooter />}
            {/* {themeId == 3 &&<Topfooter/>} */}
            {themeId == 3 && <Middlefooter/>}
            {themeId == 4 && <CafeFooter/>}
            {themeId == 8 && <NewUpdates/>}
            {themeId == 8 && <PetStoreFooter/>}
            {themeId == 9 && <JewelleryTopFooter />}
            {themeId == 9 && <JewelleryFooter />}
        </div>
        

    )
}
export default Cart;
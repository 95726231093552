import React, { useState, useEffect } from "react";
import { Container,Row,Col,Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import useValidator from '../hooks/useValidator';
import api from "../helpers/api";
function ShowErrorMessage(props) {

    const messages = props.message;
    const listItems = Object.keys(messages).map((key) => {

      return (<li>{props.message[key]}</li>)
    })
    return (

      <ul>{listItems}</ul>
    );

}
const BottomFooter =()=>{
    const [email, setEmail] = useState();
    const [CmsPages, setCmsPages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [siteSettings, setSiteSettings] = useState([]);
    const [validator, showValidationMessage] = useValidator()
    const [errorMsg, setErrorMsg] = useState();
    const [successMsg, setSuccessMsg] = useState();
    useEffect(() => {
        setTimeout(() => {
            // After 3 seconds set the show value to false
            setErrorMsg("");
            setSuccessMsg("")
        }, 3000)
    }, [errorMsg,successMsg])
    useEffect(() => {
        api.get(`/get-site-settings`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setSiteSettings(res.data.data)
          setIsLoading(false)
        });
        }, []);
    useEffect(() => {
        api.get(`/fetch_cms_pages`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setCmsPages(res.data.data)
            setIsLoading(false)
        });

    }, []);
    const submitHandler = async (e) => {

        e.preventDefault();
        if (validator.allValid())
        {
            await api.post(`/subscribe`, {
                email

            }, {
                headers: {
                'POS-API-KEY' : 'jd54elkrtlh'
                }
            }).then(res => {

                if (res.data.status == 0) {
                    if(typeof res.data.message === 'object')
                    {
                        setErrorMsg(<ShowErrorMessage message={res.data.message} />)
                    }
                    else
                    {
                        setErrorMsg(res.data.message);
                    }
                }
                else {
                    setErrorMsg("");
                    setEmail("");
                    setSuccessMsg(res.data.message);
                }

            }).catch((res) => {

                toast.error("Something went wrong");

            })
        }
        else
        {
            showValidationMessage(true);
        }
    }

    // Replace the site settings copyright with current year
    const originalString = siteSettings?.site_copyright;
    const currentYear = new Date().getFullYear(); 
    const copyright = originalString?.replace(/\d{4}/, currentYear);
    return(
        <div className="middle-footer">
            <Container fluid className="custome-container">
                <Row>
                    <Col>
                    <div className="d-flex align-items-center">
                        <div className="footer-logo">
                        <img src={siteSettings.logo} alt="" />
                    </div>
                    <span className="footer-border"></span>
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} sm={6}>
                        {errorMsg && <div className="alert alert-danger alert-dismissible">
                        {errorMsg}</div>}
                        {successMsg && <div className="alert alert-success">
                        {successMsg}</div>}
                        <Form className='footer-content pe-xl-5 pe-4 mb-lg-0 mb-5' method="post" onSubmit={submitHandler}>
                            <div className="sec1">
                                <span>Subscribe our newsletter</span>
                                <div className="footer-form  d-flex justify-content-between align-items-center">
                                    {/* <span>Enter your email</span> */}
                                    <input className="bottom-footer-input" type="text" placeholder="Enter your email" onChange={(e) => { setEmail(e.target.value) }} value={email}/>
                                    <button className="botom-color-link" type="submit"> <FontAwesomeIcon className="footer-arrow" icon={faArrowRight}/></button>  
                                    <div className="form-line"></div>
                                </div> 
                                {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Subscribe our newsletter</Form.Label>
                                    <Form.Control type="email" placeholder="Enter your email" />
                                    <FontAwesomeIcon className="footer-arrow" icon={faArrowRight} />
                                </Form.Group> */}
                            </div>
                        </Form>
                        <div className="d-flex mb-lg-5 mb-4">
                               <div className="footer-icons">
                                    <a href="https://www.facebook.com/arhamsoft.official/">
                                        <FaFacebookF />
                                    </a>
                                </div>
                                <div className="footer-icons">
                                    <a href="https://twitter.com/ArhamSoft">
                                        <FaTwitter />
                                    </a>
                                </div>
                                <div className="footer-icons">
                                    <a href="https://www.instagram.com/arhamsoft.official/">
                                        <FaInstagram />
                                    </a>
                                </div>
                                <div className="footer-icons">
                                    <a href="https://www.youtube.com/c/ArhamSoft-official">
                                        <FaYoutube />
                                    </a>
                                </div>
                        </div>
                        <div className="bot-footer mb-lg-0 mb-4 d-lg-block d-none">
                            <span>{copyright}</span>
                        </div>
                    </Col>
                    <Col lg={1} offset-1></Col>
                    <Col lg={3} sm={6}>
                        <div className="sec2">
                            <span>Service</span>
                            <ul className="list-unstyled mb-0">
                                {
                                    CmsPages != "" ? (
                                        CmsPages.map((row, key)=>(
                                            <li><Link to={'/cms/' + row.slug}>{row.title}</Link></li>
                                        ))
                                        )
                                    : ""
                                }
                            </ul>
                        </div>
                    </Col>
                    <Col lg={1} offset-1></Col>
                    <Col lg={3} sm={6}>
                        <div className="sec2">
                            <span>Help</span>
                            <ul className="list-unstyled mb-0">
                                <li><a href="/cms/about-us">Support</a></li>
                                <li><a href="/faq">FAQ</a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="bot-footer mb-lg-0 mb-4 d-lg-none d-block text-center">
                        <span>{copyright}</span>
                    </div>
                </Row>
            </Container>
        </div>

    )
}
export default BottomFooter;
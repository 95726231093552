import { Container, Col, Row, InputGroup, Form, Image } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import useValidator from "../../hooks/useValidator";
import { Link, useNavigate } from "react-router-dom";
import eyeicon from "../../../assets/FlowerShop/images/eye-icon.svg";
import eyeslash from "../../../assets/FlowerShop/images/eye-slash-icon.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../helpers/api";
function ShowErrorMessage(props) {
    const messages = props.message;
    const listItems = Object.keys(messages).map((key) => {
        return <li className="sign-form-li">{props.message[key]}</li>;
    });
    return <ul>{listItems}</ul>;
}
const Loginform = () => {
    const navigate = useNavigate();
    // Form field states
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validator, showValidationMessage] = useValidator();
    const [errorMsg, setErrorMsg] = useState();
    // Redirect to home page in case of already logged in
    useEffect(() => {
        if (localStorage.jwt_token) {
            window.location.href = "/";
        }
    });
    // Login Handler
    const submitHandler = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            await api.post(
                    `/login`,
                    {
                        email,
                        password,
                    },
                    {
                        headers: {
                            "POS-API-KEY": "jd54elkrtlh",
                        },
                    }
                )
                .then((res) => {
                    if (res.data.status == 0) {
                        if (typeof res.data.message === "object") {
                            setErrorMsg(
                                <ShowErrorMessage message={res.data.message} />
                            );
                        } else {
                            setErrorMsg(res.data.message);
                        }
                    } else {
                        localStorage.setItem("jwt_token", res.data.token);
                        localStorage.setItem("isLoggedIn", true);
                        localStorage.setItem(
                            "userData",
                            JSON.stringify(res.data.data)
                        );
                        toast.success("You are login successully");
                        navigate(-1);
                    }
                })
                .catch((res) => {
                    toast.error("Something Went wrong");
                });
        } else {
            showValidationMessage(true);
        }
    };
    // Eye icon toggler
    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };
    return (
        <div id="login-data">
            <Container className="custome-container">
                <Row>
                    <Col>
                        <div className="d-flex justify-content-center form-outside">
                            <div className="login-form text-center ">
                                {errorMsg && (
                                    <div className="alert alert-danger alert-dismissible">
                                        {errorMsg}
                                    </div>
                                )}
                                <Form method="post" onSubmit={submitHandler}>
                                    <span className="head">
                                        Log In To Your Account
                                    </span>
                                    <div className="email-inp">
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className="pass-inp">
                                        <InputGroup className="mb-3 position-relative">
                                            <Form.Control
                                                placeholder="Password"
                                                type={passwordType}
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                }}
                                            />
                                            <div
                                                className="login-eye-icon"
                                                onClick={togglePassword}
                                            >
                                                {passwordType === "password" ? (
                                                    <Image
                                                        className="slash-eye-icon"
                                                        src={eyeslash}
                                                    />
                                                ) : (
                                                    <Image src={eyeicon} />
                                                )}
                                            </div>
                                        </InputGroup>
                                    </div>
                                    <div className="account">
                                        <span>
                                            <Link to="/forget-password">
                                                {" "}
                                                Forgot Password?
                                            </Link>
                                        </span>
                                        <span>
                                            <Link to="/signup">
                                                {" "}
                                                Create Account
                                            </Link>
                                        </span>
                                    </div>
                                    <button className="log-btn" type="submit">
                                        Log In
                                    </button>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default Loginform;

import React, {useState, useEffect} from "react";
import Header from "../Header/Header";
import HomeBanner from "../HomeBanner/HomeBanner";
import Banefit from "../Benefit/Banefit";
import Medorawood from './../Medorawood/Medorawood';
import Newarrival from './../Newarrival/Newarrival';
import Ourproducts from './../Ourproducts/Ourproducts';
import Woodfurtinure from "../Woodfurtinure/Woodfurtinue";
import Topproduct from "../Topproduct/Topproduct";
import Topfooter from "../Topfooter/Topfooter";
import Middlefooter from "../Middlefooter/Middlefooter";
import { ToastContainer, toast } from 'react-toastify';
import api from "../../helpers/api";
const Home =()=>{
    const [isLoading, setIsLoading] = useState(false);
    // Get Site Settings Detail
    const [siteSettings, setSiteSettings] = useState([]);
    useEffect(() => {
      api.get(`/get-site-settings`, {
        headers: {
          'POS-API-KEY' : 'jd54elkrtlh'
        }
      }).then(res => {
        setSiteSettings(res.data.data)
        setIsLoading(false)
    });
    }, []);
    // Set Home Page Products
    const [newArrivalProducts, setNewArrivalProducts] = useState([]);
    const [mostLovedProducts, setmostLovedProducts] = useState([]);
    const [topProducts, settopProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    // Set Wishlist Counter 
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const [wishlist, setWishlist] = useState(wishlist_items);
    if(wishlist == undefined)
    {
        setWishlist([]);
    }
    //set Theme ID
    const NewThemeID = JSON.parse(localStorage.getItem('ThemeID'));
    const [themeId, setThemeId] = useState(NewThemeID?NewThemeID:[]);
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setThemeId(res.data.data.store.theme_id)
            localStorage.setItem('ThemeID', res.data.data.store.theme_id);
        });
    }, []);
    useEffect(() => {
      // Get Top Products
      setIsLoading(true)
      api.get(`/get-top-products`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          settopProducts(res.data.data)
          setIsLoading(false)
      });
      // Get New Products
      setIsLoading(true)
      api.get(`/get-new-products`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setNewArrivalProducts(res.data.data)
          setIsLoading(false)
      });
      // Get People Loved Products
      setIsLoading(true)
      api.get(`/get-people-loved-products`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setmostLovedProducts(res.data.data)
          setIsLoading(false)
      });
    }, []);

    useEffect(() => {
      // Set Item to local storage 
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
      // Get All Products
      api.get(`/get-all-products`, {
        headers: {
          'POS-API-KEY' : 'jd54elkrtlh'
        }
      }).then(res => {
        setAllProducts(res.data.data)
      });
    }, [wishlist]);

    const addToWishlist = (id) => {
        toast.dismiss();
        const check_wishlist = JSON.stringify(wishlist.find((item) =>
        item.id === id
        ));
      
        if (!check_wishlist) {
            const product = allProducts.find((item) => item.id === id);
  
            setWishlist(current  => [...current , product]);
            
            toast.success("Wishlist Added Successfully", {
              autoClose: 1000
            });
        } else {
            let items =JSON.parse(localStorage.getItem("wishlist"));
            items = items.filter((item) => item.id !== id);
            localStorage.setItem("wishlist", JSON.stringify(items));
            if (items.length === 0) {
              localStorage.removeItem("item");
            }
            setWishlist(items);
            toast.error("Item is removed from wishlist", {
              autoClose: 1000
            });
        }
  
    };
    return(
        <div className="wrapper">
          <div className="bg">
            <ToastContainer/>
             <Header siteSettings={siteSettings} />
             <HomeBanner siteSettings={siteSettings}/>
             <Banefit />
             <Medorawood/>
             {/* <Topproduct topProducts= {topProducts} isLoading={isLoading} addToWishlist={addToWishlist}/> */}
             <Newarrival siteSettings={siteSettings} newArrivalProducts= {newArrivalProducts} isLoading={isLoading} addToWishlist={addToWishlist}/>
             <Ourproducts addToWishlist={addToWishlist}/>
             {/* <Medorawood mostLovedProducts= {mostLovedProducts} isLoading={isLoading} addToWishlist={addToWishlist}/> */}
             <Woodfurtinure siteSettings={siteSettings}/>
             {/* <Topfooter/> */}
             <Middlefooter/>
          </div>
        </div>
    )
}
export default Home;
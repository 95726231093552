import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { SpinnerCircularFixed } from "spinners-react";
import api from "../../helpers/api";
const Delivery = () => {
    const [Eservices, setEservices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);
        api.get(`/get-services`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setEservices(res.data.data);
                setIsLoading(false);
            });
    }, []);
    return (
        <>
            <div className="services">
                <Container fluid className="custome-container">
                    <div className="wrap-Heading">
                        <span className="service-title">
                            <h2>Benefits you get when Using our services.</h2>
                        </span>
                    </div>
                    {isLoading ? (
                        <Row>
                            <div className="text-center pt-4 mb-4">
                                <SpinnerCircularFixed
                                    size={50}
                                    thickness={100}
                                    speed={100}
                                    color="#1239A5"
                                    secondaryColor="rgba(0, 0, 0, 0.44)"
                                />
                            </div>
                        </Row>
                    ) : Eservices.length > 0 ? (
                        <Row>
                            {Eservices.map((row, key) => (
                                <Col md={4} sm={12} className="mb-md-0 mb-3">
                                    <Card className="service-card">
                                        <div className="wrap-service-img">
                                            <Card.Img
                                                variant="top"
                                                src={row.image}
                                            />
                                        </div>
                                        <Card.Body>
                                            <div className="text-center">
                                                <Card.Title>
                                                    <h3>{row.title}</h3>
                                                </Card.Title>
                                                <Card.Text>
                                                    <p>{row.description}</p>
                                                </Card.Text>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        "No services Found"
                    )}
                </Container>
            </div>
        </>
    );
};
export default Delivery;

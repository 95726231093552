import React, { useState, useEffect } from "react";
import useValidator from '../../../../hooks/useValidator';
import { Container, Col, Row, InputGroup, Form,Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import AuthCustomer from '../../../../helpers/AuthCustomer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eyeicon from "../../../../../assets/FlowerShop/images/eye-icon.svg"
import eyeslash from "../../../../../assets/FlowerShop/images/eye-slash-icon.svg"
import api from "../../../../helpers/api";
function ShowErrorMessage(props) {
  
  const messages = props.message;
  const listItems = Object.keys(messages).map((key) => {

    return (<li className="sign-form-li">{props.message[key]}</li>)
  })
  return (

    <ul>{listItems}</ul>
  );

}
const Loginform = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validator, showValidationMessage] = useValidator()
  const [errorMsg, setErrorMsg] = useState();
  const navigate = useNavigate();

  // Redirect to Home Page if user is already logged in
  useEffect(() => {
    if (localStorage.jwt_token) {
      navigate("/");
    }
  });

  const submitHandler = async (e) => {

    e.preventDefault();
    if (validator.allValid()) {
      await api.post(`/login`, {
        email,
        password,
      }, {
        headers: {
          'POS-API-KEY' : 'jd54elkrtlh'
        }
      }).then(res => {
        if (res.data.status == 0) {
          if(typeof res.data.message === 'object')
          {
            setErrorMsg(<ShowErrorMessage message={res.data.message} />)
          }
          else
          {
            setErrorMsg(res.data.message);
          }
        }
        else {
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem('userData', JSON.stringify(res.data.data));
          localStorage.setItem('jwt_token', res.data.token);
          toast.success("You are login successully");
          navigate(-1);
        }


      }).catch(( res ) => {

        toast.error('Something Went wrong');

      })



    } else {
      showValidationMessage(true);
    }
  }
  const [passwordType, setPasswordType] = useState("password");
    const togglePassword =()=>{

      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
  return (
    <div id="login-data">
      <Container>
        <Row>
          <Col>
            <div className="d-flex justify-content-center form-outside">
              <div className="login-form text-center ">
                {errorMsg && <div className="alert alert-danger alert-dismissible">
                {errorMsg}</div>}
                <Form method="post" onSubmit={submitHandler}>
                  <span className="head">
                    Log In To Your Account
                  </span>
                  <div class="email-inp">
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Email"
                        aria-label="Email"
                        aria-describedby="basic-addon1"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                      />
                    </InputGroup>
                  </div>
                  <div className="pass-inp">
                    <InputGroup className="mb-3">

                      <Form.Control
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        type={passwordType}
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                      />
                      <div className="login-eye-icon" onClick={togglePassword}>
                        {passwordType==="password"?
                        <Image className="slash-eye-icon" src={eyeslash}/>
                        :
                        <Image src={eyeicon}/>
                        }
                      </div>
                    </InputGroup>
                  </div>
                  <div className="account">
                    <span><Link to="/forget-password"> Forgot Password?</Link></span>
                    <span><Link to="/signup"> Create Account</Link></span>
                  </div>
                  <button className="log-btn-tracking" type="submit">Log In</button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  )
}
export default Loginform;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import truck from "../../../../assets/HappyShopping/images/truck.svg";
const TopHeader = () => {
  return (
    <div id="top-header">
      <Container>
        <Row>
          <Col md={12}>
            <div className="top-header-heading justify-content-end d-flex text-center align-items-center">
              <div className="top-heaeder d-flex text-center align-items-center">
                <div className="d-flex align-items-center ">
                  <div className="me-2">
                    <img className="top-header-img" src={truck} alt="#" />
                  </div>
                  <Link className="header-color" to="/track-order">
                    Track Order
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default TopHeader;

import React, {useState, useEffect} from "react";
import Bread from "../Cart/Bread";
import Header from "../Header/Header";
import Middlefooter from "../Middlefooter/Middlefooter";
import Topfooter from "../Topfooter/Topfooter";
import Order from "./Order";
import PetStoreHeader from "../../PetStore/HomePage/Header/Header";
import NewUpdates from '../../PetStore/HomePage/NewUpdates/NewUpdates';
import PetStoreFooter from '../../PetStore/HomePage/Footer/Footer';
import JewelleryHeader from '../../Jewellery/Header/Header'
import JewelleryTopFooter from '../../Jewellery/Home-Page/Subscriber/Subscriber'
import JewelleryFooter from '../../Jewellery/Footer/Footer'
import ShopHeader from "../../ShopLove/Header/Header";
import ShopBottomFooter from "../../ShopLove/BottomFooter";
import FlowerHeader from "../../FlowerShop/HomePage/Header/Header";
import FlowerFooter from "../../FlowerShop/HomePage/Footer/Footer";
import FlowerMidFooter from "../../FlowerShop/HomePage/MiddleFooter/MiddleFooter";
import CafeHeader from "../../CafeFood/Header/Header";
import CafeFooter from "../../CafeFood/BottomFooter";
import { ToastContainer, toast } from 'react-toastify';
import api from "../../helpers/api";
const Myorder=()=>{
    const NewThemeID = JSON.parse(localStorage.getItem('ThemeID'));
    const [themeId, setThemeId] = useState(NewThemeID?NewThemeID:[]);
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setThemeId(res.data.data.store.theme_id)
            localStorage.setItem('ThemeID', res.data.data.store.theme_id);
        });
    }, []);
    return(
       <div className="wrapper">
        <ToastContainer/>
        {themeId == 1 && <FlowerHeader/>}
        {themeId == 2  && <ShopHeader/>}
        {themeId == 3 && <Header/>}
        {themeId == 4 && <CafeHeader/>}
        {themeId == 8 && <PetStoreHeader />}
        {themeId == 9 && <JewelleryHeader />}
        <Bread breadTitle="My Order"/>
        <Order/>
        {themeId == 1 && <FlowerFooter/>}
        {themeId == 1 && <FlowerMidFooter/>}
        {themeId == 2 && <ShopBottomFooter />}
        {/* {themeId == 3 &&<Topfooter/>} */}
        {themeId == 3 && <Middlefooter/>}
        {themeId == 4 && <CafeFooter/>}
        {themeId == 8 && <NewUpdates/>}
        {themeId == 8 && <PetStoreFooter/>}
        {themeId == 9 && <JewelleryTopFooter />}
        {themeId == 9 && <JewelleryFooter />}
       </div>


    )
}
export default Myorder;
import React, {useState, useEffect} from "react";
import Banner from "../Banner";
import Header from "../Header/Header";
import OurServices from "../OurServices";
import TopCategories from "../TopCategories";
import Trend from "../Trend/Trend";
import Banefits from "../Benefits";
import OurProduct from './../OurProduct';
import OurCustomer from './../OurCustomer';
import TopFooter from "../TopFooter";
import BottomFooter from "../BottomFooter";
import { ToastContainer, toast } from 'react-toastify';
import api from "../../helpers/api";
const Home=()=>{
    const [siteSettings, setSiteSettings] = useState([]);
    const [newArrivalProducts, setNewArrivalProducts] = useState([]);
    const [mostLovedProducts, setmostLovedProducts] = useState([]);
    const [topProducts, settopProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const [wishlist, setWishlist] = useState(wishlist_items);
    if(wishlist == undefined)
    {
        setWishlist([]);
    }
    useEffect(() => {
        setIsLoading(true)
        api.get(`/get-site-settings`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setSiteSettings(res.data.data)
          setIsLoading(false)
        });
    }, []);
    const NewThemeID = JSON.parse(localStorage.getItem('ThemeID'));
    const [themeId, setThemeId] = useState(NewThemeID?NewThemeID:[]);
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setThemeId(res.data.data.store.theme_id)
            localStorage.setItem('ThemeID', res.data.data.store.theme_id);
        });
        
    }, []);

    useEffect(() => {
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
          api.get(`/get-all-products`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setAllProducts(res.data.data)
          });
          setIsLoading(true)
          api.get(`/get-new-products`, {
              headers: {
                'POS-API-KEY' : 'jd54elkrtlh'
              }
            }).then(res => {
              setNewArrivalProducts(res.data.data)
              setIsLoading(false)
          });
          setIsLoading(true)
          api.get(`/get-top-products`, {
              headers: {
                'POS-API-KEY' : 'jd54elkrtlh'
              }
            }).then(res => {
              settopProducts(res.data.data)
              setIsLoading(false)
          });
          setIsLoading(true)
          api.get(`/get-people-loved-products`, {
              headers: {
                'POS-API-KEY' : 'jd54elkrtlh'
              }
            }).then(res => {
              setmostLovedProducts(res.data.data)
              setIsLoading(false)
          });
    }, [wishlist]);
    const addToWishlist = (id) => {
        toast.dismiss();
        const check_wishlist = JSON.stringify(wishlist.find((item) =>
        item.id === id
        ));
      
        if (!check_wishlist) {
            const product = allProducts.find((item) => item.id === id);
  
            setWishlist(current  => [...current , product]);
  
            toast.success("Wishlist Added Successfully", {
              autoClose: 1000
            });
        } else {
            let items =JSON.parse(localStorage.getItem("wishlist"));
            items = items.filter((item) => item.id !== id);
            localStorage.setItem("wishlist", JSON.stringify(items));
            if (items.length === 0) {
              localStorage.removeItem("item");
            }
            setWishlist(items);
            toast.error("Item is removed from wishlist", {
              autoClose: 1000
            });
        }
  
    };
    return(
        <div className="wrapper">
          <div className="body">
             <ToastContainer/>
              <Header themeId={themeId}/>
              <Banner siteSettings={siteSettings}/>
              <Trend newArrivalProducts= {newArrivalProducts} isLoading={isLoading} addToWishlist={addToWishlist}/>
              <Banefits/>
              <OurServices topProducts= {topProducts} isLoading={isLoading} addToWishlist={addToWishlist}/>
              <TopCategories addToWishlist={addToWishlist}/>
              <OurProduct mostLovedProducts= {mostLovedProducts} isLoading={isLoading} addToWishlist={addToWishlist}/>
              <OurCustomer/>
              <TopFooter siteSettings={siteSettings} isLoading={isLoading}/>
              <BottomFooter />
          </div>
        </div>
    )
}
export default Home;
import React, {useState, useEffect,useRef} from "react";
import TopHeader from './../../sections/TopHeader/TopHeader';
import MiddleHeader from './../../sections/MiddleHeader/MiddleHeader';
import BottomHeader from './../../sections/BottomHeader/BottomHeader';
import Homebanner from './Homebanner/Homebanner';
import Categories from "./Categories/Categories";
import Topproduct from './Topproduct/Topproduct';
import Peopleloved from './Peopleloved/Peopleloved';
import NewArrival from './NewArrival/NewArrival';
import TopFooter from './../../sections/TopFooter/TopFooter';
import MiddleFooter from './../../sections/MiddleFooter/Middlefooter';
import BottomFooter from './../../sections/BottomFooter/BottomFooter';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import api from "../../../helpers/api";
const Home = () => {
    const [showContent, setShowContent] = useState(0);
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const [products, setProducts] = useState([]);
    const [topProducts, settopProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [newArrivalProducts, setNewArrivalProducts] = useState([]);
    const [mostLovedProducts, setmostLovedProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [productTypeID, setProductTypeID] = useState([]);
    const [productTypeName, setProductTypeName] = useState("");
    const [wishlist, setWishlist] = useState(wishlist_items);

    if(wishlist == undefined)
    {
        setWishlist([]);
    }
    //set Theme ID
    const NewThemeID = JSON.parse(localStorage.getItem('ThemeID'));
    const [themeId, setThemeId] = useState(NewThemeID?NewThemeID:[]);
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setThemeId(res.data.data.store.theme_id)
            localStorage.setItem('ThemeID', res.data.data.store.theme_id);
        });
    }, []);
    
    const [siteSettings, setSiteSettings] = useState([]);
    useEffect(() => {
        setIsLoading(true)
        api.get(`/get-site-settings`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setSiteSettings(res.data.data)
          setIsLoading(false)
      });
    }, []);
    useEffect(() => {
        localStorage.setItem('wishlist', JSON.stringify(wishlist));
        api.get(`/get-all-products`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setAllProducts(res.data.data)
        });
        setIsLoading(true)
        api.get(`/get-new-products`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {

            setNewArrivalProducts(res.data.data)
            setIsLoading(false)
        });
        setIsLoading(true)
        api.get(`/get-top-products`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {

            settopProducts(res.data.data)
            setIsLoading(false)
        });
        setIsLoading(true)
        api.get(`/get-people-loved-products`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {

            setmostLovedProducts(res.data.data)
            setIsLoading(false)
        });
        fetchProduct();

    }, [wishlist]);

    const [categories, setCategories] = useState([]);
    const [counter, setCounter] = useState(1);
    const first = categories[0];
    useEffect(() => {
      setIsLoading(true)
      api.get(`/get-product-type`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setCategories(res.data.data)
          setIsLoading(false)
      });

    }, []);

    useEffect(() => {
      fetchProduct()
    }, [first]);
    const myRef = useRef(null);
    function fetchProduct(category_id = first?.id ,category_name = first?.name) {
        setIsLoading(true)
        var url = `/get-product-by-category/` + category_id;
        api.get(url, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
        }).then(res => {
          counter > 2 && myRef.current?.scrollIntoView({behavior: 'smooth'});
          setProducts(res.data.data)  
          setProductTypeID(category_id)
          setProductTypeName(category_name)
          setIsLoading(false)
          setCounter(counter+1)
        });
    };

    useEffect(() => {
        const TotalQty = JSON.parse(localStorage.getItem('totalQuantity'));
        setShowContent(TotalQty)
    }, []);

    const addToWishlist = (id) => {
        toast.dismiss();
        const check_wishlist = JSON.stringify(wishlist.find((item) =>
        item.id === id
        ));
        if (!check_wishlist) {
            const product = allProducts.find((item) => item.id === id);

            setWishlist(current  => [...current , product]);

            toast.success("Wishlist Added Successfully", {
              autoClose: 1000
            });
        } else {
            let items =JSON.parse(localStorage.getItem("wishlist"));
            items = items.filter((item) => item.id !== id);
            localStorage.setItem("wishlist", JSON.stringify(items));
            if (items.length === 0) {
              localStorage.removeItem("item");
            }
            setWishlist(items);
            toast.error("Item is removed from wishlist", {
              autoClose: 1000
            });
        }
    };
    return (
        <div className="wrapper">
            <TopHeader />
            {/* <ToastContainer/> */}
            <MiddleHeader showContent={showContent}/>
            <BottomHeader />
            <Homebanner siteSettings={siteSettings} isLoading={isLoading}/>
            <Categories refProp={myRef} products={products} categories={categories} fetchProduct={fetchProduct} productTypeName={productTypeName} wishlist={wishlist} addToWishlist={addToWishlist}/>
            <NewArrival addToWishlist={addToWishlist} wishlist={wishlist} newArrivalProducts={newArrivalProducts} isLoading={isLoading}/>
            <Topproduct addToWishlist={addToWishlist} wishlist={wishlist} topProducts={topProducts} isLoading={isLoading} />
            {/* <Smartadd /> */}
            {/* <Hotoffer /> */}
            <Peopleloved addToWishlist={addToWishlist} wishlist={wishlist} mostLovedProducts={mostLovedProducts} isLoading={isLoading}/>
            <TopFooter />
            <MiddleFooter />
            <BottomFooter />
        </div>

    )
}
export default Home;

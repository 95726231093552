import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMinus,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import "react-gallery-carousel/dist/index.css";
import Carousel from "react-gallery-carousel";
const Shopborder = (props) => {
    const currency = localStorage.getItem("currency")
        ? localStorage.getItem("currency")
        : "Rs";
    const productDetail = props.productDetail;
    const imageGal = props.images;
    const discountOfferName = props.discountOfferName;
    const variantName = props.variantName;
    const variantPrice = props.price;
    const vDicountOfferId = props.discountOfferId;
    const vDicountPercentage = props.discountedPercentage;
    const vDicountedAmount = props.discountedAmount;
    let vDiscountedPrice = null;
    if (vDicountOfferId !== null) {
        vDiscountedPrice = variantPrice - vDicountedAmount;
    }

    return (
        <div id="shop">
            <Container>
                <div className="shop-border">
                    <Row>
                        <Col lg={6}>
                            <div
                                className="shop-img-tag mb-lg-0 mb-3"
                                onClick={props.newchangeCourasel}
                            >
                                {productDetail.product_variants == "" ? (
                                    <img
                                        className="img-fluid"
                                        src={productDetail.image}
                                    />
                                ) : (
                                    <div className="product-img">
                                        <Carousel
                                            arrows={false}
                                            images={imageGal}
                                            hasThumbnailsAtMax={false}
                                            thumbnailWidth={"10%"}
                                            thumbnailHeight={"10%"}
                                        />
                                    </div>
                                )}
                                {discountOfferName !== null ||
                                productDetail.display_name !== null ? (
                                    <div className="discount-tag">
                                        <span>
                                            {productDetail.display_name ||
                                                discountOfferName}
                                        </span>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="shop-cont">
                                <h2>{productDetail.product_name}</h2>
                                <div className="line-shopborder"></div>
                                <div className="mb-3">
                                    {productDetail.product_variants &&
                                    productDetail.product_variants.length >
                                        0 ? (
                                        <Dropdown className="shop-dropdown-menu-shop mb-3">
                                            <Dropdown.Toggle
                                                variant="primary"
                                                id="dropdown-basic-shop"
                                            >
                                                {variantName ||
                                                    productDetail.first_variant_combination_name}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="shop-dropdown-menu-shop">
                                                {productDetail.product_variants &&
                                                    productDetail.product_variants.map(
                                                        (variant, index) => (
                                                            <Dropdown.Item
                                                                className="dropdown-shop-product"
                                                                eventKey={
                                                                    variant.variant_id
                                                                }
                                                                onClick={() => {
                                                                    props.onOptionClicked(
                                                                        variant.variant_id,
                                                                        variant.combination_names,
                                                                        variant.variant_price,
                                                                        variant.variant_image,
                                                                        variant.discount_offer_id,
                                                                        variant.display_name,
                                                                        variant.discount_sub_type,
                                                                        variant.discounted_amount,
                                                                        variant.get_discount_percentage,
                                                                        variant.discount_case,
                                                                        variant.variant_taxRate
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    variant.combination_names
                                                                }
                                                            </Dropdown.Item>
                                                        )
                                                    )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (
                                        ""
                                    )}

                                    {productDetail.discount_offer_id !== null ||
                                    vDicountOfferId !== null ? (
                                        <div className="price align-items-center">
                                            <div className="d-flex">
                                                <span className="d-price me-2">
                                                    <del>
                                                        {currency +
                                                            " " +
                                                            (parseFloat(
                                                                variantPrice
                                                            ).toFixed(2) ||
                                                                parseFloat(
                                                                    productDetail.price
                                                                ).toFixed(2))}
                                                    </del>{" "}
                                                    |{" "}
                                                </span>
                                                <span className="r-price me-2">
                                                    {currency +
                                                        " " +
                                                        (parseFloat(
                                                            vDiscountedPrice
                                                        ).toFixed(2) ||
                                                            parseFloat(
                                                                productDetail.price -
                                                                    productDetail.discounted_amount
                                                            ).toFixed(2))}
                                                </span>
                                            </div>
                                            <div className="discount-tag-shop">
                                                <span>
                                                    {productDetail.get_discount_percentage ||
                                                        vDicountPercentage}
                                                    %
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="price">
                                            <span className="r-price">
                                                {currency +
                                                    " " +
                                                    (parseFloat(
                                                        variantPrice
                                                    ).toFixed(2) ||
                                                        parseFloat(
                                                            productDetail.price
                                                        ).toFixed(2))}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                {productDetail?.available_stock > 0 ? (
                                    <div className="drop">
                                        <div className="d-flex input-adj align-items-center">
                                            <button
                                                className="btn2"
                                                onClick={() => {
                                                    props.DecreaseChange();
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faMinus}
                                                />
                                            </button>
                                            <div>
                                                <input
                                                    type="number"
                                                    placeholder={props.quantity}
                                                    disabled
                                                />
                                            </div>

                                            <button
                                                className="btn1"
                                                onClick={(e) => {
                                                    props.IncreaseChange();
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                />
                                            </button>
                                        </div>
                                        <button
                                            className="cartbtn"
                                            onClick={props.addToCart}
                                        >
                                            Add to Cart
                                        </button>
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-center">
                                            <strong className="me-3 text-black">Availability:</strong>
                                            <div className="availability-tag img">
                                                <span>
                                                    Out Of Stock
                                                </span>
                                            </div>
                                        </div>
                                )}
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: productDetail.description,
                                    }}
                                ></p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};
export default Shopborder;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NavDropdown, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Header.css";
import heart from "../../../assets/Jewellery/images/icons/heart.svg";
import cart from "../../../assets/Jewellery/images/icons/cart-jewellery.svg";
import login from "../../../assets/Jewellery/images/icons/user.svg";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import close from "../../../assets/Jewellery/images/icons/close.svg";
import menu from "../../../assets/Jewellery/images/icons/menu.svg";
import { FaSearch } from "react-icons/fa";
import { SpinnerDotted } from "spinners-react";
import {
    faAngleDoubleDown,
    faChevronRight,
    faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import api from "../../helpers/api";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
function Header(props) {
    const location = useLocation();
    let query = useQuery();
    const navigate = useNavigate();
    const [isOpen, setIsopen] = useState(false);
    const [siteSettings, setSiteSettings] = useState([]);
    const user = localStorage.getItem("isLoggedIn");
    const customer = JSON.parse(localStorage.getItem("userData"));
    const cart_items = JSON.parse(localStorage.getItem("cart"));
    const wishlist_items = JSON.parse(localStorage.getItem("wishlist"));
    const WishlistCount = wishlist_items ? wishlist_items.length : "0";
    const totalQuantity = cart_items
        ? cart_items.reduce((total, m) => total + m.quantity, 0)
        : "0";
    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        api.get(`/get-site-settings`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setSiteSettings(res.data.data);
                setIsLoading(false);
            });
        api.get(`/get-product-type`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCategories(res.data.data);
            });
    }, []);
    const onLogoutHandler = (e) => {
        e.preventDefault();
        localStorage.removeItem("userData");
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("isLoggedIn");
        window.location.href = "/";
    };
    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    };
    const [isActive, setIsActive] = useState(false);

    const [HomeSearch, setHomeSearch] = useState("");
    const handleChange = (e) => {
        setHomeSearch(e.target.value);
    };

    const handleSearch = async (e) => {
        e.preventDefault();

        if (HomeSearch) {
            navigate(`/catalog?term=${HomeSearch}`);
        }
    };
    var link = window.location.href.split("/");
    const res = query.has("type") ? link[3].replace("%20", " ") : link[3];

    const [displayedCategories, setDisplayedCategories] = useState(3);

    // Load more categories when the "Load More" button is clicked
    const loadMoreCategories = () => {
        setDisplayedCategories(displayedCategories + 3);
    };
    return (
        <>
            <div className="header">
                <Container fluid className="custom_container">
                    {/* DESKTOP-TABLET */}
                    <div className="d-view">
                        <div className="top-navbar nav justify-content-between row">
                            <div className="search-bar d-flex align-items-center col-md-4 col-12">
                                <div
                                    className="mb-0 wrap-search"
                                    onClick={handleSearch}
                                >
                                    <FaSearch />
                                </div>
                                {/* <div className="mb-0 wrap-search"><img src={truck} alt="" /></div> */}
                                <form
                                    className="header-form"
                                    onSubmit={handleSearch}
                                >
                                    <label>
                                        <input
                                            className={isActive ? "w100" : ""}
                                            type="text"
                                            placeholder="Search"
                                            onChange={
                                                location.pathname == "/catalog"
                                                    ? props.handleChange
                                                    : handleChange
                                            }
                                            value={props.search_catalog}
                                        />
                                    </label>
                                    {/* <input className={isActive ? 'w100' : ''} onClick={handleClick} type="text" placeholder="Search" /> */}
                                </form>
                            </div>
                            <div className="col-md-8 col-12">
                                <div className="row align-items-center justify-content-center">
                                    <div className="logo col-sm-6">
                                        <Navbar.Brand
                                            className="d-block mx-0 text-center"
                                            href="/"
                                        >
                                            {isLoading ? (
                                                <SpinnerDotted
                                                    size={50}
                                                    thickness={100}
                                                    speed={100}
                                                    color="#930011"
                                                />
                                            ) : (
                                                <img
                                                    className="img-fluid"
                                                    src={siteSettings.logo}
                                                    alt="Jewelry"
                                                ></img>
                                            )}
                                        </Navbar.Brand>
                                    </div>
                                    <div className="nav-icons col-sm-6">
                                        <ul className="justify-content-sm-end">
                                            {/* <li>
                                        <a className='icon-hov' href='/truck'>
                                        <FontAwesomeIcon className="header-truck" icon={faTruck} />
                                        <FontAwesomeIcon icon={faTruck} />
                                        </a>
                                    </li> */}
                                            <li>
                                                <a href="/track-order">
                                                    <FontAwesomeIcon
                                                        className="header-truck"
                                                        icon={faTruck}
                                                    />
                                                </a>
                                            </li>

                                            <li>
                                                <a
                                                    className="icon-hov"
                                                    href="/wishlist"
                                                >
                                                    <div className="tooltip">
                                                        Wishlist
                                                    </div>
                                                    <div className="header-counter position-relative d-inline-block">
                                                        <img
                                                            className="img-fluid"
                                                            src={heart}
                                                            alt="Jewelry"
                                                        ></img>
                                                        <span className="cart-counter">
                                                            {props.lengthWishlist
                                                                ? props.lengthWishlist
                                                                : WishlistCount}
                                                        </span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="icon-hov"
                                                    href="/cart"
                                                >
                                                    <div className="tooltip">
                                                        Cart
                                                    </div>
                                                    <div className="header-counter position-relative d-inline-block">
                                                        <img
                                                            className="img-fluid"
                                                            src={cart}
                                                            alt="Jewelry"
                                                        ></img>
                                                        <span className="cart-counter">
                                                            {totalQuantity}
                                                        </span>
                                                    </div>
                                                </a>
                                            </li>
                                            {/* <li>
                                        <a className='icon-hov' href='/truck'>
                                        <FontAwesomeIcon className="header-truck" icon={faTruck} />
                                        </a>
                                    </li> */}
                                            {!user && (
                                                <li>
                                                    <a
                                                        className="icon-hov"
                                                        href="/login"
                                                    >
                                                        <div className="tooltip">
                                                            Login
                                                        </div>
                                                        <img
                                                            className="img-fluid"
                                                            src={login}
                                                            alt="login"
                                                        ></img>
                                                    </a>
                                                </li>
                                            )}
                                            {user && (
                                                <div className="d-flex align-items-center">
                                                    <div className="user-profile">
                                                        <Dropdown className="d-flex ">
                                                            <div className="me-2">
                                                                {/* <img className="img-fluid" src={profile} style={{width: "38px"}}/> */}
                                                            </div>
                                                            <Dropdown.Toggle
                                                                variant="success"
                                                                id="dropdown-basic-top-change"
                                                            >
                                                                <span className="text-flow">
                                                                    {customer.first_name
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase() +
                                                                        customer.first_name.slice(
                                                                            1
                                                                        )}
                                                                </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="header-down">
                                                                <Dropdown.Item href="/myaccount">
                                                                    My Account
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="/myorder">
                                                                    My Orders
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="/wishlist">
                                                                    My Wishlist
                                                                </Dropdown.Item>
                                                                <NavDropdown.Item
                                                                    href="#"
                                                                    onClick={
                                                                        onLogoutHandler
                                                                    }
                                                                >
                                                                    Logout
                                                                </NavDropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* MOBILE */}
                    <div className="m-view">
                        <div className="top-navbar nav justify-content-between row">
                            <div className="logo col-12">
                                <Navbar.Brand
                                    className="d-block mx-0 text-center"
                                    href="/"
                                >
                                    {isLoading ? (
                                        <SpinnerDotted
                                            size={50}
                                            thickness={100}
                                            speed={100}
                                            color="#930011"
                                        />
                                    ) : (
                                        <img
                                            className="img-fluid"
                                            src={siteSettings.logo}
                                            alt="Jewelry"
                                        ></img>
                                    )}
                                </Navbar.Brand>
                            </div>
                            <div className="col-12">
                                <div className="row align-items-center justify-content-center">
                                    <div className="search-bar d-flex justify-content-center align-items-center col-md-4 col-12">
                                        <div className="mb-0 wrap-search">
                                            <FaSearch />
                                        </div>
                                        <form
                                            className="header-form"
                                            onSubmit={handleSearch}
                                        >
                                            <label>
                                                <input
                                                    className={
                                                        isActive ? "w100" : ""
                                                    }
                                                    type="text"
                                                    placeholder="Search"
                                                    onChange={
                                                        location.pathname ==
                                                        "/catalog"
                                                            ? props.handleChange
                                                            : handleChange
                                                    }
                                                    value={props.search_catalog}
                                                />
                                            </label>
                                            {/* <input className={isActive ? 'w100' : ''} onClick={handleClick} type="text" placeholder="Search" /> */}
                                        </form>
                                    </div>
                                    <div className="nav-icons col-md-8 col-12">
                                        <ul className="justify-content-sm-end">
                                            {/* <li>
                                        <a className='icon-hov' href='/truck'>
                                        <FontAwesomeIcon className="header-truck" icon={faTruck} />
                                        <FontAwesomeIcon icon={faTruck} />
                                        <FontAwesomeIcon icon="fa-thin fa-truck" />
                                        </a>
                                    </li> */}
                                            <li>
                                                <a href="/track-order">
                                                    <FontAwesomeIcon
                                                        className="header-truck"
                                                        icon={faTruck}
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="icon-hov"
                                                    href="/wishlist"
                                                >
                                                    <div className="tooltip">
                                                        Wishlist
                                                    </div>
                                                    <div className="header-counter position-relative d-inline-block">
                                                        <img
                                                            className="img-fluid"
                                                            src={heart}
                                                            alt="Jewelry"
                                                        ></img>
                                                        <span className="cart-counter">
                                                            {props.lengthWishlist
                                                                ? props.lengthWishlist
                                                                : WishlistCount}
                                                        </span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="icon-hov"
                                                    href="/cart"
                                                >
                                                    <div className="tooltip">
                                                        cart
                                                    </div>
                                                    <div className="header-counter position-relative d-inline-block">
                                                        <img
                                                            className="img-fluid"
                                                            src={cart}
                                                            alt="Jewelry"
                                                        ></img>
                                                        <span className="cart-counter">
                                                            {totalQuantity}
                                                        </span>
                                                    </div>

                                                    {/* <img className='img-fluid' src={cart} alt="Jewelry"></img> */}
                                                </a>
                                            </li>
                                            {/* <li>
                                        <a className='icon-hov' href='/truck'>
                                        <FontAwesomeIcon className="header-truck" icon={faTruck} />
                                        </a>
                                      </li> */}

                                            {!user && (
                                                <li>
                                                    <a
                                                        className="icon-hov"
                                                        href="/login"
                                                    >
                                                        <div className="tooltip">
                                                            Login
                                                        </div>
                                                        <img
                                                            className="img-fluid"
                                                            src={login}
                                                            alt="Jewelry"
                                                        ></img>
                                                    </a>
                                                </li>
                                            )}
                                            {user && (
                                                <div className="d-flex align-items-center">
                                                    <div className="user-profile">
                                                        <Dropdown className="d-flex ">
                                                            <div className="me-2">
                                                                {/* <img className="img-fluid" src={profile} style={{width: "38px"}}/> */}
                                                            </div>
                                                            <Dropdown.Toggle
                                                                variant="success"
                                                                id="dropdown-basic-top-change"
                                                            >
                                                                <span className="text-flow">
                                                                    {customer.first_name
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase() +
                                                                        customer.first_name.slice(
                                                                            1
                                                                        )}
                                                                </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="header-down">
                                                                <Dropdown.Item href="/myaccount">
                                                                    My Account
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="/myorder">
                                                                    My Orders
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="/wishlist">
                                                                    My Wishlist
                                                                </Dropdown.Item>
                                                                <NavDropdown.Item
                                                                    href="#"
                                                                    onClick={
                                                                        onLogoutHandler
                                                                    }
                                                                >
                                                                    Logout
                                                                </NavDropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Navbar expand="lg">
                        <Navbar id="basic-navbar-nav">
                            <div
                                className={`sidebar ${
                                    isOpen === true ? "active" : ""
                                }`}
                            >
                                <Nav className="m-auto align-items-center">
                                    <button
                                        className="toggle-btn"
                                        onClick={ToggleSidebar}
                                    >
                                        <img
                                            className="img-fluid"
                                            src={close}
                                            alt="Jwelery Product"
                                        ></img>
                                    </button>
                                    <Link
                                        className={
                                            link[3] == ""
                                                ? "nav-link menu-active-navdrop"
                                                : "nav-link"
                                        }
                                        to={"/"}
                                    >
                                        Home
                                    </Link>
                                    <div>
                                        <a className="item-pointer ">
                                            <a style={{ cursor: "pointer" }}>
                                                Categories
                                            </a>
                                            <div
                                                className="dropdown-menu-custom dropdown-menu-list p-0"
                                                role="menu"
                                                aria-labelledby="dropdownMenu"
                                            >
                                                <ul className="dropdown-menu-custom">
                                                    {categories.map(
                                                        (item, index) => (
                                                            <li className="submenu-li">
                                                                <div className="d-flex w-100 justify-content-between align-items-center submenu-li-x">
                                                                    <a
                                                                        href={`/catalog?type=${item.name}`}
                                                                    >
                                                                        {
                                                                            item?.name
                                                                        }
                                                                    </a>
                                                                    {item
                                                                        ?.sub_categories
                                                                        ?.length >
                                                                        0 && (
                                                                        <a className="text-black">
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faChevronRight
                                                                                }
                                                                            />
                                                                        </a>
                                                                    )}
                                                                </div>
                                                                {item
                                                                    ?.sub_categories
                                                                    ?.length >
                                                                    0 && (
                                                                    <div
                                                                        role="menu"
                                                                        aria-labelledby="dropdownMenu"
                                                                    >
                                                                        <ul className="p-0">
                                                                            <li className="dropdown-submenu-custom multi-sub-menu">
                                                                                <ul className="p-0">
                                                                                    {item?.sub_categories.map(
                                                                                        (
                                                                                            row,
                                                                                            index
                                                                                        ) => (
                                                                                            <li className="px-2 py-1">
                                                                                                <a
                                                                                                    href={`/catalog?type=${row.name}`}
                                                                                                >
                                                                                                    {
                                                                                                        row?.name
                                                                                                    }
                                                                                                </a>
                                                                                            </li>
                                                                                        )
                                                                                    )}
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </li>
                                                        )
                                                    )}
                                                    {displayedCategories <
                                                        categories?.length && (
                                                        <li>
                                                            <div
                                                                className="d-flex justify-content-center pt-2"
                                                                onClick={
                                                                    loadMoreCategories
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="text-black"
                                                                    icon={
                                                                        faAngleDoubleDown
                                                                    }
                                                                />
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </a>
                                    </div>
                                    <a
                                        href="/catalog"
                                        className={
                                            link[3] == `catalog`
                                                ? "nav-link menu-active-navdrop"
                                                : "nav-link"
                                        }
                                    >
                                        Catalog
                                    </a>
                                    <Link
                                        className={
                                            link[4] == "about-us"
                                                ? "nav-link menu-active-navdrop"
                                                : "nav-link"
                                        }
                                        to={"/cms/about-us"}
                                    >
                                        About Us
                                    </Link>
                                    <Link
                                        className={
                                            link[3] == `faq`
                                                ? "nav-link menu-active-navdrop"
                                                : "nav-link"
                                        }
                                        to={"/faq"}
                                    >
                                        Faqs
                                    </Link>
                                </Nav>
                            </div>
                        </Navbar>
                        <button className="toggle-btn" onClick={ToggleSidebar}>
                            <img
                                className="img-fluid"
                                src={menu}
                                alt="Jwelery Product"
                            ></img>
                        </button>
                    </Navbar>
                </Container>
            </div>
        </>
    );
}

export default Header;

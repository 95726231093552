import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SliderProducts from "./SliderComponents/SliderProducts";
import NonSliderProducts from "./SliderComponents/NonSliderProducts";
import { SpinnerCircularFixed } from "spinners-react";
const OurProduct = (props) => {
  const mostLovedProducts = props.mostLovedProducts;
  const addToWishlist = props.addToWishlist;
  const wishlist = props.wishlist;
  const isLoading = props.isLoading;
  return (
    <div className="our-product">
      <Container fluid className="custome-container">
        <Row>
          <Col>
            <div className="our-product-heading">
              <div className="services-border d-flex align-items-center">
                <span className="border-line-services"></span>
                <span className="service-circle">
                  <span className="inner-border"></span>
                </span>
              </div>
              <h2>People Loved Product</h2>
              <div className="services-border-active d-flex align-items-center">
                <span className="service-circle"></span>
                <span className="border-line-services">
                  <span className="inner-circle"></span>
                </span>
              </div>
            </div>
            {isLoading ? (
              <Row>
                <div className="text-center pt-4 mb-4">
                  {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                  <SpinnerCircularFixed
                    size={50}
                    thickness={100}
                    speed={100}
                    color="#1239A5"
                    secondaryColor="rgba(0, 0, 0, 0.44)"
                  />
                </div>
              </Row>
            ) : mostLovedProducts.length > 0 ? (
              mostLovedProducts.length > 4 ? (
                <SliderProducts
                  products={mostLovedProducts}
                  addToWishlist={addToWishlist}
                  wishlist={wishlist}
                />
              ) : (
                <NonSliderProducts
                  products={mostLovedProducts}
                  addToWishlist={addToWishlist}
                  wishlist={wishlist}
                />
              )
            ) : (
              <p className="category-data d-flex justify-content-center">
                No Product found in this Category
              </p>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default OurProduct;

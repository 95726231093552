import React, {useState, useEffect} from "react";
import { Container,Row,Col } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import customer from "../../assets/CafeFood/images/customer.png";
import api from "../helpers/api";
const Banner =(props)=>{
    const ratingChanged = (newRating) => {
        // console.log(newRating);
      };
    const [Banner, setBanner] = useState([]);
    const siteSettings = props.siteSettings;
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.get(`/fetch_banners`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh',
            }
          }).then(res => {
            setBanner(res.data.data[0])
            setIsLoading(false)
        });

    }, []);
    return(
        <div className="banner">
            <Container fluid className="custome-container">
                <Row>
                    <Col lg={6}>
                    <div className="border-banner-left"></div>
                        <div className="banner-paragraph">
                        <span className='banner-badge'>{Banner.small_heading}</span>
                            <h2>{Banner.title}</h2>
                            <p>{Banner.description}</p>
                            <div className="banner-block d-flex align-items-center">
                                <a className="banner-text" href="/catalog">
                                    <button className="banner-btn d-flex align-items-center me-2">
                                        Explore Food
                                    </button>
                                </a>
                                <button className="banner-button d-flex align-items-center position-relative">
                                </button>
                            </div> 
                            <div className='happy-customer'>
                                <div><img className='img-fluid ml-2 customer-img' src={customer} alt='Cafe Food'></img></div>
                                <div>
                                    <span className='order-font'>Our happy customers</span>
                                    <div className='d-flex align-items-center'>
                                        <ReactStars
                                            count={1}
                                            onChange={ratingChanged}
                                            size={24}
                                            color="#FFA934"
                                            activeColor="#FFA934"
                                        />
                                        <span className='tiny-font rating me-2'>{Banner.product_review}</span>
                                        <span className='tiny-font views'>{"("+Banner.review_count+" "+ "Reviews)"}</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="top-banner-img position-relative">
                            <img className="img-fluid" src={siteSettings.banner_image} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    )
}
export default Banner;

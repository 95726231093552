import React, {useState, useEffect} from "react";
import api from "../../../helpers/api";
const Banner = (props) => {
  const [Banner, setBanner] = useState([]);
  const siteSettings = props.siteSettings;
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.get(`/fetch_banners`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh',
            }
          }).then(res => {
            setBanner(res.data.data[0])
            setIsLoading(false)
        });

    }, []);
  return (
    <div className='banner' style={{ background: `url(${siteSettings.banner_image})`}}>
        <div className='container-width'>
            <div className='banner-content text-center'>
                <h2>{Banner.title}</h2>
                <h1>{Banner.description}</h1>
            </div>
        </div>
    </div>
  )
}

export default Banner;

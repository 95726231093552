import React, { useState, useEffect } from "react";
import useValidator from "../../../hooks/useValidator";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../helpers/api";
function ShowErrorMessage(props) {
  const messages = props.message;
  const listItems = Object.keys(messages).map((key) => {
    return <li>{props.message[key]}</li>;
  });
  return <ul>{listItems}</ul>;
}
const Description = (props) => {
  const [Reviews, setReviews] = useState([]);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [description, setDescription] = useState();
  const [validator, showValidationMessage] = useValidator();
  const [errorMsg, setErrorMsg] = useState();
  const productDetail = props.productDetail;
  const { id } = useParams();

  useEffect(() => {
    api
      .get(`/get-product-review?product_id=${id}`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setReviews(res.data.data);
      });
  }, [id]);

  function StarRating({
    count,
    value,
    inactiveColor = "#ddd",
    size = 24,
    activeColor = "#f00",
    onChange
  }) {
    // short trick
    const stars = [...Array(5).keys()].map((i) => i + 1);

    // Internal handle change function
    const handleChange = (value) => {
      onChange(value + 1);
    };

    return (
      <div>
        {stars.map((s, index) => {
          let style = inactiveColor;
          if (index < value) {
            style = activeColor;
          }
          return (
            <FontAwesomeIcon
              key={index}
              icon={faStar}
              style={{
                color: style,
                width: size,
                height: size,
                fontSize: size
              }}
              onClick={() => handleChange(index)}
            />
          );
        })}
      </div>
    );
  }

  const [rating, setRating] = useState();
  function StarRatingDemo() {
    // Get the rating from a db if required.
    // The value 3 is just for testing.

    const handleChange = (value) => {
      setRating(value);
    };
    return (
      <div className="rating-point">
        <StarRating
          count={5}
          size={27}
          value={rating}
          activeColor={"#1239A5"}
          inactiveColor={"#ddd"}
          onChange={handleChange}
        />
      </div>
    );
  }
  const submitHandler = async (e) => {
    e.preventDefault();
    e.target.myButton.disabled = true;
    if (validator.allValid()) {
      await api
        .post(
          `/store-product-review/${id}`,
          {
            name,
            email,
            rating,
            description
          },
          {
            headers: {
              "POS-API-KEY": "jd54elkrtlh"
            }
          }
        )
        .then(({ data }) => {
          if (data.status == 0) {
            if (typeof data.message === "object") {
              setErrorMsg(<ShowErrorMessage message={data.message} />);
            } else {
              toast.error(data.message);
            }
          } else {
            setErrorMsg("");
            toast.success(data.message);
            setName("");
            setEmail("");
            setDescription("");
            setRating("");
          }
        })
        .catch(({ data }) => {
          toast.error(data.message);
        });
    } else {
      showValidationMessage(true);
    }
    e.target.myButton.disabled = false;
  };

  const [activeTab, setActiveTab] = useState("pills-home-tab");
  useEffect(() => {}, [activeTab]);
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div id="shop">
      <Container>
        <div className="description">
          <Row>
            <Col lg={12}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "pills-home-tab" ? "active" : ""
                    }`}
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                    onClick={() => handleTabClick("pills-home-tab")}
                  >
                    Description
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "pills-profile-tab" ? "active" : ""
                    }`}
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                    onClick={() => handleTabClick("pills-profile-tab")}
                  >
                    Additional Information
                  </button>
                </li>
                <li className="nav-item " role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "pills-contact-tab" ? "active" : ""
                    }`}
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                    onClick={() => handleTabClick("pills-contact-tab")}
                  >
                    Reviews
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className={`tab-pane fade ${
                    activeTab === "pills-home-tab" ? "show active" : ""
                  }`}
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: productDetail.description
                    }}
                  ></p>
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "pills-profile-tab" ? "show active" : ""
                  }`}
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="table-responsive">
                    <table className="table list-table">
                      {productDetail.product_brands?.length > 0 && (
                        <tr>
                          <td className="id-color">Brand</td>
                          {productDetail.product_brands &&
                            productDetail.product_brands.map((brand, index) => (
                              <td className="s-color">{brand.brand_name}</td>
                            ))}
                          <td className="id-color"></td>
                        </tr>
                      )}

                      <tr>
                        <td className="id-color">SKU</td>
                        <td className="s-color">{productDetail.product_sku}</td>
                        <td className="id-color"></td>
                      </tr>
                      <tr>
                        <td className="id-color">Category</td>
                        {productDetail.product_types &&
                          productDetail.product_types.map((category, index) => (
                            <td className="s-color">{category.name}</td>
                          ))}
                        <td className="id-color"></td>
                      </tr>
                    </table>
                  </div>
                  {/* <div className="d-flex justify-contnet-between align-items-center">
                                      <span className="reviw-style">Brand</span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <span className="reviw-style">SKU</span>
                                      <span className="reviw-style">IBKTBI9</span>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <span className="reviw-style">Category</span>
                                      <span className="reviw-style">Kids</span>
                                    </div> */}
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "pills-contact-tab" ? "show active" : ""
                  }`}
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  {Reviews &&
                    Reviews.map((row, index) =>
                      row.is_approved == 1 ? (
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <div className="top-viewe-active d-flex align-items-center">
                            <div className="top-viewe-icon">
                              <FontAwesomeIcon
                                className="shop-img-icon"
                                icon={faAddressCard}
                              />
                              {/* <FontAwesomeIcon className="shop-img-icon" icon={faImage} /> */}
                              {/* <FontAwesomeIcon icon={faSquareUser} /> */}
                            </div>
                            <div className="top-review">
                              <div className="heading-top-review">
                                <div className="heading-top-review me-3">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="review-name">
                                      <span>{row.name}</span>
                                    </div>
                                    <div className="review-dates">
                                      <span>{row.created_at}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <StarRating
                                    count={5}
                                    size={25}
                                    value={row.rating}
                                    activeColor={"#1239A5"}
                                    inactiveColor={"#ddd"}
                                  />
                                </div>
                              </div>
                              <p>{row.description}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}

                  <div className="heading-form-review mb-4">
                    <h5 className="ms-3">Add a review</h5>
                  </div>
                  {errorMsg && (
                    <div className="alert alert-danger alert-dismissible">
                      {errorMsg}
                    </div>
                  )}
                  <Form method="post" onSubmit={submitHandler}>
                    <div className="review-form ">
                      <span className="d-flex mb-2">Your Rating</span>
                      <StarRatingDemo />
                      <span className="d-flex mb-2">Your Reviews</span>
                      <textarea
                        id="shop-description"
                        name="description"
                        rows="4"
                        cols="50"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      ></textarea>
                      <Form.Group className="mb-3" controlId="description-form">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="Name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="description-form">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <button
                        className="log-btn-description"
                        name="myButton"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default Description;


import React, { useState, useEffect } from "react";
import { Row, Col, Image } from 'react-bootstrap';
import AOS from "aos";
import api from "../../../helpers/api";
const BannerBox = () => {
    const [Eservices, setEservices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.get(`/get-services`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setEservices(res.data.data)
            setIsLoading(false)
        });
  
    }, []);
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className='banner-box container-width'>
            <Row>
            {
              Eservices ?
                Eservices.map((row, key)=>( 
                <Col md='4'>
                    <div className='banner-box-content d-flex' data-aos="flip-left" data-aos-duration="1500" key={row?.id}>
                        <div className='banner-box-content-image me-4'>
                            <Image src={row.image} fluid />
                        </div>
                        <div className='banner-box-content-info'>
                            <h6>{row.title}</h6>
                            <p className='mb-0'>{row.description}</p>
                        </div>
                    </div>
                </Col>
                ))
                : "No services Found"
            }
            </Row>
        </div>
    )
}

export default BannerBox;;

import React from "react";
import { Container,Row,Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Wishlist from './../Wishlist';

const Bread =()=>{
    const link = window.location.href;
    const lastSegment = link.split("/").pop();
    const splitting = lastSegment.split('my').slice(0,2).join('');
    const breadName = splitting.charAt(0).toUpperCase() + splitting.slice(1);
    return(
        <div id="bread" className="mb-4">
            <Container>
                <Row>
                    <Col>
                        <div className="top-bar">
                            <div>
                                <span>{breadName}</span>
                            </div>
                            <div aria-label="breadcrumb ">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link className="text-decorarion" to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{breadName}</li>
                                </ol>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
     </div>
    )
}
export default Bread;
import React, { useState, useEffect } from "react";
import { Container, Row, Col, InputGroup, Form } from "react-bootstrap";

import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useValidator from '../../hooks/useValidator';
import api from "../../helpers/api";
function ShowErrorMessage(props) {

    const messages = props.message;
    const listItems = Object.keys(messages).map((key) => {

      return (<li>{props.message[key]}</li>)
    })
    return (

      <ul>{listItems}</ul>
    );

}
const TopFooter = () => {
    const [email, setEmail] = useState();
    const [validator, showValidationMessage] = useValidator()
    const [errorMsg, setErrorMsg] = useState();
    const [successMsg, setSuccessMsg] = useState();
    useEffect(() => {
        setTimeout(() => {
            // After 3 seconds set the show value to false
            setErrorMsg("");
            setSuccessMsg("")
        }, 3000)
    }, [errorMsg,successMsg])
    const submitHandler = async (e) => {

        e.preventDefault();
        if (validator.allValid())
        {
            await api.post(`/subscribe`, {
                email

            }, {
                headers: {
                'POS-API-KEY' : 'jd54elkrtlh'
                }
            }).then(res => {
                if (res.data.status == 0) {
                    if(typeof res.data.message === 'object')
                    {
                        setErrorMsg(<ShowErrorMessage message={res.data.message} />)
                    }
                    else
                    {
                        setErrorMsg(res.data.message);
                    }
                    setSuccessMsg("")
                    // toast.info(res.data.message);
                }
                else {
                    setErrorMsg("");
                    setEmail("");
                    setSuccessMsg(res.data.message);
                    // toast.success(res.data.message);
                }

            }).catch((res) => {

                toast.error("Something went wrong");

            })
        }
        else
        {
            showValidationMessage(true);
        }
    }
    return (
        <div id="newsletter-subscriber">
            <div id="top-footer">
            <ToastContainer/>
                <Container>
                    <Row className="align-items-center">
                        <Col xl={6}>
                            <div>
                                <h2>Keep In Touch For Latest Updates</h2>
                                <span>Save up to 45% off on your next order</span>
                            </div>
                        </Col>
                        <Col xl={6}>
                        {errorMsg && <div className="alert alert-danger alert-dismissible-footer">
                        {errorMsg}</div>}
                        {successMsg && <div className="alert alert-success">
                        {successMsg}</div>}
                            <form method="post" onSubmit={submitHandler}>
                                <div className="wrap">
                                    <div className="search">
                                        <InputGroup className="mb-lg-3 mb-0">
                                            <Form.Control className="searchTerm" placeholder="Enter your email" aria-label="Username" aria-describedby="basic-addon1"
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value) }}
                                            />
                                        </InputGroup>
                                        <button type="submit" className="searchButton">
                                            Subscribe
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>


    )
}
export default TopFooter;

import React, {useState, useEffect} from "react";
import Header from '../Header/Header'
import Banner from './Banner/Banner'
import JewelrySelection from './Jewelry-selection/Jewelry-selection'
import LuxuryProduct from './Luxury-Product/Luxury-Product'
import CustomizeJewl from './Customize-Jewl/Customize-Jewl'
import JewelrySelection2 from './Jewelry-selection2/Jewelry-selection2'
import Subscriber from './Subscriber/Subscriber'
import Footer from '../Footer/Footer'
import { ToastContainer, toast } from 'react-toastify';
import api from "../../helpers/api";
function HomePage() {
    // Set Home Page Products
    const [newArrivalProducts, setNewArrivalProducts] = useState([]);
    const [mostLovedProducts, setmostLovedProducts] = useState([]);
    const [topProducts, settopProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // Set Wishlist Counter 
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const [wishlist, setWishlist] = useState(wishlist_items);
    if(wishlist == undefined)
    {
        setWishlist([]);
    }
    // Get Site Settings Detail
    const [siteSettings, setSiteSettings] = useState([]);
    useEffect(() => {
        api.get(`/get-site-settings`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setSiteSettings(res.data.data)
          setIsLoading(false)
      });
    }, []);
    
    useEffect(() => {
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
      // Get All Products
      api.get(`/get-all-products`, {
        headers: {
          'POS-API-KEY' : 'jd54elkrtlh'
        }
      }).then(res => {
        setAllProducts(res.data.data)
      }); 
      // Get Top Products
      setIsLoading(true)
      api.get(`/get-top-products`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          settopProducts(res.data.data)
          setIsLoading(false)
      });
      // Get New Products
      setIsLoading(true)
      api.get(`/get-new-products`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setNewArrivalProducts(res.data.data)
          setIsLoading(false)
      });
      // Get People Loved Products
      setIsLoading(true)
      api.get(`/get-people-loved-products`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setmostLovedProducts(res.data.data)
          setIsLoading(false)
      });
    }, [wishlist]);
    // Add Item to Wishlist
    const addToWishlist = (id) => {
        toast.dismiss();
        const check_wishlist = JSON.stringify(wishlist.find((item) =>
        item.id === id
        ));
      
        if (!check_wishlist) {
            const product = allProducts.find((item) => item.id === id);
  
            setWishlist(current  => [...current , product]);
  
            toast.success("Wishlist Added Successfully", {
              autoClose: 1000
            });
        } else {
            let items =JSON.parse(localStorage.getItem("wishlist"));
            items = items.filter((item) => item.id !== id);
            localStorage.setItem("wishlist", JSON.stringify(items));
            if (items.length === 0) {
              localStorage.removeItem("item");
            }
            setWishlist(items);
            toast.error("Item is removed from wishlist", {
              autoClose: 1000
            });
        }
  
    };
  return(
    <>
        <div className="wrapper">
      <ToastContainer/>
      <Header />
      <Banner siteSettings={siteSettings}/>
      <JewelrySelection topProducts= {topProducts}  isLoading={isLoading} addToWishlist={addToWishlist}/>
      <LuxuryProduct siteSettings={siteSettings}/>
      <CustomizeJewl siteSettings={siteSettings} newArrivalProducts= {newArrivalProducts} isLoading={isLoading} addToWishlist={addToWishlist}/>
      {/* <Gallery /> */}
      <JewelrySelection2 mostLovedProducts= {mostLovedProducts} isLoading={isLoading} addToWishlist={addToWishlist}/>
      <Subscriber />
      <Footer />
    </div>
    </>
  )
}

export default HomePage;
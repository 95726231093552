import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Categorydata from '../Categorydata/Categorydata';
import { SpinnerRoundOutlined, SpinnerCircularFixed} from 'spinners-react';

const Categories = (props) => {
    const categories = props.categories;
    const first = categories[0];
    const Typename = first?.name;
    const productTypeName = props.productTypeName == "" ? Typename : props.productTypeName;
    const products = props.products;
    const [isLoading, setIsLoading] = useState(false);
    const addToWishlist = props.addToWishlist;
    const wishlist = props.wishlist;
    const refProp = props.refProp;
    return (
        <div className="banner-margin">
            <Container>
                <div id="catagories">
                    <Row>
                        <div className="col-12">
                            <div className="h-title">
                                <h2>Browse Our Hottest Categories</h2>
                            </div>
                        </div>
                    </Row>
                    {isLoading ?
                        <Row>
                            <div className="text-center pt-4 mb-4">
                                {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                                <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                            </div>
                        </Row> :
                        <Row className="">
                            {categories.map((item, index) => (
                                <Col xl={2} sm={4} className="">
                                    <Link to="/" className={(item.id == props.productTypeID || item.name == productTypeName ? "text-center d-block cat-pad active" : "text-center d-block cat-pad")} onClick={() => { props.fetchProduct(item.id,item.name); }}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                                            <g id="Group_41" data-name="Group 41" transform="translate(-164.093 570.528)">
                                                <g id="Group_33" data-name="Group 33">
                                                    <path id="Path_26" data-name="Path 26"
                                                        d="M205.546-512.117H167.827a3.738,3.738,0,0,1-3.734-3.734v-50.943a3.738,3.738,0,0,1,3.734-3.734H235.16a3.738,3.738,0,0,1,3.734,3.734v2.981h-3v-2.981a.735.735,0,0,0-.734-.734H167.827a.735.735,0,0,0-.734.734v50.943a.735.735,0,0,0,.734.734h37.719Z"
                                                        fill="#1239A5" />
                                                </g>
                                                <g id="Group_34" data-name="Group 34">
                                                    <rect id="Rectangle_39" data-name="Rectangle 39" width="39.953"
                                                        height="3" transform="translate(165.593 -524.048)" fill="#1239A5" />
                                                </g>
                                                <g id="Group_35" data-name="Group 35">
                                                    <path id="Path_27" data-name="Path 27"
                                                        d="M205.546-500.193H185.2a1.5,1.5,0,0,1-1.483-1.272,1.5,1.5,0,0,1,1.032-1.659,23.091,23.091,0,0,0,5.325-2.554c.385-.267,1.289-.895,1.854-4.454l.591-3.72,2.963.47-.591,3.721c-.675,4.249-1.949,5.644-3.106,6.448l-.029.02h13.793Z"
                                                        fill="#1239A5" />
                                                </g>
                                                <g id="Group_36" data-name="Group 36">
                                                    <path id="Path_28" data-name="Path 28"
                                                        d="M200.9-519.614a1.516,1.516,0,0,0-1.516,1.516,1.515,1.515,0,0,0,1.516,1.515,1.515,1.515,0,0,0,1.516-1.515,1.516,1.516,0,0,0-1.516-1.516Z"
                                                        fill="#1239A5" />
                                                </g>
                                                <g id="Group_37" data-name="Group 37">
                                                    <path id="Path_29" data-name="Path 29"
                                                        d="M226.56-535.441a1.992,1.992,0,0,0-1.992,1.992,1.992,1.992,0,0,0,1.992,1.993,1.992,1.992,0,0,0,1.992-1.993,1.992,1.992,0,0,0-1.992-1.992Z"
                                                        fill="#1239A5" />
                                                </g>
                                                <g id="Group_38" data-name="Group 38">
                                                    <path id="Path_30" data-name="Path 30"
                                                        d="M238.093-559.252a3,3,0,0,1,3,3v59.724a3,3,0,0,1-3,3H215.027a3,3,0,0,1-3-3v-59.724a3,3,0,0,1,3-3h23.066m0-3H215.027a6,6,0,0,0-6,6v59.724a6,6,0,0,0,6,6h23.066a6,6,0,0,0,6-6v-59.724a6,6,0,0,0-6-6Z"
                                                        fill="#1239A5" />
                                                </g>
                                                <g id="Group_39" data-name="Group 39">
                                                    <path id="Path_31" data-name="Path 31"
                                                        d="M237.717-550.471H215.4v3.188h22.315v-3.188Z" fill="#1239A5" />
                                                </g>
                                                <g id="Group_40" data-name="Group 40">
                                                    <path id="Path_32" data-name="Path 32"
                                                        d="M237.717-544.343H215.4v3.188h22.315v-3.188Z" fill="#1239A5" />
                                                </g>
                                            </g>
                                        </svg> */}
                                        <div className="categorie-image">
                                        <img src={item.image} alt="" />
                                        </div>
                                        <h4>{item.name}</h4>
                                        <span>{item.pCount + " " + "items"}</span>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    }
                </div>
            </Container>
           <Categorydata refProp={refProp} products={products} wishlist={wishlist} addToWishlist={addToWishlist} isLoading={isLoading}/>

        </div>

    )
}
export default Categories;

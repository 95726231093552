import React from "react";
import { Container, Row, Col,Tooltip } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import dinning from "../../../assets/Furniture/images/dinning chair.png";
import {faStar} from "@fortawesome/free-solid-svg-icons"

const NonSliderProducts = (props) => {
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : "Rs";
    const wishlist = props.wishlist == undefined ? wishlist_items : props.wishlist;

    function StarRating({count, value, 
        inactiveColor='#ddd',
        size=20,
        activeColor='#f00', onChange}) {
    
      // short trick 
      const stars = Array.from({length: count}, () => '🟊')
  
      return (
        <div>
          {stars.map((s, index) => {
            let style = inactiveColor;
            if (index < value) {
              style=activeColor;
            }
            return (
                <FontAwesomeIcon icon={faStar} style={{color: style, width:size, height:size, fontSize: size, marginLeft: "-6px",}}/>
            )
          })}
        </div>
      )
    }
    return (
        <div className="slider-count-wrape d-flex">
            {props.products.map((row, index) => (

                <div className="slider-cont not-slick-slider ">
                
                <div className="position-relative">
                    <Link className="product-text" to={`/shop/${row.id}`}>
                    <div className="new-image-single">
                        <img className="img-fluid" src={row.image} alt=""/>
                    </div>
                    </Link>
                    { row.display_name !== null ?
                    <div className="discount-tag">
                        <span>{row.display_name}</span>
                    </div>
                    :
                    ''
                    }
                    <div className="heart-icon" key={index} data-id={row.id} onClick={() => { props.addToWishlist(row.id); }}>
                    {
                            (JSON.stringify(wishlist.find((item) => item.id === row.id))) ?
                            <svg xmlns="http://www.w3.org/2000/svg" className="top-product-svg" width="20" height="20"
                            viewBox="0 0 27 23">
                            <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                transform="translate(-0.823 -2.997)" fill="none"
                                stroke="#3f5b6c" strokeLinecap="round" strokeLinejoin="round"
                                strokeWidth="3" />
                        </svg>

                            : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                            viewBox="0 0 27 23">
                            <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                transform="translate(-0.823 -2.997)" fill="none"
                                stroke="#3f5b6c" strokeLinecap="round" strokeLinejoin="round"
                                strokeWidth="3" />
                        </svg>

                        }
                    </div>
                </div>
                <Link className="product-text" to={`/shop/${row.id}`}>
                <div className="top-text-active not-text-active ">
                    <div className="shop-items-product">
                       {/* <Tooltip title={row.product_name}>
                           <h3>{row.product_name}</h3>
                        </Tooltip> */}
                        <h3 className="heading-newarival-slider" title={row.product_name}>{row.product_name}</h3>
                        <div className="starRating mb-3">
                            <StarRating
                            count={5}
                            size={20}
                            value={row.product_reviews}
                            activeColor ={'#FEBE37'}
                            inactiveColor={'#ddd'}/>
                        </div>
                        {
                            row.discount_offer_id != null ?
                            <div className="newarrival-slider-price">
                            <span className="d-price"><del><span className="currency-font">{currency + " "}</span>{row.price}</del> | </span>
                            <span className="r-price"><span className="currency-font">{currency + " "}</span>{row.price - row.discounted_amount}</span>
                            </div>
                            :
                            <div className="newarrival-slider-price">
                                <span className="currency-font">{currency + " "}</span>
                                <span>{row.price}</span>
                            </div>
                        }
                    </div> 
                </div>
                </Link>
                </div>
            ))}
        </div>
    )
}
export default NonSliderProducts;

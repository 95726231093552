import React from "react";
import Header from "../Header/Header";
import Middlefooter from "../Middlefooter/Middlefooter";
import Topfooter from "../Topfooter/Topfooter";
import Shipping from "./Shipping";

const Shippingmethod=()=>{
    return(

        <div className="wrapper">
            <Header/>
            <Shipping/>
            <Topfooter/>
            <Middlefooter/>
        </div>
    )
}
export default Shippingmethod;
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SpinnerCircularFixed } from "spinners-react";
const About = (props) => {
    return (
        <div className="About">
            <div className="about-middle">
                <Container>
                    <Row></Row>
                    {props.isLoading ? (
                        <Row>
                            <div className="text-center pt-4 mb-4">
                                <SpinnerCircularFixed
                                    size={50}
                                    thickness={100}
                                    speed={100}
                                    color="#1239A5"
                                    secondaryColor="rgba(0, 0, 0, 0.44)"
                                />
                            </div>
                        </Row>
                    ) : (
                        <Row>
                            <Col>
                                <div className="about-middle">
                                    <div className="middle-about-heading">
                                        <h2>{props.AboutusPages.title}</h2>
                                    </div>
                                    <div
                                        className="about-paragraph"
                                        dangerouslySetInnerHTML={{
                                            __html: props.AboutusPages.content,
                                        }}
                                    ></div>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </div>
    );
};
export default About;

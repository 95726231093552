import React from "react";
import TopHeader from '../../sections/TopHeader/TopHeader';
import MiddleHeader from '../../sections/MiddleHeader/MiddleHeader';
import BottomHeader from '../../sections/BottomHeader/BottomHeader';
import Shipping from "./Shipping";
import MiddleFooter from '../../sections/MiddleFooter/Middlefooter';
import BottomFooter from '../../sections/BottomFooter/BottomFooter';

const ShippingMethod =()=>{
    return(
        <div className="wrapper">
            <TopHeader/>
            <MiddleHeader/>
            <BottomHeader/>
            <Shipping/>
            <MiddleFooter/>
            <BottomFooter/>
        </div>
    )
}
export default ShippingMethod;

import React, {useState, useEffect} from "react";
import { Container,Row,Col,InputGroup,Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import useValidator from '../../hooks/useValidator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../helpers/api";
function ShowErrorMessage(props) {
  const messages = props.message;
  const listItems = Object.keys(messages).map((key) => {
    return (<li>{props.message[key]}</li>)
  })
  return (
    <ul>{listItems}</ul>
  );
}
const Vocher = () => {
    const [track_order, setTrackOrder] = useState([]);
    const [validator, showValidationMessage] = useValidator()
    const [SalesOrderProducts, setSalesOrderProducts] = useState([])
    const [errorMsg, setErrorMsg] = useState();
    useEffect(() => {
      setTimeout(() => {
          // After 3 seconds set the show value to false
          setErrorMsg("");
      }, 3000)
  }, [errorMsg])
    const navigate = useNavigate();
    useEffect(() => {
      const storedList = localStorage.getItem("order_id");

      if (storedList) {
        localStorage.setItem('order_id', []);
      }
      }, []);
    const submitHandler = async (e) => {

        e.preventDefault();

        if (validator.allValid()) {
          await api.post(`/get-track-order`, {
            track_order,
          }, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(({ data }) => {

            if (data.status == 0) {
              if(typeof data.message === 'object')
              {
                setErrorMsg(<ShowErrorMessage message={data.message} />)
              }
              else
              {
                setErrorMsg(data.message);
              }
            }
            else {
                setErrorMsg("");
                navigate("/orderdetail/" + data.sale_id)
            }

          }).catch(({ data }) => {

            toast.error(data.message);

          })

        } else {
          showValidationMessage(true);
        }
      }
    return (
        <div className="track-order">
           <div id="login-data">
          <ToastContainer/>
            <Container>
                <Row>
                    <Col>
                        <div class="d-flex justify-content-center form-outside">
                            <div class="login-form text-center ">
                                {errorMsg && <div className="alert alert-danger alert-dismissible">
                                {errorMsg}</div>}
                                <Form method="post" onSubmit={submitHandler}>
                                    <span class="head">
                                        Track Order Details
                                    </span>
                                    <div class="email-inp">
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                            placeholder="Enter Order ID"
                                            aria-label="Order;"
                                            aria-describedby="basic-addon1"
                                            value={track_order}
                                            onChange={(e) => { setTrackOrder(e.target.value) }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <button class="log-btn-tracking" type="submit">Submit</button>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
         </div>
        </div>
       
    );
};
export default Vocher;

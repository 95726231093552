import React, { useState, useEffect } from "react";
import './Subscriber.css'
import Container from 'react-bootstrap/Container';
import mail from '../../../../assets/Jewellery/images/icons/mail.svg'
import { toast } from 'react-toastify';
import api from "../../../helpers/api";
function ShowErrorMessage(props) {

  const messages = props.message;
  const listItems = Object.keys(messages).map((key) => {

    return (<li>{props.message[key]}</li>)
  })
  return (

    <ul>{listItems}</ul>
  );

}
function Subscriber() {
  const [email, setEmail] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  useEffect(() => {
    setTimeout(() => {
        // After 3 seconds set the show value to false
        setErrorMsg("");
        setSuccessMsg("")
    }, 3000)
}, [errorMsg,successMsg])
  const submitHandler = async (e) => {

    e.preventDefault();

    await api.post(`/subscribe`, {
        email

    }, {
        headers: {
          'POS-API-KEY' : 'jd54elkrtlh'
        }
      }).then(res => {

        if (res.data.status == 0) {
          if(typeof res.data.message === 'object')
          {
              setSuccessMsg("")
              setErrorMsg(<ShowErrorMessage message={res.data.message} />)
          }
          else
          {
              setSuccessMsg("")
              setErrorMsg(res.data.message);
          }

        }
        else {
          setErrorMsg("");
          setEmail("");
          setSuccessMsg(res.data.message);
        }

    }).catch((res) => {

          toast.error("Something went wrong");

    })

}
  return(
    <>
        <div className='subscriber'>
          <Container fluid className='custom_container'>
            <div className='subscribe-flex'>
              <div className='subscribe-form-title'>
                <div className='d-flex align-items-lg-end align-items-center'>
                  <figure className='me-lg-4 me-2 mb-0'>
                    <img className='img-fluid mail-img' src={mail} alt='Subscribe'></img>
                  </figure>
                  <h2 className='mb-0'>Subscribe To Newsletter</h2>
                </div>
              </div>
              <div className='main-subscribe-form'>
                {
                errorMsg &&
                    <div className="alert alert-danger alert-dismissible footer">
                    {errorMsg}</div>
                }
                {successMsg && <div className="alert alert-success footer">
                {successMsg}</div>}
                <form className='subscribe-form' method="post" onSubmit={submitHandler}>
                      <label>
                          <input type="text" placeholder="YOUR EMAIL" onChange={(e) => { setEmail(e.target.value) }} value={email}/>
                      </label>
                      <div className="theme-btn">
                        <button className='black-btn' type='submit'><span>Subscribe</span></button>
                      </div>
                  </form>
              </div>
            </div>
          </Container>
        </div>
    </>
  )
}

export default Subscriber;
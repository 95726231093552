import React from "react";
import { Container,Row,Col } from "react-bootstrap";
import footer from "../../../assets/Furniture/images/footerimg.png";

const Topfooter =()=>{
    return(
        <div className="top-footer">
            <Row>
                <Col lg={3} md={6} sm={6}>
                    <div className="d-flex align-items-center text-align-item-center">
                        <div className="footer-img">
                            <img src={footer} alt="" />
                        </div>
                        <div className="heading-footer">
                            <h2>Subscribe for new updates</h2>
                        </div>
                    </div>

                </Col>
                <Col lg={3} md={6} sm={6}>
                <div className="d-flex align-items-center text-align-item-center">
                        <div className="footer-img">
                            <img src={footer} alt="" />
                        </div>
                        <div className="heading-footer">
                            <h2>Subscribe for new updates</h2>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={6} sm={6}>
                    <div className="d-flex align-items-center text-align-item-center">
                            <div className="footer-img">
                                <img src={footer} alt="" />
                            </div>
                            <div className="heading-footer">
                                <h2>Subscribe for new updates</h2>
                            </div>
                        </div>
                </Col>
                <Col lg={3} md={6} sm={6}>
                    <div className="d-flex align-items-center text-align-item-center">
                            <div className="footer-img">
                                <img src={footer} alt="" />
                            </div>
                            <div className="heading-footer">
                                <h2>Subscribe for new updates</h2>
                            </div>
                        </div>
                </Col>
                
            </Row>
        </div>

    )
}
export default Topfooter;
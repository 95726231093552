import React from 'react'

const ArrivalWrapper = (props) => {
    const siteSettings = props.siteSettings;
    return (
        <div className='arrival-wrapper' style={{ background: `url(${siteSettings.default_image_1})`}}>

        </div>
    )
}

export default ArrivalWrapper

import React from 'react';
import './Luxury-Product.css'
import Container from 'react-bootstrap/Container';
function LuxuryProduct(props) {
  const siteSettings = props.siteSettings;
  return(
    <>
        <div className='section'>
          <div className='luxury-product'>
            <Container fluid className='custom_container'>
              <div className='row align-items-center'>
                <div className='col-lg-6 mb-lg-0 mb-3'>
                  <div className='wrap-text'>
                    <h2>We Create Luxury Products</h2>
                    <p>Vestibulum commodo sapien non elit porttitor, vitae volutpat nibh mollis. Nulla porta risus id neque tempor, in efficitur justo imperdiet. Etiam a ex at ante tincidunt imperdiet. Nunc congue ex vel nisl viverra, sit amet aliquet lectus ullamcorper.</p>
                    <div className="theme-btn">
                      <a href='/catalog' className='black-btn btn-mt'><span>SHOP NOW</span></a>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 mb-lg-0 mb-3'>
                  <div className='wrap-img'>
                   <figure className='luxury-img'>
                    <img className='img-fluid' src={siteSettings.default_image_1} alt='Engagement Rings'></img>
                   </figure>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
    </>
  )
}

export default LuxuryProduct;
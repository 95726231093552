import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { SpinnerCircularFixed} from 'spinners-react';
import SliderProducts from './../SliderComponents/SliderProducts';
import NonSliderProducts from './../SliderComponents/NonSliderProducts';
const Categorydata = (props) => {
    const products = props.products;
    const wishlist = props.wishlist;
    const isLoading = props.isLoading;
    const addToWishlist = props.addToWishlist;
    const refProp = props.refProp;
    return (
        <div className="banner-margin">
            <Container>
                <div id="catagory-data" ref={refProp}>
                    {isLoading ?
                        <Row>
                            <div className="text-center pt-4 mb-4">
                                <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                            </div>
                        </Row> :
                        <Row>
                            <Col>
                                {products.length > 0 ?
                                    products.length > 3 ?
                                    <SliderProducts products = {products} addToWishlist={addToWishlist} wishlist = {wishlist}/>
                                    :
                                    <NonSliderProducts products = {products} addToWishlist={addToWishlist} wishlist = {wishlist}/>
                                : <p className="category-data d-flex justify-content-center">No Product found in this Category</p>
                                }
                            </Col>
                        </Row>
                    }
                </div>
            </Container>
        </div>

    )
}
export default Categorydata;

import React, {useState, useEffect} from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { SpinnerCircularFixed} from 'spinners-react';
import api from "../../../helpers/api";
function Order(){
    const [SaleProducts, setSaleProducts] = useState([])
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        fetchSaleProducts()
    },[])

    const fetchSaleProducts = async (pageNumber = 1) => {
        const customer = JSON.parse(localStorage.getItem('userData'));
        setIsLoading(true)
        api.get(`/get-sale-products?user_id=${customer.user_id}&page=${pageNumber}`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setSaleProducts(res.data.data)
            setData(res.data.meta)
            setIsLoading(false)
        });
    };
    return(

        <div id="my-order">
            <Container>
                {isLoading ?
                    <Row>
                        <div className="text-center pt-4 mb-4">
                            <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                        </div>
                    </Row> :
                <div className="table-responsive">
                    <table className="table list-table">

                    <thead>
                    <tr className="order-table">
                        <th>Order No</th>
                        <th>Track No</th>
                        <th>Order Date</th>
                        <th>Location</th>
                        <th>Status</th>
                        <th>Action</th>
                   </tr>
                    </thead>
                   <tbody>
                    {

                        SaleProducts.length > 0 ? (
                            SaleProducts.map((row, key)=>(
                                <tr key={key}>
                                    <td className="id-color">{"#"+row.sale_id}</td>
                                    <td>{row.track_no}</td>
                                    <td className="s-color">{row.created_at}</td>
                                    {/* <td>{row.product_name}</td> */}
                                    <td>{row.customer_city || row.customer_country ?row.customer_address+ ' , ' + row.customer_city + ' , ' + row.customer_country : ''}</td>
                                    {/* <td>{row.qty}</td> */}
                                    {
                                        row.payment_status == 2 && row.fullfillment_status == 12 ?<td className="rej-color">Pending</td>:<td className="comp-color">Completed</td>
                                    }
                                    <td><Link to={`/orderdetail/${row.id}`}><button className="view-btn">View Order Detail</button></Link></td>
                                </tr>
                            ))
                        ):  <tr>
                               <td colspan="6">
                               <p className="text-center">No order Found</p>
                               </td>
                           </tr>
                    }
                     </tbody>
                    </table>
                </div>
                }
                {
                        SaleProducts.length > 0 &&
                        <Pagination
                            activePage={data?.current_page ? data?.current_page : 0}
                            itemsCountPerPage={data?.per_page ? data?.per_page : 0 }
                            totalItemsCount={data?.total ? data?.total : 0}
                            onChange={(pageNumber) => {
                                fetchSaleProducts(pageNumber)
                            }}
                            pageRangeDisplayed={5}
                            itemClass="page-item"
                            linkClass="page-link"
                            firstPageText="First Page"
                            lastPageText="Last Lage"
                        />
                }
            </Container>
       </div>

    )
}
export default Order

import React, {useState, useEffect} from "react";
import TopFooter from "./../../sections/TopFooter/TopFooter";
import TopHeader from "./../../sections/TopHeader/TopHeader";
import MiddleHeader from "./../../sections/MiddleHeader/MiddleHeader";
import BottomHeader from "./../../sections/BottomHeader/BottomHeader";
import MiddleFooter from "./../../sections/MiddleFooter/Middlefooter";
import BottomFooter from "./../../sections/BottomFooter/BottomFooter";
import Vocher from "./Vocher";
import SalonHeader from "../../../HairBeauty/BottomHeader/BottomHeader";
import SalonTopFooter from "../../../HairBeauty/TopFooter/TopFooter";
import SalonMiddleFooter from "../../../HairBeauty/MiddleFooter/Middlefooter";
import SalonBottomFooter from "../../../HairBeauty/BottomFooter/BottomFooter";
import api from "../../../helpers/api";
const Recipt =()=>{
    const [showContent, setShowContent] = useState(0);
    const NewThemeID = JSON.parse(localStorage.getItem('ThemeID'));
    const [themeId, setThemeId] = useState(NewThemeID);
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setThemeId(res.data.data.store.theme_id)
            localStorage.setItem('ThemeID', res.data.data.store.theme_id);
        });
    }, []);
    useEffect(() => {
        const TotalQty = JSON.parse(localStorage.getItem('totalQuantity')); 
        setShowContent(TotalQty)
    }, []);
    return(
        <div className="wrapper">
            {themeId == 1 && <TopHeader/>}
            {themeId == 1 && <MiddleHeader showContent={showContent}/>}
            {themeId == 1 && <BottomHeader/>}
            {themeId == 8 && <SalonHeader/>}
            <Vocher/>
            {themeId == 1 && <TopFooter/>}
            {themeId == 1 && <MiddleFooter/>}
            {themeId == 1 && <BottomFooter/>}
            {themeId == 8 && <SalonTopFooter/>}
            {themeId == 8 && <SalonMiddleFooter/>}
            {themeId == 8 && <SalonBottomFooter/>}
        </div>

    )
}
export default Recipt;
import React, { useState, useEffect } from "react";
import './Jewelry-selection.css'
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
function JewelrySelection(props) {
  const currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : "Rs";
  const topProducts= props.topProducts;
  const addToWishlist = props.addToWishlist;
  const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
  return(
    <>
        <div className='section'>
          <div className='jewelry-selection'>
            <Container fluid className='custom_container'>
              <div className='wrap-text text-center'>
                  <h2>Top in Jewelry Selection</h2>
                  <p>Vestibulum commodo sapien non elit porttitor, vitae volutpat nibh mollis. Nulla porta risus id neque tempor, in efficitur justo imperdiet. Etiam a ex at ante tincidunt imperdiet. Nunc congue ex vel nisl viverra, sit amet aliquet lectus ullamcorper.</p>
              </div>
              <div className='row'>
                  {topProducts.length > 0 ?
                    // topProducts.length > 4 ?
                    // <SliderProducts products = {topProducts} addToWishlist={addToWishlist} wishlist={wishlist}/>
                    // :
                    // <NonSliderProducts products = {topProducts} addToWishlist={addToWishlist} wishlist={wishlist}/>
                    topProducts.slice(0,4).map((row, index) => (
                      <Card className='jewelry-products non-jewelery-product'>
                        <Card.Body>
                        <Link className="product-text" to={`/shop/${row.id}`}>
                        <div className='card-img'>
                          <figure className='mb-0'>
                            <img src={row.image} alt='Jwelery Product'></img>
                          </figure>
                        </div>
                        </Link>
                        { row.display_name !== null ?
                          <div className="discount-tag">
                              <span>{row.display_name}</span>
                          </div>
                          :
                          ''
                        }
                        <div className="heart-icon" key={index} data-id={row.id} onClick={() => { addToWishlist(row.id); }}>
                          {
                                  (JSON.stringify(wishlist_items.find((item) => item.id === row.id))) ?
                                  <svg xmlns="http://www.w3.org/2000/svg" className="top-product-svg" width="20" height="20"
                                  viewBox="0 0 27 23">
                                  <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                      d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                      transform="translate(-0.823 -2.997)" fill="none"
                                      stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="3" />
                              </svg>
          
                                  : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                  viewBox="0 0 27 23">
                                  <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                      d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                      transform="translate(-0.823 -2.997)" fill="none"
                                      stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="3" />
                              </svg>
          
                              }
                          </div>
                          <Link className="product-text" to={`/shop/${row.id}`}>
                          <Card.Footer className='text-center'>
                            <p>{row.product_name}</p>
                            {
                            row.discount_offer_id != null ?
                            <div>
                            <span><del>{currency +" "+row.price}</del> | </span>
                            <span>{currency +" "+row.price - row.discounted_amount}</span>
                            </div>
                            :
                            <span>{currency +" "+row.price}</span>
                            }
                          </Card.Footer>
                          </Link>
                        </Card.Body>
                      </Card>
                   
                  ))
                : <p className="category-data d-flex justify-content-center">No Product found in this Category</p>
                  }
              </div>
            </Container>
          </div>
        </div>
    </>
  )
}

export default JewelrySelection;
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import api from "../../helpers/api";
const Medorawood=(props)=>{
    // Fetch All Categories
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        api.get(`/get-product-type`, {
            headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
            }
        }).then(res => {
            setCategories(res.data.data)
            setIsLoading(false)
        });
    }, []);
    return(
      <div className="Medorawood">
        <Container fluid className="custome-container">
                <Row>
                    <Col>
                    <div className="medorawood-heading">
                        <h2>See Furniture Categories</h2>
                    </div>
                    </Col>
                </Row>
                <Row>
                    {
                    categories.slice(0,4).map((item, index) => (
                    <Col lg={3} md={4}>
                        <a className="medorawooed-deco" href={`/catalog?type=${item.name}`}>
                        <div className="medorawooed-img">
                        <img className="img-fluid" src={item.image} alt="" />
                        </div>
                        <h2 className="medorawooed-img-h2">{item.name}</h2>
                        </a>
                    </Col>
                    ))
                    }
                    <div className="button-medorawood">
                    <Link to="/catalog" className="medorawood-button">Explore by Categories</Link>
                    </div>
                </Row>
        </Container>
     </div>
    )
}
export default Medorawood;

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import api from "../../../helpers/api";
const BottomFooter = () => {
    const [siteSettings, setSiteSettings] = useState([]);
    const [CmsPages, setCmsPages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.get(`/get-site-settings`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setSiteSettings(res.data.data);
                setIsLoading(false);
            });
        api.get(`/fetch_cms_pages`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCmsPages(res.data.data);
                setIsLoading(false);
            });
    }, []);

    // Replace the site settings copyright with current year
    const originalString = siteSettings?.site_copyright;
    const currentYear = new Date().getFullYear(); 
    const copyright = originalString?.replace(/\d{4}/, currentYear);

    return (
        <div className="middle-footer">
            <div className="container-width">
                <Row>
                    <Col>
                        <div className="footer-logo">
                            {/* <img src={siteSettings.logo} alt="" /> */}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} sm={6}>
                        <div className="sec2">
                            <span>Service</span>
                            <ul className="list-unstyled mb-0">
                                {CmsPages != ""
                                    ? CmsPages.map((row, key) => (
                                          <li>
                                              <Link to={"/cms/" + row.slug}>
                                                  {row.title}
                                              </Link>
                                          </li>
                                      ))
                                    : ""}
                            </ul>
                        </div>
                    </Col>
                    <Col lg={4} sm={6}>
                        <div className="sec2">
                            <span>Quick links</span>
                            <ul className="list-unstyled mb-0">
                                <li>
                                    <a href="/catalog">All Brands</a>
                                </li>
                                <li>
                                    <a href="/catalog">All Product Types</a>
                                </li>
                                <li>
                                    <a href="/catalog">All Product Tags</a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={4} sm={6}>
                        <div className="sec2">
                            <span>Help</span>
                            <ul className="list-unstyled mb-0">
                                <li>
                                    <a href="/cms/about-us">Support</a>
                                </li>
                                <li>
                                    <a href="/faq">FAQ</a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <ul className="footer-social p-0 d-flex align-items-center justify-content-center">
                        <li>
                            <a
                                href={siteSettings.fb_social_url}
                                className="d-flex align-items-center justify-content-center"
                                target="_blank"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="24"
                                    viewBox="0 0 12 24"
                                >
                                    <path
                                        id="Icon_awesome-facebook-f"
                                        data-name="Icon awesome-facebook-f"
                                        d="M12.823,13.5l.622-4.343H9.555V6.338a2.1,2.1,0,0,1,2.286-2.347h1.769V.293A20.182,20.182,0,0,0,10.47,0c-3.2,0-5.3,2.08-5.3,5.846v3.31H1.609V13.5H5.171V24H9.555V13.5Z"
                                        transform="translate(-1.609)"
                                    />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a
                                href={siteSettings.twitter_social_url}
                                className="d-flex align-items-center justify-content-center"
                                target="_blank"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="24"
                                    viewBox="0 0 30 24"
                                >
                                    <path
                                        id="Icon_awesome-twitter"
                                        data-name="Icon awesome-twitter"
                                        d="M26.916,9.362c.019.262.019.525.019.788A17.236,17.236,0,0,1,9.442,27.381,17.568,17.568,0,0,1,0,24.662a12.911,12.911,0,0,0,1.485.075A12.433,12.433,0,0,0,9.118,22.15a6.149,6.149,0,0,1-5.749-4.2,7.869,7.869,0,0,0,1.161.094,6.6,6.6,0,0,0,1.618-.206,6.081,6.081,0,0,1-4.93-5.944v-.075A6.264,6.264,0,0,0,4,12.587,6.029,6.029,0,0,1,1.256,7.543a5.942,5.942,0,0,1,.838-3.056,17.566,17.566,0,0,0,12.678,6.338,6.738,6.738,0,0,1-.152-1.387,6.1,6.1,0,0,1,6.149-6.056A6.178,6.178,0,0,1,25.26,5.293a12.218,12.218,0,0,0,3.9-1.462,6.057,6.057,0,0,1-2.7,3.337A12.474,12.474,0,0,0,30,6.231a13.111,13.111,0,0,1-3.084,3.131Z"
                                        transform="translate(0 -3.381)"
                                    />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a
                                href={siteSettings.instagram_social_url}
                                className="d-flex align-items-center justify-content-center"
                                target="_blank"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <g
                                        id="Icon_feather-instagram"
                                        data-name="Icon feather-instagram"
                                        transform="translate(-3 -2.999)"
                                    >
                                        <path
                                            id="Subtraction_1"
                                            data-name="Subtraction 1"
                                            d="M18,24H6a6.006,6.006,0,0,1-6-6V6A6.007,6.007,0,0,1,6,0H18a6.007,6.007,0,0,1,6,6V18A6.006,6.006,0,0,1,18,24ZM12.05,7.148a4.8,4.8,0,1,0,.706.052A4.893,4.893,0,0,0,12.05,7.148ZM18.634,4a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,18.634,4Z"
                                            transform="translate(3 2.999)"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </li>
                    </ul>
                    <div className="footer-copyright text-center">
                        <p className="mb-0">{copyright}</p>
                    </div>
                </Row>
            </div>
        </div>
    );
};
export default BottomFooter;

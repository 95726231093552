import React from 'react'
import { Image, Button } from 'react-bootstrap';
import notes from '../../../../assets/PetStore/images/notes-img.svg';
import curveimg from '../../../../assets/PetStore/images/curve-arrow.svg';
import updateone from '../../../../assets/PetStore/images/update-one.png';

const NewUpdates = () => {
    return (
        <div className='newupdates'>
            <div className='container-width'>
                <div className='newsupdates-area d-flex justify-content-between align-items-center'>
                    <div className='newupdates-content d-flex align-items-center'>
                        <div className='newupdates-content-img me-3'>
                            <Image src={notes} fluid />
                        </div>
                        <h6 className='mb-0'>Subscribe for new updates</h6>
                        <div className='curve-arrow-img'>
                            <Image src={curveimg} fluid />
                        </div>
                    </div>
                    <div className='newupdates-content responsive d-flex align-items-center'>
                        <h6 className='mb-0'>Litter running low? Try FREE Same-Day Delivery or Curbside Pickup</h6>
                        <div className='new-updated-img'>
                            <Image src={updateone} fluid />
                        </div>
                    </div>
                    <div className='show-now-button'>
                        <Button><a href="/catalog">Shop Now</a></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewUpdates;

import React from "react";
import { Container,Row } from "react-bootstrap";
import { SpinnerCircularFixed } from "spinners-react";
const TopFooter=(props)=>{
    const siteSettings = props.siteSettings;
    return(
        <div className="top-footer">
            <Container fluid className="custome-container">
                {
                props.isLoading ? 
                <Row>
                    <div className="text-center pt-4 mb-4">
                        {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                        <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                    </div>
                </Row>
                :
                <Row>
                    <div className="footer-img">
                        <img src={siteSettings && siteSettings.default_image_2 }/>
                    </div>
                </Row>
                }
            </Container>
        </div>

    )
}
export default TopFooter;
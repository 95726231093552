import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom'
import { SpinnerCircularFixed} from 'spinners-react';
import api from "../../../helpers/api";
const About = () => {
    const navigate = useNavigate();
    const { slug } = useParams()
    const [AboutusPages, setAboutusPages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true)
        api.get(`/cms-page/${slug}`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            window.scrollTo(0, 0)
            setAboutusPages(res.data)
            setIsLoading(false)
        });

    }, [slug]);
    return (
        <div className="About">
            <Container>
                {/* <Row>
                    <Col>
                        <div className="about-top-heading">
                            <h2>{AboutusPages.title}</h2>
                        </div>
                    </Col>
                </Row> */}
                {isLoading ? 
                <Row>
                    <div className="text-center pt-4 mb-4">
                        {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                        <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                    </div>
                </Row>
                :
                <Row>
                    <Col>
                        <div className="middle-about-heading">
                            <h2>{AboutusPages.title}</h2>
                        </div>
                        <div className="about-paragraph" dangerouslySetInnerHTML={{ __html: AboutusPages?.content }}>
                        </div>
                    </Col>
                </Row>
                }
            </Container>
        </div>
    );
};
export default About;

import React, {useState, useEffect} from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import api from "../../../helpers/api";
const Homebanner =()=>{
    const [siteSettings, setSiteSettings] = useState([]);
    const [Banner, setBanner] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.get(`/get-site-settings`, {
            headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
            }
        }).then(res => {
            setSiteSettings(res.data.data)
            setIsLoading(false)
        });
        api.get(`/fetch_banners`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh',
            }
          }).then(res => {
            setBanner(res.data.data[0])
            setIsLoading(false)
        });
    }, []);
    return(
       <div className="banner-margin" id="banner">
            <div className="banner-cont">
                <Container>
                <div className="home-banner-position">
                    <h1>{Banner.title}</h1>
                    <span>{Banner.description}</span>
                    <Link className="btn" to="/catalog">{Banner.button_text}</Link>
                </div>
                <div className="banner-img">
                 <img src={siteSettings.banner_image} alt="" />
                </div>
                </Container>
                
           </div>
       </div>

    )
}
export default Homebanner;

import React, { useState, useEffect } from "react";
import { Container,Row,Col } from "react-bootstrap";
import { SpinnerCircularFixed } from 'spinners-react';
import api from "../helpers/api";
const Banefits =()=>{
    const [Eservices, setEservices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.get(`/get-services`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setEservices(res.data.data)
            setIsLoading(false)
        });
  
    }, []);
    return(
        <div className="our-services">
            <Container fluid className="custome-container">
                    <Row>
                        <Col>
                        <div className="our-service-heading">
                            <div className="services-border d-flex align-items-center">
                                <span className="border-line-services"></span>
                                <span className="service-circle">
                                <span className="inner-border"></span>
                                </span>
                            </div>
                            <h2>Benefits Of Our Services</h2>
                            <div className="services-border-active d-flex align-items-center">
                                <span className="service-circle"></span>
                                <span className="border-line-services">
                                    <span className="inner-circle"></span>
                                </span> 
                            </div>
                        </div>
                        </Col>
                        {isLoading ? 
                        <Row>
                            <div className="text-center pt-4 mb-4">
                                {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                                <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                            </div>
                        </Row>
                        :
                        <Row className="text-center">
                            {
                                Eservices ? 
                                Eservices.map((row, key)=>(
                                <Col md={4} className="services-space">
                                    <div className="order-image">
                                        <img src={row.image} alt="" />  
                                    </div>
                                    <div className="order-heading">
                                        <h2>{row.title}</h2>
                                        <p>{row.description}</p>
                                    </div>
                                
                                </Col>
                                ))
                                : "No services Found"
                            }
                        </Row>
                        }
                    </Row>
            </Container>
        </div>

    )
}
export default Banefits;
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { SpinnerCircularFixed} from 'spinners-react';
import SliderProducts from '../SliderComponents/SliderProducts';
import NonSliderProducts from '../SliderComponents/NonSliderProducts';
import api from "../../../helpers/api";
const ShopDeals = (props) => {
    const [categories, setCategories] = useState([]);
    var first = categories[0];
    const [products, setProducts] = useState([]);
    const [productTypeID, setProductTypeID] = useState([]);
    const [productTypeName, setProductTypeName] = useState(first?.name);
    const addToWishlist = props.addToWishlist;
    const wishlist = props.wishlist;
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        api.get(`/get-product-type`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCategories(res.data.data);

                setIsLoading(false);
            });
    }, []);
    useEffect(() => {
        showCategoriesByProduct()
      }, [first]);

    function showCategoriesByProduct(category_id = first?.id,category_name = first?.name) {
        setIsLoading(true)
        var url = `/get-product-by-category/` + category_id;
        api.get(url, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
        }).then(res => {
            setProducts(res.data.data)
            setProductTypeID(category_id)
            setProductTypeName(category_name)
            setIsLoading(false)
        });
    }
    return (
        <div className="shopdeals">
            <div className="section-heading text-center">
                <h3>Shop Deals by Pet</h3>
            </div>
            <div className="container-width">
                {/* <Tabs
                    defaultActiveKey="Bottoms"
                    id="uncontrolled-tab-example"
                    className="shopdeals-tabs d-flex justify-content-center" 
                    >
                    {categories.map((item, index) => (
                        <Tab eventKey={item.name} title={item.name} >
                            <div className='tabs-content-wrapper mb-xl-5 mb-3 d-flex flex-wrap justify-content-lg-between justify-content-start' 
                            >
                                <div className='tabs-content-area'>
                                    <div className='tabs-content'>
                                        <div className='tabs-content-img d-flex justify-content-center mb-5'>
                                            <Image src={tabimgone} fluid />
                                        </div>
                                        <p>Wobbler Ringleader Dog Toy-Squeaker</p>
                                        <div >
                                            <div className='rating-icons mb-4 d-flex align-items-center'>
                                                <FontAwesomeIcon icon={faStar} className="icons" />
                                                <FontAwesomeIcon icon={faStar} className="icons" />
                                                <FontAwesomeIcon icon={faStar} className="icons" />
                                                <FontAwesomeIcon icon={faStar} className="icons" />
                                                <FontAwesomeIcon icon={faStar} className="icons" />
                                            </div>
                                            <h6 className="orange">$40</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tabs-more-btn text-center'>
                                <Button>See more</Button>
                            </div>
                        </Tab>
                    ))}  
                </Tabs> */}
                <Row>
                    <Col>
                        <div className="nav " id="nav-tab" role="tablist">
                            <ul
                                className="nav nav-pills "
                                id="pills-tab"
                                role="tablist"
                            >
                                {categories.map((item, index) => (
                                    <li className="nav-item">
                                        <a
                                            className={
                                                item.id == productTypeID ||
                                                item.name == productTypeName
                                                    ? "nav-link-chair"
                                                    : "nav-link"
                                            }
                                            onClick={() =>
                                                showCategoriesByProduct(
                                                    item.id,
                                                    item.name
                                                )
                                            }
                                            id="pills-tab"
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Col>
                </Row>

                {isLoading ? (
                    <Row>
                        <div className="text-center pt-4 mb-4">
                            {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                            <SpinnerCircularFixed
                                size={50}
                                thickness={100}
                                speed={100}
                                color="#1239A5"
                                secondaryColor="rgba(0, 0, 0, 0.44)"
                            />
                        </div>
                    </Row>
                ) : (
                    <Row>
                        <Col className="slider-color">
                            {products.length > 0 ? (
                                products.length > 4 ? (
                                    <SliderProducts products={products} addToWishlist={addToWishlist} wishlist={wishlist}/>
                                ) : (
                                    <NonSliderProducts products={products} addToWishlist={addToWishlist} wishlist={wishlist}/>
                                )
                            ) : (
                                <p className="category-data d-flex justify-content-center">
                                    No Product found in this Category
                                </p>
                            )}
                        </Col>
                        <div className="tabs-more-btn">
                            <button>
                                <a href={`/catalog?type=${productTypeName}`}>
                                    See More
                                </a>
                            </button>
                        </div>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default ShopDeals;

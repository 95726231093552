import React, {useState, useEffect,useRef} from "react";
import { Container,Row,Col,Tooltip } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import api from "../helpers/api";
const TopCategories =(props)=>{
    const [categories, setCategories] = useState([]);
    const currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : "Rs";
    const first = categories[0];
    const [products, setProducts] = useState([]);
    const [productTypeID, setProductTypeID] = useState([]);
    const [productTypeName, setProductTypeName] = useState("");
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    useEffect(() => {
        api.get(`/get-product-type`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {

            setCategories(res.data.data)
        });
    }, []);
    useEffect(() => {
      showCategoriesByProduct()
    }, [first]);
    const myRef = useRef(null);
    const [counter, setCounter] = useState(1);
    function showCategoriesByProduct(category_id = first?.id,category_name = first?.name) {
        var url = `/get-product-by-category/` + category_id;
        api.get(url, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
        }).then(res => {
          counter > 2 && myRef.current?.scrollIntoView({behavior: 'smooth'});
          setProducts(res.data.data)
          setProductTypeID(category_id)
          setProductTypeName(category_name)
          setCounter(counter+1)
        });
    };
    
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
        nextArrow: <FontAwesomeIcon icon={faArrowRight} />,
        
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots:false
            }
          },
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots:false
            }
          },
            
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots:false
              }
            },
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false
                }
              },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
                breakpoint: 575,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 2
                }
              },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    
	
    };
    function StarRating({count, value, 
      inactiveColor='#ddd',
      size=24,
      activeColor='#f00', onChange}) {
  
      // short trick 
      const stars = Array.from({length: count}, () => '🟊')

      return (
        <div>
          {stars.map((s, index) => {
            let style = inactiveColor;
            if (index < value) {
              style=activeColor;
            }
            return (
                <FontAwesomeIcon icon={faStar} style={{color: style, width:size, height:size, fontSize: size, marginLeft: "-6px"}}/>
            )
          })}
        </div>
      )
    }
    return(
        <div className="top-categoreis">
            <Container fluid className="custome-container">
                    <Row>
                        <Col>
                        <div className="category-heading">
                            <h2>Top Categories</h2>
                        </div>
                        </Col>
                    </Row>
                    <Row ref={myRef}>
                        <Col lg={4} md={12}>
                            <div className="side-bar">
                                <ul className="ps-0">
                                    {categories.map((item, index) => (
                                    <li className={(item.id == productTypeID || item.name == productTypeName ? "active-category-color" : "non-active")} onClick={() => showCategoriesByProduct(item.id,item.name)}>
                                        <div className="active-color d-flex align-items-center">
                                        <div className="side-bar-img heel">
                                            <img src={item.image} alt="" />
                                          </div>
                                          <a >{item.name}</a>
                                        </div>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                        {/* <Col  lg={1} offset-1></Col> */}
                        <Col lg={8} md={12} className="slider-space">
                            {
                                products.length > 0 ?
                                  products.length > 2 ?
                                  <Slider className="prod-slider" {...settings}>
                                  {
                                      products.map((row, index) => (
                                          <div className="slider-cont">
                                            <Link className="product-text" to={`/shop/${row.id}`}>
                                              <div className="winter-coat">
                                                  <img src={row.image} alt=""/>
                                              </div>
                                              </Link>
                                              { row.display_name !== null ?
                                                <div className="discount-tag">
                                                    <span>{row.display_name}</span>
                                                </div>
                                                :
                                                ''
                                              }
                                              <div className="heart-icon" key={index} data-id={row.id} onClick={() => { props.addToWishlist(row.id); }}>
                                              {
                                                    (JSON.stringify(wishlist_items.find((item) => item.id === row.id))) ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="top-product-svg" width="20" height="20"
                                                    viewBox="0 0 27 23">
                                                    <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                                        d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                                        transform="translate(-0.823 -2.997)" fill="none"
                                                        stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="3" />
                                                </svg>

                                                    : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    viewBox="0 0 27 23">
                                                    <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                                        d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                                        transform="translate(-0.823 -2.997)" fill="none"
                                                        stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="3" />
                                                </svg>

                                                }
                                              </div>
                                              <Link className="product-text" to={`/shop/${row.id}`}>
                                              <div className="ms-2">
                                                  <div>
                                                  <Tooltip title={row.product_name}>
                                                      <h3>{row.product_name}</h3>
                                                   </Tooltip>
                                                      <h3 className="heading-top-slider mb-3">{row.product_name}</h3>
                                                      <StarRating 
                                                      count={5}
                                                      size={20}
                                                      value={row.product_reviews}
                                                      activeColor ={'#FEBE37'}
                                                      inactiveColor={'#ddd'}/>  
                                                      <div className="d-flex justify-content-between  align-items-center trend-slider-price lg-pt-4 md-pt-2">
                                                          {
                                                              row.discount_offer_id != null ?
                                                              <div className="slid-price">
                                                                <span className="d-price"><del>{currency +" "+row.price}</del> | </span>
                                                                <span className="price-color">{currency +" "+ (row.price - row.discounted_amount)}</span>
                                                              </div>
                                                              :
                                                              <span className="price-color">{currency +" "+row.price}</span>
                                                          }
                                                          <button className="slider-button">
                                                              <span>Order Now</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              </Link>
                                          </div>
                                      ))
                                  }
                                  </Slider>
                                  :
                                    products.map((row, index) => (
                                  
                                  <div className="slider-cont no-slider">
                                    <Link className="product-text" to={`/shop/${row.id}`}>
                                      <div className="winter-coat">
                                          <img src={row.image} alt=""/>
                                      </div>
                                      </Link>
                                      { row.display_name !== null ?
                                                <div className="discount-tag">
                                                    <span>{row.display_name}</span>
                                                </div>
                                                :
                                                ''
                                              }
                                              <div className="heart-icon" key={index} data-id={row.id} onClick={() => { props.addToWishlist(row.id); }}>
                                              {
                                                    (JSON.stringify(wishlist_items.find((item) => item.id === row.id))) ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="top-product-svg" width="20" height="20"
                                                    viewBox="0 0 27 23">
                                                    <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                                        d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                                        transform="translate(-0.823 -2.997)" fill="none"
                                                        stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="3" />
                                                </svg>

                                                    : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    viewBox="0 0 27 23">
                                                    <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                                        d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                                        transform="translate(-0.823 -2.997)" fill="none"
                                                        stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="3" />
                                                </svg>

                                                }
                                              </div>
                                              <Link className="product-text" to={`/shop/${row.id}`}>
                                                <div className="ms-2">
                                                    <div>
                                                        <h3 className="heading-top-slider mb-3">{row.product_name}</h3>
                                                        <StarRating 
                                                        count={5}
                                                        size={20}
                                                        value={row.product_reviews}
                                                        activeColor ={'#FEBE37'}
                                                        inactiveColor={'#ddd'}/>  
                                                        <div className="d-flex justify-content-between  align-items-center trend-slider-price pt-4">
                                                          {
                                                                row.discount_offer_id != null ?
                                                                <div className="slid-price">
                                                                  <span className="d-price"><del>{currency +" "+row.price}</del> | </span>
                                                                  <span className="price-color">{currency +" "+(row.price - row.discounted_amount)}</span>
                                                                </div>
                                                                :
                                                                <span className="price-color">{currency +" "+row.price}</span>
                                                            }
                                                            <button className="slider-button">
                                                                <span>Order Now</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                              </Link>
                                  </div>
                             
                              ))
                                  
                                  
                                :
                                <Col md={12}>
                                  <p className="text-center mt-5">No Product Found</p>
                                </Col>
                            }
                        </Col>
                    </Row>
            </Container>
        </div>
    )
}
export default TopCategories;
import React, { useState, useEffect } from "react";
import './Footer.css'
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import api from "../../helpers/api";
function Footer() {
  const [CmsPages, setCmsPages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [siteSettings, setSiteSettings] = useState([]);
  const user = localStorage.getItem("isLoggedIn");
  useEffect(() => {
      api.get(`/fetch_cms_pages`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setCmsPages(res.data.data)
          setIsLoading(false)
      });
      api.get(`/get-site-settings`, {
        headers: {
          'POS-API-KEY' : 'jd54elkrtlh'
        }
      }).then(res => {
        setSiteSettings(res.data.data)
        setIsLoading(false)
    });
  }, []);
  // Replace the site settings copyright with current year
  const originalString = siteSettings?.site_copyright;
  const currentYear = new Date().getFullYear(); 
  const copyright = originalString?.replace(/\d{4}/, currentYear);
  return(
    <>
        <div className='footer'>
          <Container fluid className='custom_container'>
            <div className='row'>
              <div className='col-md-4 mb-md-0 mb-3'>
                <div className='wrap-footer-links'>
                  <h3>My Account</h3>
                  <ul>
                    { user && <li><a className='footer-navlink' href='/myaccount'>My Account</a></li>}
                    <li><a className='footer-navlink' href='/wishlist'>Wishlist</a></li>
                    <li><a className='footer-navlink' href='/cart'>Cart</a></li>
                    { user && <li><a className='footer-navlink' href='/myorder'>My Orders</a></li>}
                  </ul>
                </div>
              </div>
              <div className='col-md-4 mb-md-0 mb-3'>
                <div className='wrap-footer-links'>
                    <h3>Information</h3>
                    <ul>
                    {
                        CmsPages != "" ? (
                            CmsPages.map((row, key)=>(
                                <li><Link className='footer-navlink' to={'/cms/' + row.slug}>{row.title}</Link></li>
                            ))
                            )
                        : ""
                    }
                    </ul>
                  </div>
              </div>
              <div className='col-md-4'>
                <div className='wrap-footer-links'>
                    <h3>Our Contacts</h3>
                    <p>{siteSettings.address}</p>
                    <ul>
                      <li><a href={"tel:" + siteSettings.phone_no}><span className='bold-span'>Phone:</span> {siteSettings.phone_no}</a></li>
                      <li><a href={"mailto:" + siteSettings.email}><span className='bold-span'>Email:</span> {siteSettings.email}</a></li>
                      {/* <li><a href='/'><span className='bold-span d-block'>STORE HOURS:</span> 10:00 AM to 07:30 PM</a></li> */}
                    </ul>
                  </div>
              </div>
            </div>
            <div className='footer-img text-center'>
                <figure className='mb-0'>
                  <a href="/">
                  <img className='img-fluid' src={siteSettings.logo} alt='Jewelry'></img>
                  </a>
                </figure>
              </div>
            <div className='footer-bottom text-center'>
              <p className='copyright mb-0'>{copyright}
              <a href='/cms/about-us' className='links'>Terms of use</a> and <a href='/cms/return-refund-policy' className='links'>Privacy Policy</a></p>
            </div>
          </Container>
        </div>
    </>
  )
}

export default Footer;
import React from "react";
import Header from "../Header/Header";
import Middlefooter from "../Middlefooter/Middlefooter";
import Topfooter from "../Topfooter/Topfooter";
import Paymentmethod from "./Paymentmethod";

const Shippingpayment =()=>{
    return(
        <div className="wrapper">
            <Header/>
            <Paymentmethod/>
            <Topfooter/>
            <Middlefooter/>
        </div>
    )
}
export default Shippingpayment;
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
const SliderProducts = (props) => {
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : "Rs";
    const wishlist = props.wishlist == undefined ? wishlist_items : props.wishlist;
    const settings = {
        dots: false,
        infinite: true,
        centerMode: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
        nextArrow: <FontAwesomeIcon icon={faArrowRight} />,
        responsive: [


            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false

                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false

                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <Slider className="prod-slider" {...settings}>
            {props.products.map((row, index) => (
                <div className="slider-cont">
                    <Link to={`/shop/${row.id}`}>
                    <div className="image">
                        <img src={row.image} alt="" />
                    </div>
                    </Link>
                    <div>
                    { row.display_name !== null ?
                        <div className="discount-tag">
                            <span>{row.display_name}</span>
                        </div>
                    :
                    ''
                    }
                        <div className="heart-icon" key={index} data-id={row.id} onClick={() => { props.addToWishlist(row.id); }}>
                        {
                                (JSON.stringify(wishlist.find((item) => item.id === row.id))) ?
                                <svg xmlns="http://www.w3.org/2000/svg" className="top-product-svg" width="20" height="20"
                                viewBox="0 0 27 23">
                                <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                    d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                    transform="translate(-0.823 -2.997)" fill="none"
                                    stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="3" />
                            </svg>

                                : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                viewBox="0 0 27 23">
                                <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                    d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                    transform="translate(-0.823 -2.997)" fill="none"
                                    stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="3" />
                            </svg>

                            }
                        </div>

                    </div>
                    <Link to={`/shop/${row.id}`}>
                    <div className="active-product ms-3">
                        <div>
                            <h3 title={row.product_name}>{row.product_name}</h3>
                            {
                                row.discount_offer_id != null ?
                                <div className="price">
                                <span className="d-price"><del>{currency +" "+ row.price}</del> | </span>
                                <span className="r-price">{currency + " " + (row.price - row.discounted_amount)}</span>
                                </div>
                                :
                                <div className="price">
                                <span className="r-price">{currency + " " + row.price}</span>
                                </div>
                            }
                        </div>
                        <div className="people-loved-link">
                        <Link to={`/shop/${row.id}`} className="top-product-cart">ADD TO CART </Link>
                        </div>

                    </div>
                    </Link>
                </div>
            ))}
        </Slider>
    )
}
export default SliderProducts;

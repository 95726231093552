import React from "react";
import { Row } from "react-bootstrap";
import { SpinnerCircularFixed } from "spinners-react";
import SliderProducts from "../SliderComponents/SliderProducts";
import NonSliderProducts from "../SliderComponents/NonSliderProducts";
const DealDay = (props) => {
    const topProducts = props.topProducts;
    const addToWishlist = props.addToWishlist;
    const wishlist = props.wishlist;
    return (
        <div id="new-arrival">
            <div className="deal-day">
                <div className="section-heading text-center">
                    <h3>Top Products</h3>
                </div>
                <div className="container-width">
                    {props.isLoading ? (
                        <Row>
                            <div className="text-center pt-4 mb-4">
                                {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                                <SpinnerCircularFixed
                                    size={50}
                                    thickness={100}
                                    speed={100}
                                    color="#1239A5"
                                    secondaryColor="rgba(0, 0, 0, 0.44)"
                                />
                            </div>
                        </Row>
                    ) : (
                        <Row>
                            {topProducts.length > 0 ? (
                                topProducts.length > 4 ? (
                                    <SliderProducts
                                        products={topProducts}
                                        addToWishlist={addToWishlist}
                                        wishlist={wishlist}
                                    />
                                ) : (
                                    <NonSliderProducts
                                        products={topProducts}
                                        addToWishlist={addToWishlist}
                                        wishlist={wishlist}
                                    />
                                )
                            ) : (
                                <p className="category-data d-flex justify-content-center">
                                    No Product found in this Category
                                </p>
                            )}
                        </Row>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DealDay;

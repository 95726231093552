import React, { useState, useEffect } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { SpinnerCircularFixed } from "spinners-react";
import api from "../../../helpers/api";
const Question = () => {
    const [Questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);
        api.get(`/fetch_faqs`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setQuestions(res.data.data);
                setIsLoading(false);
            });
    }, []);
    return (
        <div className="Question">
            <Container>
                <Row>
                    <Col>
                        {/* <div className="Question-heading">
                            <h2 className="mb-4 pt-4">FAQ</h2>
                            <p>
                                Get started with a free trial and setup your
                                store. Activate a plan when you’re ready.
                            </p>
                        </div> */}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="frequntly-question">
                            <h2 className="mb-4">Frequently Asked Questions</h2>
                            <p>
                                If you are hesitating, do not worry - we are
                                here to explain{" "}
                                <p className="display-block">
                                    {" "}
                                    everything you might want to know. Let us
                                    help!
                                </p>
                            </p>
                        </div>
                    </Col>
                </Row>
                {isLoading ? (
                    <div className="text-center pt-4 mb-4">
                        {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                        <SpinnerCircularFixed
                            size={50}
                            thickness={100}
                            speed={100}
                            color="#1239A5"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                    </div>
                ) : (
                    <Row>
                        <Col>
                            <Accordion defaultActiveKey="0" flush>
                                {Questions != "" ? (
                                    Questions.map((row, key) => (
                                        <Accordion.Item eventKey={key}>
                                            <Accordion.Header>
                                                {row.question}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {row.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                ) : (
                                    <p>No Question Found</p>
                                )}
                            </Accordion>
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
};
export default Question;

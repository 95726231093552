import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "react-bootstrap";
import { Row, Tooltip } from "react-bootstrap";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { SpinnerCircularFixed } from "spinners-react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import api from "../../../helpers/api";
const BestSeller = (props) => {
    const [categories, setCategories] = useState([]);
    const first = categories[0];
    const [products, setProducts] = useState([]);
    const [productTypeID, setProductTypeID] = useState([]);
    const [productTypeName, setProductTypeName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const wishlist_items = JSON.parse(localStorage.getItem("wishlist"));
    const currency = localStorage.getItem("currency")
        ? localStorage.getItem("currency")
        : "Rs";

    useEffect(() => {
        setIsLoading(true);
        api.get(`/get-product-type`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCategories(res.data.data);

                setIsLoading(false);
            });
    }, []);
    useEffect(() => {
        showCategoriesByProduct();
    }, [first]);
    function showCategoriesByProduct(
        category_id = first?.id,
        category_name = first?.name
    ) {
        setIsLoading(true);
        var url = `/get-product-by-category/` + category_id;
        api.get(url, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setProducts(res.data.data);
                setProductTypeID(category_id);
                setProductTypeName(category_name);
                setIsLoading(false);
            });
    }
    const arrival = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: (
            <FontAwesomeIcon icon={faArrowLeft} className="arrow-left" />
        ),
        nextArrow: (
            <FontAwesomeIcon icon={faArrowRight} className="arrow-right" />
        ),
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1650,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="best-seller">
            <div className="section-heading text-center">
                <h3>Our Categories</h3>
            </div>
            <div className="container-width">
                <ul className="best-seller-list d-flex align-items-center p-0 mb-5">
                    {categories.map((item, index) => (
                        <li
                            className={
                                item.id == productTypeID ||
                                item.name == productTypeName
                                    ? "category-active"
                                    : ""
                            }
                            key={item?.id}
                        >
                            <a
                                onClick={() =>
                                    showCategoriesByProduct(item.id, item.name)
                                }
                            >
                                {item.name}
                            </a>
                        </li>
                    ))}
                </ul>
                {isLoading ? (
                    <Row>
                        <div className="text-center pt-4 mb-4">
                            {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                            <SpinnerCircularFixed
                                size={50}
                                thickness={100}
                                speed={100}
                                color="#1239A5"
                                secondaryColor="rgba(0, 0, 0, 0.44)"
                            />
                        </div>
                    </Row>
                ) : products.length > 0 ? (
                    products.length > 5 ? (
                        <div className="best-seller-content">
                            <Slider {...arrival} className="prod-slider">
                                {products.map((row, index) => (
                                    <div className="best-seller-content-area">
                                        <div className="best-seller-content-wrapper">
                                            {row.display_name !== null ? (
                                                <div className="discount-tag">
                                                    <span>
                                                        {row.display_name}
                                                    </span>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <div
                                                className="heart-icon"
                                                key={index}
                                                data-id={row.id}
                                                onClick={() => {
                                                    props.addToWishlist(row.id);
                                                }}
                                            >
                                                {JSON.stringify(
                                                    wishlist_items.find(
                                                        (item) =>
                                                            item.id === row.id
                                                    )
                                                ) ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="top-product-svg"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 27 23"
                                                    >
                                                        <path
                                                            id="Icon_feather-heart"
                                                            data-name="Icon feather-heart"
                                                            d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                                            transform="translate(-0.823 -2.997)"
                                                            fill="none"
                                                            stroke="#3f5b6c"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="3"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 27 23"
                                                    >
                                                        <path
                                                            id="Icon_feather-heart"
                                                            data-name="Icon feather-heart"
                                                            d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                                            transform="translate(-0.823 -2.997)"
                                                            fill="none"
                                                            stroke="#3f5b6c"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="3"
                                                        />
                                                    </svg>
                                                )}
                                            </div>
                                            <Link
                                                className="product-text"
                                                to={`/shop/${row.id}`}
                                            >
                                                <div className="best-seller-content-image">
                                                    <Image
                                                        src={row.image}
                                                        fluid
                                                    />
                                                </div>
                                                <div className="best-seller-content-info">
                                                    <div className="content-wrapper">
                                                        <h6>PLANT</h6>
                                                        <Tooltip
                                                            title={
                                                                row.product_name
                                                            }
                                                        >
                                                            <h5>
                                                                {
                                                                    row.product_name
                                                                }
                                                            </h5>
                                                        </Tooltip>
                                                        <h5 className="mb-3">
                                                            {row.product_name}
                                                        </h5>
                                                        {row.discount_offer_id !=
                                                        null ? (
                                                            <div className="best-seller-content-bottom d-flex">
                                                                <h6 className="me-2">
                                                                    {currency +
                                                                        " " +
                                                                        (row.price -
                                                                            row.discounted_amount)}{" "}
                                                                    |{" "}
                                                                </h6>
                                                                <p>
                                                                    <del>
                                                                        {currency +
                                                                            " " +
                                                                            row.price}
                                                                    </del>
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <div className="best-seller-content-bottom d-flex">
                                                                <h6 className="me-4">
                                                                    {currency +
                                                                        " " +
                                                                        row.price}
                                                                </h6>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    ) : (
                        <div className="best-seller-content not-slick-slider">
                            {products.map((row, index) => (
                                <div className="best-seller-content-area not-content-area">
                                    <div className="best-seller-content-wrapper">
                                        {row.display_name !== null ? (
                                            <div className="discount-tag">
                                                <span>{row.display_name}</span>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div
                                            className="heart-icon"
                                            key={index}
                                            data-id={row.id}
                                            onClick={() => {
                                                props.addToWishlist(row.id);
                                            }}
                                        >
                                            {JSON.stringify(
                                                wishlist_items.find(
                                                    (item) => item.id === row.id
                                                )
                                            ) ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="top-product-svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 27 23"
                                                >
                                                    <path
                                                        id="Icon_feather-heart"
                                                        data-name="Icon feather-heart"
                                                        d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                                        transform="translate(-0.823 -2.997)"
                                                        fill="none"
                                                        stroke="#3f5b6c"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="3"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 27 23"
                                                >
                                                    <path
                                                        id="Icon_feather-heart"
                                                        data-name="Icon feather-heart"
                                                        d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                                        transform="translate(-0.823 -2.997)"
                                                        fill="none"
                                                        stroke="#3f5b6c"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="3"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                        <Link
                                            className="product-text"
                                            to={`/shop/${row.id}`}
                                        >
                                            <div className="best-seller-content-image">
                                                <Image src={row.image} fluid />
                                            </div>
                                            <div className="best-seller-content-info">
                                                <div className="content-wrapper">
                                                    <h6>PLANT</h6>
                                                    <Tooltip
                                                        title={row.product_name}
                                                    >
                                                        <h5>
                                                            {row.product_name}
                                                        </h5>
                                                    </Tooltip>
                                                    <h5 className="mb-3">
                                                        {row.product_name}
                                                    </h5>
                                                    {row.discount_offer_id !=
                                                    null ? (
                                                        <div className="best-seller-content-bottom d-flex">
                                                            <h6 className="me-2">
                                                                {currency +
                                                                    " " +
                                                                    (row.price -
                                                                        row.discounted_amount)}{" "}
                                                                |{" "}
                                                            </h6>
                                                            <p>
                                                                <del>
                                                                    {currency +
                                                                        " " +
                                                                        row.price}
                                                                </del>
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <div className="best-seller-content-bottom d-flex">
                                                            <h6 className="me-4">
                                                                {currency +
                                                                    " " +
                                                                    row.price}
                                                            </h6>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
                ) : (
                    <p className="category-data d-flex justify-content-center">
                        No Product found in this Category
                    </p>
                )}
            </div>
        </div>
    );
};

export default BestSeller;

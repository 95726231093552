import React, {useState, useEffect} from "react";
import Header from "../Header/Header";
import About from './About';
import Middlefooter from "../Middlefooter/Middlefooter";
import PetStoreHeader from "../../PetStore/HomePage/Header/Header";
import NewUpdates from '../../PetStore/HomePage/NewUpdates/NewUpdates';
import PetStoreFooter from '../../PetStore/HomePage/Footer/Footer';
import JewelleryHeader from '../../Jewellery/Header/Header'
import JewelleryTopFooter from '../../Jewellery/Home-Page/Subscriber/Subscriber'
import JewelleryFooter from '../../Jewellery/Footer/Footer'
import ShopHeader from "../../ShopLove/Header/Header";
import ShopBottomFooter from "../../ShopLove/BottomFooter";
import FlowerHeader from "../../FlowerShop/HomePage/Header/Header";
import FlowerFooter from "../../FlowerShop/HomePage/Footer/Footer";
import FlowerMidFooter from "../../FlowerShop/HomePage/MiddleFooter/MiddleFooter";
import CafeHeader from "../../CafeFood/Header/Header";
import CafeFooter from "../../CafeFood/BottomFooter";
import { ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom'
import api from "../../helpers/api";
const Aboutus=()=>{
    const [showContent, setShowContent] = useState(0);
    const NewThemeID = JSON.parse(localStorage.getItem('ThemeID'));
    const [themeId, setThemeId] = useState(NewThemeID);
    useEffect(() => {
        const TotalQty = JSON.parse(localStorage.getItem('totalQuantity')); 
        setShowContent(TotalQty)
    }, []);
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setThemeId(res.data.data.store.theme_id)
            localStorage.setItem('ThemeID', res.data.data.store.theme_id);
        });
    }, []);
    const { slug } = useParams()
    
    const [AboutusPages, setAboutusPages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        
        setIsLoading(true)
        api.get(`/cms-page/${slug}`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            window.scrollTo(0, 0)
            setAboutusPages(res.data)
            setIsLoading(false)
        });

    }, [slug]);
    
    return(
        <div className="wrapper">
            <ToastContainer/>
            {themeId == 1 && <FlowerHeader/>}
            {themeId == 2  && <ShopHeader/>}
            {themeId == 3 && <Header/>}
            {themeId == 4 && <CafeHeader/>}
            {themeId == 8 && <PetStoreHeader />}
            {themeId == 9 && <JewelleryHeader />}
            <About AboutusPages={AboutusPages} isLoading={isLoading}/>
            {themeId == 1 && <FlowerFooter/>}
            {themeId == 1 && <FlowerMidFooter/>}
            {themeId == 2 && <ShopBottomFooter />}
            {/* {themeId == 3 &&<Topfooter/>} */}
            {themeId == 3 && <Middlefooter/>}
            {themeId == 4 && <CafeFooter/>}
            {themeId == 8 && <NewUpdates/>}
            {themeId == 8 && <PetStoreFooter/>}
            {themeId == 9 && <JewelleryTopFooter />}
            {themeId == 9 && <JewelleryFooter />}
        </div>

    )
}
export default Aboutus;
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom"
function ShopItems(props){
    const productDetail = props.productDetail;
    return(
        <>
            <div id="bread">
                <Container className="custome-container">
                    <Row>
                        <Col sm={12}>
                            <div className="top-bar">
                                <div aria-label="breadcrumb ">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"><Link to={`/catalog?type=${productDetail.product_type_name}`}> {productDetail.product_type_name ? productDetail.product_type_name : "Others"}</Link> </li>
                                        <li className="breadcrumb-item active products" aria-current="page">{productDetail.product_name}</li>
                                    </ol>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default ShopItems;
import React, { useState, useEffect } from "react";
import useValidator from "../../hooks/useValidator";
import { Container, Row, Col, Form, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../helpers/api";
function ShowErrorMessage(props) {
  const messages = props.message;
  return (
    <ul>
      {Object.keys(messages).map((item) => (
        <li key={item}>{messages[item]}</li>
      ))}
    </ul>
  );
}
const Description = (props) => {
  const [key, setKey] = useState("home");
  const [Reviews, setReviews] = useState([]);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [description, setDescription] = useState();
  const [validator, showValidationMessage] = useValidator();
  const [errorMsg, setErrorMsg] = useState();
  const productDetail = props.productDetail;
  const BrandProducts = props.BrandProducts;
  const { id } = useParams();

  useEffect(() => {
    api
      .get(`/get-product-review?product_id=${id}`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setReviews(res.data.data);
      });
    // submitHandler()
  }, []);

  function StarRating({
    count,
    value,
    inactiveColor = "#ddd",
    size = 24,
    activeColor = "#f00",
    onChange
  }) {
    // short trick
    const stars = [...Array(5).keys()].map((i) => i + 1);

    // Internal handle change function
    const handleChange = (value) => {
      onChange(value + 1);
    };

    return (
      <div>
        {stars.map((s, index) => {
          let style = inactiveColor;
          if (index < value) {
            style = activeColor;
          }
          return (
            <FontAwesomeIcon
              key={index}
              icon={faStar}
              style={{
                color: style,
                width: size,
                height: size,
                fontSize: size
              }}
              onClick={() => handleChange(index)}
            />
          );
        })}
      </div>
    );
  }

  const [rating, setRating] = useState();
  function StarRatingDemo() {
    const handleChange = (value) => {
      setRating(value);
    };
    return (
      <div className="rating-point">
        <StarRating
          count={5}
          size={25}
          value={rating}
          activeColor={"#ED8B04"}
          inactiveColor={"#ddd"}
          onChange={handleChange}
        />
      </div>
    );
  }
  const submitHandler = async (e) => {
    e.preventDefault();
    e.target.myButton.disabled = true;
    if (validator.allValid()) {
      await api
        .post(
          `/store-product-review/${id}`,
          {
            name,
            email,
            rating,
            description
          },
          {
            headers: {
              "POS-API-KEY": "jd54elkrtlh"
            }
          }
        )
        .then(({ data }) => {
          if (data.status == 0) {
            if (typeof data.message === "object") {
              setErrorMsg(<ShowErrorMessage message={data.message} />);
            } else {
              toast.error(data.message);
            }
          } else {
            setErrorMsg("");
            toast.success(data.message);
            setName("");
            setEmail("");
            setDescription("");
            setRating("");
          }
        })
        .catch(({ data }) => {
          toast.error(data.message);
        });
    } else {
      showValidationMessage(true);
    }
    e.target.myButton.disabled = false;
  };
  return (
    <div id="shop">
      <Container className="custome-container">
        <div className="description">
          <Row>
            <Col lg={12}>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="nav nav-pills mb-3"
              >
                <Tab eventKey="home" title="Description">
                  <div className="description-para">
                    <p>{productDetail.description}</p>
                  </div>
                </Tab>
                <Tab eventKey="profile" title="Additonal information">
                  <div className="table-responsive">
                    <table className="table list-table">
                      {productDetail.product_brands?.length > 0 && (
                        <tr>
                          <td className="id-color">Brand</td>
                          {productDetail.product_brands &&
                            productDetail.product_brands.map((brand, index) => (
                              <td className="s-color">{brand.brand_name}</td>
                            ))}
                          <td class="s-color"></td>
                        </tr>
                      )}
                      <tr>
                        <td className="id-color">SKU</td>
                        <td className="s-color">{productDetail.product_sku}</td>
                        <td className="id-color"></td>
                      </tr>
                      <tr>
                        <td className="id-color">Category</td>
                        {productDetail.product_types &&
                          productDetail.product_types.map((category, index) => (
                            <td className="s-color">{category.name}</td>
                          ))}
                      </tr>
                    </table>
                  </div>
                </Tab>
                <Tab eventKey="contact" title="Review">
                  <div className="description-para">
                    {/* <p>Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet
                                                consectetur sapien. Etiam dui ipsum, viverra vel turpis ut, dignissim
                                                elementum mauris. Sed dapibus auctor scelerisque. Aenean at leo tellus.
                                                Morbi eu leo sapien. Fusce libero dolor, venenatis eget enim sed, commodo
                                                efficitur arcu. Pellentesque habitant morbi tristique senectus et netus et
                                                malesuada fames ac turpis egestas. Fusce justo ipsum, placerat vitae erat
                                                ac, porttitor tincidunt lacus. In fermentum nulla nec fermentum tempus.
                                            </p> */}
                  </div>
                  {Reviews &&
                    Reviews.map((row, index) =>
                      row.is_approved == 1 ? (
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <div className="top-viewe-active d-flex align-items-center">
                            <div className="top-viewe-icon">
                              <FontAwesomeIcon
                                className="shop-img-icon"
                                icon={faAddressCard}
                              />
                            </div>
                            <div className="top-review">
                              <div className="heading-top-review">
                                <div className="heading-top-review me-3">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="review-name">
                                      <span>{row.name}</span>
                                    </div>
                                    <div className="review-dates">
                                      <span>{row.created_at}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <StarRating
                                    count={5}
                                    size={20}
                                    value={row.rating}
                                    activeColor={"#ED8B04"}
                                    inactiveColor={"#ddd"}
                                  />
                                </div>
                              </div>
                              <p>{row.description}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}

                  <div className="heading-form-review mb-4">
                    <h5 className="ms-3">Add a review</h5>
                  </div>
                  {errorMsg && (
                    <div className="alert alert-danger alert-dismissible">
                      {errorMsg}
                    </div>
                  )}
                  <Form method="post" onSubmit={submitHandler}>
                    <div className="review-form ">
                      <span className="d-flex mb-2">Your Rating</span>
                      <StarRatingDemo />
                      <span className="d-flex mb-2">Your Reviews</span>
                      <textarea
                        id="shop-description"
                        name="description"
                        rows="4"
                        cols="50"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      ></textarea>
                      <Form.Group className="mb-3" controlId="description-form">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="Name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="description-form">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <button
                        className="log-btn-description"
                        name="myButton"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default Description;

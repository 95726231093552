import React, {useState, useEffect} from "react";
import {Container, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from "react-slick";
import { faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import api from "../helpers/api";
const Reviews = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    useEffect(() => {
        setIsLoading(true)
        api.get(`/get-testimonials`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {

            setTestimonials(res.data.data)
            setIsLoading(false)
        });
    }, []);
    var settings = {
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
        nextArrow: <FontAwesomeIcon icon={faArrowRight} />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: false
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return(
        <>
        <section>
            <div className='customer-reviews'>
                <Container fluid className='custome-container'>
                    <div className='wrap-Heading'>
                        <h2>What our customer says?</h2>   
                    </div>
                    <div className='reviews-slider'>
                        <Slider {...settings}>
                        {
                            testimonials.map((row, key)=>(
                            <div>
                                <Card className='customer-card'>
                                <div className='card-flex'>
                                        <div className='review-content'>
                                            <p>
                                                {row.description}
                                            </p>
                                        </div>
                                    <div className='customer-profile'>
                                        <div className='customer-img'><Card.Img variant="top" src={row.image} /></div>
                                        <Card.Title className='customer-title'>{row.title}</Card.Title>
                                    </div>
                                </div>
                                </Card>
                            </div>
                            ))
                        }
                        </Slider>
                    </div>
                </Container>
            </div>
        </section>
        </>
    )
}
export default Reviews;
import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import login from "../../../assets/ShopLove/images/user-icon.svg";
import logo from "../../../assets/ShopLove/images/shop-logo.png";
import { Link } from "react-router-dom";
import search from "../../../assets/ShopLove/images/search_shop.png";
import cart from "../../../assets/ShopLove/images/cart_shop.png";
import {
    faTruck,
    faXmark,
    faChevronRight, faAngleDoubleDown
} from "@fortawesome/free-solid-svg-icons";
import heart from "../../../assets/ShopLove/images/heart-icon.svg";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import api from "../../helpers/api";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Header = (props) => {
    let query = useQuery();
    const location = useLocation();
    const [categories, setCategories] = useState([]);
    const [siteSettings, setSiteSettings] = useState([]);
    const cart_items = JSON.parse(localStorage.getItem("cart"));
    const [isLoading, setIsLoading] = useState(false);
    const totalQuantity = cart_items
        ? cart_items.reduce((total, m) => total + m.quantity, 0)
        : "0";
    const user = localStorage.getItem("isLoggedIn");
    const customer = JSON.parse(localStorage.getItem("userData"));
    const wishlist_items = JSON.parse(localStorage.getItem("wishlist"));
    const WishlistCount = wishlist_items ? wishlist_items.length : "0";
    const navigate = useNavigate();
    useEffect(() => {
        api.get(`/get-product-type`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCategories(res.data.data);
            });
    }, []);
    useEffect(() => {
        setIsLoading(true);
        api.get(`/get-site-settings`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setSiteSettings(res.data.data);
                setIsLoading(false);
            });
    }, []);
    const onLogoutHandler = (e) => {
        e.preventDefault();
        localStorage.removeItem("userData");
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("isLoggedIn");
        window.location.href = "/";
    };
    const [isActive, setIsActive] = useState(false);

    const handleClick = (event) => {
        // 👇️ toggle isActive state on click
        setIsActive((current) => !current);
    };
    const [search_item, setSearchItem] = useState(query.get("term"));
    useEffect(() => {
        if (search_item != null) {
            setIsActive(true);
        }
    }, []);
    const [HomeSearch, setHomeSearch] = useState("");
    const handleChange = (e) => {
        setHomeSearch(e.target.value);
    };

    const handleSearch = async (e) => {
        e.preventDefault();

        if (HomeSearch) {
            navigate(`/catalog?term=${HomeSearch}`);
        }
    };
    var link = window.location.href.split("/");

    const [displayedCategories, setDisplayedCategories] = useState(3);
    // Load more categories when the "Load More" button is clicked
    const loadMoreCategories = () => {
        setDisplayedCategories(displayedCategories + 3);
    };
    return (
        <div className="Header">
            <Navbar expand="lg">
                <Container
                    fluid
                    className="custome-container position-relative"
                >
                    {isLoading ? (
                        <div className="header-logo">
                            <Nav.Link as={Link} to="/">
                                <img src={logo} alt="" />
                            </Nav.Link>
                        </div>
                    ) : (
                        <div className="header-logo">
                            <Nav.Link as={Link} to="/">
                                <img src={siteSettings.logo} alt="" />
                            </Nav.Link>
                        </div>
                    )}
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="home-bar my-2 my-lg-0">
                            <Nav.Link
                                as={Link}
                                to="/"
                                className={link[3] == "" && "menu-active-nav"}
                            >
                                Home
                            </Nav.Link>
                            {/* <NavDropdown title="Categories" className={query.has('type') ? "menu-active-nav header-dropdown" : "header-dropdown"} id="navbarScrollingDropdown">
                        {categories.map((item, index) => (
                        <NavDropdown.Item className={link[3] == `catalog?type=${item.name}` && "menu-active-navdrop"}  href={`/catalog?type=${item.name}`}>{item.name}</NavDropdown.Item>
                        ))}
                        </NavDropdown> */}
                            <div>
                                <a className="item-pointer nav-link">
                                    <a className="dropdown-toggle opacity">Categories</a>
                                    <div
                                        className="dropdown-menu-custom dropdown-menu-list p-0"
                                        role="menu"
                                        aria-labelledby="dropdownMenu"
                                    >
                                        <ul className="dropdown-menu-custom">
                                            {categories?.slice(0, displayedCategories).map((item, index) => (
                                                <li className="submenu-li">
                                                    <div className="d-flex w-100 justify-content-between align-items-center submenu-li-x">
                                                    <a
                                                        href={`/catalog?type=${item.name}`}
                                                    >
                                                        {item?.name}
                                                    </a>
                                                    {item
                                                                ?.sub_categories
                                                                ?.length >
                                                                0 && (
                                                                <a className="text-black">
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faChevronRight
                                                                        }
                                                                    />
                                                                </a>
                                                            )}
                                                    </div>
                                                    {item?.sub_categories
                                                        ?.length > 0 && (
                                                        <div
                                                            role="menu"
                                                            aria-labelledby="dropdownMenu"
                                                        >
                                                            <ul className="p-0">
                                                                <li className="dropdown-submenu-custom multi-sub-menu">
                                                                    <ul className="p-0">
                                                                        {item?.sub_categories.map(
                                                                            (
                                                                                row,
                                                                                index
                                                                            ) => (
                                                                                <li className="px-2 py-1">
                                                                                    <a
                                                                                        href={`/catalog?type=${row.name}`}
                                                                                    >
                                                                                        {
                                                                                            row?.name
                                                                                        }
                                                                                    </a>
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )}
                                                </li>
                                            ))}
                                            {displayedCategories <
                                                categories?.length && (
                                                <li className="submenu-li">
                                                    <a
                                                        style={{cursor:"pointer"}}
                                                        className="d-flex justify-content-center pt-2"
                                                        onClick={
                                                            loadMoreCategories
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            className="text-black"
                                                            icon={
                                                                faAngleDoubleDown
                                                            }
                                                        />
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </a>
                            </div>
                            <Nav.Link
                                href="/catalog"
                                className={
                                    link[3] == "catalog" && "menu-active-nav"
                                }
                            >
                                Catalog
                            </Nav.Link>
                            {/* <Nav.Link as={Link} to="/cms/about-us">About Us</Nav.Link>
                        <Nav.Link as={Link} to="/faq">FAQs</Nav.Link> */}
                            <NavDropdown
                                title="More"
                                className={
                                    link[3] == "cms" || link[3] == "faq"
                                        ? "menu-active-nav header-dropdown"
                                        : "header-dropdown"
                                }
                                id="navbarScrollingDropdown"
                            >
                                <NavDropdown.Item
                                    as={Link}
                                    to="/cms/about-us"
                                    className={
                                        link[4] == "about-us" &&
                                        "menu-active-navdrop "
                                    }
                                >
                                    About Us
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    as={Link}
                                    to="/faq"
                                    className={
                                        link[3] == "faq" &&
                                        "menu-active-navdrop "
                                    }
                                >
                                    Faqs
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        {/* <Nav className="ms-auto my-2 my-lg-0">
                    {!user && 
                        <div className="d-sm-flex align-items-center">
                            <a href="/login">
                                <button className="header-button">
                                 <span>Sign In</span>
                                </button>
                            </a>
                            <a href="/signup">
                                <button className="header-button-active">
                                 <span>Sign Up</span>
                                </button>
                            </a>
                        </div>
                        <div className="user-icon">
                           <a href="/user">
                                <img src={user} alt="" />
                            </a>
                        </div>
                    </Nav> */}
                    </Navbar.Collapse>
                    <Nav className="icon-bar my-2 my-lg-0">
                        <div className="header-icons">
                            <div className="search header me-1">
                                {isActive == false && (
                                    <img src={search} onClick={handleClick} />
                                )}
                                {isActive == true && (
                                    <FontAwesomeIcon
                                        className="cross-header"
                                        icon={faXmark}
                                        onClick={handleClick}
                                    />
                                )}
                            </div>
                            <Nav.Link
                                className="me-2 ms-2"
                                as={Link}
                                to="/track-order"
                            >
                                <div className="search me-1">
                                    <FontAwesomeIcon icon={faTruck} />
                                </div>
                            </Nav.Link>
                            <Nav.Link
                                className="me-0 ms-0"
                                as={Link}
                                to="/wishlist"
                            >
                                {/* <div className="cart position-relative d-inline-block">
                                <img className="img-fluid" src={cart} alt="" />
                                <span>{totalQuantity}</span>
                            </div> */}
                                <div className="bottom-header-heart position-relative d-inline-block me-1">
                                    {/* <Link className="bottom-header-img" to="/wishlist"> */}
                                    <img
                                        className="img-fluid"
                                        src={heart}
                                        alt=""
                                    />
                                    {/* </Link> */}
                                    {/* <span>{props.lengthWishlist ? props.lengthWishlist : WishlistCount}</span> */}
                                    <span>
                                        {props.lengthWishlist
                                            ? props.lengthWishlist
                                            : WishlistCount}
                                    </span>
                                </div>
                            </Nav.Link>
                            <Nav.Link className="ms-2" as={Link} to="/cart">
                                <div className="cart position-relative d-inline-block me-3">
                                    <img
                                        className="img-fluid"
                                        src={cart}
                                        alt=""
                                    />
                                    <span>{totalQuantity}</span>
                                </div>
                            </Nav.Link>
                        </div>
                        {/* {isActive == true && <form className='header-form'onSubmit={handleSearch} >
                            <input className={isActive ? 'w100' : ''}  type="text" placeholder="Search" value={data}
                            onChange={(e) => { setData(e.target.value) }}/>
                            <button className="header-search-button" type="submit">
                            <img src={search}/>
                            </button>
                        </form>} */}
                    </Nav>
                    <Nav className="header-btn my-2 my-lg-0">
                        {!user && (
                            <div className="d-sm-flex align-items-center">
                                <Nav.Link
                                    className="me-0 ms-0"
                                    as={Link}
                                    to="/login"
                                >
                                    <button className="header-button">
                                        <span>Sign In</span>
                                    </button>
                                </Nav.Link>
                                <Nav.Link
                                    className="me-0 ms-0"
                                    as={Link}
                                    to="/signup"
                                >
                                    <button className="header-button-active">
                                        <span>Sign Up</span>
                                    </button>
                                </Nav.Link>
                            </div>
                        )}
                        {/* {
                        user &&
                        <div className="d-sm-flex align-items-center">
                            <div className="user-profile">
                               <Dropdown className="d-flex ">
                                    <div className="me-2">
                                    </div>
                                    <Dropdown.Toggle variant="none" className="login-header" id="dropdown-basic-top-change">
                                      <span className="text-flow">{customer.first_name.charAt(0).toUpperCase() + customer.first_name.slice(1)}</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="header-down">
                                        <Dropdown.Item href="/myaccount">My Account</Dropdown.Item>
                                        <Dropdown.Item href="/myorder">My Orders</Dropdown.Item>
                                        <Dropdown.Item href="/wishlist">My Wishlist</Dropdown.Item>
                                        <NavDropdown.Item href="#" onClick={onLogoutHandler}>Logout</NavDropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    } */}
                    </Nav>
                    {!user && (
                        <div className="user-icon">
                            <Link to="/login">
                                <img src={login} alt="" />
                            </Link>
                        </div>
                    )}
                    {user && (
                        <div className="user-profile">
                            <Dropdown className="d-flex ">
                                <div className="me-2"></div>
                                <Dropdown.Toggle
                                    variant="none"
                                    className="login-header"
                                    id="dropdown-basic-top-change"
                                >
                                    <span className="text-flow">
                                        {customer.first_name
                                            .charAt(0)
                                            .toUpperCase() +
                                            customer.first_name.slice(1)}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="header-down">
                                    <Dropdown.Item as={Link} to="/myaccount">
                                        My Account
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/myorder">
                                        My Orders
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/wishlist">
                                        My Wishlist
                                    </Dropdown.Item>
                                    <NavDropdown.Item
                                        href="#"
                                        onClick={onLogoutHandler}
                                    >
                                        Logout
                                    </NavDropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}
                    {/* <div className="header-search-form-bottom d-flex align-items-center"> */}
                    {isActive == true && (
                        <form
                            className="header-form d-flex align-items-center"
                            onSubmit={handleSearch}
                        >
                            <input
                                className={isActive ? "w100" : ""}
                                type="text"
                                placeholder="Search"
                                onChange={
                                    location.pathname == "/catalog" ||
                                    location.pathname == "/Search"
                                        ? props.handleChange
                                        : handleChange
                                }
                                value={props.search_catalog}
                            />
                            {location.pathname != "/catalog" && (
                                <button
                                    className="header-search-button"
                                    type="submit"
                                >
                                    <img src={search} />
                                </button>
                            )}
                        </form>
                    )}
                    {/* </div> */}
                </Container>
            </Navbar>
        </div>
    );
};
export default Header;

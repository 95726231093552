import React, {useState, useEffect} from "react";
import { Container,Row,Col } from "react-bootstrap";
import monitor from "../../../../assets/HairBeauty/images/monitor.png";
import mobile from "../../../../assets/HairBeauty/images/mobile.png";
import router from "../../../../assets/HairBeauty/images/router.png";
import api from "../../../helpers/api";
const Homebanner =()=>{
    
    const [siteSettings, setSiteSettings] = useState([]);
    const [Banner, setBanner] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.get(`/get-site-settings`, {
            headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
            }
        }).then(res => {
            setSiteSettings(res.data.data)
            setIsLoading(false)
        });
        api.get(`/fetch_banners`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh',
            }
          }).then(res => {
            setBanner(res.data.data[0])
            setIsLoading(false)
        });
    }, []);
    return(
       <div className="banner-margin" id="banner">
            <Row>
                <Col>
                
                    <div className="banner-cont justify-content-sm-between" style={{ background: `url(${Banner.cover_img})`}}>
                        <div className="text-center">
                            {/* <h1>{Banner.title}</h1>
                            <span>{Banner.description}</span> */}
                             <h1 className="banner-love">{Banner.title}</h1>
                            <span className="banner-mekup">{Banner.small_heading}</span>
                            <span className="banner-off text-align-right">{Banner.description}</span>
                            
                        </div>
                     

                    </div>
                </Col>
            </Row>
            <div className="banner-category">
            {/* <Container>
            <Row>
                <Col xxl={4} lg={4}>
                    <div className="discount d-flex">
                        <div>
                            <span>30% Off</span>
                            <h3>Computers</h3>
                            <button className="btn">SHOP NOW</button>
                        </div>
                        <div className="image">
                            <img className="img-fluid" src={monitor} alt=""/>
                        </div>
                    </div>
                </Col>
                <Col xxl={4} lg={4}>
                    <div className="discount1 d-flex">
                        <div>
                            <span>20% Off</span>
                            <h3>Mobile Phones</h3>
                            <button className="btn">SHOP NOW</button>
                        </div>
                        <div className="image"><img className="img-fluid" src={mobile} alt=""/></div>
                    </div>
                </Col>
                <Col xxl={4} lg={4}>
                    <div className="discount2 d-flex">
                        <div>
                            <span>25% Off</span>
                            <h3>Accessories</h3>
                            <button className="btn">SHOP NOW</button>
                        </div>
                        <div className="image">
                            <img className="img-fluid" src={router} alt=""/>
                        </div>
                    </div>
                </Col>
            </Row>
            </Container> */}
            </div>
       </div>

    )
}
export default Homebanner;
import React, {useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const Addcart = (props) => {
    const currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : "Rs";
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);
    const cart = props.cart;
    const cart_items = props.cart_items;
    return (
        <div id="add-cart">
        <Container className="custome-container">
            <Row>
            <Col>
                    <div className="table-responsive mb-0">
                        <table className="table list-table">
                            <tr className="add-cart-items">
                                <th></th>
                                <th></th>
                                <th>Products</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Total</th>
                            </tr>
                            {
                                cart_items != "" ?
                                cart != null && (
                                    cart.map((row, key)=>(
                                        <tr className="border-0 ">
                                            <td onClick={() => props.RemoveCart(row.variant_id, row.prooduct_id)}>
                                                <FontAwesomeIcon className="cart-active" icon={faXmark} />
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-center">
                                                <div className="img-sec">
                                                    <img className="img-fluid" src={row.first_variant_image == null ? row.image : row.first_variant_image} alt="" />
                                                </div>
                                                </div>
                                            </td>
                                            <td>
                                            {row.variant_name == "" ? row.product_name : row.variant_name}
                                            </td>
                                            <td className="s-color">
                                                <div className="d-flex input-adj align-items-center justify-content-center">
                                                    <button className="btn2" onClick={() => { props.DecreaseChange(row.variant_id !="" ? row.variant_id : row.prooduct_id);
                                                }}>
                                                        <FontAwesomeIcon icon={faMinus} />
                                                    </button>
                                                    <div>
                                                        <input type="text" placeholder={row.quantity} disabled />
                                                    </div>
                                                    <button className="btn1" onClick={() => { props.IncreaseChange(row.variant_id !="" ? row.variant_id : row.prooduct_id);
                                                }}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </button>
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    row.discountedAmount ?
                                                        <div className="price">
                                                            <div className="d-flex align-items-center">
                                                                <span className="d-price me-2"><span className="currency-font">{currency+" "}</span><del>{(parseFloat(row.price).toFixed(2))}</del> | </span>
                                                                <span className="r-price"><span className="currency-font">{currency+" "}</span>{(parseFloat(row.price - row.discountedAmount).toFixed(2))}</span>
                                                            </div>
                                                        </div>
                                                    :
                                                    <div><span className="currency-font">{currency+" "}</span>{row.price}</div>
                                                }
                                            </td>
                                            <td><span className="currency-font">{currency+" "}</span>{(row.discountedAmount ? parseFloat((row.price - row.discountedAmount) * row.quantity).toFixed(2) : parseFloat(row.price * row.quantity).toFixed(2))}</td>
                                        </tr>
                                    ))
                                )
                                :<tr>
                                <td colspan="6">
                                <p className="text-center">No Product Found</p>
                                </td>
                                </tr>
                            }
                            

                        </table>
                    </div>
            </Col>
            </Row>
            <div className="coupen-outside">
                <Row>
                    <Col lg={6}>
                    {/* <div className="coupen-inp d-flex">
                        <div>
                        <input type="coupen" placeholder="Enter Coupon Code" />
                        </div>
                        <button className="coupen-btn">Apply Coupon</button>
                    </div> */}
                    </Col>
                    <Col lg={6}>
                    <div className="total">
                        <span className="price currency-font">Total Amount:
                        {currency+" " +(cart != null && cart.reduce((prev, cur) => ((cur.discountedAmount ? cur.price - cur.discountedAmount : cur.price) * cur.quantity) + prev, 0).toFixed(2))}</span>
                        <span>
                        Shipping, taxes & discounts will be calculated at checkout
                        </span>
                    </div>
                    </Col>
                </Row>
            </div>
            <Row>
            <Col>
                    <div className="last-button">
                    <button
                        className="shopbtn"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate("/catalog");
                            }}
                    >
                        Continue Shopping
                    </button>
                    {
                    cart_items != "" &&
                    <button
                        className="shopbtn ms-sm-4 mb-0"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate("/payment");
                            }}
                            >
                        Proceed to Checkout
                    </button>
                    }
                    </div>
            </Col>
            </Row>
        </Container>
        </div>
    );
};
export default Addcart;

import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import banner from "../../../assets/Furniture/images/banner-img.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import api from "../../helpers/api";
const HomeBanner = (props) => {
    const [sliderSite, setSiteSlider] = useState([]);
    const siteSettings = props.siteSettings;
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.get(`/fetch_banners`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setSiteSlider(res.data.data)
            setIsLoading(false)
        });
    }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="Home-banner">
          <Row>
              <Col>
                <div className="banner-slider">
                  <Slider className="banner-slider" {...settings}>
                      {sliderSite.length > 0 &&
                        sliderSite.map((item, index) => (
                          <div className="slider-cont">
                            <div className="banner-active">
                              <div className="position-relative">
                                 <span>{item.title}</span>
                                  <h3 className="banner-slider-color">{item.small_heading} </h3>
                              </div>
                                
                                <div className="banner-paragraph">
                                    <p>
                                      {item.description}
                                    </p>
                                </div>
                                <a href="/catalog"><button className="banner-button" >{item.button_text}</button></a>
                            </div>
                          </div>
                        ))
                      }
                  </Slider>
                </div>
                <div className="banner-img">
                  <img src={siteSettings ? siteSettings.banner_image : banner} alt="" />
                </div>
              </Col>
          </Row>
      </div>
  
  );
};
export default HomeBanner;

import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "./BottomFooter.css";
import api from "../../helpers/api";
const BottomFooter = () => {
    const [siteSettings, setSiteSettings] = useState([]);
    useEffect(() => {
        api.get(`/get-site-settings`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setSiteSettings(res.data.data);
            });
    }, []);

    // Replace the site settings copyright with current year
    const originalString = siteSettings?.site_copyright;
    const currentYear = new Date().getFullYear(); 
    const copyright = originalString?.replace(/\d{4}/, currentYear);
    return (
        <Container>
            <div id="bot-footer">
                <span className="bottom-footer-heading">
                    {copyright}
                </span>
            </div>
        </Container>
    );
};

export default BottomFooter;

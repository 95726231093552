import React, { useState, useEffect } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import api from "../../../helpers/api";
const AboutUs = (props) => {
    const slug = "about-us";
    const [AboutusPages, setAboutusPages] = useState([]);
    const [content, setContent] = useState([]);
    const siteSettings = props.siteSettings;
    useEffect(() => {
        api.get(`/cms-page/${slug}`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setAboutusPages(res.data);
                setContent(res.data.content.substring(0, 200) + "...");
            });
    }, []);
    return (
        <div className="about-us">
            <div className="section-heading text-center">
                <h3>About us</h3>
            </div>
            <div className="container-width">
                <Row>
                    <Col lg="5" className="d-flex align-items-center">
                        <div
                            className="about-us-content"
                            data-aos="fade-right"
                            data-aos-duration="2500"
                        >
                            <h2 className="mb-5">{AboutusPages.title}</h2>
                            <p
                                className="mb-5"
                                dangerouslySetInnerHTML={{ __html: content }}
                            ></p>
                            <a href="/cms/about-us">
                                <Button>About US</Button>
                            </a>
                        </div>
                    </Col>
                    <Col lg="7">
                        <div
                            className="about-us-img"
                            data-aos="fade-left"
                            data-aos-duration="2500"
                        >
                            <Image src={siteSettings.default_image_1} fluid />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AboutUs;

import React, {useState, useEffect} from "react";
import Middlefooter from "../Middlefooter/Middlefooter";
import Topfooter from "../Topfooter/Topfooter";
import Header from './../Header/Header';
import Account from './Account';
import Bread from './../Cart/Bread';
import PetStoreHeader from "../../PetStore/HomePage/Header/Header";
import NewUpdates from '../../PetStore/HomePage/NewUpdates/NewUpdates';
import PetStoreFooter from '../../PetStore/HomePage/Footer/Footer';
import JewelleryHeader from '../../Jewellery/Header/Header'
import JewelleryTopFooter from '../../Jewellery/Home-Page/Subscriber/Subscriber'
import JewelleryFooter from '../../Jewellery/Footer/Footer'
import ShopHeader from "../../ShopLove/Header/Header";
import ShopBottomFooter from "../../ShopLove/BottomFooter";
import FlowerHeader from "../../FlowerShop/HomePage/Header/Header";
import FlowerFooter from "../../FlowerShop/HomePage/Footer/Footer";
import FlowerMidFooter from "../../FlowerShop/HomePage/MiddleFooter/MiddleFooter";
import CafeHeader from "../../CafeFood/Header/Header";
import CafeFooter from "../../CafeFood/BottomFooter";
import { ToastContainer, toast } from 'react-toastify';
import api from "../../helpers/api";
function ShowErrorMessage(props) {
    const messages = props.message;
    const listItems = Object.keys(messages).map((key) => {
  
      return (<li className="sign-form-li">{props.message[key]}</li>)
    })
    return (
  
      <ul>{listItems}</ul>
    );
  
}
const Myaccount=()=>{
    const NewThemeID = JSON.parse(localStorage.getItem('ThemeID'));
    const [themeId, setThemeId] = useState(NewThemeID?NewThemeID:[]);
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setThemeId(res.data.data.store.theme_id)
            localStorage.setItem('ThemeID', res.data.data.store.theme_id);
        });
    }, []);
    // customer profile info
    const customer = JSON.parse(localStorage.getItem('userData'));
    const [first_name, setFirstName] = useState(customer?.first_name);
    const [last_name, setLastName] = useState(customer?.last_name);
    const [email, setEmail] = useState(customer?.email);
    const [phone, setPhone] = useState(customer?.phone);
    const [gender, setGender] = useState(customer?.gender);
    const [showPersonalProfileModal, setShowPersonalProfileModal] = useState(false);
    const [profileName, setProfileName] = useState();
    const [errorMsg, setErrorMsg] = useState(); // missing form details Or Invalid case
    const handleProfileShow = () => setShowPersonalProfileModal(true);
    // close modal
    const handleClose = event => {
        event.preventDefault();
        setShowPersonalProfileModal(false);
    };

    const submitProfileInfo = async (e) => {
        e.preventDefault();
        await api.post(`/add-profile?user_id=${customer.user_id}`, {
            first_name,
            last_name,
            email,
            gender,
            phone
        }, {
            headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
            }
        }).then(({ data }) => {
            if (data.status == 0) {
            if(typeof data.message === 'object')
            {
              setShowPersonalProfileModal(true)
              setErrorMsg(<ShowErrorMessage message={data.message} />)
            }
            else
            {
                setErrorMsg(data.message);
            }
            }
            else {
                setErrorMsg("")
                setShowPersonalProfileModal(false);
                localStorage.setItem('userData', JSON.stringify(data.data));
                setProfileName(first_name)
                toast.success(data.message);
            }

        }).catch(({ data }) => {
            toast.error("something went wrong");
        })
    }
    return(

        <div className="wrapper">
            <ToastContainer/>
            {themeId == 1 && <FlowerHeader/>}
            {themeId == 2  && <ShopHeader/>}
            {themeId == 3 && <Header/>}
            {themeId == 4 && <CafeHeader/>}
            {themeId == 8 && <PetStoreHeader />}
            {themeId == 9 && <JewelleryHeader />}
            <Bread breadTitle="Manage My Account"/>
            <Account customer={customer} errorMsg={errorMsg} handleProfileShow={handleProfileShow} handleClose={handleClose} submitProfileInfo={submitProfileInfo} showPersonalProfileModal={showPersonalProfileModal} first_name={first_name} onFirstNameChange={setFirstName} last_name={last_name} onLastNameChange={setLastName} email={email} onEmailChange={setEmail} phone={phone} onPhoneChange={setPhone} gender={gender} onGenderChange={setGender}/>
            {themeId == 1 && <FlowerFooter/>}
            {themeId == 1 && <FlowerMidFooter/>}
            {themeId == 2 && <ShopBottomFooter />}
            {/* {themeId == 3 &&<Topfooter/>} */}
            {themeId == 3 && <Middlefooter/>}
            {themeId == 4 && <CafeFooter/>}
            {themeId == 8 && <NewUpdates/>}
            {themeId == 8 && <PetStoreFooter/>}
            {themeId == 9 && <JewelleryTopFooter />}
            {themeId == 9 && <JewelleryFooter />}
        </div>
    )
}
export default Myaccount;
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image } from 'react-bootstrap';
import {Tooltip} from "react-bootstrap";
import { Link } from "react-router-dom";
const NonSliderProducts = (props) => {
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : "Rs";
    const wishlist = props.wishlist == undefined ? wishlist_items : props.wishlist;
    return (
        <div className='best-seller-content not-best-seller-content flex-wrap '>
        {props.products.map((row, index) => (
            
                <div className='deal-day-content not-slider' data-aos="fade-right" data-aos-duration="2500">
                    { row.display_name !== null ?
                        <div className="discount-tag">
                            <span>{row.display_name}</span>
                        </div>
                    :
                    ''
                    }
                    <div className="heart-icon" key={index} data-id={row.id} onClick={() => { props.addToWishlist(row.id); }}>
                    {
                            (JSON.stringify(wishlist.find((item) => item.id === row.id))) ?
                            <svg xmlns="http://www.w3.org/2000/svg" className="top-product-svg" width="20" height="20"
                            viewBox="0 0 27 23">
                            <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                transform="translate(-0.823 -2.997)" fill="none"
                                stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" />
                        </svg>

                            : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                            viewBox="0 0 27 23">
                            <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                transform="translate(-0.823 -2.997)" fill="none"
                                stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="3" />
                        </svg>
                        }
                    </div>
                    <Link className="product-text" to={`/shop/${row.id}`}>
                    <div className='deal-day-content-img mb-4'>
                        <Image className="img-fluid" src={row.image} />
                    </div>
                    <div className='content-wrapper'>
                        <h6>PLANT</h6>
                        <Tooltip title={row.product_name}>
                          <h5>{row.product_name}</h5>
                        </Tooltip>
                        <h5 className='mb-3'>{row.product_name}</h5>
                        {
                            row.discount_offer_id != null ?
                            <div className='deal-day-content-info d-flex align-items-center'>
                                <h6 className='me-2'>{currency + " " +(row.price - row.discounted_amount)} | </h6>
                                <p><del>{currency + " " + row.price}</del></p>
                            </div>
                            :
                            <div className='deal-day-content-info d-flex align-items-center'>
                                <h6 className='me-4'>{currency + " " + row.price}</h6>
                            </div>
                        }
                        
                    </div>
                    </Link>
                </div>
        ))}
        </div>
    )
}
export default NonSliderProducts;

import React, {useState, useEffect} from "react";
import BottomHeader from "./../../sections/BottomHeader/BottomHeader";
import MiddleHeader from "./../../sections/MiddleHeader/MiddleHeader";
import TopHeader from './../../sections/TopHeader/TopHeader';
import Bread from "./Bread/Bread";
import Product from "./Product";
import TopFooter from "./../../sections/TopFooter/TopFooter";
import MiddleFooter from "./../../sections/MiddleFooter/Middlefooter";
import BottomFooter from './../../sections/BottomFooter/BottomFooter';
import SalonHeader from "../../../HairBeauty/BottomHeader/BottomHeader";
import SalonTopFooter from "../../../HairBeauty/TopFooter/TopFooter";
import SalonMiddleFooter from "../../../HairBeauty/MiddleFooter/Middlefooter";
import SalonBottomFooter from "../../../HairBeauty/BottomFooter/BottomFooter";
import KidMidHeader from "../../../BearKid/MiddleHeader/MiddleHeader";
import KidBotomHeader from "../../../BearKid/BottomHeader/BottomHeader";
import KidTopFooter from "../../../BearKid/TopFooter/TopFooter";
import KidMiddleFooter from "../../../BearKid/MiddleFooter/Middlefooter";
import KidBottomFooter from "../../../BearKid/BottomFooter/BottomFooter";
import api from "../../../helpers/api";
function Wishlist() {
    const [showContent, setShowContent] = useState(0);
    const NewThemeID = JSON.parse(localStorage.getItem('ThemeID'));
    const [themeId, setThemeId] = useState(NewThemeID);
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setThemeId(res.data.data.store.theme_id)
            localStorage.setItem('ThemeID', res.data.data.store.theme_id);
        });
    }, []);
    useEffect(() => {
        const TotalQty = JSON.parse(localStorage.getItem('totalQuantity')); 
        setShowContent(TotalQty)
    }, []);
  return (
    <div className="wrapper">
      {themeId == 7 && <TopHeader/>}
      {themeId == 7 && <MiddleHeader showContent={showContent}/>}
      {themeId == 7 && <BottomHeader/>}
      {themeId == 5 && <SalonHeader/>}
      {themeId == 6 && <KidMidHeader showContent={showContent}/>}
      {themeId == 6 && <KidBotomHeader/>}
      <Bread/>
      <Product/>
      {themeId == 7 && <TopFooter/>}
      {themeId == 7 && <MiddleFooter/>}
      {themeId == 7 && <BottomFooter/>}
      {themeId == 5 && <SalonTopFooter/>}
      {themeId == 5 && <SalonMiddleFooter/>}
      {themeId == 5 && <SalonBottomFooter/>}
      {themeId == 6 && <KidTopFooter/>}
      {themeId == 6 && <KidMiddleFooter/>}
      {themeId == 6 && <KidBottomFooter/>}
    </div>
  );
}
export default Wishlist;
import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { SpinnerCircularFixed} from 'spinners-react';
import SliderProducts from '../SliderComponents/SliderProducts';
import NonSliderProducts from '../SliderComponents/NonSliderProducts';
import api from "../../helpers/api";
const Ourproducts =(props)=>{
    const [categories, setCategories] = useState([]);
    const first = categories[0];
    const [products, setProducts] = useState([]);
    const [productTypeID, setProductTypeID] = useState([]);
    const [productTypeName, setProductTypeName] = useState([]);
    const addToWishlist = props.addToWishlist;
    const wishlist = props.wishlist;
    const [isLoading, setIsLoading] = useState(false);
    const settings = {
        prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
        nextArrow: <FontAwesomeIcon icon={faArrowRight} />,
    };
    
    useEffect(() => {
        setIsLoading(true)
        api.get(`/get-product-type`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {

            setCategories(res.data.data)

            setIsLoading(false)
        });
    }, []);
    useEffect(() => {
        showCategoriesByProduct()
    }, [first]);
    function showCategoriesByProduct(category_id = first?.id,category_name = first?.name) {
        setIsLoading(true)
        var url = `/get-product-by-category/` + category_id;
        api.get(url, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
        }).then(res => {
            setProducts(res.data.data)
            setProductTypeID(category_id)
            setProductTypeName(category_name)
            setIsLoading(false)
        });
    };


    return(
        <div id="shop">
            <Container fluid className="custome-container">
            <div className="shop-pro">
                <Row>
                    <Col>
                        <div className="ouer-products-heading">
                          <h2>Our Products</h2>
                        </div>
                            <div className="nav " id="nav-tab" role="tablist">
                                <ul className="nav nav-pills " id="pills-tab" role="tablist">
                                    {categories.map((item, index) => (
                                        <li className="nav-item">
                                        <a className={(item.id == productTypeID || item.name == productTypeName ? "nav-link-chair" : "nav-link")} onClick={() => showCategoriesByProduct(item.id,item.name)} id="pills-tab">{item.name}</a>
                                    </li>
                                    ))}
                                </ul>
                           </div>

                    </Col>
                </Row>
                {
                isLoading ? 
                <Row>
                    <div className="text-center pt-4 mb-4">
                        {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                        <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                    </div>
                </Row>
                :
                <Row>
                    <Col className="slider-color">
                        {products.length > 0 ?
                            products.length > 4 ?
                            <SliderProducts products = {products} addToWishlist={addToWishlist} wishlist={wishlist}/>
                            :
                            <NonSliderProducts products = {products} addToWishlist={addToWishlist} wishlist={wishlist}/>
                        : <p className="category-data d-flex justify-content-center">No Product found in this Category</p>
                        }

                    </Col>
                    <div className="our-product-btn">
                        {/* <button><a href={`/catalog?type=${productTypeName}`}>See More</a></button> */}
                       <a className="see-more-btn" href={`/catalog?type=${productTypeName}`}>See More</a>                    
                    </div>
                </Row>
                }
                </div>
            </Container>
        </div>


    )
}
export default Ourproducts;

import React, { useState, useEffect } from "react";
import './Banner.css'
import Container from 'react-bootstrap/Container';
import api from "../../../helpers/api";
function Banner(props) {
  const siteSettings = props.siteSettings;
  const [Banner, setBanner] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true)
    api.get(`/get-product-type`, {
        headers: {
          'POS-API-KEY' : 'jd54elkrtlh'
        }
      }).then(res => {
        setCategories(res.data.data)
        setIsLoading(false)
    });
    setIsLoading(true)
    api.get(`/fetch_banners`, {
      headers: {
        'POS-API-KEY' : 'jd54elkrtlh',
      }
    }).then(res => {
      setBanner(res.data.data[0])
      setIsLoading(false)
  });

  }, []);
  return(
    <>
        <div>
          <div className='banner bg' style={{ background: `url(${siteSettings.banner_image})`}}>
            <Container fluid className='custom_container'>
              <div className='row'>
                <div className='col-md-7'>
                  <div className='wrap-banner-text'>
                    <span className='text-white'>{Banner.title}</span>
                    <h1>{Banner.description}</h1>
                    <span className='d-block theme-span'>AVAILABLE IN STOCK</span>
                    <div className="theme-btn">
                      <a href='/catalog' className='black-btn btn-mt'><span>SHOP NOW</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <div className='banner-card'>
            <Container fluid className='custom_container'>
              <div className='row'>
              {
                categories.slice(0,3).map((item, index) => (
                  <div className='banner-card-padding col-md-4 mb-md-0 mb-5'>
                    <div className='shop-card'>
                      <div className='shop-card-Banner'>
                        <img className='img-fluid' src={item.image} alt='Engagement Rings'></img>
                      </div>
                      <div className='shop-card-footer'>
                        <h2 className='card-h2'>{item.name}</h2>
                        <span className='card-span'><a href={`/catalog?type=${item.name}`}>SHOP NOW</a></span>
                      </div>
                    </div>
                  </div>
                ))
              }
              </div>
            </Container>
          </div>
        </div>
    </>
  )
}

export default Banner;
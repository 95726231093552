import React from "react";
import { Image } from 'react-bootstrap';
const FlowerShop = (props) => {
    const siteSettings = props.siteSettings;
    return (
        <div className='flower-shop'>
            <div className='flower-shop-heading text-center'>
                <h5>Share your setups with us on instagram.</h5>
                <h2>#flowershop</h2>
            </div>
            <div className='gallery-image'>
                <Image src={siteSettings.default_image_2} fluid/>
            </div>
        </div>
    )
}

export default FlowerShop;

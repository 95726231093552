import React, {useState, useEffect} from "react";
import { Container,Row,Col } from "react-bootstrap"
import { Link } from 'react-router-dom';
import { SpinnerCircularFixed} from 'spinners-react';
import api from "../../helpers/api";
const Tracking =()=>{
    const order_id = localStorage.getItem('order_id');
    const [siteSettings, setSiteSettings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const order_item = order_id.toUpperCase();
    useEffect(() => {
        window.scrollTo(0, 0)
        localStorage.setItem('cart', JSON.stringify([]));
        localStorage.setItem('totalQuantity', 0);
    }, []);
    useEffect(() => {
        setIsLoading(true)
        api.get(`/get-site-settings`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setSiteSettings(res.data.data)
          setIsLoading(false)
        });
    }, []);
    return(
        <div className="order-tracking">
            <Container>
            {isLoading ? 
                <Row>
                    <div className="text-center pt-4 mb-4">
                        {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                        <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                    </div>
                </Row>
                :
                <Row>
                    <Col>
                      <div className="order-box">
                      <div className="heading-order-tracking">
                          <h4 className="mb-0"> {"Thank You For Choosing" + " " +siteSettings.title} </h4>
                          <span className="mb-0">{"Your order ID is : " + order_id}</span>
                          <br/>
                          
                      </div>
                      <div className="tracking-button">
                      <Link to={`/`}>
                        <button className="log-btn-tracking">Continue Shopping</button>
                        </Link>
                      </div>
                      </div>
                    </Col>
                </Row>
                }
            </Container>
        </div>

    )
}
export default Tracking;
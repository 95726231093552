import React, { useState, useEffect } from 'react'
import {Container,Row,Col,NavDropdown,Dropdown} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../../assets/BearKid/images/bear-kid-logo.png'
import heart from '../../../assets/BearKid/images/heart-icon.svg'
import shopping from '../../../assets/BearKid/images/shopping-cart.svg'
import search from '../../../assets/BearKid/images/search-icon.svg'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useLocation } from 'react-router-dom'
import api from "../../helpers/api";
function useQuery() {
  return new URLSearchParams(useLocation().search)
}
const MiddleHeader = (props) => {
  let query = useQuery()
  const location = useLocation();
  const user = localStorage.getItem('isLoggedIn')
  const customer = JSON.parse(localStorage.getItem('userData'))
  const cart_items = JSON.parse(localStorage.getItem('cart'))
  const wishlist_items = JSON.parse(localStorage.getItem('wishlist'))
  const WishlistCount = wishlist_items ? wishlist_items.length : '0'
  const totalQuantity = cart_items ? props.showContent : '0'
  const [isLoading, setIsLoading] = useState(false)
  const [siteSettings, setSiteSettings] = useState([])
  useEffect(() => {
    setIsLoading(true)
    api.get(`/get-site-settings`, {
        headers: {
          'POS-API-KEY': 'jd54elkrtlh',
        },
      })
      .then((res) => {
        setSiteSettings(res.data.data)
        setIsLoading(false)
      })
  }, [])

  const onLogoutHandler = (e) => {
    e.preventDefault()
    localStorage.removeItem('userData')
    localStorage.removeItem('jwt_token')
    localStorage.removeItem('isLoggedIn')
    window.location.href = '/'
  }

  const [HomeSearch, setHomeSearch] = useState('')
  const navigate = useNavigate()
  const handleChange = (e) => {
    setHomeSearch(e.target.value)
  }

  const handleSearch = (e) => {
    if (HomeSearch) {
      navigate(`/catalog?term=${HomeSearch}`)
    }
  }
  const customer_name = user
    ? customer.first_name.charAt(0).toUpperCase() + customer.first_name.slice(1)
    : ''
  return (
    <div id="middle-header">
      <Container>
        <Row>
          <Col lg={3} md={2} sm={12}>
            <strong className="d-inline-block">
                <Link to="/" className="middle-header-logo d-inline-block ">
                  {isLoading ?
                  <img src={logo} alt="logo" />
                  :
                  <img src={siteSettings.logo} alt="logo" />}
                </Link>
              
            </strong>
          </Col>

          <Col xl={5} lg={4} md={6} sm={6}>
            <div className="wrap position-relative">
              <div className="search">
                <input
                  type="text"
                  className="searchTerm"
                  placeholder="What are you looking for?"
                  onChange={
                    location.pathname == '/catalog'
                      ? props.handleChange
                      : handleChange
                  }
                  value={props.search_catalog}
                />
              </div>
              <button
                type="submit"
                className="searchButton"
                onClick={handleSearch}
              >
                <i className="fa fa-search"></i>Search
              </button>
              <div className="search-icons">
                <img src={search} />
              </div>
            </div>
          </Col>
          <Col xl={4} lg={5} md={4} sm={6}>
            <div className="heart-sec d-flex align-items-center justify-content-end ms-lg-2">
              <div className="middle-header-heart-icon d-flex me-4">
                <Link to="/track-order">
                  <div className="middle-header-truck">
                    <FontAwesomeIcon
                      className="middle-header-truck"
                      icon={faTruck}
                    />
                  </div>
                </Link>
                {/* <button > */}
                <Link className="bottom-header-img" to="/wishlist">
                  <div className="middle-header-heart">
                    <img src={heart} alt="" />
                    <span>
                      {props.lengthWishlist
                        ? props.lengthWishlist
                        : WishlistCount}
                    </span>
                  </div>
                </Link>

                {/* </button> */}
              </div>
              <Link to="/cart">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="cart position-relative d-inline-block">
                    <div className="bottom-header-truck">
                      <img src={shopping} alt="" />
                    </div>
                    <span>{totalQuantity}</span>
                  </div>
                </div>
              </Link>

              {!user && (
                <div className="d-flex align-items-center">
                  <div className="top-header-login me-auto">
                    <Link to="/login">
                      <span>Login</span>
                    </Link>
                  </div>
                </div>
              )}
              {user && (
                <div className="d-flex align-items-center">
                  <div className="user-profile">
                    <Dropdown className="d-flex ">
                      {/* <div className="me-2">
                                                <img className="img-fluid" src={profile} style={{width: "38px"}}/>
                                            </div> */}
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic-top-change"
                      >
                        <span className="text-flow">{customer_name}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="/myaccount">
                          My Account
                        </Dropdown.Item>
                        <Dropdown.Item href="/myorder">My Orders</Dropdown.Item>
                        <Dropdown.Item href="/wishlist">
                          My Wishlist
                        </Dropdown.Item>
                        <NavDropdown.Item href="#" onClick={onLogoutHandler}>
                          Logout
                        </NavDropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default MiddleHeader

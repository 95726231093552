import React, { useState, useEffect } from "react";
import { Container, Row, Col, InputGroup, Form, Image } from "react-bootstrap";
import useValidator from "../../hooks/useValidator";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import eyeicon from "../../../assets/FlowerShop/images/eye-icon.svg";
import eyeslash from "../../../assets/FlowerShop/images/eye-slash-icon.svg";
import api from "../../helpers/api";
function ShowErrorMessage(props) {
    const messages = props.message;
    const listItems = Object.keys(messages).map((key) => {
        return <li className="sign-form-li">{props.message[key]}</li>;
    });
    return <ul>{listItems}</ul>;
}
const Signupform = () => {
    const navigate = useNavigate();
    const [first_name, setFirstName] = useState();
    const [last_name, setLastName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [verify_password, setVerifyPassword] = useState();
    const [validator, showValidationMessage] = useValidator();
    const [errorMsg, setErrorMsg] = useState();
    useEffect(() => {
        if (localStorage.jwt_token) {
            navigate("/");
        }
    });
    
    const submitHandler = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            await api.post(
                    `/register`,
                    {
                        first_name,
                        last_name,
                        email,
                        password,
                        verify_password,
                    },
                    {
                        headers: {
                            "POS-API-KEY": "jd54elkrtlh",
                        },
                    }
                )
                .then(({ data }) => {
                    if (data.status == 0) {
                        if (typeof data.message === "object") {
                            setErrorMsg(
                                <ShowErrorMessage message={data.message} />
                            );
                        } else {
                            setErrorMsg(data.message);
                        }
                    } else {
                        setErrorMsg("");
                        setFirstName("");
                        setEmail("");
                        setLastName("");
                        setPassword("");
                        setVerifyPassword("");
                        navigate("/login");
                        
                        setTimeout(() => {
                            toast.success(data.message);
                        }, 1000); // Delay for 1 second before navigating
                    }
                })
                .catch(({ data }) => {
                    Swal.fire({
                        text: data.message,
                        icon: "error",
                    });
                });
        } else {
            showValidationMessage(true);
        }
    };
    const [passwordType, setPasswordType] = useState("password");
    const [passwordTypeVerify, setPasswordTypeVerify] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };

    const toggleVerifyPassword = () => {
        if (passwordTypeVerify === "password") {
            setPasswordTypeVerify("text");
            return;
        }
        setPasswordTypeVerify("password");
    };
    return (
        <div id="login-data">
            <Container className="custome-container">
                <Row>
                    <Col>
                        <div className="d-flex justify-content-center form-outside">
                            <div className="login-form text-center ">
                                {errorMsg && (
                                    <div className="alert alert-danger alert-dismissible">
                                        {errorMsg}
                                    </div>
                                )}
                                <form method="post" onSubmit={submitHandler}>
                                    <div className="signup-top">
                                        <span className="create">
                                            Create Your Account
                                        </span>
                                        <span className="already">
                                            Already have an account?
                                            <Link to="/login"> Log In</Link>
                                        </span>
                                    </div>
                                    <div className="d-sm-flex email-inp">
                                        <div className="w-100 name-mar">
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    className="input-from"
                                                    placeholder="First Name"
                                                    aria-label="First Name"
                                                    aria-describedby="basic-addon1"
                                                    value={first_name}
                                                    onChange={(e) => {
                                                        setFirstName(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="ms-sm-3 w-100">
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    placeholder="Last Name"
                                                    aria-label="Last Name"
                                                    aria-describedby="basic-addon1"
                                                    value={last_name}
                                                    onChange={(e) => {
                                                        setLastName(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className="email-inp">
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Email"
                                                type="email"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className="email-inp">
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Password"
                                                type={passwordType}
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <div
                                                className="login-eye-icon"
                                                onClick={togglePassword}
                                            >
                                                {passwordType === "password" ? (
                                                    <Image
                                                        className="slash-eye-icon"
                                                        src={eyeslash}
                                                    />
                                                ) : (
                                                    <Image src={eyeicon} />
                                                )}
                                            </div>
                                        </InputGroup>
                                    </div>
                                    <div className="v-pass-inp">
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Verify Password"
                                                type={passwordTypeVerify}
                                                value={verify_password}
                                                onChange={(e) => {
                                                    setVerifyPassword(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <div
                                                className="login-eye-icon"
                                                onClick={toggleVerifyPassword}
                                            >
                                                {passwordTypeVerify ===
                                                "password" ? (
                                                    <Image
                                                        className="slash-eye-icon"
                                                        src={eyeslash}
                                                    />
                                                ) : (
                                                    <Image src={eyeicon} />
                                                )}
                                            </div>
                                        </InputGroup>
                                    </div>
                                    <button className="log-btn" type="submit">
                                        Register
                                    </button>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default Signupform;

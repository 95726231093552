import React, { useState, useEffect } from "react";
import TopHeader from "./../../sections/TopHeader/TopHeader";
import MiddleHeader from "./../../sections/MiddleHeader/MiddleHeader";
import BottomHeader from "./../../sections/BottomHeader/BottomHeader";
import Bread from "./bread/bread";
import Addcart from "./Addcart/Addcart";
import TopFooter from "./../../sections/TopFooter/TopFooter";
import MiddleFooter from "./../../sections/MiddleFooter/Middlefooter";
import BottomFooter from "./../../sections/BottomFooter/BottomFooter";
import SalonHeader from "../../../HairBeauty/BottomHeader/BottomHeader";
import SalonTopFooter from "../../../HairBeauty/TopFooter/TopFooter";
import SalonMiddleFooter from "../../../HairBeauty/MiddleFooter/Middlefooter";
import SalonBottomFooter from "../../../HairBeauty/BottomFooter/BottomFooter";
import KidMidHeader from "../../../BearKid/MiddleHeader/MiddleHeader";
import KidBotomHeader from "../../../BearKid/BottomHeader/BottomHeader";
import KidTopFooter from "../../../BearKid/TopFooter/TopFooter";
import KidMiddleFooter from "../../../BearKid/MiddleFooter/Middlefooter";
import KidBottomFooter from "../../../BearKid/BottomFooter/BottomFooter";
import { toast } from "react-toastify";
import api from "../../../helpers/api";
function Cart() {
  const [showContent, setShowContent] = useState(0);

  const cart_items = JSON.parse(localStorage.getItem("cart"));

  const [cart, setCart] = useState(cart_items);

  if (cart == null) {
    setCart([]);
  }
  const [newQuantity, setNewQuantity] = useState(0);
  const NewThemeID = JSON.parse(localStorage.getItem("ThemeID"));
  const [themeId, setThemeId] = useState(NewThemeID);
  useEffect(() => {
    api
      .get(`/get-store-theme-details`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setThemeId(res.data.data.store.theme_id);
        localStorage.setItem("ThemeID", res.data.data.store.theme_id);
      });
  }, []);
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
    const TotalQty = cart.reduce((total, m) => total + m.quantity, 0);
    setNewQuantity(cart.quantity);
    setShowContent(TotalQty);
    localStorage.setItem("totalQuantity", JSON.stringify(TotalQty));
  }, [cart]);
  const IncreaseChange = (id) => {
    const check_index = cart.find((item) =>
      item.variant_id != "" ? item.variant_id === id : item.prooduct_id === id
    );
    if (check_index?.productStock > 0) {
      if (check_index !== -1) {
        const newList = cart.map((item) => {
          if (
            item.variant_id != ""
              ? item.variant_id === id
              : item.prooduct_id === id
          ) {
            const updatedItem = {
              ...item,
              quantity: item.quantity + 1,
              productStock: item.productStock - 1
            };

            return updatedItem;
          }

          return item;
        });
        setCart(newList);
      }
    } else {
      toast.error(
        "You cannot add more items of this product in cart. Available stock is full",
        {
          autoClose: 1000
        }
      );
    }
  };

  const DecreaseChange = (id) => {
    const check_index = cart.find((item) =>
      item.variant_id != "" ? item.variant_id === id : item.prooduct_id === id
    );

    if (check_index !== -1) {
      const newList = cart.map((item) => {
        if (
          item.variant_id != ""
            ? item.variant_id === id
            : item.prooduct_id === id
        ) {
          if (item.quantity > 1) {
            const updatedItem = {
              ...item,
              quantity: item.quantity - 1,
              productStock: item.productStock + 1
            };

            return updatedItem;
          }
        }

        return item;
      });

      setCart(newList);
    }
  };

  const RemoveCart = (variant_id, product_id) => {
    const items =
      variant_id == ""
        ? cart.filter((item) => item.prooduct_id !== product_id)
        : cart.filter((item) => item.variant_id !== variant_id);
    localStorage.setItem("cart", JSON.stringify(items));
    if (items.length === 0) {
      localStorage.removeItem("item");
    }
    setCart(items);
    toast.error("Item is removed from Cart", {
      autoClose: 1000
    });
  };
  return (
    <div className="wrapper">
      {themeId == 7 && <TopHeader />}
      {themeId == 7 && <MiddleHeader showContent={showContent} />}
      {themeId == 7 && <BottomHeader />}
      {themeId == 5 && <SalonHeader />}
      {themeId == 6 && <KidMidHeader showContent={showContent} />}
      {themeId == 6 && <KidBotomHeader />}
      <Bread />
      <Addcart
        newQuantity={newQuantity}
        cart_items={cart_items}
        cart={cart}
        IncreaseChange={IncreaseChange}
        DecreaseChange={DecreaseChange}
        RemoveCart={RemoveCart}
      />
      {themeId == 7 && <TopFooter />}
      {themeId == 7 && <MiddleFooter />}
      {themeId == 7 && <BottomFooter />}
      {themeId == 5 && <SalonTopFooter />}
      {themeId == 5 && <SalonMiddleFooter />}
      {themeId == 5 && <SalonBottomFooter />}
      {themeId == 6 && <KidTopFooter />}
      {themeId == 6 && <KidMiddleFooter />}
      {themeId == 6 && <KidBottomFooter />}
    </div>
  );
}
export default Cart;

import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Categorydata from '../Categorydata/Categorydata';
import { SpinnerCircularFixed} from 'spinners-react';

const Categories = (props) => {
    const categories = props.categories;
    const first = categories[0];
    const Typename = first?.name;
    const productTypeName = props.productTypeName == "" ? Typename : props.productTypeName;
    const products = props.products;
    const [isLoading, setIsLoading] = useState(false);
    const addToWishlist = props.addToWishlist;
    const wishlist = props.wishlist;
    const refProp = props.refProp;
    return (
        <div className="banner-margin">
            <Container>
                <div id="catagories">
                    <Row>
                        <div className="col-12">
                            <div className="h-title">
                                <h2>Browse our hottest categories</h2>
                            </div>
                        </div>
                    </Row>
                    {isLoading ?
                        <Row>
                            <div className="text-center pt-4 mb-4">
                                <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                            </div>
                        </Row> :
                        <Row className="">
                        {
                            categories.length > 0 &&
                            categories.map((item, index) => (
                                <Col xl={2} sm={4} className="">
                                    <Link to="/" className={(item.id == props.productTypeID || item.name == productTypeName ? "text-center d-block cat-pad active" : "text-center d-block cat-pad")} onClick={() => { props.fetchProduct(item.id,item.name); }}>
                                        <div className="categorie-image">
                                        <img src={item.image} alt="" />
                                        </div>
                                        <h4>{item.name}</h4>
                                        <span>{item.pCount + " " + "items"}</span>
                                    </Link>
                                </Col>
                            ))
                        }
                        </Row>
                    }
                </div>
            </Container>
           <Categorydata refProp={refProp} products={products} wishlist={wishlist} addToWishlist={addToWishlist} isLoading={isLoading}/>

        </div>
    )
}
export default Categories;

import React, {useState, useEffect} from "react";
import { Container,Row,Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import api from "../helpers/api";
const TopCategories =(props)=>{
    const currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : "Rs";
    const [categories, setCategories] = useState([]);
    const first = categories[0];
    const [products, setProducts] = useState([]);
    
    const [productTypeID, setProductTypeID] = useState([]);
    const [productTypeName, setProductTypeName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    useEffect(() => {
        setIsLoading(true)
        api.get(`/get-product-type`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {

            setCategories(res.data.data)

            setIsLoading(false)
        });
    }, []);
    useEffect(() => {
      showCategoriesByProduct()
    }, [first]);
    function showCategoriesByProduct(category_id = first?.id,category_name = first?.name) {
        setIsLoading(true)
        var url = `/get-product-by-category/` + category_id;
        api.get(url, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
        }).then(res => {
          setProducts(res.data.data)
          setProductTypeID(category_id)
          setProductTypeName(category_name)
          setIsLoading(false)
        });
    };
    
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
        nextArrow: <FontAwesomeIcon icon={faArrowRight} />,
        
        responsive: [            
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots:false
              }
            },
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false
                }
              },
            
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
                breakpoint: 575,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 2
                }
              },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    
	
    };
    return(
        <div className="top-categoreis">
            <Container fluid className="custome-container">
                    <Row>
                        <Col>
                        <div className="category-heading">
                            <h2>Menu that always make You fall in love.</h2>
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} md={12}>
                            <div className="side-bar">
                                <ul className="ps-0">
                                    {categories.map((item, index) => (
                                    <li className={(item.id == productTypeID || item.name == productTypeName ? "active-category-color " : "non-active")} onClick={() => showCategoriesByProduct(item.id,item.name)}>
                                        <div className="active-color d-flex align-items-center">
                                        <div className="side-bar-items">
                                            <div className="side-bar-image">
                                              <img src={item.image} alt="" />
                                            </div>
                                          </div>
                                          <a >{item.name}</a>
                                        </div>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                        <Col lg={9} md={12} className="slider-space">
                        {
                            products.length > 0 ?
                              products.length > 2 ?
                              <Slider className="prod-slider" {...settings}>
                              {
                                  products.map((row, index) => (
                                      <div className="slider-cont">
                                        <Link className="product-text" to={`/shop/${row.id}`}>
                                          <div className="winter-coat">
                                              <img src={row.image} alt=""/>
                                          </div>
                                        </Link>
                                          { row.display_name !== null ?
                                                <div className="discount-tag">
                                                    <span>{row.display_name}</span>
                                                </div>
                                                :
                                                ''
                                          }
                                          <div className="heart-icon" key={index} data-id={row.id} onClick={() => { props.addToWishlist(row.id); }}>
                                              {
                                                    (JSON.stringify(wishlist_items.find((item) => item.id === row.id))) ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="top-product-svg" width="20" height="20"
                                                    viewBox="0 0 27 23">
                                                    <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                                        d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                                        transform="translate(-0.823 -2.997)" fill="none"
                                                        stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="3" />
                                                </svg>

                                                    : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    viewBox="0 0 27 23">
                                                    <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                                        d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                                        transform="translate(-0.823 -2.997)" fill="none"
                                                        stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="3" />
                                                </svg>

                                                }
                                          </div>
                                          <Link className="product-text" to={`/shop/${row.id}`}>
                                          <div className="ms-2">
                                              <div>
                                                  <h3 className="heading-top-slider mb-3" title={row.product_name}>{row.product_name}</h3>
                                                  {/* <FontAwesomeIcon className="color-star" icon={faStar} />
                                                  <FontAwesomeIcon className="color-star" icon={faStar} />
                                                  <FontAwesomeIcon className="color-star" icon={faStar} />
                                                  <FontAwesomeIcon className="color-star" icon={faStar} />
                                                  <FontAwesomeIcon className="color-star" icon={faStar} /> */}
                                                   <div className="slider-descrip">
                                                      <p>
                                                      {row.description}
                                                      </p>
                                                      </div>
                                                  <div className="d-flex justify-content-between  align-items-center trend-slider-price pt-4">
                                                      {
                                                          row.discount_offer_id != null ?
                                                          <div className="slid-price">
                                                            <span className="d-price"><del>{currency +" "+row.price}</del> | </span>
                                                            <span className="price-color">{currency +" "+(row.price - row.discounted_amount)}</span>
                                                          </div>
                                                          :
                                                          <span className="price-color">{currency +" "+row.price}</span>
                                                      }
                                                      <button className="slider-button">
                                                          <span>Order Now</span>
                                                      </button>
                                                  </div>
                                              </div>
                                          </div>
                                          </Link>
                                      </div>
                                      // <div className="top-food">
                                      //     <div className="food-img">
                                      //         <img src={row.image} alt="/" />
                                      //         <div className="overlay">
                                      //             <div className="overlay-text">
                                      //                 <h2 className='food-title'>Italian Pizza</h2>
                                      //                 <h3>$35.00</h3>
                                      //                 <Button className='arrowBtn'>Order now
                                      //                     <span className='ms-2'><FaArrowRight /></span> 
                                      //                 </Button>
                                      //             </div>
                                      //         </div>
                                      //     </div>
                                      // </div>
                                  ))
                              }
                              </Slider>
                              :
                                  products.map((row, index) => (
                                <div className="slider-cont no-slider">
                                    <Link className="product-text" to={`/shop/${row.id}`}>
                                      <div className="winter-coat">
                                          <img src={row.image} alt=""/>
                                      </div>
                                    </Link>
                                    { row.display_name !== null ?
                                          <div className="discount-tag">
                                              <span>{row.display_name}</span>
                                          </div>
                                          :
                                          ''
                                    }
                                    <div className="heart-icon" key={index} data-id={row.id} onClick={() => { props.addToWishlist(row.id); }}>
                                        {
                                              (JSON.stringify(wishlist_items.find((item) => item.id === row.id))) ?
                                              <svg xmlns="http://www.w3.org/2000/svg" className="top-product-svg" width="20" height="20"
                                              viewBox="0 0 27 23">
                                              <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                                  d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                                  transform="translate(-0.823 -2.997)" fill="none"
                                                  stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="3" />
                                          </svg>

                                              : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                              viewBox="0 0 27 23">
                                              <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                                  d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                                  transform="translate(-0.823 -2.997)" fill="none"
                                                  stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="3" />
                                          </svg>

                                          }
                                    </div>
                                    <Link className="product-text" to={`/shop/${row.id}`}>
                                    <div className="ms-2">
                                        <div>
                                            <h3 className="heading-top-slider mb-3" title={row.product_name}>{row.product_name}</h3>
                                            <div className="slider-descrip">
                                              <p>
                                                {row.description}
                                              </p>
                                             </div>
                                            {/* <FontAwesomeIcon className="color-star" icon={faStar} />
                                            <FontAwesomeIcon className="color-star" icon={faStar} />
                                            <FontAwesomeIcon className="color-star" icon={faStar} />
                                            <FontAwesomeIcon className="color-star" icon={faStar} />
                                            <FontAwesomeIcon className="color-star" icon={faStar} /> */}
                                            <div className="d-flex justify-content-between  align-items-center trend-slider-price pt-4">
                                                {
                                                  row.discount_offer_id != null ?
                                                  <div className="slid-price">
                                                    <span className="d-price"><del>{currency +" "+row.price}</del> | </span>
                                                    <span className="price-color">{currency +" "+(row.price - row.discounted_amount)}</span>
                                                  </div>
                                                  :
                                                  <span className="price-color">{currency +" "+row.price}</span>
                                                }
                                                <button className="slider-button">
                                                    <span>Order Now</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                                  ))
                              :
                              <Col md={12}>
                                <p className="text-center mt-5">No Product Found</p>
                              </Col>
                        }
                        </Col>
                    </Row>
            </Container>
        </div>
    )
}
export default TopCategories;

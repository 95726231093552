import React from "react";
import { Row } from "react-bootstrap";
import banner from "../../assets/ShopLove/images/footer-banner.png";
import { SpinnerCircularFixed} from 'spinners-react';
const TopFooter=(props)=>{
    const siteSettings = props.siteSettings;
    return(
        props.isLoading ? 
            <Row>
                <div className="text-center pt-4 mb-4">
                    <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                </div>
            </Row>
            :
        <div className="footer-banner">
            <img src={siteSettings? siteSettings.default_image_1 : banner}  alt="banner-image"/>  
        </div>
    )
}
export default TopFooter;
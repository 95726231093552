import React, {useState, useEffect} from "react";
import { Container,Row,Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./MiddleFooter.css";
import api from "../../../helpers/api";
const MiddleFooter =()=>{
    const [CmsPages, setCmsPages] = useState([]);
    const [siteSettings, setSiteSettings] = useState([]);
    useEffect(() => {
        api.get(`/fetch_cms_pages`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setCmsPages(res.data.data)
        });
        api.get(`/get-site-settings`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setSiteSettings(res.data.data)
        });
    }, []);

    return(
        <div id="middle-footer">
            <Container>
                <Row>
                    <Col lg={4} sm={6}>
                        <div className="sec1">
                            <strong>
                                <Link className="middle-footer-logo" to="/">
                                    <img className="img-fluid" src={siteSettings.logo} alt=""/>
                                </Link>
                            </strong>
                            <p>It is a long established fact that a reader will be distracted by the readable
                                content.
                            </p>
                        </div>
                    </Col>
                    <Col lg={4} sm={6}>
                        <div className="sec2">
                            <span>Customer Service</span>
                            <ul className="list-unstyled mb-0">
                            {
                               CmsPages != "" ? (
                                CmsPages.map((row, key)=>(
                                <li className="pt-0"><Link to={'/cms/' + row.slug}>{row.title}</Link></li>
                                ))
                                )
                                : ""
                             }
                            </ul>
                        </div>
                    </Col>
                    <Col lg={4} sm={6}>
                        <div className="sec2">
                            <span>About Us</span>
                            <ul className="list-unstyled mb-0">
                                <li className="pt-0"><Link to="/catalog">All Brands </Link></li>
                                {/* <li><Link to="/#newsletter-subscriber">Newsletter </Link></li> */}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>

    )
}
export default MiddleFooter;

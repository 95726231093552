import React from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-gallery-carousel/dist/index.css";
import Carousel from "react-gallery-carousel";
const Shopborder = (props) => {
    const currency = localStorage.getItem("currency")
        ? localStorage.getItem("currency")
        : "Rs";
    const productDetail = props.productDetail;
    const imageGal = props.images;
    const discountOfferName = props.discountOfferName;
    const variantName = props.variantName;
    const variantPrice = props.price;
    const vDicountOfferId = props.discountOfferId;
    const vDicountedAmount = props.discountedAmount;
    let vDiscountedPrice = null;
    if (vDicountOfferId !== null) {
        vDiscountedPrice = variantPrice - vDicountedAmount;
    }
    const product_reviews = productDetail.product_reviews;
    function StarRating({
        count,
        value,
        inactiveColor = "#ddd",
        size = 24,
        activeColor = "#f00",
        onChange,
    }) {
        // short trick
        const stars = Array.from({ length: count }, () => "🟊");

        return (
            <div>
                {stars.map((s, index) => {
                    let style = inactiveColor;
                    if (index < value) {
                        style = activeColor;
                    }
                    return (
                        <FontAwesomeIcon
                            icon={faStar}
                            style={{
                                color: style,
                                width: size,
                                height: size,
                                fontSize: size,
                                marginLeft: "-6px",
                            }}
                        />
                    );
                })}
            </div>
        );
    }
    return (
        <div id="shop">
            <Container className="custome-container">
                <div className="shop-border">
                    <Row>
                        <Col lg={6}>
                            <div className="position-relative">
                                <div
                                    className="shop-product-img"
                                    onClick={props.newchangeCourasel}
                                >
                                    {productDetail.product_variants == "" ? (
                                        <img
                                            className="img-fluid"
                                            src={productDetail.image}
                                        />
                                    ) : (
                                        <div className="product-img">
                                            <Carousel
                                                arrows={false}
                                                images={imageGal}
                                                hasThumbnailsAtMax={false}
                                                thumbnailWidth={"13%"}
                                                thumbnailHeight={"13%"}
                                                disableThumbnailSwipe={true}
                                            />
                                        </div>
                                    )}
                                </div>

                                {discountOfferName !== null ||
                                    productDetail.display_name !== null ? (
                                    <div className="discount-tag img">
                                        <span>
                                            {productDetail.display_name ||
                                                discountOfferName}
                                        </span>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="shop-cont">
                                <div className="star-mar">
                                    <StarRating
                                        count={5}
                                        size={27}
                                        value={product_reviews}
                                        activeColor={"#FEBE37"}
                                        inactiveColor={"#ddd"}
                                    />
                                </div>
                                <div className="position-relative">
                                    <h2>{productDetail.product_name} </h2>
                                    <span className="border-line"></span>
                                </div>

                                {productDetail.product_variants &&
                                    productDetail.product_variants.length > 0 ? (
                                    <Dropdown className="shop-dropdown-menu-shop mb-3">
                                        <Dropdown.Toggle
                                            variant="primary"
                                            id="dropdown-basic-shop"
                                        >
                                            {variantName ||
                                                productDetail.first_variant_combination_name}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="shop-dropdown-menu-shop">
                                            {productDetail.product_variants &&
                                                productDetail.product_variants.map(
                                                    (variant, index) => (
                                                        <Dropdown.Item
                                                            className="dropdown-shop-product"
                                                            eventKey={
                                                                variant.variant_id
                                                            }
                                                            onClick={() => {
                                                                props.onOptionClicked(
                                                                    variant.variant_id,
                                                                    variant.combination_names,
                                                                    variant.variant_price,
                                                                    variant.variant_image,
                                                                    variant.discount_offer_id,
                                                                    variant.display_name,
                                                                    variant.discount_sub_type,
                                                                    variant.discounted_amount,
                                                                    variant.get_discount_percentage,
                                                                    variant.discount_case,
                                                                    variant.variant_taxRate
                                                                );
                                                            }}
                                                        >
                                                            {
                                                                variant.combination_names
                                                            }
                                                        </Dropdown.Item>
                                                    )
                                                )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : (
                                    ""
                                )}
                                {productDetail.discount_offer_id !== null ||
                                    vDicountOfferId !== null ? (
                                    <div className="price align-items-center">
                                        <div className="d-flex align-items-center">
                                            <span className="d-price me-2">
                                                <del>
                                                    <span className="currency-font">
                                                        {currency + " "}
                                                    </span>
                                                    {parseFloat(
                                                        variantPrice
                                                    ).toFixed(2) ||
                                                        parseFloat(
                                                            productDetail.price
                                                        ).toFixed(2)}
                                                </del>{" "}
                                                |{" "}
                                            </span>
                                            <span className="r-price me-2">
                                                <span className="currency-font">
                                                    {currency + " "}
                                                </span>
                                                {parseFloat(
                                                    vDiscountedPrice
                                                ).toFixed(2) ||
                                                    parseFloat(
                                                        productDetail.price -
                                                        productDetail.discounted_amount
                                                    ).toFixed(2)}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <span className="price">
                                        <span className="currency-font">
                                            {currency + " "}
                                        </span>
                                        {parseFloat(variantPrice).toFixed(2) ||
                                            parseFloat(
                                                productDetail.price
                                            ).toFixed(2)}
                                    </span>
                                )}
                                <div>
                                    <p>{productDetail.description}</p>
                                    {productDetail?.available_stock > 0 ? (
                                        <div className="drop">
                                            <div className="d-flex input-adj align-items-center">
                                                <button
                                                    className="btn2"
                                                    onClick={() => {
                                                        props.DecreaseChange();
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faMinus}
                                                    />
                                                </button>
                                                <div>
                                                    <input
                                                        type="number"
                                                        placeholder={
                                                            props.quantity
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                                <button
                                                    className="btn1"
                                                    onClick={(e) => {
                                                        props.IncreaseChange();
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                    />
                                                </button>
                                            </div>
                                            <button
                                                className="cartbtn"
                                                onClick={props.addToCart}
                                            >
                                                Add to Cart
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center">
                                            <strong className="me-3 text-black">Availability:</strong>
                                            <div className="availability-tag img">
                                                <span>
                                                    out of stock
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};
export default Shopborder;

import React from "react";
import { Row,Breadcrumb } from "react-bootstrap";
import shop from "../../../assets/Furniture/images/shop img.png";
const Shipping =()=>{
    return(
        <div id="payment">
            <div className="custome-container">
                <Row>
                  <div className="set-width">
                     <div className="d-lg-flex">
                           <div className="cont1">
                                <div className="nav " id="nav-tab" role="tablist">
                                    <Breadcrumb>
                                      <Breadcrumb.Item href="#">Cart</Breadcrumb.Item>
                                      <Breadcrumb.Item href="#">Information</Breadcrumb.Item>
                                      <Breadcrumb.Item href="#">Shipping</Breadcrumb.Item>
                                      <Breadcrumb.Item href="#">Payment</Breadcrumb.Item>
                                   </Breadcrumb>
                                </div>
                                    <div >
                                        <div className="tab-pane fade show active" id="pills-ship" role="tabpanel" aria-labelledby="pills-ship-tab">
                                            <div className="cont1-inside">
                                                <ul>
                                                    <li className="d-flex">
                                                        <label for="#">Contact</label>
                                                        <span>Your Name (yourname@email.com)</span>
                                                        <a href="/" className="active-change  text-end" to="/">Change</a>
                                                    </li>
                                                    <li className="d-flex border-0">
                                                        <label for="#">Ship to</label>
                                                        <span>Your complete address with postal code</span>
                                                        <a href="/" className="active-change text-end" to="/">Change</a>
                                                    </li>
                                                </ul>
                                                <div className="heading">
                                                    <h2>Shipping Method</h2>
                                                </div>
                                                <div className="radio-border ">
                                                    <div className="form-check border-0">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                            id="flexRadioDefault1" />
                                                        <label className="form-check-label lab-color" for="flexRadioDefault1">
                                                            Standard
                                                        </label>
                                                    </div>
                                                </div>
                                                <button className="endbtn">Continue to Payment</button>
                                             <button className="endbtn">Return to information</button>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cont2">
                                    <div className="cont2-inside">
                                        <div className="d-flex cont2-adj">
                                            <div className="img-cont">
                                                <img className="w-100 h-100" src={shop} alt='' />
                                            </div>
                                            <span className="p-width">Apple iMac 27-inch 2020 with Retina 5K display -
                                                MXWV2</span>
                                            <span className="text-end price">$1,799</span>
                                        </div>
                                        <div className="sect1">
                                            <ul className="list-unstyled mb-0">
                                                <li>
                                                    <label for="#">Subtotal</label>
                                                    <span className="price">$1,799</span>
                                                </li>
                                                <li className="mb-0">
                                                    <label for="#">Shipping</label>
                                                    <span className="color">Calculated at next step</span>
                                                </li>
                                            </ul>
                                            <ul className="list-unstyled border-0 mb-0 pb-0">
                                                <li className="mb-0 ">
                                                    <label for="#">Total</label>
                                                    <span className="price2"> $1,799</span>
                                                </li>
                                            </ul>
                                        </div>
                                   </div>
                           </div>
                      </div>
                  </div>
                </Row>
            </div>
    </div>
    )
}
export default Shipping;
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderProducts from "./../../others/Home/SliderComponents/SliderProducts";
import NonSliderProducts from "./../../others/Home/SliderComponents/NonSliderProducts";
import "react-toastify/dist/ReactToastify.css";

const Relatedproduct = (props) => {
  const products = props.relatedProducts;
  const wishlist = props.wishlist;
  const addToWishlist = props.addToWishlist;
  return (
    <div id="shop">
      <Container>
        <div className="shop-pro">
          <Row>
            <Col>
              <div>
                <h2>Related Products</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {products.length > 0 ? (
                products.length > 3 ? (
                  <SliderProducts
                    products={products}
                    addToWishlist={addToWishlist}
                    wishlist={wishlist}
                  />
                ) : (
                  <NonSliderProducts
                    products={products}
                    addToWishlist={addToWishlist}
                    wishlist={wishlist}
                  />
                )
              ) : (
                <p className="category-data d-flex justify-content-center">
                  No Product found in this Category
                </p>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default Relatedproduct;

// resources/assets/js/components/App.js
import React, { useState, useEffect } from "react";
import { Routes } from "react-router-dom";
import { BrowserRouter, Route } from "react-router-dom";
import Theme1Signup from "./components/HappyShopping/auth/Signup/Signup";
import Theme1Login from "./components/HappyShopping/auth/Login/Login";
import Theme1Home from "./components/HappyShopping/others/Home/Home";
import Theme1Category from "./components/HappyShopping/others/Category/Category";
import Theme1Cart from "./components/HappyShopping/others/Cart/Cart";
import Theme1Wishlist from "./components/HappyShopping/others/Wishlist/Wishlist";
import Theme1MyAccount from "./components/HappyShopping/others/MyAccount/myaccount";
import Theme1MyOrder from "./components/HappyShopping/others/MyOrder/myOrder";
import Theme1OrderDetail from "./components/HappyShopping/others/OrderDetail/orderDetail";
import Theme1Payment from "./components/HappyShopping/others/Payment/payment";
import Theme1Shop from "./components/HappyShopping/others/Shop/shop";
import Theme1ShippingMethod from "./components/HappyShopping/others/Shippingmethod/Shippingmethod";
import Theme1ShippingPayment from "./components/HappyShopping/others/Shippingpayment/Shippingpayment";
import Theme1FAQ from "./components/HappyShopping/others/FAQ/FAQ";
import Theme1Aboutus from "./components/HappyShopping/others/Aboutus/Aboutus";
import Theme1ResetPassword from "./components/HappyShopping/others/Resetpassword/ResetPassword";
import Theme1ForgetPassword from "./components/HappyShopping/others/ForgetPassword/ForgetPassword";
import Theme1OrderTracking from "./components/HappyShopping/others/Ordertracking/Ordertracking";
import Theme1Track from "./components/HappyShopping/others/TrackOrder/Track";
import Theme1Recipt from "./components/HappyShopping/others/Recipt/Recipt";
import Theme2Home from "./components/Furniture/Home/Home";
import Theme2Category from "./components/Furniture/Category/Category";
import Theme2Shop from "./components/Furniture/Shop/Shop";
import Theme2Cart from "./components/Furniture/Cart/Cart";
import Theme2Payment from "./components/Furniture/Payment/Payment";
import Theme2Shippingmethod from "./components/Furniture/Shippingmethod/Shippingmethod";
import Theme2Shippingpayment from "./components/Furniture/Shippingpayment/Shippingpayment";
import Theme2Myorder from "./components/Furniture/Myorder/Myorder";
import Theme2Orderdetail from "./components/Furniture/Orderdetail/Orderdetail";
import Theme2Myaccount from "./components/Furniture/Myaccount/Myaccount";
import Theme2Wishlist from "./components/Furniture/Wishlist/Wishlist";
import Theme2Login from "./components/Furniture/Login/Login";
import Theme2Signup from "./components/Furniture/Signup/Signup";
import Theme2Aboutus from "./components/Furniture/Aboutus/Aboutus";
import Theme2OrderTracking from "./components/Furniture/Ordertracking/Ordertracking";
import Theme2Track from "./components/Furniture/TrackOrder/Track";
import Theme2FAQ from "./components/Furniture/FAQ/FAQ";
import Theme2ForgetPassword from "./components/Furniture/ForgetPassword/ForgetPassword";
import Theme2ResetPassword from "./components/Furniture/ResetPassword/ResetPassword";
import PetStoreHome from "./components/PetStore/HomePage/HomePage";
import JewelleryHome from "./components/Jewellery/Home-Page/HomePage";
import ShopLoveHome from "./components/ShopLove/Home/Home";
import FlowerShopHome from "./components/FlowerShop/HomePage/HomePage";
import CafeFoodHome from "./components/CafeFood/Home/Home";
import HairBeautyHome from "./components/HairBeauty/Home/Home";
import BearKidHome from "./components/BearKid/Home/Home";
import LoadingSpinner from "./components/helpers/LoadingSpinner/LoadingSpinner";
import SetAuthToken from "./components/helpers/SetAuthToken";
import SetBaseURL from "./components/helpers/SetBaseURL";
import SetCurrency from "./components/helpers/SetCurrency";
import api from "./components/helpers/api";
import PageNotFound from "./components/helpers/PageNotFound";
import PackageExpirePage from "./components/helpers/PackageExpirePage/PackageExpirePage";
// Set Jwt Auth Token
if (localStorage.getItem("jwt_token")) {
  SetAuthToken(localStorage.getItem("jwt_token"));
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [storeOwner, setStoreOwner] = useState([]);
  const [packageExpire, setPackageExpire] = useState(false);
  const [theme, setTheme] = useState(null);
  // Get Current Store Theme Owner
  useEffect(() => {
    api
      .get(`/get-store-owner`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setStoreOwner(res.data.data.user);
        const expiryDate = new Date(res.data.data.user.package_expiry_date);
        const currentDate = new Date();
        if (expiryDate < currentDate) {
          setPackageExpire(true);
        }
      });
  }, []);
  // Get Current Store Theme Details
  useEffect(() => {
    api
      .get(`/get-store-theme-details`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        SetBaseURL(res.data.data.store.domain);
        const id = res.data.data.store.theme_id;
        var themeComponentsObj = "";
        if (id == 1) {
          // Flower Shop Theme
          Promise.all([
            import("./assets/FlowerShop/css/HomePage/app.css"),
            import("./assets/FlowerShop/css/DefaultPages/app.css"),
            import("./assets/FlowerShop/css/aos.css")
          ])
            .then(() => {
              themeComponentsObj = {
                home: <FlowerShopHome />,
                login: <Theme2Login />,
                signup: <Theme2Signup />,
                category: <Theme2Category />,
                cart: <Theme2Cart />,
                wishlist: <Theme2Wishlist />,
                myaccount: <Theme2Myaccount />,
                myorder: <Theme2Myorder />,
                orderdetail: <Theme2Orderdetail />,
                payment: <Theme2Payment />,
                shop: <Theme2Shop />,
                shippingMethod: <Theme2Shippingmethod />,
                shippingPayment: <Theme2Shippingpayment />,
                Aboutus: <Theme2Aboutus />,
                OrderTracking: <Theme2OrderTracking />,
                Track: <Theme2Track />,
                FAQ: <Theme2FAQ />,
                ForgetPassword: <Theme2ForgetPassword />,
                ResetPassword: <Theme2ResetPassword />
              };
              setTheme(themeComponentsObj);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error loading CSS:", error);
              setIsLoading(false);
            });
        } else if (id == 2) {
          // Shop Love
          Promise.all([
            import("./assets/ShopLove/css/DefaultPages/app.css"),
            import("./assets/ShopLove/css/HomePage/app.css")
          ])
            .then(() => {
              themeComponentsObj = {
                home: <ShopLoveHome />,
                login: <Theme2Login />,
                signup: <Theme2Signup />,
                category: <Theme2Category />,
                cart: <Theme2Cart />,
                wishlist: <Theme2Wishlist />,
                myaccount: <Theme2Myaccount />,
                myorder: <Theme2Myorder />,
                orderdetail: <Theme2Orderdetail />,
                payment: <Theme2Payment />,
                shop: <Theme2Shop />,
                shippingMethod: <Theme2Shippingmethod />,
                shippingPayment: <Theme2Shippingpayment />,
                Aboutus: <Theme2Aboutus />,
                OrderTracking: <Theme2OrderTracking />,
                Track: <Theme2Track />,
                FAQ: <Theme2FAQ />,
                ForgetPassword: <Theme2ForgetPassword />,
                ResetPassword: <Theme2ResetPassword />
              };
              setTheme(themeComponentsObj);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error loading CSS:", error);
              setIsLoading(false);
            });
        } else if (id == 3) {
          // Furniture
          Promise.all([import("./assets/Furniture/css/app.css")])
            .then(() => {
              themeComponentsObj = {
                home: <Theme2Home />,
                login: <Theme2Login />,
                signup: <Theme2Signup />,
                category: <Theme2Category />,
                cart: <Theme2Cart />,
                wishlist: <Theme2Wishlist />,
                myaccount: <Theme2Myaccount />,
                myorder: <Theme2Myorder />,
                orderdetail: <Theme2Orderdetail />,
                payment: <Theme2Payment />,
                shop: <Theme2Shop />,
                shippingMethod: <Theme2Shippingmethod />,
                shippingPayment: <Theme2Shippingpayment />,
                Aboutus: <Theme2Aboutus />,
                OrderTracking: <Theme2OrderTracking />,
                Track: <Theme2Track />,
                FAQ: <Theme2FAQ />,
                ForgetPassword: <Theme2ForgetPassword />,
                ResetPassword: <Theme2ResetPassword />
              };
              setTheme(themeComponentsObj);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error loading CSS:", error);
              setIsLoading(false);
            });
        } else if (id == 4) {
          // Cafe Food
          Promise.all([
            import("./assets/CafeFood/css/DefaultPages/app.css"),
            import("./assets/CafeFood/css/HomePage/app.css")
          ])
            .then(() => {
              themeComponentsObj = {
                home: <CafeFoodHome />,
                login: <Theme2Login />,
                signup: <Theme2Signup />,
                category: <Theme2Category />,
                cart: <Theme2Cart />,
                wishlist: <Theme2Wishlist />,
                myaccount: <Theme2Myaccount />,
                myorder: <Theme2Myorder />,
                orderdetail: <Theme2Orderdetail />,
                payment: <Theme2Payment />,
                shop: <Theme2Shop />,
                shippingMethod: <Theme2Shippingmethod />,
                shippingPayment: <Theme2Shippingpayment />,
                Aboutus: <Theme2Aboutus />,
                OrderTracking: <Theme2OrderTracking />,
                Track: <Theme2Track />,
                FAQ: <Theme2FAQ />,
                ForgetPassword: <Theme2ForgetPassword />,
                ResetPassword: <Theme2ResetPassword />
              };
              setTheme(themeComponentsObj);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error loading CSS:", error);
              setIsLoading(false);
            });
        } else if (id == 5) {
          // Hair Beauty
          Promise.all([import("./assets/HairBeauty/css/app.css")])
            .then(() => {
              themeComponentsObj = {
                home: <HairBeautyHome />,
                login: <Theme1Login />,
                signup: <Theme1Signup />,
                category: <Theme1Category />,
                cart: <Theme1Cart />,
                wishlist: <Theme1Wishlist />,
                myaccount: <Theme1MyAccount />,
                myorder: <Theme1MyOrder />,
                orderdetail: <Theme1OrderDetail />,
                payment: <Theme1Payment />,
                shop: <Theme1Shop />,
                shippingMethod: <Theme1ShippingMethod />,
                shippingPayment: <Theme1ShippingPayment />,
                FAQ: <Theme1FAQ />,
                Aboutus: <Theme1Aboutus />,
                ResetPassword: <Theme1ResetPassword />,
                ForgetPassword: <Theme1ForgetPassword />,
                OrderTracking: <Theme1OrderTracking />,
                Recipt: <Theme1Recipt />,
                Track: <Theme1Track />
              };
              setTheme(themeComponentsObj);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error loading CSS:", error);
              setIsLoading(false);
            });
        } else if (id == 6) {
          // Bear Kid
          Promise.all([import("./assets/BearKid/css/app.css")])
            .then(() => {
              themeComponentsObj = {
                home: <BearKidHome />,
                login: <Theme1Login />,
                signup: <Theme1Signup />,
                category: <Theme1Category />,
                cart: <Theme1Cart />,
                wishlist: <Theme1Wishlist />,
                myaccount: <Theme1MyAccount />,
                myorder: <Theme1MyOrder />,
                orderdetail: <Theme1OrderDetail />,
                payment: <Theme1Payment />,
                shop: <Theme1Shop />,
                shippingMethod: <Theme1ShippingMethod />,
                shippingPayment: <Theme1ShippingPayment />,
                FAQ: <Theme1FAQ />,
                Aboutus: <Theme1Aboutus />,
                ResetPassword: <Theme1ResetPassword />,
                ForgetPassword: <Theme1ForgetPassword />,
                OrderTracking: <Theme1OrderTracking />,
                Recipt: <Theme1Recipt />,
                Track: <Theme1Track />
              };
              setTheme(themeComponentsObj);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error loading CSS:", error);
              setIsLoading(false);
            });
        } else if (id == 7) {
          // Happy Shopping
          Promise.all([import("./assets/HappyShopping/css/app.css")])
            .then(() => {
              themeComponentsObj = {
                home: <Theme1Home />,
                login: <Theme1Login />,
                signup: <Theme1Signup />,
                category: <Theme1Category />,
                cart: <Theme1Cart />,
                wishlist: <Theme1Wishlist />,
                myaccount: <Theme1MyAccount />,
                myorder: <Theme1MyOrder />,
                orderdetail: <Theme1OrderDetail />,
                payment: <Theme1Payment />,
                shop: <Theme1Shop />,
                shippingMethod: <Theme1ShippingMethod />,
                shippingPayment: <Theme1ShippingPayment />,
                FAQ: <Theme1FAQ />,
                Aboutus: <Theme1Aboutus />,
                ResetPassword: <Theme1ResetPassword />,
                ForgetPassword: <Theme1ForgetPassword />,
                OrderTracking: <Theme1OrderTracking />,
                Recipt: <Theme1Recipt />,
                Track: <Theme1Track />
              };
              setTheme(themeComponentsObj);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error loading CSS:", error);
              setIsLoading(false);
            });
        } else if (id == 8) {
          // Pet Store
          Promise.all([
            import("./assets/PetStore/css/DefaultPages/app.css"),
            import("./assets/PetStore/css/HomePage/app.css")
          ])
            .then(() => {
              themeComponentsObj = {
                home: <PetStoreHome />,
                login: <Theme2Login />,
                signup: <Theme2Signup />,
                category: <Theme2Category />,
                cart: <Theme2Cart />,
                wishlist: <Theme2Wishlist />,
                myaccount: <Theme2Myaccount />,
                myorder: <Theme2Myorder />,
                orderdetail: <Theme2Orderdetail />,
                payment: <Theme2Payment />,
                shop: <Theme2Shop />,
                shippingMethod: <Theme2Shippingmethod />,
                shippingPayment: <Theme2Shippingpayment />,
                Aboutus: <Theme2Aboutus />,
                OrderTracking: <Theme2OrderTracking />,
                Track: <Theme2Track />,
                FAQ: <Theme2FAQ />,
                ForgetPassword: <Theme2ForgetPassword />,
                ResetPassword: <Theme2ResetPassword />
              };
              setTheme(themeComponentsObj);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error loading CSS:", error);
              setIsLoading(false);
            });
        } else if (id == 9) {
          // Jewellery
          Promise.all([
            import("./assets/Jewellery/css/DefaultPages/app.css"),
            import("./assets/Jewellery/css/HomePage/app.css")
          ])
            .then(() => {
              themeComponentsObj = {
                home: <JewelleryHome />,
                login: <Theme2Login />,
                signup: <Theme2Signup />,
                category: <Theme2Category />,
                cart: <Theme2Cart />,
                wishlist: <Theme2Wishlist />,
                myaccount: <Theme2Myaccount />,
                myorder: <Theme2Myorder />,
                orderdetail: <Theme2Orderdetail />,
                payment: <Theme2Payment />,
                shop: <Theme2Shop />,
                shippingMethod: <Theme2Shippingmethod />,
                shippingPayment: <Theme2Shippingpayment />,
                Aboutus: <Theme2Aboutus />,
                OrderTracking: <Theme2OrderTracking />,
                Track: <Theme2Track />,
                FAQ: <Theme2FAQ />,
                ForgetPassword: <Theme2ForgetPassword />,
                ResetPassword: <Theme2ResetPassword />
              };
              setTheme(themeComponentsObj);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("Error loading CSS:", error);
              setIsLoading(false);
            });
        }
      });
  }, []);
  // Get Current Store Currency
  useEffect(() => {
    api
      .get(`/get-store-currency`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        SetCurrency(res.data.data);
      });
  }, []);
  // Site Settings
  useEffect(() => {
    api
      .get(`/get-site-settings`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        document.title = res.data.data.title;
        const linkElement = document.querySelector('link[rel="icon"]');
        if (linkElement) {
          linkElement.href = res.data.data.logo;
        }
      });
  }, []);
  return (
    <BrowserRouter>
      {isLoading ? (
        <LoadingSpinner />
      ) : packageExpire ? (
        <Routes>
          <Route exact path="*" element={<PackageExpirePage />} />
        </Routes>
      ) : (
        <Routes>
          <Route exact path="/" element={theme.home} />
          <Route exact path="/login" element={theme.login} />
          <Route exact path="/signup" element={theme.signup} />
          <Route path="/catalog" element={theme.category} />
          <Route path="/search" element={theme.search} />
          <Route path="/cart" element={theme.cart} />
          <Route path="/wishlist" element={theme.wishlist} />
          <Route path="/myaccount" element={theme.myaccount} />
          <Route path="/myorder" element={theme.myorder} />
          <Route path="/orderdetail/:id" element={theme.orderdetail} />
          <Route path="/payment" element={theme.payment} />
          <Route path="/shop/:id" element={theme.shop} />
          <Route path="/shipping" element={theme.shippingMethod} />
          <Route path="/payment-method" element={theme.shippingPayment} />
          <Route path="/faq" element={theme.FAQ} />
          <Route path="/cms/:slug" element={theme.Aboutus} />
          <Route path="/reset/password/:token" element={theme.ResetPassword} />
          <Route path="/forget-password" element={theme.ForgetPassword} />
          <Route path="/order-tracking" element={theme.OrderTracking} />
          <Route path="/payment-success/:token" element={theme.OrderTracking} />
          <Route path="/reciept/:id" element={theme.Recipt} />
          <Route path="/track-order" element={theme.Track} />
          <Route path="*" element={<PageNotFound />} />
          {/* <Route path='/productdetail ' element={theme.detail} /> */}
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { SpinnerCircularFixed } from "spinners-react";
import api from "../../helpers/api";
function Detail() {
    const { id } = useParams();
    const currency = localStorage.getItem("currency")
        ? localStorage.getItem("currency")
        : "Rs";
    const [OrderProducts, setOrderProducts] = useState([]);
    const [salesProduct, setSales] = useState([]);
    const [paymentMethod, setMethod] = useState([]);
    const customer = JSON.parse(localStorage.getItem("userData"));
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const sub_total = OrderProducts.reduce(
        (prev, cur) =>
            (cur.vairant_id != 0
                ? cur.price_per_unit - cur.product_variants[0].discounted_amount
                : cur.discounted_amount
                ? cur.price_per_unit - cur.discounted_amount
                : cur.price_per_unit) *
                cur.qty +
            prev,
        0
    );

    const tax_rate = OrderProducts.reduce(
        (prev, cur) =>
            (cur.vairant_id != 0
                ? (cur.tax_rate / 100) *
                  ((cur.price_per_unit -
                      cur.product_variants[0].discounted_amount) *
                      cur.qty)
                : cur.discounted_amount
                ? (cur.tax_rate / 100) *
                  ((cur.price_per_unit - cur.discounted_amount) * cur.qty)
                : (cur.tax_rate / 100) * (cur.price_per_unit * cur.qty)) + prev,
        0
    );

    useEffect(() => {
        fetchOrderProducts();
    }, []);

    const fetchOrderProducts = async () => {
        setIsLoading(true);
        api.get(`/get-order-products/${id}`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setOrderProducts(res.data.data);
                setSales(res.data.sale_data);
                setData(res.data.meta);
                setMethod(res.data.payment_data);
                setIsLoading(false);
            });
    };

    return (
        <>
            <div id="order-detail">
                <Container className="custome-container">
                    {isLoading ? (
                        <Row>
                            <div className="text-center pt-4 mb-4">
                                {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                                <SpinnerCircularFixed
                                    size={50}
                                    thickness={100}
                                    speed={100}
                                    color="#1239A5"
                                    secondaryColor="rgba(0, 0, 0, 0.44)"
                                />
                            </div>
                        </Row>
                    ) : (
                        <Row>
                            <Col lg={6}>
                                <div className="detail">
                                    <h3>Order Info</h3>
                                    <div className="order-info ">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label for="#">Name</label>
                                            <span className="mb-3">
                                                {salesProduct.customer_first_name +
                                                    " " +
                                                    salesProduct.customer_last_name}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label for="#">Ordered Date</label>
                                            <span className="mb-3">
                                                {salesProduct.invoice_date}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label for="#">Status</label>
                                            {salesProduct.payment_status == 2 &&
                                            salesProduct.fullfillment_status ==
                                                12 ? (
                                                <span className="pending mb-3">
                                                    {" "}
                                                    Pending
                                                </span>
                                            ) : (
                                                <span className="complete mb-3">
                                                    Completed
                                                </span>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label for="#">Email</label>
                                            <span className="mb-3">
                                                {salesProduct.customer_email}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label className="mb-0" for="#">
                                                Phone Number
                                            </label>
                                            <span className="mb-3">
                                                {salesProduct.customer_phone_no}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="order-info1">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label for="#">Postcode</label>
                                            <span className="mb-3">
                                                {
                                                    salesProduct.customer_postal_code
                                                }
                                            </span>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <label for="#">City</label>
                                            <span className="mb-3">
                                                {salesProduct.customer_city}
                                            </span>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <label for="#">Country</label>
                                            <span className="mb-3">
                                                {salesProduct.customer_country}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label for="#">Payment</label>
                                            <span className="mb-3">
                                                {paymentMethod.payment_method_id ==
                                                    7 ||
                                                paymentMethod.payment_method_id ==
                                                    8
                                                    ? paymentMethod.payment_method_id ==
                                                      7
                                                        ? "Payment With Paypal"
                                                        : "Payment With Stripe"
                                                    : "Cash On Delivery"}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label for="#">Invoice ID</label>
                                            <span className="mb-3">
                                                {salesProduct.invoice_id}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="detail">
                                    <h3>Order History</h3>
                                    {OrderProducts.length > 0 ? (
                                        OrderProducts.map((row, key) => (
                                            <div className="order-detail d-flex justify-content-between">
                                                <div className="img-cont">
                                                    <img
                                                        className="w-100 "
                                                        src={
                                                            row.product_category ==
                                                            0
                                                                ? row.image
                                                                : row
                                                                      .product_variants[0]
                                                                      .variant_image
                                                        }
                                                    />
                                                </div>
                                                <div className="mar-start">
                                                    <div className="product">
                                                        Products
                                                    </div>
                                                    <div className="prod-detail">
                                                        <Link
                                                            className="product-text"
                                                            to={`/shop/${row.product_id}`}
                                                        >
                                                            {row.product_name}
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="mar-start">
                                                    <div className="product">
                                                        Qty
                                                    </div>
                                                    <div className="prod-detail">
                                                        {row.qty}
                                                    </div>
                                                </div>
                                                <div className="mar-start price">
                                                    <div className="product">
                                                        Price
                                                    </div>
                                                    <div className="prod-detail">
                                                        {row.vairant_id != 0 ? (
                                                            <div>
                                                                {row
                                                                    .product_variants[0]
                                                                    .display_name ? (
                                                                    <div className="d-flex justify-contnet-between align-items-center position-relative">
                                                                        <FontAwesomeIcon
                                                                            className="payment-icon mb-3 me-2"
                                                                            icon={
                                                                                faTag
                                                                            }
                                                                        />
                                                                        <span className="price-details-disc ">
                                                                            {
                                                                                row
                                                                                    .product_variants[0]
                                                                                    .display_name
                                                                            }
                                                                            <span className="me-2">
                                                                                {" "}
                                                                                |{" "}
                                                                                {currency +
                                                                                    " " +
                                                                                    row.price_per_unit}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    <span className="ms-2">
                                                                        {" "}
                                                                        {currency +
                                                                            " " +
                                                                            row.price_per_unit}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        ) : row.display_name ? (
                                                            <div>
                                                                <div className="d-flex justify-contnet-between align-items-center">
                                                                    <FontAwesomeIcon
                                                                        className="payment-icon mb-3 me-2"
                                                                        icon={
                                                                            faTag
                                                                        }
                                                                    />
                                                                    <span className="price-details-disc mb-3">
                                                                        {
                                                                            row.display_name
                                                                        }
                                                                        <span className="me-2">
                                                                            {" "}
                                                                            |{" "}
                                                                            {currency +
                                                                                " " +
                                                                                row.price_per_unit}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <span className="currency-font">
                                                                    {currency +
                                                                        " "}
                                                                </span>
                                                                {
                                                                    row.price_per_unit
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="mar-start">
                                                    <div className="product">
                                                        Total
                                                    </div>
                                                    <div className="prod-detail">
                                                        {row.vairant_id != 0 ? (
                                                            <div>
                                                                <span className="currency-font">
                                                                    {currency +
                                                                        " "}
                                                                </span>
                                                                {parseFloat(
                                                                    (row.price_per_unit -
                                                                        row
                                                                            .product_variants[0]
                                                                            .discounted_amount) *
                                                                        row.qty
                                                                ).toFixed(2)}
                                                            </div>
                                                        ) : row.discounted_amount ? (
                                                            <div>
                                                                <span className="currency-font">
                                                                    {currency +
                                                                        " "}
                                                                </span>
                                                                {parseFloat(
                                                                    (row.price_per_unit -
                                                                        row.discounted_amount) *
                                                                        row.qty
                                                                ).toFixed(2)}
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <span className="currency-font">
                                                                    {currency +
                                                                        " "}
                                                                </span>
                                                                {parseFloat(
                                                                    row.price_per_unit *
                                                                        row.qty
                                                                ).toFixed(2)}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No Product Found</p>
                                    )}
                                    <div className="order-amount">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label for="#">Sub Total</label>
                                            <span className="price-space">
                                                <span className="currency-font">
                                                    {currency + " "}
                                                </span>
                                                {sub_total.toFixed(2)}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label for="#">GST Tax</label>
                                            <span className="price-space">
                                                <span className="currency-font">
                                                    {currency + " "}
                                                </span>
                                                {tax_rate.toFixed(2)}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <label for="#">Delivery</label>
                                            <span className="price-space">
                                                <span className="currency-font">
                                                    {currency + " "}
                                                </span>
                                                80
                                            </span>
                                        </div>
                                    </div>

                                    <button className="pay-btn">
                                        Payment Summary{" "}
                                        <span>
                                            <span className="currency-font">
                                                {currency + " "}
                                            </span>
                                            {parseFloat(
                                                sub_total + tax_rate + 80
                                            ).toFixed(2)}
                                        </span>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </>
    );
}
export default Detail;

import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    InputGroup,
    Form,
    Spinner,
} from "react-bootstrap";
import useValidator from "../../hooks/useValidator";
import Swal from "sweetalert2";
import api from "../../helpers/api";
function ShowErrorMessage(props) {
    const messages = props.message;
    const listItems = Object.keys(messages).map((key) => {
        return <li className="sign-form-li">{props.message[key]}</li>;
    });
    return <ul>{listItems}</ul>;
}
const Password = () => {
    const [email, setEmail] = useState("");
    const [validator, showValidationMessage] = useValidator();
    const [errorMsg, setErrorMsg] = useState();
    const [successMsg, setSuccessMsg] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        // After 3 seconds set the show value to false
        setTimeout(() => {
            setErrorMsg("");
            setSuccessMsg("");
        }, 3000);
    }, [errorMsg, successMsg]);
    useEffect(() => {
        if (localStorage.jwt_token) {
            window.location.href = "/";
        }
    });
    const submitHandler = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            setIsLoading(true);
            await api.post(
                    `/forgot-password`,
                    {
                        email,
                    },
                    {
                        headers: {
                            "POS-API-KEY": "jd54elkrtlh",
                        },
                    }
                )
                .then((res) => {
                    if (res.data.status == 0) {
                        if (typeof res.data.message === "object") {
                            setSuccessMsg("");
                            setErrorMsg(
                                <ShowErrorMessage message={res.data.message} />
                            );
                        } else {
                            setSuccessMsg("");
                            setErrorMsg(res.data.message);
                        }
                    } else {
                        setErrorMsg("");
                        setEmail("");
                        setSuccessMsg(res.data.message);
                    }
                    setIsLoading(false);
                })
                .catch((res) => {
                    Swal.fire({
                        text: "Something went wrong",
                        icon: "error",
                    });
                });
        } else {
            showValidationMessage(true);
        }
    };
    return (
        <div className="track-order">
            <div id="login-data">
                <Container>
                    <Row>
                        <Col>
                            <div class="d-flex justify-content-center form-outside">
                                <div class="login-form text-center ">
                                    {errorMsg && (
                                        <div className="alert alert-danger alert-dismissible">
                                            {errorMsg}
                                        </div>
                                    )}
                                    {successMsg && (
                                        <div className="alert alert-success">
                                            {successMsg}
                                        </div>
                                    )}
                                    <Form
                                        method="post"
                                        onSubmit={submitHandler}
                                    >
                                        <span class="head">
                                            Forgot Password
                                        </span>
                                        <div class="email-inp">
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    placeholder="Email"
                                                    aria-label="Eamil;"
                                                    aria-describedby="basic-addon1"
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </InputGroup>
                                        </div>

                                        <button
                                            class="log-btn-tracking"
                                            type="submit"
                                            disabled={
                                                isLoading == true ? true : false
                                            }
                                        >
                                            {"Submit" + " "}
                                            {isLoading && (
                                                <Spinner
                                                    as="span"
                                                    variant="blue"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    animation="border"
                                                />
                                            )}
                                        </button>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};
export default Password;

import React from "react";
import PaymentMethod from "./Paymentmethod";
import TopHeader from "../../sections/TopHeader/TopHeader";
import MiddleHeader from "../../sections/MiddleHeader/MiddleHeader";
import BottomHeader from '../../sections/BottomHeader/BottomHeader';
import MiddleFooter from "../../sections/MiddleFooter/Middlefooter";
import BottomFooter from '../../sections/BottomFooter/BottomFooter';

const ShippingPayment =()=>{
    return(
        <div className="wrapper">
            <TopHeader/>
            <MiddleHeader/>
            <BottomHeader/>
            <PaymentMethod/>
            <MiddleFooter/>
            <BottomFooter/>
        </div>
    )
}
export default ShippingPayment;

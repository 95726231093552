import React, { useState, useEffect } from "react";
import {
    Container,
    Dropdown,
    Button,
    Spinner,
    Form,
    Modal,
    Row,
    Col,
} from "react-bootstrap";
import useValidator from "../../../hooks/useValidator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import api from "../../../helpers/api";
function ShowErrorMessage(props) {
    const messages = props.message;
    const listItems = Object.keys(messages).map((key) => {
        return <li>{props.message[key]}</li>;
    });
    return <ul>{listItems}</ul>;
}

function PaymentDetails() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const user = localStorage.getItem("isLoggedIn");
    const cart_items = JSON.parse(localStorage.getItem("cart"));

    const customer = JSON.parse(localStorage.getItem("userData"));
    const [user_id, setUserId] = useState(customer?.user_id);

    const TotalPrice =
        cart_items.reduce(
            (prev, cur) =>
                (cur.discountedAmount
                    ? cur.price - cur.discountedAmount
                    : cur.price) *
                    cur.quantity +
                prev,
            0
        ) +
        cart_items.reduce(
            (prev, cur) => (cur.TaxRate / 2) * cur.quantity + prev,
            0
        ) +
        80;

    const [customer_first_name, setFirstName] = useState();
    const [customer_last_name, setLastName] = useState();
    const [customer_email, setEmail] = useState(customer?.email);

    const [customer_address, setAddress] = useState();
    const [customer_city, setCity] = useState();
    const [customerCountry, setCustomerCountry] = useState("");
    const [customer_appartment, setAppartment] = useState();
    const [customer_state, setCustomerState] = useState();
    const [customer_postal_code, setPostalCode] = useState();
    const [customer_phone_number, setCustomerPhoneNumber] = useState();

    const [billing_address, setBillingAddress] = useState();
    const [billing_appartment, setBillingAppartment] = useState([]);
    const [billing_state, setBillingState] = useState([]);
    const [billing_city, setBillingCity] = useState();
    const [billing_country, setBillingCountry] = useState();
    const [billing_postal_code, setBillingPostalCode] = useState();
    const [billing_phone_number, setBillingPhoneNumber] = useState();

    const [customer_shipping, setShippingMethod] = useState("Standard");
    const [payment_method, setPaymentMethod] = useState(0);
    const [shipping_address, setShippingAddress] = useState();
    const [validator, showValidationMessage] = useValidator();
    const [errorMsg, setErrorMsg] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const [showOrHide, setShowOrHide] = useState(false);
    const [showOrHideDropdown, setShowOrHideDropdown] = useState(false);
    const [shippingSection, setShippingSection] = useState(false);
    const [OrderAddress, setOrderAddresses] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [AllCountries, setAllCountries] = useState([]);
    const [ShippingCountryDropdown, setShippingCountryDropdown] = useState([]);
    const [BillingCountryDropdown, setBillingCountryDropdown] = useState([]);
    const [AddressDropdown, setAddressDropdwon] = useState();
    const [AddressOrderDropdown, setAddressOrderDropdwon] = useState();
    const [PaymentGateway, setpaymentGateway] = useState([]);

    //set Card Details
    const [sellerId, setSellerId] = useState("253343429332");
    const [publishableKey, setpublishablekey] = useState(
        "13D5C2A3-A2E0-499E-B999-2BDCC81522D5"
    );
    const [token, setToken] = useState("");
    const [cardNumber, setCardNumber] = useState();
    const [exp_month, setExpiryMonth] = useState();
    const [exp_year, setExpiryYear] = useState();
    const [cvc, setCVC] = useState();
    const [showCardBox, setCardBox] = useState(false);
    const [showCardInformation, setShowCardInformation] = useState(false);

    const handleClose = (event) => {
        event.preventDefault();
        setCardBox(false);
    };

    const optionsAddress = [
        //   { value: 'existing', label: 'Use Existing Address' },
        { value: "new_address", label: "Set New Address" },
    ];

    useEffect(() => {
        setAddress(customer_address);
    }, [customer_address]);

    useEffect(() => {
        api.get(`/get-order-address/${user_id}`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setOrderAddresses(res.data.data);
            });

        api.get(`/get-countries`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setAllCountries(res.data.data);
            });

        api.get(`/get-gateway-details`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setpaymentGateway(res.data.data);
            });
    }, []);

    if (customer != null && OrderAddress.length > 0) {
        optionsAddress.push({
            value: "existing",
            label: "Use Existing Address",
        });
    }

    const handleChange = (e) => {
        const Checked = e.target.checked;
        setIsChecked(Checked);
    };

    const HideAndShowDiv = (e) => {
        const Checked = e.target.checked;
        setShowOrHide(Checked);
    };

    const ShowAddress = (e) => {
        setAddressDropdwon(e);
        if (e.value == "new_address") {
            setIsDisabled(false);
            setShowOrHideDropdown(false);
            setShippingSection(true);
            setFirstName("");
            setLastName("");
            setAddress("");
            setCity("");
            setCustomerCountry();
            setShippingCountryDropdown([]);
            setAppartment([]);
            setCustomerState([]);
            setPostalCode([]);
            setCustomerPhoneNumber([]);
        }

        if (e.value == "existing") {
            setIsDisabled(true);
            setShowOrHideDropdown(true);
            api.get(`/get-order-address/${user_id}`, {
                    headers: {
                        "POS-API-KEY": "jd54elkrtlh",
                    },
                })
                .then((res) => {
                    setOrderAddresses(res.data.data);
                });
        }
    };
    const ShowCountries = (e) => {
        setShippingCountryDropdown(e);
        setCustomerCountry(e.value);
    };
    const HandleBillingCountries = (e) => {
        setBillingCountryDropdown(e);
        setBillingCountry(e.value);
    };

    const ShowAddressRecord = async (
        id,
        FirstName,
        LastName,
        StreetAddress,
        Cus_City,
        Cus_Country,
        Cus_PostalCode,
        Cus_appartment,
        Cus_State,
        Cus_Phone
    ) => {
        setAddressOrderDropdwon(StreetAddress);
        setShippingSection(true);
        setFirstName(FirstName);
        setLastName(LastName);
        setAddress(StreetAddress);
        setCity(Cus_City);
        setCustomerCountry(Cus_Country);
        setAppartment(Cus_appartment);
        setPostalCode(Cus_PostalCode);
        setCustomerState(Cus_State);
        setCustomerPhoneNumber(Cus_Phone);
        const arrayCountry = [{ value: Cus_Country, label: Cus_Country }];
        setShippingCountryDropdown(arrayCountry);
    };

    const handleChangePayment = (event) => {
        if (
            event.target.value == "Stripe" ||
            event.target.value == "TwoCheckout"
        ) {
            setCardBox(true);
        } else if (
            event.target.value == "Standard" ||
            event.target.value == "Paypal"
        ) {
            setShowCardInformation(false);
        }
        setShippingMethod(event.target.value);
    };

    // useEffect(() => {
    //     TCO.loadPubKey('sandbox');
    // }, []);
    const [errorMessage, setErrorMessage] = useState("");
    const SaveCardInfo = (e) => {
        e.preventDefault();
        if (
            cardNumber == undefined ||
            exp_month == undefined ||
            exp_year == undefined ||
            cvc == undefined
        ) {
            setErrorMessage("Please Enter Complete Card Detail");
        } else {
            setCardBox(false);
            setShowCardInformation(true);
        }
    };
    const StoreOrderInformation = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            setIsLoading(true);
            await api.post(
                    `/store-order-info`,
                    {
                        user_id,
                        customer_first_name,
                        customer_last_name,
                        customer_email,
                        customer_address,
                        customer_city,
                        customerCountry,
                        customer_appartment,
                        customer_state,
                        customer_postal_code,
                        customer_phone_number,
                        customer_shipping,
                        shipping_address,
                        payment_method,
                        cart_items,
                        TotalPrice,
                        showOrHide,
                        billing_address,
                        billing_appartment,
                        billing_state,
                        billing_city,
                        billing_country,
                        billing_postal_code,
                        billing_phone_number,
                        cardNumber,
                        exp_month,
                        exp_year,
                        cvc,
                    },
                    {
                        headers: {
                            "POS-API-KEY": "jd54elkrtlh",
                        },
                    }
                )
                .then(({ data }) => {
                    if (data.status == 0) {
                        if (typeof data.message === "object") {
                            window.scrollTo(0, 0);
                            setErrorMsg(
                                <ShowErrorMessage message={data.message} />
                            );
                            setIsLoading(false);
                        } else {
                            setErrorMsg(data.message);
                            setIsLoading(false);
                        }
                    } else {
                        setErrorMsg("");

                        localStorage.setItem("order_id", data.salesRefId);
                        if (customer_shipping == "PayPal") {
                            window.location.href = data.payment;
                        } else {
                            navigate("/order-tracking");
                        }
                    }
                })
                .catch((err) => setErrorMsg(err.message));
        } else {
            setIsLoading(false);
            showValidationMessage(true);
        }
    };
    const first_name = customer_first_name
        ? customer_first_name
        : "Your First Name";
    const email = customer_email ? customer_email : "Your Email";
    const address = customer_address ? customer_address : "Your Address";
    const postal_code = customer_postal_code
        ? customer_postal_code
        : "& Postal Code";
    const Bill_address = billing_address
        ? billing_address
        : "Your Billing Address";
    const Bill_PostalCode = billing_postal_code
        ? billing_postal_code
        : "& Your Billing Postal Code";

    // Validation of Required Tabs
    const [activeTab, setActiveTab] = useState("pills-ship-tab");
    useEffect(() => {}, [activeTab]);

    useEffect(() => {
        validateTab(activeTab);
    }, [
        customer_email,
        AddressDropdown,
        customer_first_name,
        customer_last_name,
        customer_address,
        customer_city,
        customerCountry,
        customer_postal_code,
        customer_phone_number,
    ]);

    const validateTab = (tab) => {
        if (tab === "pills-ship-tab1") {
            if (
                customer_email === undefined ||
                AddressDropdown === undefined ||
                customer_first_name === undefined ||
                customer_last_name === undefined ||
                customer_address === undefined ||
                customer_city === undefined ||
                customerCountry === undefined ||
                customer_postal_code === undefined ||
                customer_phone_number === undefined
            ) {
                setErrorMsg("Please fill all required fields");
                return false; // Prevent switching to shipping tab
            }
            setErrorMsg("");
            return true; // Allow switching for other tabs
        }
        setErrorMsg("");
        return true; // Allow switching for other tabs
    };
    const handleTabClick = (event, tabId) => {
        if (!validateTab(tabId)) {
            event.preventDefault(); // Prevent default behavior of the anchor element
        } else {
            setActiveTab(tabId);
        }
    };

    const discountedAmount = cart_items.reduce(
        (prev, cur) =>
            (cur.discountedAmount
                ? cur.price - cur.discountedAmount
                : cur.price) *
                cur.quantity +
            prev,
        0
    );
    const SubTotal = cart_items.reduce(
        (prev, cur) => cur.price * cur.quantity + prev,
        0
    );
    const GstTax = parseFloat(
        cart_items.reduce(
            (prev, cur) =>
                (cur.TaxRate / 100) *
                    ((cur.price - cur.discountedAmount) * cur.quantity) +
                prev,
            0
        )
    );

    return (
        <Container>
            <div id="payment">
                <div className="set-width">
                    <div className="d-lg-flex">
                        <div className="cont1">
                            <div className="nav " id="nav-tab" role="tablist">
                                <ul
                                    className="nav nav-pills "
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <a className="nav-link" href="/cart">
                                            Cart{" "}
                                        </a>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <a
                                            className={`nav-link ${
                                                activeTab === "pills-ship-tab"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="pills-info-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-info"
                                            role="tab"
                                            aria-controls="pills-info"
                                            aria-selected="true"
                                        >
                                            {" "}
                                            <span className="ms-lg-2 ms-md-1">
                                                /
                                            </span>{" "}
                                            <span className="ms-lg-1 ms-md-1 me-lg-2 me-md-1">
                                                information /{" "}
                                            </span>{" "}
                                        </a>
                                    </li>
                                    <li
                                        className="nav-item "
                                        role="presentation"
                                    >
                                        <a
                                            className={`nav-link ${
                                                activeTab === "pills-ship-tab1"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="pills-ship-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-ship"
                                            role="tab"
                                            aria-controls="pills-ship"
                                            aria-selected="false"
                                        >
                                            <span className="ms-lg-0 ms-md-1">
                                                Shipping /
                                            </span>
                                        </a>
                                    </li>
                                    <li
                                        className="nav-item  "
                                        role="presentation"
                                    >
                                        <a
                                            className={`nav-link ps-lg-3 ps-2 ${
                                                activeTab === "pills-ship-tab2"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="pills-payment-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-payment"
                                            role="tab"
                                            aria-controls="pills-ship"
                                            aria-selected="false"
                                        >
                                            Payment
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {errorMsg && (
                                <div className="alert alert-danger alert-dismissible">
                                    {errorMsg}
                                </div>
                            )}
                            <form
                                method="post"
                                onSubmit={StoreOrderInformation}
                            >
                                <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                >
                                    <div
                                        className={`tab-pane fade ${
                                            activeTab === "pills-ship-tab"
                                                ? "show active"
                                                : ""
                                        }`}
                                        id="pills-info"
                                        role="tabpanel"
                                        aria-labelledby="pills-info-tab"
                                    >
                                        <div className="cont-info">
                                            <h2 className="mb-lg-0 mb-md-0 mb-2">
                                                Contact Information
                                            </h2>
                                            {!user && (
                                                <span>
                                                    Already have an account?
                                                    <a
                                                        href="/login"
                                                        className="login ms-1"
                                                    >
                                                        Log In
                                                    </a>
                                                </span>
                                            )}
                                        </div>
                                        <div>
                                            <input
                                                type="email"
                                                value={customer_email}
                                                onChange={(e) => {
                                                    setEmail(
                                                        e.target.value
                                                    );
                                                }}
                                                placeholder="Email"
                                            />
                                        </div>

                                        <h2 className="mb-3">
                                            Address Information
                                        </h2>
                                        <Select
                                            className="border-color-account"
                                            defaultValue={AddressDropdown}
                                            onChange={ShowAddress}
                                            value={AddressDropdown}
                                            options={optionsAddress}
                                            // menuIsOpen={true}
                                        />

                                        {showOrHideDropdown == true && (
                                            <div className="payment-detail-drop">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="success"
                                                        id="dropdown-basic-payment"
                                                    >
                                                        {AddressOrderDropdown
                                                            ? AddressOrderDropdown
                                                            : "Choose Address"}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item> */}
                                                        {OrderAddress.map(
                                                            (item, index) => (
                                                                <Dropdown.Item className="payment-details-address">
                                                                    <ul
                                                                        className="payment-flow d-flex justify-content-between align-items-center"
                                                                        onClick={() =>
                                                                            ShowAddressRecord(
                                                                                item.id,
                                                                                item.first_name,
                                                                                item.last_name,
                                                                                item.shipping_address,
                                                                                item.shipping_city,
                                                                                item.shipping_country,
                                                                                item.shipping_postal_code,
                                                                                item.shipping_appartment,
                                                                                item.shipping_state,
                                                                                item.shipping_phone_number
                                                                            )
                                                                        }
                                                                    >
                                                                        <li className="payment-li">
                                                                            {
                                                                                item.shipping_address
                                                                            }
                                                                        </li>
                                                                        <li className="payment-li">
                                                                            {
                                                                                item.shipping_country
                                                                            }
                                                                        </li>
                                                                        <li className="payment-li">
                                                                            {
                                                                                item.shipping_city
                                                                            }
                                                                        </li>
                                                                        <li className="payment-li">
                                                                            {
                                                                                item.shipping_postal_code
                                                                            }
                                                                        </li>
                                                                        <li className="payment-li">
                                                                            {
                                                                                item.shipping_state
                                                                            }
                                                                        </li>
                                                                        <li className="payment-li">
                                                                            {
                                                                                item.shipping_phone_number
                                                                            }
                                                                        </li>
                                                                    </ul>
                                                                </Dropdown.Item>
                                                            )
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        )}
                                        {shippingSection == true && (
                                            <div>
                                                <h2 className="mar-bot">
                                                    Shipping Address
                                                </h2>

                                                <div className="payment-select-wrap d-flex">
                                                    <div className="w-100 me-sm-2">
                                                        <input
                                                            type="name"
                                                            value={
                                                                customer_first_name
                                                            }
                                                            onChange={(e) => {
                                                                setFirstName(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                            placeholder="First Name"
                                                            disabled={
                                                                isDisabled ==
                                                                true
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div>
                                                    <div className="w-100 ms-lg-3 ms-md-3 ms-sm-0">
                                                        <input
                                                            type="name"
                                                            value={
                                                                customer_last_name
                                                            }
                                                            onChange={(e) => {
                                                                setLastName(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                            placeholder="Last Name"
                                                            disabled={
                                                                isDisabled ==
                                                                true
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <input
                                                        type="address"
                                                        value={customer_address}
                                                        onChange={(e) => {
                                                            setAddress(
                                                                e.target
                                                                    .value
                                                            );
                                                        }}
                                                        placeholder="Address"
                                                        disabled={
                                                            isDisabled == true
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div className="payment-select-wrap d-flex">
                                                    <div className="w-100 me-sm-2">
                                                        <input
                                                            type="apartment"
                                                            value={
                                                                customer_appartment
                                                            }
                                                            onChange={(e) => {
                                                                setAppartment(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                            placeholder="Apartment, suite, etc. (optional)"
                                                            disabled={
                                                                isDisabled ==
                                                                true
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div>
                                                    <div className="w-100 ms-lg-3 ms-md-3 ms-0">
                                                        <input
                                                            type="name"
                                                            value={
                                                                customer_state
                                                            }
                                                            onChange={(e) => {
                                                                setCustomerState(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                            placeholder="State"
                                                            disabled={
                                                                isDisabled ==
                                                                true
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <input
                                                        type="city"
                                                        value={customer_city}
                                                        onChange={(e) => {
                                                            setCity(
                                                                e.target
                                                                    .value
                                                            );
                                                        }}
                                                        placeholder="City"
                                                        disabled={
                                                            isDisabled == true
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div className="payment-select-wrap d-flex align-items-center">
                                                    <div className="w-100 me-sm-2">
                                                        <Select
                                                            className="border-color-account"
                                                            defaultValue={
                                                                ShippingCountryDropdown
                                                            }
                                                            onChange={
                                                                ShowCountries
                                                            }
                                                            // menuIsOpen={true}
                                                            value={
                                                                ShippingCountryDropdown
                                                            }
                                                            options={AllCountries.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return {
                                                                        value: item.name,
                                                                        label: item.name,
                                                                    };
                                                                }
                                                            )}
                                                        />

                                                        {/* <input type="name" value={customer_country} onChange={(e)=>{setCountry(event.target.value)}} placeholder="Country" disabled = {isDisabled == true ? true : false}/> */}
                                                    </div>
                                                    <div className="w-100 ms-lg-3 ms-md-3 ms-sm-0">
                                                        <input
                                                            type="name"
                                                            value={
                                                                customer_postal_code
                                                            }
                                                            onChange={(e) => {
                                                                setPostalCode(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                            placeholder="Postal Code"
                                                            disabled={
                                                                isDisabled ==
                                                                true
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={
                                                            customer_phone_number
                                                        }
                                                        onChange={(e) => {
                                                            setCustomerPhoneNumber(
                                                                e.target
                                                                    .value
                                                            );
                                                        }}
                                                        placeholder="Phone Number"
                                                        disabled={
                                                            isDisabled == true
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <label className="check-cont">
                                                    <input
                                                        type="checkbox"
                                                        onChange={
                                                            HideAndShowDiv
                                                        }
                                                    />
                                                    <span className="checkmark"></span>
                                                    <span>
                                                        Use different Billing
                                                        Address
                                                    </span>
                                                </label>

                                                {showOrHide == true && (
                                                    <div>
                                                        <h2 className="mar-bot">
                                                            Billing Address
                                                        </h2>
                                                        <div>
                                                            <input
                                                                type="address"
                                                                value={
                                                                    billing_address
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setBillingAddress(
                                                                        e
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                                placeholder="Address"
                                                            />
                                                        </div>
                                                        <div className="payment-select-wrap d-flex">
                                                            <div className="w-100 me-sm-2">
                                                                <input
                                                                    type="apartment"
                                                                    value={
                                                                        billing_appartment
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setBillingAppartment(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    placeholder="Apartment, suite, etc. (optional)"
                                                                />
                                                            </div>
                                                            <div className="w-100 ms-lg-3 ms-md-2 ms-sm-0 ">
                                                                <input
                                                                    type="state"
                                                                    value={
                                                                        billing_state
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setBillingState(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    placeholder="State"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="city"
                                                                value={
                                                                    billing_city
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setBillingCity(
                                                                        e
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                                placeholder="City"
                                                            />
                                                        </div>
                                                        <div className="payment-select-wrap d-flex align-items-center">
                                                            <div className="w-100 me-sm-2">
                                                                <Select
                                                                    className="border-color-account"
                                                                    defaultValue={
                                                                        BillingCountryDropdown
                                                                    }
                                                                    onChange={
                                                                        HandleBillingCountries
                                                                    }
                                                                    value={
                                                                        BillingCountryDropdown
                                                                    }
                                                                    // menuIsOpen={true}
                                                                    options={AllCountries.map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => {
                                                                            return {
                                                                                value: item.name,
                                                                                label: item.name,
                                                                            };
                                                                        }
                                                                    )}
                                                                />
                                                                {/* <input type="name" value={billing_country} onChange={(e)=>{setBillingCountry(event.target.value)}} placeholder="Country" /> */}
                                                            </div>
                                                            <div className="w-100 ms-lg-3 ms-md-3 ms-sm-0">
                                                                <input
                                                                    type="name"
                                                                    value={
                                                                        billing_postal_code
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setBillingPostalCode(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    placeholder="Postal Code"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    billing_phone_number
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setBillingPhoneNumber(
                                                                        e
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                                placeholder="Phone Number"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <label className="check-cont">
                                                    {/* <input type="checkbox" onChange={handleChange} />
                                                <span className="checkmark"></span> */}
                                                    {/* <span>Save this information for next time</span> */}
                                                </label>
                                            </div>
                                        )}

                                        <div
                                            className="nav "
                                            id="nav-tab button-active"
                                            role="tablist"
                                        >
                                            <ul
                                                className="nav nav-pills "
                                                id="pills-tab"
                                                role="tablist"
                                            >
                                                <li
                                                    className="nav-item  "
                                                    role="presentation"
                                                >
                                                    <a
                                                        className="nav-link ps-3"
                                                        href="/cart"
                                                    >
                                                        <span className="red-col endbtn">
                                                            Return to cart
                                                        </span>
                                                    </a>
                                                </li>
                                                <li
                                                    className="nav-item "
                                                    role="presentation"
                                                >
                                                    <a className={`nav-link ${
                                                            activeTab ===
                                                            "pills-ship-tab1"
                                                                ? "active"
                                                                : ""
                                                        }`}
                                                        id="pills-ship-tab"
                                                        onClick={(event) =>
                                                            handleTabClick(
                                                                event,
                                                                "pills-ship-tab1"
                                                            )
                                                        }
                                                    >
                                                        <span className="endbtn">
                                                            Continue Shipping
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        className={`tab-pane fade ${
                                            activeTab === "pills-ship-tab1"
                                                ? "show active"
                                                : ""
                                        }`}
                                        id="pills-ship"
                                        role="tabpanel"
                                        aria-labelledby="pills-ship-tab"
                                    >
                                        <div className="cont1-inside">
                                            <ul>
                                                <li className="d-flex justify-content-between">
                                                    <label for="#">
                                                        Contact
                                                    </label>
                                                    <span className="word-break">
                                                        {email}
                                                    </span>
                                                    {/* <a className="text-end" href="#">Change</a> */}
                                                    <ul className="nav nav-pills p-0 border-0 mb-0">
                                                        <li
                                                            className="nav-item border-0"
                                                            id="pills-tab"
                                                            role="tablist"
                                                        >
                                                            <a
                                                                className={`nav-link ps-3 ${
                                                                    activeTab ===
                                                                    "pills-ship-tab"
                                                                        ? "active"
                                                                        : ""
                                                                }`}
                                                                id="pills-ship-tab" onClick={(event) =>
                                                                    handleTabClick(
                                                                        event,
                                                                        "pills-ship-tab"
                                                                    )
                                                                }
                                                            >
                                                                <span className="text-end">
                                                                    Change
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="d-flex justify-content-between ">
                                                    <label for="#">
                                                        Ship to
                                                    </label>
                                                    <span className="word-break">
                                                        {address +
                                                            " " +
                                                            postal_code}
                                                    </span>
                                                    {/* <a className="text-end" href="#">Change</a> */}
                                                    <ul className="nav nav-pills p-0 border-0 mb-0">
                                                        <li
                                                            className="nav-item border-0"
                                                            id="pills-tab"
                                                            role="tablist"
                                                        >
                                                            <a className={`nav-link ps-3 ${
                                                                    activeTab ===
                                                                    "pills-ship-tab"
                                                                        ? "active"
                                                                        : ""
                                                                }`}
                                                                id="pills-ship-tab" onClick={(event) =>
                                                                    handleTabClick(
                                                                        event,
                                                                        "pills-ship-tab"
                                                                    )
                                                                }
                                                            >
                                                                <span
                                                                    className="text-end"
                                                                >
                                                                    Change
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>

                                                {showOrHide == true && (
                                                    <li className="d-flex justify-content-between border-0">
                                                        <label for="#">
                                                            Bill to
                                                        </label>
                                                        <span className="word-break">
                                                            {Bill_address +
                                                                " " +
                                                                Bill_PostalCode}
                                                        </span>
                                                        {/* <a className="text-end" href="#">Change</a> */}
                                                        <ul className="nav nav-pills p-0 border-0 mb-0">
                                                            <li
                                                                className="nav-item border-0"
                                                                id="pills-tab"
                                                                role="tablist"
                                                            >
                                                                <a
                                                                    className="nav-link ps-3"
                                                                    id="pills-payment-tab"
                                                                    data-bs-toggle="pill"
                                                                    data-bs-target="#pills-info"
                                                                    role="tab"
                                                                    aria-controls="pills-ship"
                                                                    aria-selected="false"
                                                                >
                                                                    <span
                                                                        className="text-end"
                                                                    >
                                                                        Change
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                )}
                                            </ul>
                                            <div className="heading">
                                                <h2>Shipping Method</h2>
                                            </div>
                                            <ul>
                                                <li className="d-flex justify-content-between">
                                                    <Form.Check
                                                        name="method"
                                                        id="standard"
                                                        value="Standard"
                                                        type="radio"
                                                        label="Standard"
                                                        onChange={
                                                            handleChangePayment
                                                        }
                                                        checked={
                                                            customer_shipping ==
                                                            "Standard"
                                                        }
                                                    />
                                                    <span className="word-break">
                                                        80
                                                    </span>
                                                </li>
                                                {PaymentGateway.length > 0 &&
                                                    PaymentGateway.map(
                                                        (item, index) => (
                                                            <li className="d-flex justify-content-between">
                                                                <Form.Check
                                                                    name="method"
                                                                    id={
                                                                        item.payment_name
                                                                    }
                                                                    value={
                                                                        item.payment_name
                                                                    }
                                                                    type="radio"
                                                                    label={
                                                                        item.payment_name
                                                                    }
                                                                    onChange={
                                                                        handleChangePayment
                                                                    }
                                                                />
                                                                {/* <a className="text-end" href="#">Change</a> */}
                                                            </li>
                                                        )
                                                    )}

                                                {/* <li className="d-flex justify-content-between ">
                                                    <Form.Check name="method" id="stripe" value="stripe"  type="radio" label="stripe" onChange={handleChangePayment} />
                                                </li> */}
                                                {/* <li className="d-flex justify-content-between ">
                                                <Form.Check name="method" id="TwoCheckout" value="TwoCheckout"  type="radio" label="2Checkout" onChange={handleChangePayment} />
                                                </li> */}
                                            </ul>
                                        </div>
                                        {showCardInformation == true && (
                                            <div className="cont1-inside">
                                                <div className="heading">
                                                    <h2>Card Information</h2>
                                                </div>
                                                <ul>
                                                    <li className="d-flex justify-content-between">
                                                        <label
                                                            className="card-width"
                                                            for="#"
                                                        >
                                                            Card Number
                                                        </label>
                                                        <span className="card-text">
                                                            {cardNumber}
                                                        </span>
                                                        <ul className="nav nav-pills p-0 border-0 mb-0">
                                                            <li
                                                                className="nav-item border-0"
                                                                id="pills-tab"
                                                                role="tablist"
                                                            >
                                                                <a
                                                                    onClick={() =>
                                                                        setCardBox(
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="text-end">
                                                                        Change
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="d-flex justify-content-between ">
                                                        <label
                                                            className="card-width"
                                                            for="#"
                                                        >
                                                            Expiry Month
                                                        </label>
                                                        <span className="card-text">
                                                            {exp_month}
                                                        </span>
                                                        <ul className="nav nav-pills p-0 border-0 mb-0">
                                                            <li
                                                                className="nav-item border-0"
                                                                id="pills-tab"
                                                                role="tablist"
                                                            >
                                                                <a
                                                                    onClick={() =>
                                                                        setCardBox(
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="text-end">
                                                                        Change
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="d-flex justify-content-between ">
                                                        <label
                                                            className="card-width"
                                                            for="#"
                                                        >
                                                            Expiry Year
                                                        </label>
                                                        <span className="card-text">
                                                            {exp_year}
                                                        </span>
                                                        <ul className="nav nav-pills p-0 border-0 mb-0">
                                                            <li
                                                                className="nav-item border-0"
                                                                id="pills-tab"
                                                                role="tablist"
                                                            >
                                                                <a
                                                                    onClick={() =>
                                                                        setCardBox(
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="text-end">
                                                                        Change
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="d-flex justify-content-between ">
                                                        <label
                                                            className="card-width"
                                                            for="#"
                                                        >
                                                            CVC
                                                        </label>
                                                        <span className="card-text">
                                                            {cvc}
                                                        </span>
                                                        <ul className="nav nav-pills p-0 border-0 mb-0">
                                                            <li
                                                                className="nav-item border-0"
                                                                id="pills-tab"
                                                                role="tablist"
                                                            >
                                                                <a
                                                                    onClick={() =>
                                                                        setCardBox(
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="text-end">
                                                                        Change
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    {/* {showOrHide == true &&
                                                    <li className="d-flex justify-content-between border-0">
                                                        <label for="#">Bill to</label>
                                                        <span>{Bill_address  + " " + Bill_PostalCode}</span>
                                                    </li>
                                                    } */}
                                                </ul>
                                            </div>
                                        )}
                                        <div
                                            className="nav "
                                            id="nav-tab"
                                            role="tablist"
                                        >
                                            <ul
                                                className="nav nav-pills "
                                                id="pills-tab"
                                                role="tablist"
                                            >
                                                <li
                                                    className="nav-item  "
                                                    role="presentation"
                                                >
                                                    <a className={`nav-link ps-3 ${
                                                            activeTab ===
                                                            "pills-ship-tab"
                                                                ? "active"
                                                                : ""
                                                        }`}
                                                        id="pills-ship-tab" onClick={(event) =>
                                                            handleTabClick(
                                                                event,
                                                                "pills-ship-tab"
                                                            )
                                                        }
                                                    >
                                                        <span className="endbtn me-2 button-active">
                                                            Return to
                                                            information
                                                        </span>
                                                    </a>
                                                </li>
                                                <li
                                                    className="nav-item "
                                                    role="presentation"
                                                >
                                                    <a
                                                        className={
                                                            activeTab ===
                                                            "pills-ship-tab2"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        id="pills-ship-tab2"
                                                        onClick={(event) =>
                                                            handleTabClick(
                                                                event,
                                                                "pills-ship-tab2"
                                                            )
                                                        }
                                                    >
                                                        <span className="endbtn">
                                                            Continue to Payment
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        className={`tab-pane fade ${
                                            activeTab === "pills-ship-tab2"
                                                ? "show active"
                                                : ""
                                        }`}
                                        id="pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="pills-payment-tab"
                                    >
                                        <div className="cont1-inside">
                                            <ul>
                                                <li className="d-flex justify-content-between">
                                                    <label for="#">
                                                        Contact
                                                    </label>
                                                    <span className="word-break">
                                                        {email}
                                                    </span>
                                                    {/* <a className="text-end" href="#">Change</a> */}
                                                    <ul className="nav nav-pills p-0 border-0 mb-0">
                                                        <li
                                                            className="nav-item border-0"
                                                            id="pills-tab"
                                                            role="tablist"
                                                        >
                                                            <a
                                                                className={`nav-link ps-3 ${
                                                                    activeTab ===
                                                                    "pills-ship-tab"
                                                                        ? "active"
                                                                        : ""
                                                                }`}
                                                                id="pills-ship-tab" onClick={(event) =>
                                                                    handleTabClick(
                                                                        event,
                                                                        "pills-ship-tab"
                                                                    )
                                                                }
                                                            >
                                                                <span
                                                                    className="text-end"
                                                                >
                                                                    Change
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <label for="#">
                                                        Ship to
                                                    </label>
                                                    <span>
                                                        {address +
                                                            " " +
                                                            postal_code}
                                                    </span>
                                                    {/* <a className="text-end" href="#">Change</a> */}
                                                    <ul className="nav nav-pills p-0 border-0 mb-0">
                                                        <li
                                                            className="nav-item border-0"
                                                            id="pills-tab"
                                                            role="tablist"
                                                        >
                                                            <a
                                                                className={`nav-link ps-3 ${
                                                                    activeTab ===
                                                                    "pills-ship-tab"
                                                                        ? "active"
                                                                        : ""
                                                                }`}
                                                                id="pills-ship-tab" onClick={(event) =>
                                                                    handleTabClick(
                                                                        event,
                                                                        "pills-ship-tab"
                                                                    )
                                                                }
                                                            >
                                                                <span
                                                                    className="text-end"
                                                                >
                                                                    Change
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="d-flex justify-content-between border-0">
                                                    <label for="#">
                                                        Method
                                                    </label>
                                                    <span>
                                                        {customer_shipping}
                                                    </span>
                                                    <ul className="nav nav-pills p-0 border-0 mb-0">
                                                        <li
                                                            className="nav-item border-0"
                                                            id="pills-tab"
                                                            role="tablist"
                                                        >
                                                            <a className={`nav-link ps-3 ${
                                                                    activeTab ===
                                                                    "pills-ship-tab1"
                                                                        ? "active"
                                                                        : ""
                                                                }`}
                                                                id="pills-ship-tab1" onClick={(event) =>
                                                                    handleTabClick(
                                                                        event,
                                                                        "pills-ship-tab1"
                                                                    )
                                                                }
                                                            >
                                                                <span
                                                                    className="text-end"
                                                                >
                                                                    Change
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <div className="heading">
                                                <h2>Payments</h2>
                                                <span>
                                                    All transactions are secure
                                                    and encrypted.
                                                </span>
                                            </div>
                                            <div className="radio-border">
                                                {customer_shipping ==
                                                    "Stripe" ||
                                                customer_shipping ==
                                                    "PayPal" ? (
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault"
                                                            id="flexRadioDefault1"
                                                        />
                                                        <label
                                                            className="form-check-label lab-color"
                                                            for="flexRadioDefault1"
                                                        >
                                                            Credit Card / Debit
                                                            Card / Net Banking
                                                        </label>
                                                    </div>
                                                ) : (
                                                    <div className="form-check border-0">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault2"
                                                            id="flexRadioDefault2"
                                                            checked
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for="flexRadioDefault2"
                                                        >
                                                            Cash on Delivery
                                                            (COD)
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            className="nav "
                                            id="nav-tab"
                                            role="tablist"
                                        >
                                            <ul
                                                className="nav nav-pills"
                                                id="pills-tab"
                                                role="tablist"
                                            >
                                                <li
                                                    className="nav-item"
                                                    role="presentation"
                                                >
                                                    <a
                                                        className={
                                                            activeTab ===
                                                            "pills-ship-tab1"
                                                                ? "active"
                                                                : ""
                                                        }
                                                        id="pills-payment-tab" onClick={(event) =>
                                                            handleTabClick(
                                                                event,
                                                                "pills-ship-tab1"
                                                            )
                                                        }
                                                    >
                                                        <span
                                                            className="endbtn button-shipping  button-order me-3"
                                                        >
                                                            Return to shipping
                                                        </span>
                                                    </a>
                                                </li>
                                                <li
                                                    className="nav-item  ps-3 "
                                                    role="presentation"
                                                >
                                                    {isLoading ? (
                                                        <Button
                                                            className="endbtn"
                                                            disabled
                                                        >
                                                            {"Order Complete" +
                                                                " "}
                                                            <Spinner
                                                                as="span"
                                                                variant="blue"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                animation="border"
                                                            />
                                                        </Button>
                                                    ) : (
                                                        <button
                                                            className="endbtn"
                                                            type="submit"
                                                        >
                                                            Order Complete
                                                        </button>
                                                    )}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="cont2">
                            <div className="cont2-inside">
                                <div className="scroll-product">
                                    {cart_items != "" ? (
                                        cart_items.map((row, key) => (
                                            <div className="d-flex cont2-adj">
                                                <div className="img-cont">
                                                    <img
                                                        className="w-100 h-100"
                                                        src={
                                                            row.first_variant_image ==
                                                            null
                                                                ? row.image
                                                                : row.first_variant_image
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                                <span className="p-width">
                                                    {row.variant_name == ""
                                                        ? row.product_name
                                                        : row.variant_name}
                                                    {row.discountedAmount ? (
                                                        <div className="d-flex justify-contnet-between align-items-center">
                                                            <FontAwesomeIcon
                                                                className="payment-icon mb-3 me-2"
                                                                icon={faTag}
                                                            />
                                                            <span className="price-details-disc mb-3">
                                                                <span className="payment-off-sale me-2">
                                                                    {row.discountedPercentage +
                                                                        "%"}
                                                                </span>
                                                                <span className="ms-2">
                                                                    {" "}
                                                                    {row.price}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div>{row.price}</div>
                                                    )}
                                                    <span>
                                                        {"Qty: " + row.quantity}
                                                    </span>
                                                </span>

                                                {/* <span className="text-end price">{row.price}</span> */}
                                            </div>
                                        ))
                                    ) : (
                                        <p>No Product Found</p>
                                    )}
                                </div>
                                <div className="sect1">
                                    <ul className="list-unstyled mb-0">
                                        <li>
                                            <label for="#">Subtotal</label>
                                            {SubTotal == discountedAmount ? (
                                                <span className="price-details">
                                                    Rs {SubTotal.toFixed(2)}
                                                </span>
                                            ) : (
                                                <span className="d-price">
                                                    <del>
                                                        Rs {SubTotal.toFixed(2)}
                                                    </del>
                                                </span>
                                            )}
                                        </li>
                                        {SubTotal != discountedAmount && (
                                            <li className="mb-4">
                                                <label for="#">
                                                    Dicounted Amount
                                                </label>
                                                <div className="payment-detail-icon d-flex align-items-center judtify-content-between">
                                                    <span className="price-details">
                                                        Rs
                                                        {discountedAmount.toFixed(
                                                            2
                                                        )}
                                                    </span>
                                                </div>
                                            </li>
                                        )}
                                        <li className="mb-4">
                                            <label for="#">GST Tax</label>
                                            <span className="price">
                                                Rs {GstTax.toFixed(2)}
                                            </span>
                                        </li>
                                        <li className="mb-4">
                                            <label for="#">Shipping</label>
                                            <span className="price">Rs 80</span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled border-0 mb-0 pb-0">
                                        <li className="mb-0 ">
                                            <label for="#">Total</label>
                                            <span className="price2">
                                                <span className="usd">Rs.</span>{" "}
                                                {(
                                                    discountedAmount +
                                                    GstTax +
                                                    80
                                                ).toFixed(2)}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        className="account-radio"
                        show={showCardBox}
                        onHide={handleClose}
                    >
                        <Modal.Header>
                            <Modal.Title>Add Card Details</Modal.Title>
                        </Modal.Header>
                        {errorMessage && (
                            <div className="alert alert-danger alert-dismissible">
                                {errorMessage}
                            </div>
                        )}
                        <form>
                            <Modal.Body>
                                <Row className="mb-3">
                                    <Form.Group
                                        as={Col}
                                        controlId="modal-body-input"
                                    >
                                        <Form.Label>
                                            Card Number
                                            <span class="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="name"
                                            value={cardNumber}
                                            onChange={(e) => {
                                                setCardNumber(e.target.value);
                                            }}
                                            placeholder="Card Number"
                                        />
                                    </Form.Group>
                                </Row>
                                <div className="mb-3">
                                    <Form.Group
                                        as={Col}
                                        controlId="modal-body-input"
                                    >
                                        <Form.Label>
                                            Expiry Month
                                            <span class="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="name"
                                            value={exp_month}
                                            onChange={(e) => {
                                                setExpiryMonth(
                                                    e.target.value
                                                );
                                            }}
                                            placeholder="Expiry Month"
                                        />
                                    </Form.Group>
                                </div>
                                <div className="mb-3">
                                    <Form.Group
                                        as={Col}
                                        controlId="modal-body-input"
                                    >
                                        <Form.Label>
                                            Expiry Year
                                            <span class="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="name"
                                            value={exp_year}
                                            onChange={(e) => {
                                                setExpiryYear(
                                                    e.target.value
                                                );
                                            }}
                                            placeholder="Expiry Year"
                                        />
                                    </Form.Group>
                                </div>
                                <div className="mb-3">
                                    <Form.Group
                                        as={Col}
                                        controlId="modal-body-input"
                                    >
                                        <Form.Label>
                                            CVC
                                            <span class="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="name"
                                            value={cvc}
                                            onChange={(e) => {
                                                setCVC(e.target.value);
                                            }}
                                            placeholder="CVC"
                                        />
                                    </Form.Group>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="d-flex">
                                    <button
                                        class="log-btn-account me-3"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                    <button
                                        class="log-btn-account-active"
                                        onClick={SaveCardInfo}
                                    >
                                        Save Change
                                    </button>
                                </div>
                                {/* <Button variant="primary" type="submit">
                                Save Changes
                            </Button> */}
                            </Modal.Footer>
                        </form>
                    </Modal>
                </div>
            </div>
        </Container>
    );
}
export default PaymentDetails;

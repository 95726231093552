import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import Bread from "../Wishlist/Bread/Bread";
import useValidator from "../../../hooks/useValidator";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import capitilizeFirst from "../../../hooks/capitilizeFirst";
import api from "../../../helpers/api";

function ShowErrorMessage(props) {
    const messages = props.message;
    const listItems = Object.keys(messages).map((key) => {
        return <li className="sign-form-li">{props.message[key]}</li>;
    });
    return <ul>{listItems}</ul>;
}
function Account(props) {
    const customer = props.customer;
    const [show, setShow] = useState(false);

    // customer addresses info
    const [address_id, setAddressID] = useState();
    const [AddressFirstName, setAddressFirstName] = useState();
    const [AddressLastName, setAddressLastName] = useState();
    const [streetAddress, setAddress] = useState();
    const [city, setCity] = useState();
    const [customerCountry, setCustomerCountry] = useState([]); // Set dropdown value of country
    const [selectedCountry, setselectedCountry] = useState([]); // set country
    const [customer_appartment, setAppartment] = useState();
    const [customer_state, setState] = useState();
    const [customer_phone_number, setCustomerPhoneNumber] = useState();
    const [zip_code, setZipCode] = useState();
    const [isChecked, setIsChecked] = useState(); // default shipping address check
    const [AllAddress, setallAddresses] = useState([]); // all addresses
    const [AllCountries, setAllCountries] = useState([]); // country dropdown
    const [validator, showValidationMessage] = useValidator();

    // handle country on change
    const handleChangeCountry = (e) => {
        setCustomerCountry(e);
        setselectedCountry(e.value);
    };
    //handle default shipping checkbox
    const showCheck = (e) => {
        const Checked = e.target.checked;
        setIsChecked(Checked);
    };

    // close modal
    const handleClose = (event) => {
        event.preventDefault();
        setShow(false);
    };

    useEffect(() => {
        getAddresses(); // get all addresses
        // get all countries
        api.get(`/get-countries`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setAllCountries(res.data.data);
            });
    }, []);

    const getAddresses = async () => {
        // setIsLoading(true)
        api.get(`/show-address?user_id=${customer.user_id}`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setallAddresses(res.data.data);
                // setIsLoading(false)
            });
    };

    const HandleEditAddress = async (
        id,
        FirstName,
        LastName,
        StreetAddress,
        Cus_City,
        Cus_Country,
        Cus_PostalCode,
        Cus_appartment,
        Cus_State,
        Cus_default,
        Cus_phone
    ) => {
        setErrorAddressMsg("");
        setShow(true);
        setAddressFirstName(FirstName);
        setAddressLastName(LastName);
        setAddress(StreetAddress);
        setCity(Cus_City);
        setselectedCountry(Cus_Country);
        setZipCode(Cus_PostalCode);
        setAppartment(Cus_appartment);
        setState(Cus_State);
        setIsChecked(Cus_default);
        setAddressID(id);
        const arrayCountry = [{ value: Cus_Country, label: Cus_Country }];
        setCustomerCountry(arrayCountry);
        setCustomerPhoneNumber(Cus_phone);
    };

    const handleAddAddress = async () => {
        setErrorAddressMsg("");
        setShow(true);
        setAddressFirstName([]);
        setAddressLastName([]);
        setAddress([]);
        setCity([]);
        setselectedCountry([]);
        setCustomerCountry([]);
        setZipCode([]);
        setAppartment([]);
        setState([]);
        setIsChecked(false);
    };

    const HandleDeleteAddress = async (id) => {
        api.get(
                `/delete-address?address_id=${id}&user_id=${customer.user_id}`,
                {
                    headers: {
                        "POS-API-KEY": "jd54elkrtlh",
                    },
                }
            )
            .then((res) => {
                setallAddresses(res.data.data);
            });
    };

    // Update Profile Info Fields
    const handleInputFirstChange = useCallback(
        (event) => {
            props.onFirstNameChange(event.target.value);
        },
        [props.onFirstNameChange]
    );
    const handleInputLastChange = useCallback(
        (event) => {
            props.onLastNameChange(event.target.value);
        },
        [props.onLastNameChange]
    );
    const handleInputEmailChange = useCallback(
        (event) => {
            props.onEmailChange(event.target.value);
        },
        [props.onEmailChange]
    );
    const handleInputPhoneChange = useCallback(
        (event) => {
            props.onPhoneChange(event.target.value);
        },
        [props.onPhoneChange]
    );
    const handleInputGenderChange = useCallback(
        (event) => {
            props.onGenderChange(event.target.value);
        },
        [props.onGenderChange]
    );

    const [errorAddressMsg, setErrorAddressMsg] = useState(); // missing form details
    // submit address info
    const submitHandler = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            await api.post(
                    `/add-address?user_id=${customer.user_id}&address_id=${address_id}`,
                    {
                        AddressFirstName,
                        AddressLastName,
                        streetAddress,
                        city,
                        // phone,
                        selectedCountry,
                        customer_state,
                        customer_phone_number,
                        customer_appartment,
                        zip_code,
                        isChecked,
                    },
                    {
                        headers: {
                            "POS-API-KEY": "jd54elkrtlh",
                        },
                    }
                )
                .then(({ data }) => {
                    if (data.status == 0) {
                        if (typeof data.message === "object") {
                            setErrorAddressMsg(
                                <ShowErrorMessage message={data.message} />
                            );
                        } else {
                            setErrorAddressMsg(data.message);
                        }
                    } else {
                        setallAddresses(data.data);
                        setShow(false);
                        toast.success(data.message);
                    }
                })
                .catch(({ data }) => {
                    toast.error("something went wrong");
                });
        } else {
            showValidationMessage(true);
        }
    };
    const customerData = JSON.parse(localStorage.getItem("userData"));
    const customerFullName =
        capitilizeFirst(customerData.first_name) +
        " " +
        capitilizeFirst(customerData.last_name);

    return (
        <>
            <Bread />
            <div id="my-account">
                <Container>
                    <Row>
                        <Col lg={4} className="mb-lg-0 mb-3">
                            <div className="profile-info">
                                <div className="d-flex per-mar justify-content-between">
                                    <h3>Personal Profile</h3>
                                    <span
                                        className="account-model ms-3"
                                        onClick={props.handleProfileShow}
                                    >
                                        Edit
                                    </span>
                                </div>
                                <span>{customerFullName}</span>
                                <span>{customer?.email}</span>
                                <span>{customerData?.phone}</span>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="d-sm-flex address-pad">
                                <div className="address">
                                    <div className="mar-b">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h3>Address Book</h3>
                                            <button
                                                className="account-model ms-3"
                                                onClick={handleAddAddress}
                                            >
                                                Add New Address
                                            </button>
                                        </div>
                                    </div>
                                    <div className="account-class-active">
                                        {AllAddress.length > 0 ? (
                                            AllAddress.map((row, key) => (
                                                <div>
                                                    <div className="mb-2 d-flex justify-content-between align-items-center">
                                                        <div className="d-flex">
                                                            <div className="">
                                                                <span className="bruce">
                                                                    {row?.first_name +
                                                                        " " +
                                                                        row?.last_name}
                                                                </span>
                                                                <span>
                                                                    {row?.shipping_address +
                                                                        " "}
                                                                </span>
                                                                <span>
                                                                    {row?.shipping_city
                                                                        ? "," +
                                                                          row?.shipping_city +
                                                                          " "
                                                                        : null}
                                                                </span>
                                                                <span>
                                                                    {row?.shipping_country
                                                                        ? "," +
                                                                          row?.shipping_country
                                                                        : null}
                                                                </span>
                                                            </div>
                                                            {row.default_address ==
                                                            1 ? (
                                                                <span className="badge bg-warning ms-2">
                                                                    Default
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className="d-flex">
                                                            <span
                                                                className="account-model ms-3"
                                                                onClick={() => {
                                                                    HandleEditAddress(
                                                                        row.id,
                                                                        row.first_name,
                                                                        row.last_name,
                                                                        row.shipping_address,
                                                                        row.shipping_city,
                                                                        row.shipping_country,
                                                                        row.shipping_postal_code,
                                                                        row.shipping_appartment,
                                                                        row.shipping_state,
                                                                        row.default_address,
                                                                        row.shipping_phone_number
                                                                    );
                                                                }}
                                                            >
                                                                Edit
                                                            </span>
                                                            <span
                                                                className="account-model ms-3"
                                                                onClick={() => {
                                                                    HandleDeleteAddress(
                                                                        row.id
                                                                    );
                                                                }}
                                                            >
                                                                Delete
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-center">
                                                No Address Found
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                className="account-radio"
                show={props.showPersonalProfileModal}
                onHide={props.handleClose}
            >
                <Modal.Header>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                {props.errorMsg && (
                    <div className="alert alert-danger alert-dismissible">
                        {props.errorMsg}
                    </div>
                )}
                <form method="post" onSubmit={props.submitProfileInfo}>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="modal-body-input">
                                <Form.Label>
                                    First Name{" "}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="name"
                                    value={props.first_name}
                                    onChange={handleInputFirstChange}
                                    placeholder="First Name"
                                />
                            </Form.Group>
                        </Row>
                        <Form.Group
                            as={Col}
                            controlId="modal-body-input"
                            className="mb-3"
                        >
                            <Form.Label>
                                Last Name <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="name"
                                value={props.last_name}
                                onChange={handleInputLastChange}
                                placeholder="Last Name"
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="modal-body-input">
                            <Form.Label>
                                Email <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="name"
                                value={props.email}
                                onChange={handleInputEmailChange}
                                placeholder="Email"
                                disabled
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 pt-3">
                            <Form.Label>
                                Gender <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    name="gender"
                                    id="men"
                                    checked={props.gender == 0}
                                    value="0"
                                    type="radio"
                                    label="Men"
                                    onChange={handleInputGenderChange}
                                />
                                <Form.Check
                                    name="gender"
                                    id="women"
                                    checked={props.gender == 1}
                                    value="1"
                                    type="radio"
                                    label="Women"
                                    onChange={handleInputGenderChange}
                                />
                            </Form.Group>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="modal-body-input"
                        >
                            <Form.Label>
                                Phone <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                placeholder="xxxxxxxxxxx"
                                value={props.phone}
                                onChange={handleInputPhoneChange}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex">
                            <button
                                class="log-btn-account me-3"
                                onClick={props.handleClose}
                            >
                                Close
                            </button>
                            <button
                                class="log-btn-account-active"
                                type="submit"
                            >
                                Save Change
                            </button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal className="account-radio" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Edit Address</Modal.Title>
                </Modal.Header>
                {errorAddressMsg && (
                    <div className="alert alert-danger alert-dismissible">
                        {errorAddressMsg}
                    </div>
                )}
                <form method="post" onSubmit={submitHandler}>
                    <Modal.Body>
                        <Row className="mb-xxl-3 mb-xl-3 mb-2">
                            <Form.Group as={Col} controlId="modal-body-input">
                                <Form.Label>
                                    First Name{" "}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="name"
                                    value={AddressFirstName}
                                    onChange={(e) => {
                                        setAddressFirstName(e.target.value);
                                    }}
                                    placeholder="First Name"
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="modal-body-input">
                                <Form.Label>
                                    Last Name{" "}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="name"
                                    value={AddressLastName}
                                    onChange={(e) => {
                                        setAddressLastName(e.target.value);
                                    }}
                                    placeholder="Last Name"
                                />
                            </Form.Group>
                        </Row>
                        <Form.Group
                            className="mb-xxl-3 mb-xl-3 mb-2"
                            controlId="modal-body-input"
                        >
                            <Form.Label>
                                Streat Address{" "}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                placeholder="1234 Main St"
                                value={streetAddress}
                                onChange={(e) => {
                                    setAddress(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Row className="mb-xxl-3 mb-xl-3 mb-2">
                            <Form.Group as={Col} controlId="modal-body-input">
                                <Form.Label>
                                    City <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder="City"
                                    value={city}
                                    onChange={(e) => {
                                        setCity(e.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="modal-body-input">
                                <Form.Label>
                                    Zip <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder="Zip"
                                    value={zip_code}
                                    onChange={(e) => {
                                        setZipCode(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Form.Group as={Col} controlId="modal-body-input">
                            <Form.Label className="mb-0">
                                Country <span className="text-danger">*</span>
                            </Form.Label>
                            <Select
                                className="border-color-account model-view"
                                defaultValue={customerCountry}
                                onChange={handleChangeCountry}
                                value={customerCountry}
                                options={AllCountries.map((item, index) => {
                                    return {
                                        value: item.name,
                                        label: item.name,
                                    };
                                })}
                            />
                        </Form.Group>
                        <Row className="mb-xxl-3 mb-xl-3 mb-2">
                            <Form.Group as={Col} controlId="modal-body-input">
                                <Form.Label>
                                    State <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    placeholder="State"
                                    value={customer_state}
                                    onChange={(e) => {
                                        setState(e.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="modal-body-input">
                                <Form.Label>Appartment</Form.Label>
                                <Form.Control
                                    placeholder="Appartment"
                                    value={customer_appartment}
                                    onChange={(e) => {
                                        setAppartment(e.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Row>
                        <Form.Group
                            className="mb-3"
                            controlId="modal-body-input"
                        >
                            <Form.Label>
                                Phone Number
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                placeholder="xxxxxxxxxxx"
                                value={customer_phone_number}
                                onChange={(e) => {
                                    setCustomerPhoneNumber(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <label className="check-cont">
                            <input
                                type="checkbox"
                                onChange={showCheck}
                                checked={isChecked == 1}
                            />
                            <span className="checkmark"></span>&nbsp;
                            <span>Use as default shipping address</span>
                        </label>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex">
                            <button
                                class="log-btn-account me-3"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                            <button
                                class="log-btn-account-active"
                                type="submit"
                            >
                                Save Change
                            </button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
export default Account;

import React, {useState, useEffect} from "react";
import BottomHeader from "./../../sections/BottomHeader/BottomHeader";
import MiddleHeader from "./../../sections/MiddleHeader/MiddleHeader";
import TopHeader from './../../sections/TopHeader/TopHeader';
// import Banner from './Banner/Banner';
import Sidebar from './Sidebar/Sidebar';
import TopFooter from "./../../sections/TopFooter/TopFooter";
import MiddleFooter from "./../../sections/MiddleFooter/Middlefooter";
import BottomFooter from './../../sections/BottomFooter/BottomFooter';
import SalonHeader from "../../../HairBeauty/BottomHeader/BottomHeader";
import SalonTopFooter from "../../../HairBeauty/TopFooter/TopFooter";
import SalonMiddleFooter from "../../../HairBeauty/MiddleFooter/Middlefooter";
import SalonBottomFooter from "../../../HairBeauty/BottomFooter/BottomFooter";
import KidMidHeader from "../../../BearKid/MiddleHeader/MiddleHeader";
import KidBotomHeader from "../../../BearKid/BottomHeader/BottomHeader";
import KidTopFooter from "../../../BearKid/TopFooter/TopFooter";
import KidMiddleFooter from "../../../BearKid/MiddleFooter/Middlefooter";
import KidBottomFooter from "../../../BearKid/BottomFooter/BottomFooter";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import api from "../../../helpers/api";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
function Category() {
    let query = useQuery();
    const [search_item, setSearchItem] = useState(query.get("term"));
    const [search_catalog, setSearchCatalog] = useState();
    useEffect(() => {
        if(search_item != null)
        {
            setSearchCatalog(search_item);
        }
    }, []);
    const [showContent, setShowContent] = useState(0);
    const NewThemeID = JSON.parse(localStorage.getItem('ThemeID'));
    const [themeId, setThemeId] = useState(NewThemeID);
    const [products, setProducts] = useState([]);
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const [wishlist, setWishlist] = useState(wishlist_items);
    const lengthWishlist = wishlist ? wishlist.length : 0;
    if(wishlist == undefined)
    {
        setWishlist([]);
    }
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setThemeId(res.data.data.store.theme_id)
            localStorage.setItem('ThemeID', res.data.data.store.theme_id);
        });
    }, []);
    useEffect(() => {
      api.get(`/get-all-products`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setProducts(res.data.data)
      });
    }, []);
      useEffect(() => {
        const TotalQty = JSON.parse(localStorage.getItem('totalQuantity'));
        setShowContent(TotalQty)
      }, []);
      useEffect(() => {
        localStorage.setItem('wishlist', JSON.stringify(wishlist));
    }, [wishlist]);
    const addToWishlist = (id) => {
        const check_wishlist = JSON.stringify(wishlist.find((item) => item.id === id));
        if (!check_wishlist) {

            const product = products.find((item) => item.id === id);

            setWishlist(current  => [...current , product]);
            toast.success("Wishlist Added Successfully");
        } else {
            let items =JSON.parse(localStorage.getItem("wishlist"));
            items = items.filter((item) => item.id !== id);
            localStorage.setItem("wishlist", JSON.stringify(items));
            if (items.length === 0) {
              localStorage.removeItem("item");
            }
            setWishlist(items);
            toast.error("Item is removed from wishlist");
        }

    };
    // Search Products
    const [data, setData] = useState("");
    const navigate = useNavigate();
    const handleChange = (e) => {
      setSearchCatalog(e.target.value)
      setData(e.target.value);
    }
    const handleSearch = async (e) => {
        e.preventDefault();
        if(data)
        {
            navigate(`/catalog?term=${data}`);
        }
    }
    return (
      <div className="wrapper">
        {/* <ToastContainer/> */}
        {themeId == 7 && <TopHeader/>}
        {themeId == 7 && <MiddleHeader showContent={showContent} lengthWishlist={lengthWishlist} handleSearch={handleSearch} handleChange={handleChange} search_catalog={search_catalog}/>}
        {themeId == 7 && <BottomHeader/>}
        {themeId == 5 && <SalonHeader showContent={showContent} lengthWishlist={lengthWishlist}/>}
        {themeId == 6 && <KidMidHeader showContent={showContent} lengthWishlist={lengthWishlist} handleSearch={handleSearch} handleChange={handleChange} search_catalog={search_catalog}/>}
        {themeId == 6 && <KidBotomHeader/>}
        {/* <Banner/> */}
        <Sidebar addToWishlist={addToWishlist} wishlist={wishlist} searchName={data} new={data} search_catalog={search_catalog}/>
        {themeId == 7 && <TopFooter/>}
        {themeId == 7 && <MiddleFooter/>}
        {themeId == 7 && <BottomFooter/>}
        {themeId == 5 && <SalonTopFooter/>}
        {themeId == 5 && <SalonMiddleFooter/>}
        {themeId == 5 && <SalonBottomFooter/>}
        {themeId == 6 && <KidTopFooter/>}
        {themeId == 6 && <KidMiddleFooter/>}
        {themeId == 6 && <KidBottomFooter/>}
      </div>
    );
}
export default Category;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderProducts from '../SliderComponents/SliderProducts';
import NonSliderProducts from '../SliderComponents/NonSliderProducts';
const Relatedproduct = (props) => {
    const relatedProducts = props.relatedProducts;
    const wishlist = props.wishlist;
    const addToWishlist = props.addToWishlist;
    return (
        <div id="shop">
            <Container className="custome-container">
                <div className="shop-pro">
                    <Row>
                        <Col>
                            <div>
                                <h2>Related Products</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {relatedProducts.length > 0 ?
                                relatedProducts.length > 4 ?
                                <SliderProducts products = {relatedProducts} addToWishlist={addToWishlist} wishlist = {wishlist}/>
                                :
                                <NonSliderProducts products = {relatedProducts} addToWishlist={addToWishlist} wishlist = {wishlist}/>
                            : <p className="category-data d-flex justify-content-center">No Product found in this Category</p>
                            }
                        </Col>
                        
                    </Row>
                </div>
            </Container>
        </div>
    );
};
export default Relatedproduct;

import React, {useState, useEffect} from "react";
import Header from "../Header/Header";
import Middlefooter from "../Middlefooter/Middlefooter";
import Topfooter from "../Topfooter/Topfooter";
import Banner from './Banner/Banner';
import Sidebar from "./Sidebar";
import PetStoreHeader from "../../PetStore/HomePage/Header/Header";
import NewUpdates from '../../PetStore/HomePage/NewUpdates/NewUpdates';
import PetStoreFooter from '../../PetStore/HomePage/Footer/Footer';
import JewelleryHeader from '../../Jewellery/Header/Header'
import JewelleryTopFooter from '../../Jewellery/Home-Page/Subscriber/Subscriber'
import JewelleryFooter from '../../Jewellery/Footer/Footer'
import ShopHeader from "../../ShopLove/Header/Header";
import ShopBottomFooter from "../../ShopLove/BottomFooter";
import FlowerHeader from "../../FlowerShop/HomePage/Header/Header";
import FlowerFooter from "../../FlowerShop/HomePage/Footer/Footer";
import FlowerMidFooter from "../../FlowerShop/HomePage/MiddleFooter/MiddleFooter";
import CafeHeader from "../../CafeFood/Header/Header";
import CafeFooter from "../../CafeFood/BottomFooter";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../helpers/api";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Category=()=>{
    // Check Theme ID
    const NewThemeID = JSON.parse(localStorage.getItem('ThemeID'));
    const [themeId, setThemeId] = useState(NewThemeID);
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setThemeId(res.data.data.store.theme_id)
            localStorage.setItem('ThemeID', res.data.data.store.theme_id);
        });
        
    }, []);

    // Get All Products
    const [products, setProducts] = useState([]);
    useEffect(() => {
        api.get(`/get-all-products`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setProducts(res.data.data)
        });
    }, []);

    // Set or get Wishlist Items counter
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const [wishlist, setWishlist] = useState(wishlist_items);
    const lengthWishlist = wishlist ? wishlist.length : 0;
    if(wishlist == undefined)
    {
        setWishlist([]);
    }
    
    useEffect(() => {
        localStorage.setItem('wishlist', JSON.stringify(wishlist));
    }, [wishlist]);

    // Add the products to wishlist
    const addToWishlist = (id) => {
        toast.dismiss();
        const check_wishlist = JSON.stringify(wishlist.find((item) => item.id === id));
        if (!check_wishlist) {

            const product = products.find((item) => item.id === id);

            setWishlist(current  => [...current , product]);
            toast.success("Wishlist Added Successfully", {
                autoClose: 1000
            });
        } else {
            let items =JSON.parse(localStorage.getItem("wishlist"));
            items = items.filter((item) => item.id !== id);
            localStorage.setItem("wishlist", JSON.stringify(items));
            if (items.length === 0) {
              localStorage.removeItem("item");
            }
            setWishlist(items);
            toast.error("Item is removed from wishlist", {
                autoClose: 1000
            });
        }

    };
    // get Search item by param
    let query = useQuery();
    const [search_item, setSearchItem] = useState(query.get("term"));
    const [search_catalog, setSearchCatalog] = useState();
    useEffect(() => {
        if(search_item != null)
        {
            setSearchCatalog(search_item);
        }
    }, []);
    // Search Products
    const [data, setData] = useState("");
    const navigate = useNavigate();
    const handleChange = (e) => {
        setSearchCatalog(e.target.value)
        setData(e.target.value);
    }
    const handleSearch = async (e) => {
        e.preventDefault();
        if(data)
        {
            navigate(`/catalog?term=${data}`);
        }
    }
    
    return(
        <div className="wrapper">
            <ToastContainer/>
            {themeId == 1 && <FlowerHeader lengthWishlist={lengthWishlist} />}
            {themeId == 2  && <ShopHeader lengthWishlist={lengthWishlist} handleSearch={handleSearch} handleChange={handleChange} search_catalog={search_catalog}/>}
            {themeId == 3 && <Header lengthWishlist={lengthWishlist} handleSearch={handleSearch} handleChange={handleChange} search_catalog={search_catalog}/>}
            {themeId == 4 && <CafeHeader lengthWishlist={lengthWishlist} handleSearch={handleSearch} handleChange={handleChange} search_catalog={search_catalog}/>}
            {themeId == 8 && <PetStoreHeader lengthWishlist={lengthWishlist} handleSearch={handleSearch} handleChange={handleChange} search_catalog={search_catalog}/>}
            {themeId == 9 && <JewelleryHeader lengthWishlist={lengthWishlist} handleSearch={handleSearch} handleChange={handleChange} search_catalog={search_catalog}/>}
            {/* <Banner/> */}
            <Sidebar addToWishlist={addToWishlist} wishlist={wishlist} searchName={data} new={data} search_catalog={search_catalog}/>
            {themeId == 1 && <FlowerFooter/>}
            {themeId == 1 && <FlowerMidFooter/>}
            {themeId == 2 && <ShopBottomFooter />}
            {/* {themeId == 3 &&<Topfooter/>} */}
            {themeId == 3 && <Middlefooter/>}
            {themeId == 4 && <CafeFooter/>}
            {themeId == 8 && <NewUpdates/>}
            {themeId == 8 && <PetStoreFooter/>}
            {themeId == 9 && <JewelleryTopFooter />}
            {themeId == 9 && <JewelleryFooter />}
         
        </div>

    )
}
export default Category;
import React from "react";
import "./Customize-Jewl.css";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SpinnerDotted } from "spinners-react";
import { Link } from "react-router-dom";
function CustomizeJewl(props) {
  const currency = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "Rs";
  const siteSettings = props.siteSettings;
  const newArrivalProducts = props.newArrivalProducts;
  const isLoading = props.isLoading;
  const wishlist_items = JSON.parse(localStorage.getItem("wishlist"));
  return (
    <>
      <div className="section">
        <div className="jewelry-selection flex-jewl">
          <Container fluid className="custom_container">
            <div className="wrap-text jewelry-padding text-center">
              <h2 className="pb-0">New in Jewelry Selection</h2>
            </div>
            {isLoading ? (
              <div className="jewl-flex jewel-loader">
                <div className="text-center pt-4 mb-4">
                  {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                  <SpinnerDotted
                    size={50}
                    thickness={100}
                    speed={100}
                    color="#930011"
                    secondaryColor="rgba(0, 0, 0, 0.44)"
                  />
                </div>
              </div>
            ) : (
              <div className="jewl-flex">
                {newArrivalProducts.length > 0 ? (
                  // newArrivalProducts.length > 5 ?
                  // <Slider {...arrival} className="prod-slider" >
                  //   {newArrivalProducts.map((row, index) => (
                  // <div className='jewl-col'>
                  //   <Card className='jewelry-products'>
                  //     <Card.Body>
                  //     { row.display_name !== null ?
                  //           <div className="discount-tag">
                  //               <span>{row.display_name}</span>
                  //           </div>
                  //           :
                  //           ''
                  //         }
                  //         <div className="heart-icon" key={index} data-id={row.id} onClick={() => { props.addToWishlist(row.id); }}>
                  //           {
                  //                   (JSON.stringify(wishlist_items.find((item) => item.id === row.id))) ?
                  //                   <svg xmlns="http://www.w3.org/2000/svg" className="top-product-svg" width="20" height="20"
                  //                   viewBox="0 0 27 23">
                  //                   <path id="Icon_feather-heart" data-name="Icon feather-heart"
                  //                       d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                  //                       transform="translate(-0.823 -2.997)" fill="none"
                  //                       stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                  //                       stroke-width="3" />
                  //               </svg>

                  //                   : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                  //                   viewBox="0 0 27 23">
                  //                   <path id="Icon_feather-heart" data-name="Icon feather-heart"
                  //                       d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                  //                       transform="translate(-0.823 -2.997)" fill="none"
                  //                       stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                  //                       stroke-width="3" />
                  //               </svg>

                  //               }
                  //         </div>
                  //       <Link to={`/shop/${row.id}`}>
                  //       <figure className='mb-0 product-img'>
                  //         <img src={row.image} alt='Jwelery Product'></img>
                  //       </figure>
                  //       <Card.Footer>

                  //         <p>{row.product_name}</p>
                  //         {
                  //           row.discount_offer_id != null ?
                  //           <div>
                  //           <span><del>Rs{row.price}</del> | </span>
                  //           <span>Rs{row.price - row.discounted_amount}</span>
                  //           </div>
                  //           :
                  //           <span>{row.price}</span>
                  //         }

                  //       </Card.Footer>
                  //       </Link>
                  //     </Card.Body>

                  //   </Card>
                  // </div>
                  // ))}
                  // </Slider>
                  // :
                  newArrivalProducts.slice(0, 5).map((row, index) => (
                    <div className="jewl-col">
                      <Card className="jewelry-products">
                        <Card.Body>
                          {row.display_name !== null ? (
                            <div className="discount-tag">
                              <span>{row.display_name}</span>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className="heart-icon"
                            key={index}
                            data-id={row.id}
                            onClick={() => {
                              props.addToWishlist(row.id);
                            }}
                          >
                            {JSON.stringify(
                              wishlist_items.find((item) => item.id === row.id)
                            ) ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="top-product-svg"
                                width="20"
                                height="20"
                                viewBox="0 0 27 23"
                              >
                                <path
                                  id="Icon_feather-heart"
                                  data-name="Icon feather-heart"
                                  d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                  transform="translate(-0.823 -2.997)"
                                  fill="none"
                                  stroke="#3f5b6c"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="3"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 27 23"
                              >
                                <path
                                  id="Icon_feather-heart"
                                  data-name="Icon feather-heart"
                                  d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                  transform="translate(-0.823 -2.997)"
                                  fill="none"
                                  stroke="#3f5b6c"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="3"
                                />
                              </svg>
                            )}
                          </div>
                          <Link to={`/shop/${row.id}`}>
                            <figure className="mb-0 product-img">
                              <img src={row.image} alt="Jwelery Product"></img>
                            </figure>
                            <div className="color-jewellry"></div>
                          </Link>
                        </Card.Body>
                      </Card>
                      <Card.Footer>
                        <p className="mb-0">{row.product_name}</p>
                        {row.discount_offer_id != null ? (
                          <div>
                            <span>
                              <del>{currency + " " + row.price}</del> |{" "}
                            </span>
                            <span>
                              {currency +
                                " " +
                                (row.price - row.discounted_amount)}
                            </span>
                          </div>
                        ) : (
                          <span>{currency + " " + row.price}</span>
                        )}
                      </Card.Footer>
                    </div>
                  ))
                ) : (
                  <p className="category-data d-flex justify-content-center">
                    No Product found in this Category
                  </p>
                )}
              </div>
            )}
          </Container>
        </div>
        <div className="customize">
          <Container fluid className="custom_container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="wrap-img">
                  <figure className="customize-img">
                    <img
                      className="img-fluid"
                      src={siteSettings.default_image_2}
                      alt="Engagement Rings"
                    ></img>
                  </figure>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wrap-text">
                  <h2>People Loved In Jewelry</h2>
                  <p>
                    Design your engagement ring, wedding jewelry your way. Start
                    with a ring setting and then add the perfect center stone -
                    or vice versa. It’s really up to you!
                  </p>
                  <p className="mb-0">
                    Vestibulum commodo sapien non elit porttitor, vitae volutpat
                    nibh mollis. Nulla porta risus id neque tempor, in efficitur
                    justo imperdiet. Etiam a ex at ante tincidunt imperdiet.
                    Nunc congue ex vel nisl viverra, sit amet aliquet lectus
                    ullamcorper.
                  </p>
                  <div className="theme-btn">
                    <a
                      href="/#People-loved-jewellery"
                      className="black-btn btn-mt"
                    >
                      <span>Shop Now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default CustomizeJewl;

import React, {useState, useEffect} from "react";
import ArrivalWrapper from './ArrivalWrapper/ArrivalWrapper';
import Banner from './Banner/Banner';
import Features from './Features/Features';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import NewArrival from './NewArrival/NewArrival';
import NewPet from './NewPet/NewPet';
import NewServices from './NewServices/NewServices';
import NewUpdates from './NewUpdates/NewUpdates';
import ShopDeals from './ShopDeals/ShopDeals';
import { ToastContainer, toast } from 'react-toastify';
import api from "../../helpers/api";
const HomePage = () => {
    const [siteSettings, setSiteSettings] = useState([]);
    const [newArrivalProducts, setNewArrivalProducts] = useState([]);
    const [mostLovedProducts, setmostLovedProducts] = useState([]);
    const [topProducts, settopProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const [wishlist, setWishlist] = useState(wishlist_items);
    if(wishlist == undefined)
    {
        setWishlist([]);
    }
    useEffect(() => {
        api.get(`/get-site-settings`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setSiteSettings(res.data.data)
          setIsLoading(false)
      });
    }, []);
    useEffect(() => {
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
          api.get(`/get-all-products`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setAllProducts(res.data.data)
          });
          setIsLoading(true)
          api.get(`/get-top-products`, {
              headers: {
                'POS-API-KEY' : 'jd54elkrtlh'
              }
            }).then(res => {
              settopProducts(res.data.data)
              setIsLoading(false)
          });
          setIsLoading(true)
          api.get(`/get-new-products`, {
              headers: {
                'POS-API-KEY' : 'jd54elkrtlh'
              }
            }).then(res => {
              setNewArrivalProducts(res.data.data)
              setIsLoading(false)
          });
          setIsLoading(true)
          api.get(`/get-people-loved-products`, {
              headers: {
                'POS-API-KEY' : 'jd54elkrtlh'
              }
            }).then(res => {
              setmostLovedProducts(res.data.data)
              setIsLoading(false)
          });
    }, [wishlist]);
    const addToWishlist = (id) => {
        toast.dismiss();
        const check_wishlist = JSON.stringify(wishlist.find((item) =>
        item.id === id
        ));
      
        if (!check_wishlist) {
            const product = allProducts.find((item) => item.id === id);
  
            setWishlist(current  => [...current , product]);
  
            toast.success("Wishlist Added Successfully", {
              autoClose: 1000
            });
        } else {
            let items =JSON.parse(localStorage.getItem("wishlist"));
            items = items.filter((item) => item.id !== id);
            localStorage.setItem("wishlist", JSON.stringify(items));
            if (items.length === 0) {
              localStorage.removeItem("item");
            }
            setWishlist(items);
            toast.error("Item is removed from wishlist", {
              autoClose: 1000
            });
        }
    };
    return (
        <div className='wrapper'>
            <ToastContainer/>
            <Header siteSettings={siteSettings}/>
            <Banner siteSettings={siteSettings}/>
            <NewServices/>
            <NewPet topProducts= {topProducts} isLoading={isLoading} addToWishlist={addToWishlist}/>
            <ArrivalWrapper siteSettings={siteSettings}/>
            <NewArrival newArrivalProducts= {newArrivalProducts} isLoading={isLoading} addToWishlist={addToWishlist}/>
            <ShopDeals addToWishlist={addToWishlist}/>
            <Features mostLovedProducts= {mostLovedProducts} isLoading={isLoading} addToWishlist={addToWishlist}/>
            <NewUpdates/>
            <Footer/>
        </div>
    )
}

export default HomePage;

import React, { useState, useEffect } from "react";
import { Col, Row, Image } from 'react-bootstrap';
import { SpinnerCircularFixed} from 'spinners-react';
import api from "../../../helpers/api";
const NewServices = () => {
    const [Eservices, setEservices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true)
        api.get(`/get-services`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setEservices(res.data.data)
            setIsLoading(false)
        });
  
    }, []);
    return (
        <div className='new-services container-width'>
            <div className='section-heading text-center'>
                <h1 className='yellow'>Benefits</h1>
                <h3>You Get When Using Our Services.</h3>
            </div>

            {
            isLoading ?
            <Row>
                <div className="text-center pt-4 mb-4">
                    <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                </div>
            </Row>
            :
              Eservices &&
                Eservices != "" &&
            <Row>
                <Col lg="4" className='mb-lg-0 mb-4'>
                    <div className='new-services-content blue d-flex'>
                        <div className='new-services-content-img me-xxl-5 me-4'>
                            <Image src={Eservices[0].image} fluid/>
                        </div>
                        <div className='new-services-content-info'>
                            <h5 className='mb-xxl-4 mb-3'>{Eservices[0].title}</h5>
                            <p className='mb-0'>{Eservices[0].description}</p>
                        </div>
                    </div>
                </Col>
                <Col lg="4" className='mb-lg-0 mb-4'>
                    <div className='new-services-content brown d-flex'>
                        <div className='new-services-content-img me-xxl-5 me-4'>
                            <Image src={Eservices[1].image} fluid/>
                        </div>
                        <div className='new-services-content-info'>
                            <h5 className='mb-xxl-4 mb-3'>{Eservices[1].title}</h5>
                            <p className='mb-0'>{Eservices[1].description}</p>
                        </div>
                    </div>
                </Col>
                <Col lg="4" className='mb-lg-0 mb-4'>
                    <div className='new-services-content yellow d-flex'>
                        <div className='new-services-content-img me-xxl-5 me-4'>
                            <Image src={Eservices[2].image} fluid/>
                        </div>
                        <div className='new-services-content-info'>
                            <h5 className='mb-xxl-4 mb-3'>{Eservices[2].title}</h5>
                            <p className='mb-0'>{Eservices[2].description}</p>
                        </div>
                    </div>
                </Col>
            </Row>
            }
        </div>
    )
}

export default NewServices;

import React, { useState, useEffect, useRef } from "react";
import MiddleHeader from "../MiddleHeader/MiddleHeader";
import BottomHeader from "../BottomHeader/BottomHeader";
import Homebanner from "./Homebanner/Homebanner";
import Categories from "./Categories/Categories";
import Topproduct from "./Topproduct/Topproduct";
import Peopleloved from "./Peopleloved/Peopleloved";
import NewArrival from "./NewArrival/NewArrival";
import TopFooter from "../TopFooter/TopFooter";
import MiddleFooter from "../MiddleFooter/Middlefooter";
import BottomFooter from "../BottomFooter/BottomFooter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../helpers/api";
const Home = () => {
    const [productTypeName, setProductTypeName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    // Set Wishlist Counter
    const wishlist_items = JSON.parse(localStorage.getItem("wishlist"));
    const [wishlist, setWishlist] = useState(wishlist_items);
    if (wishlist == undefined) {
        setWishlist([]);
    }
    // Set Home Page Products
    const [topProducts, settopProducts] = useState([]);
    const [newArrivalProducts, setNewArrivalProducts] = useState([]);
    const [mostLovedProducts, setmostLovedProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        localStorage.setItem("wishlist", JSON.stringify(wishlist));
        api.get(`/get-all-products`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setAllProducts(res.data.data);
            });
        setIsLoading(true);
        api.get(`/get-new-products`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setNewArrivalProducts(res.data.data);
                setIsLoading(false);
            });

        api.get(`/get-top-products`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                settopProducts(res.data.data);
                setIsLoading(false);
            });

        api.get(`/get-people-loved-products`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setmostLovedProducts(res.data.data);
                setIsLoading(false);
            });
        fetchProduct();
    }, [wishlist]);

    // Fetch All Categories
    const [categories, setCategories] = useState([]);
    const [counter, setCounter] = useState(1);
    const first = categories[0]; // Get first category
    useEffect(() => {
        setIsLoading(true);
        api.get(`/get-product-type`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCategories(res.data.data);
                setIsLoading(false);
            });
    }, []);
    useEffect(() => {
        fetchProduct();
    }, [first]);
    var link = window.location.href.split("/");
    useEffect(() => {
        if (link[3] == "#new-arrival") {
            document.getElementById("new-arrival").scrollIntoView();
        } else if (link[3] == "#top-product") {
            document.getElementById("top-product").scrollIntoView();
        }
    }, []);
    const myRef = useRef(null);
    // fetch Products By Category
    const [products, setProducts] = useState([]);
    function fetchProduct(
        category_id = first?.id,
        category_name = first?.name
    ) {
        setIsLoading(true);
        var url = `/get-product-by-category/` + category_id;
        api.get(url, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                counter > 2 &&
                    myRef.current?.scrollIntoView({ behavior: "smooth" });
                setProducts(res.data.data);
                setProductTypeName(category_name);
                setIsLoading(false);
                setCounter(counter + 1);
            });
    }

    // Cart Counter
    const [showContent, setShowContent] = useState(0);
    useEffect(() => {
        const TotalQty = JSON.parse(localStorage.getItem("totalQuantity"));
        setShowContent(TotalQty);
    }, []);

    // Add Item to Wishlist
    const addToWishlist = (id) => {
        toast.dismiss();
        const check_wishlist = JSON.stringify(
            wishlist.find((item) => item.id === id)
        );

        if (!check_wishlist) {
            const product = allProducts.find((item) => item.id === id);

            setWishlist((current) => [...current, product]);

            toast.success("Wishlist Added Successfully", {
                autoClose: 1000,
            });
        } else {
            let items = JSON.parse(localStorage.getItem("wishlist"));
            items = items.filter((item) => item.id !== id);
            localStorage.setItem("wishlist", JSON.stringify(items));
            if (items.length === 0) {
                localStorage.removeItem("item");
            }
            setWishlist(items);
            toast.error("Item is removed from wishlist", {
                autoClose: 1000,
            });
        }
    };
    return (
        <div className="wrapper">
            {/* <TopHeader /> */}
            <MiddleHeader showContent={showContent} />
            <BottomHeader />
            <Homebanner />
            <Categories
                refProp={myRef}
                products={products}
                categories={categories}
                productTypeName={productTypeName}
                fetchProduct={fetchProduct}
                wishlist={wishlist}
                addToWishlist={addToWishlist}
            />
            <NewArrival
                addToWishlist={addToWishlist}
                wishlist={wishlist}
                newArrivalProducts={newArrivalProducts}
                isLoading={isLoading}
            />
            <Topproduct
                addToWishlist={addToWishlist}
                wishlist={wishlist}
                topProducts={topProducts}
                isLoading={isLoading}
            />
            {/* <Smartadd /> */}
            {/* <Hotoffer /> */}
            <Peopleloved
                addToWishlist={addToWishlist}
                wishlist={wishlist}
                mostLovedProducts={mostLovedProducts}
                isLoading={isLoading}
            />
            <TopFooter />
            <MiddleFooter />
            <BottomFooter />
        </div>
    );
};
export default Home;

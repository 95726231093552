import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../../assets/CafeFood/images/cafe-logo.svg";
import search from "../../../assets/CafeFood/images/icons/header-search.svg";
import cart from "../../../assets/CafeFood/images/icons/header-cart.svg";
import {
    faAngleDoubleDown,
    faChevronRight,
    faTruck,
    faUser,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import heart from "../../../assets/CafeFood/images/heart-icon.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import api from "../../helpers/api";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Header = (props) => {
    let query = useQuery();
    const location = useLocation();
    const [siteSettings, setSiteSettings] = useState([]);
    const user = localStorage.getItem("isLoggedIn");
    const customer = JSON.parse(localStorage.getItem("userData"));
    const wishlist_items = JSON.parse(localStorage.getItem("wishlist"));
    const WishlistCount = wishlist_items ? wishlist_items.length : "0";
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const cart_items = JSON.parse(localStorage.getItem("cart"));
    const totalQuantity = cart_items
        ? cart_items.reduce((total, m) => total + m.quantity, 0)
        : "0";
    const navigate = useNavigate();
    const onLogoutHandler = (e) => {
        e.preventDefault();
        localStorage.removeItem("userData");
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("isLoggedIn");
        window.location.href = "/";
    };
    useEffect(() => {
        api.get(`/get-product-type`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCategories(res.data.data);
            });
    }, []);
    useEffect(() => {
        setIsLoading(true);
        api.get(`/get-site-settings`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setSiteSettings(res.data.data);
                setIsLoading(false);
            });
    }, []);
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    });
    const isSticky = (e) => {
        const header = document.querySelector(".header");
        const scrollTop = window.scrollY;
        const resolution = window.screen.width;
        scrollTop >= 20
            ? header.classList.add("is-sticky")
            : header.classList.remove("is-sticky");
    };
    const [isActive, setIsActive] = useState(false);

    const handleClick = (event) => {
        // 👇️ toggle isActive state on click
        setIsActive((current) => !current);
    };
    const [search_item, setSearchItem] = useState(query.get("term"));
    useEffect(() => {
        if (search_item != null) {
            setIsActive(true);
        }
    }, []);
    const [HomeSearch, setHomeSearch] = useState("");
    const handleChange = (e) => {
        setHomeSearch(e.target.value);
    };

    const handleSearch = async (e) => {
        e.preventDefault();

        if (HomeSearch) {
            navigate(`/catalog?term=${HomeSearch}`);
        }
    };
    var link = window.location.href.split("/");

    const [displayedCategories, setDisplayedCategories] = useState(3);

    // Load more categories when the "Load More" button is clicked
    const loadMoreCategories = () => {
        setDisplayedCategories(displayedCategories + 3);
    };
    return (
        <>
            <div className="header">
                <Navbar expand="lg">
                    <Container
                        fluid
                        className="custome-container position-relative"
                    >
                        <Navbar.Brand as={Link} to="/">
                            <div className="header-logo">
                                {isLoading ? (
                                    <img className="img-fluid" src={logo}></img>
                                ) : (
                                    <img
                                        className="img-fluid"
                                        src={siteSettings.logo}
                                    ></img>
                                )}
                            </div>
                        </Navbar.Brand>

                        <div>
                            <Navbar.Toggle aria-controls="navbarScroll" />
                            <Navbar.Collapse id="navbarScroll">
                                <Nav
                                    className="me-auto my-2 my-lg-0"
                                    navbarScroll
                                >
                                    <div className="flex-Nav w-100">
                                        <div className="nav-link-flex">
                                            <Nav.Link
                                                as={Link}
                                                to="/"
                                                className={
                                                    link[3] == "" &&
                                                    "menu-active-nav"
                                                }
                                            >
                                                Home
                                            </Nav.Link>
                                            {/* <NavDropdown title="Categories" className={query.has('type') ? "menu-active-nav header-menu-top" : "header-menu-top"}  id="basic-nav-dropdown">
                                                    {categories.map((item, index) => (
                                                        <NavDropdown.Item className={link[3] == `catalog?type=${item.name}` && "menu-active-navdrop"} href={`/catalog?type=${item.name}`}>{item.name}</NavDropdown.Item>
                                                    ))}
                                                </NavDropdown> */}

                                            <a className="item-pointer nav-link">
                                                <a className="dropdown-toggle">
                                                    Categories
                                                </a>
                                                <div
                                                    className="dropdown-menu-custom dropdown-menu-list p-0"
                                                    role="menu"
                                                    aria-labelledby="dropdownMenu"
                                                >
                                                    <ul className="dropdown-menu-custom">
                                                        {categories
                                                            ?.slice(
                                                                0,
                                                                displayedCategories
                                                            )
                                                            .map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <li className="submenu-li">
                                                                        <div className="d-flex w-100 justify-content-between align-items-center submenu-li-x">
                                                                            <a
                                                                                href={`/catalog?type=${item.name}`}
                                                                            >
                                                                                {
                                                                                    item?.name
                                                                                }
                                                                            </a>
                                                                            {item
                                                                                ?.sub_categories
                                                                                ?.length >
                                                                                0 && (
                                                                                <a className="text-black">
                                                                                    <FontAwesomeIcon
                                                                                        icon={
                                                                                            faChevronRight
                                                                                        }
                                                                                    />
                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                        {item
                                                                            ?.sub_categories
                                                                            ?.length >
                                                                            0 && (
                                                                            <div
                                                                                role="menu"
                                                                                aria-labelledby="dropdownMenu"
                                                                            >
                                                                                <ul className="p-0">
                                                                                    <li className="dropdown-submenu-custom multi-sub-menu">
                                                                                        <ul className="p-0">
                                                                                            {item?.sub_categories.map(
                                                                                                (
                                                                                                    row,
                                                                                                    index
                                                                                                ) => (
                                                                                                    <li className="px-2 py-1">
                                                                                                        <a
                                                                                                            href={`/catalog?type=${row.name}`}
                                                                                                        >
                                                                                                            {
                                                                                                                row?.name
                                                                                                            }
                                                                                                        </a>
                                                                                                    </li>
                                                                                                )
                                                                                            )}
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        )}
                                                                    </li>
                                                                )
                                                            )}
                                                        {displayedCategories <
                                                            categories?.length && (
                                                            <li className="submenu-li">
                                                                <a
                                                                style={{cursor:"pointer"}}
                                                                    className="d-flex justify-content-center pt-2"
                                                                    onClick={
                                                                        loadMoreCategories
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className="text-black"
                                                                        icon={
                                                                            faAngleDoubleDown
                                                                        }
                                                                    />
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </a>

                                            <Nav.Link
                                                href="/catalog"
                                                className={
                                                    link[3] == "catalog" &&
                                                    "menu-active-nav"
                                                }
                                            >
                                                Catalog
                                            </Nav.Link>
                                            <NavDropdown
                                                title="More"
                                                className={
                                                    link[3] == "cms" ||
                                                    link[3] == "faq"
                                                        ? "menu-active-nav header-dropdown"
                                                        : "header-dropdown"
                                                }
                                                id="navbarScrollingDropdown"
                                            >
                                                <NavDropdown.Item
                                                    as={Link}
                                                    to="/cms/about-us"
                                                    className={
                                                        link[4] == "about-us" &&
                                                        "menu-active-navdrop "
                                                    }
                                                >
                                                    About Us
                                                </NavDropdown.Item>
                                                <NavDropdown.Item
                                                    as={Link}
                                                    to="/faq"
                                                    className={
                                                        link[3] == "faq" &&
                                                        "menu-active-navdrop "
                                                    }
                                                >
                                                    Faqs
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                        </div>
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                        <div className="header-wishlist d-flex ">
                            <div className="Nav-Btns">
                                <div className="nav-btn me-3">
                                    <div className="header-search">
                                        {isActive == false && (
                                            <img
                                                className="img-fluid"
                                                src={search}
                                                onClick={handleClick}
                                            />
                                        )}
                                        <div className="header-cross">
                                            {isActive == true && (
                                                <FontAwesomeIcon
                                                    className="cross-header"
                                                    icon={faXmark}
                                                    onClick={handleClick}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <Link
                                    as={Link}
                                    to="/track-order"
                                    className="nav-btn me-3"
                                >
                                    <div className="header-truck">
                                        <FontAwesomeIcon icon={faTruck} />
                                    </div>
                                </Link>
                                <Link
                                    as={Link}
                                    to="/cart"
                                    className="cart nav-btn me-3"
                                >
                                    <div className="header-cart">
                                        <img
                                            className="img-fluid"
                                            src={cart}
                                            alt="Add to cart"
                                        ></img>
                                    </div>
                                    <div className="cart-counter">
                                        <span className="d-flex align-items-center justify-content-center">
                                            {totalQuantity}
                                        </span>
                                    </div>
                                </Link>
                                <div className="nav-btn me-1 position-relative">
                                    <Link
                                        className="header-heart me-0"
                                        to="/wishlist"
                                    >
                                        <div className="header-heart">
                                            <img src={heart} alt="" />
                                        </div>
                                    </Link>
                                    <div className="cart-counter">
                                        <span>
                                            {props.lengthWishlist
                                                ? props.lengthWishlist
                                                : WishlistCount}
                                        </span>
                                    </div>
                                </div>
                                {!user && (
                                    <Link
                                        to="/login"
                                        className="nav-btn login-top  ms-3"
                                    >
                                        <div className="user-login">
                                            <FontAwesomeIcon
                                                className="header-user"
                                                icon={faUser}
                                            />
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                        {!user && (
                            <div className="header-grp-btn">
                                <Link to="/login" className="orange-formBtn">
                                    Sign In
                                </Link>
                                <Link to="/signup" className="purple-formBtn">
                                    Sign Up
                                </Link>
                            </div>
                        )}
                        {user && (
                            <div className="user-profile  ms-3">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant="none"
                                        className="header-btn-color"
                                        id="dropdown-basic-top-change"
                                    >
                                        <span className="text-flow">
                                            {customer.first_name
                                                .charAt(0)
                                                .toUpperCase() +
                                                customer.first_name.slice(1)}
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="header-down">
                                        <Dropdown.Item href="/myaccount">
                                            My Account
                                        </Dropdown.Item>
                                        <Dropdown.Item href="/myorder">
                                            My Orders
                                        </Dropdown.Item>
                                        <Dropdown.Item href="/wishlist">
                                            My Wishlist
                                        </Dropdown.Item>
                                        <NavDropdown.Item
                                            href="#"
                                            onClick={onLogoutHandler}
                                        >
                                            Logout
                                        </NavDropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )}
                    </Container>
                </Navbar>
                <div className="custome-container">
                    {isActive == true && (
                        <form
                            className="header-form d-flex align-items-center position-relative"
                            onSubmit={handleSearch}
                        >
                            <input
                                className={isActive ? "w100" : ""}
                                type="text"
                                placeholder="Search"
                                onChange={
                                    location.pathname == "/catalog" ||
                                    location.pathname == "/Search"
                                        ? props.handleChange
                                        : handleChange
                                }
                                value={props.search_catalog}
                            />
                            {location.pathname != "/catalog" && (
                                <button
                                    className="header-search-button"
                                    type="submit"
                                >
                                    <img src={search} />
                                </button>
                            )}
                        </form>
                    )}
                </div>
            </div>
        </>
    );
};
export default Header;

import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { SpinnerCircularFixed } from "spinners-react";
import api from "../../../helpers/api";
function Detail() {
  const { id } = useParams();
  const currency = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "Rs";
  const [OrderProducts, setOrderProducts] = useState([]);
  const [salesProduct, setSales] = useState([]);
  const [paymentMethod, setMethod] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const sub_total = OrderProducts.reduce(
    (prev, cur) =>
      (cur.vairant_id != 0
        ? cur.price_per_unit - cur.product_variants[0].discounted_amount
        : cur.discounted_amount
        ? cur.price_per_unit - cur.discounted_amount
        : cur.price_per_unit) *
        cur.qty +
      prev,
    0
  );
  const tax_rate = OrderProducts.reduce(
    (prev, cur) =>
      (cur.vairant_id != 0
        ? (cur.tax_rate / 100) *
          ((cur.price_per_unit - cur.product_variants[0].discounted_amount) *
            cur.qty)
        : cur.discounted_amount
        ? (cur.tax_rate / 100) *
          ((cur.price_per_unit - cur.discounted_amount) * cur.qty)
        : (cur.tax_rate / 100) * (cur.price_per_unit * cur.qty)) + prev,
    0
  );
  useEffect(() => {
    fetchOrderProducts();
  }, []);

  const fetchOrderProducts = async () => {
    const customer = JSON.parse(localStorage.getItem("userData"));
    setIsLoading(true);
    api
      .get(`/get-order-products/${id}`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setOrderProducts(res.data.data);
        setSales(res.data.sale_data);
        setData(res.data.meta);
        setMethod(res.data.payment_data);
        setIsLoading(false);
      });
  };
  return (
    <>
      <div id="order-detail">
        <div className="container">
          {isLoading ? (
            <Row>
              <div className="text-center pt-4 mb-4">
                <SpinnerCircularFixed
                  size={50}
                  thickness={100}
                  speed={100}
                  color="#1239A5"
                  secondaryColor="rgba(0, 0, 0, 0.44)"
                />
              </div>
            </Row>
          ) : (
            <div className="row">
              <div className="col-lg-6">
                <div>
                  <h3>Order Info</h3>
                  <div className="order-info">
                    <div className="item  d-flex justify-content-between w-100 align-items-center ">
                      <label for="#">Name</label>
                      <span>
                        {salesProduct.customer_first_name +
                          " " +
                          salesProduct.customer_last_name}
                      </span>
                    </div>
                    <div className="item  d-flex justify-content-between w-100 align-items-center ">
                      <label for="#">Ordered Date</label>
                      <span>{salesProduct.invoice_date}</span>
                    </div>
                    <div className="item  d-flex justify-content-between w-100 align-items-center ">
                      <label for="#">Status</label>
                      {salesProduct.payment_status == 2 &&
                      salesProduct.fullfillment_status == 12 ? (
                        <span className="pending"> Pending</span>
                      ) : (
                        <span className="complete">Completed</span>
                      )}
                    </div>
                    <div className="item  d-flex justify-content-between w-100 align-items-center ">
                      <label for="#">Email</label>
                      <span>{salesProduct.customer_email}</span>
                    </div>
                    <div className="item  d-flex justify-content-between w-100 align-items-center ">
                      <label className="mb-0" for="#">
                        Phone Number
                      </label>
                      <span className="mb-0">
                        {salesProduct.customer_phone_no}
                      </span>
                    </div>
                  </div>
                  <div className="order-info1 ">
                    <div className="item d-flex justify-content-between w-100 align-items-center ">
                      <label className="mb-0" for="#">
                        Address
                      </label>
                      <span className="mb-0">
                        {salesProduct.customer_address}
                      </span>
                    </div>
                    <div className="item  d-flex justify-content-between w-100 align-items-center ">
                      <label for="#">Postcode</label>
                      <span>{salesProduct.customer_postal_code}</span>
                    </div>
                    <div className="item d-flex justify-content-between w-100 align-items-center ">
                      <label for="#">City</label>
                      <span>{salesProduct.customer_city}</span>
                    </div>
                    <div className="item d-flex justify-content-between w-100 align-items-center ">
                      <label for="#">Country</label>
                      <span>{salesProduct.customer_country}</span>
                    </div>
                    <div className="item d-flex justify-content-between w-100 align-items-center ">
                      <label className="mb-0" for="#">
                        Payment
                      </label>
                      <span className="mb-0">
                        {paymentMethod.payment_method_id == 7 ||
                        paymentMethod.payment_method_id == 8
                          ? paymentMethod.payment_method_id == 7
                            ? "Payment With Paypal"
                            : "Payment With Stripe"
                          : "Cash On Delivery"}
                      </span>
                    </div>

                    <div className="item d-flex justify-content-between w-100 align-items-center ">
                      <label className="mb-0" for="#">
                        Invoice ID
                      </label>
                      <span className="mb-0">{salesProduct.invoice_id}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h3>Order Detail</h3>
                {OrderProducts.length > 0 ? (
                  OrderProducts.map((row, key) => (
                    <div className="order-detail d-flex justify-content-between">
                      <Link to={`/shop/${row.product_id}`}>
                        <div className="img-cont">
                          <img
                            className="w-100 h-100 "
                            src={
                              row.product_category == 0
                                ? row.image
                                : row.product_variants[0].variant_image
                            }
                          />
                        </div>
                      </Link>
                      <div className="mar-start">
                        <span className="product">Products</span>
                        <span className="prod-detail">
                          <Link to={`/shop/${row.product_id}`}>
                            {row.product_name}
                          </Link>
                        </span>
                      </div>
                      <div className="mar-start">
                        <span className="product">Qty.</span>
                        <span className="prod-detail">{row.qty}</span>
                      </div>
                      <div className="mar-start price">
                        <span className="product">Price</span>
                        <span className="prod-detail">
                          {row.vairant_id != 0 ? (
                            <div>
                              {row.product_variants[0].display_name ? (
                                <div className="d-flex justify-contnet-between align-items-center position-relative tag-wrap justify-content-center">
                                  <FontAwesomeIcon
                                    className="payment-icon mb-3 me-2"
                                    icon={faTag}
                                  />

                                  <span className="price-details-disc">
                                    <span className="payment-off-sale me-2">
                                      {row.product_variants[0].display_name}
                                    </span>
                                    <span className="ms-2">
                                      {" "}
                                      {currency + " " + row.price_per_unit}
                                    </span>
                                  </span>
                                </div>
                              ) : (
                                <span className="ms-2">
                                  {" "}
                                  {currency + " " + row.price_per_unit}
                                </span>
                              )}
                            </div>
                          ) : row.display_name ? (
                            <div>
                              <div className="d-flex justify-contnet-between align-items-center position-relative tag-wrap justify-content-center">
                                <FontAwesomeIcon
                                  className="payment-icon mb-3 me-2"
                                  icon={faTag}
                                />
                                <span className="price-details-disc">
                                  <span className="payment-off-sale me-2">
                                    {row.display_name}
                                  </span>
                                  <span className="ms-2">
                                    {" "}
                                    {currency + " " + row.price_per_unit}
                                  </span>
                                </span>
                              </div>
                            </div>
                          ) : (
                            currency + " " + row.price_per_unit
                          )}
                        </span>
                      </div>
                      <div className="mar-start">
                        <span className="product">Total</span>
                        <span className="prod-detail">
                          {row.vairant_id != 0
                            ? currency +
                              " " +
                              parseFloat(
                                (row.price_per_unit -
                                  row.product_variants[0].discounted_amount) *
                                  row.qty
                              ).toFixed(2)
                            : row.discounted_amount
                            ? currency +
                              " " +
                              parseFloat(
                                (row.price_per_unit - row.discounted_amount) *
                                  row.qty
                              ).toFixed(2)
                            : currency +
                              " " +
                              parseFloat(row.price_per_unit * row.qty).toFixed(
                                2
                              )}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No Product Found</p>
                )}

                <div className="amount">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <label className="mb-2" for="#">
                      Amount
                    </label>
                    <span className="d-flex fw-bold mb-2">
                      {currency + " " + sub_total.toFixed(2)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <label className="mb-2" for="#">
                      GST Tax
                    </label>
                    <span className="d-flex fw-bold mb-2">
                      {currency + " " + tax_rate.toFixed(2)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <label className="mb-2" for="#">
                      Delivery
                    </label>
                    <span className="d-flex fw-bold mb-2">
                      {currency + " " + 80}
                    </span>
                  </div>
                </div>
                <button className="pay-btn">
                  Payment Summary{" "}
                  <span>
                    {currency +
                      " " +
                      parseFloat(sub_total + tax_rate + 80).toFixed(2)}
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default Detail;

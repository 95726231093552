import React, { useState, useEffect } from "react";
import { Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import useValidator from '../../../hooks/useValidator';
import api from "../../../helpers/api";
function ShowErrorMessage(props) {

    const messages = props.message;
    const listItems = Object.keys(messages).map((key) => {

      return (<li>{props.message[key]}</li>)
    })
    return (

      <ul>{listItems}</ul>
    );

}
const Footer = () => {
    const [email, setEmail] = useState();
    const [validator, showValidationMessage] = useValidator()
    const [errorMsg, setErrorMsg] = useState();
    const [successMsg, setSuccessMsg] = useState();
    useEffect(() => {
        setTimeout(() => {
            // After 3 seconds set the show value to false
            setErrorMsg("");
            setSuccessMsg("")
        }, 3000)
    }, [errorMsg,successMsg])
    const submitHandler = async (e) => {
        e.preventDefault();
        if (validator.allValid())
        {
            await api.post(`/subscribe`, {
                email
            }, {
                headers: {
                  'POS-API-KEY' : 'jd54elkrtlh'
                }
              }).then(res => {

                if (res.data.status == 0) {
                    if(typeof res.data.message === 'object')
                    {
                        setSuccessMsg("")
                        setErrorMsg(<ShowErrorMessage message={res.data.message} />)
                    }
                    else
                    {
                        setSuccessMsg("")
                        setErrorMsg(res.data.message);
                    }
                    // toast.info(res.data.message);
    
                }
                else {
                    setErrorMsg("");
                    setEmail("");
                    setSuccessMsg(res.data.message);
                    // toast.success(res.data.message);
                }
            }).catch((res) => {
    
                  toast.error("Something Went Wrong");
            })
        }
        else
        {
            showValidationMessage(true);
        }
    }
    return (
        <div className='footer'>
            <div className='container-width'>
                <div className='footer-content text-center'>
                    <h2>Subscribe To Daily Newsletter</h2>
                    <h5>Where flowers are our inspiration to create lasting memories.</h5>
                </div>
                {errorMsg && <div className="alert alert-danger alert-dismissible footer">
                {errorMsg}</div>}
                {successMsg && <div className="alert alert-success footer">
                {successMsg}</div>}
                <Form className='footer-form' method="post" onSubmit={submitHandler}>
                    <Form.Group className="input-wrapper" controlId="exampleForm.ControlInput1">
                        <Form.Control type="email" placeholder='Your Email…' value={email} onChange={(e) => { setEmail(e.target.value) }}/>
                        <Button type="submit">SUBSCRIBE</Button>
                    </Form.Group>
                </Form>
            </div>
        </div>
    )
}

export default Footer;

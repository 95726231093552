import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
const SliderProducts = (props) => {
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : "Rs";
    const arrival = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <FontAwesomeIcon icon={faArrowLeft} className="arrow-left"/>,
        nextArrow:  <FontAwesomeIcon icon={faArrowRight} className="arrow-right"/>,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    return (
        <Slider {...arrival} className="prod-slider" >
            {props.products.map((row, index) => (
                
                    <div className="slider-count">
                        <Card className='jewelry-products check-out-img mb-3'>
                            <Card.Body>
                                <Link className="product-text" to={`/shop/${row.id}`}>
                                <div className='card-img'>
                                    <figure className='mb-0'>
                                    <img className='img-fluid' src={row.image} alt='Jwelery Product'></img>
                                    </figure>
                                </div>
                                </Link>
                                { row.display_name !== null ?
                                    <div className="discount-tag">
                                        <span>{row.display_name}</span>
                                    </div>
                                    :
                                    ''
                                }
                                <div className="heart-icon" key={index} data-id={row.id} onClick={() => { props.addToWishlist(row.id); }}>
                                {
                                        (JSON.stringify(wishlist_items.find((item) => item.id === row.id))) ?
                                        <svg xmlns="http://www.w3.org/2000/svg" className="top-product-svg" width="20" height="20"
                                        viewBox="0 0 27 23">
                                        <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                            d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                            transform="translate(-0.823 -2.997)" fill="none"
                                            stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="3" />
                                    </svg>

                                        : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                        viewBox="0 0 27 23">
                                        <path id="Icon_feather-heart" data-name="Icon feather-heart"
                                            d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                            transform="translate(-0.823 -2.997)" fill="none"
                                            stroke="#3f5b6c" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="3" />
                                    </svg>

                                    }
                                </div>
                                <Link className="product-text" to={`/shop/${row.id}`}>
                               
                                </Link>
                            </Card.Body>
                        </Card>
                        <Card.Footer className='text-center'>
                                <p>{row.product_name}</p>
                                {
                                row.discount_offer_id != null ?
                                <div>
                                <span><del>{currency +" "+row.price}</del> | </span>
                                <span>{currency +" "+ (row.price - row.discounted_amount)}</span>
                                </div>
                                :
                                <span>{currency +" "+row.price}</span>
                                }
                             </Card.Footer>
                    </div>
            ))}
        </Slider>
    )
}
export default SliderProducts;

import React, { useState, useEffect } from "react";
import { Col, Row, Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { SpinnerCircularFixed} from 'spinners-react';
import api from "../../../helpers/api";
const NewPet = (props) => {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        api.get(`/get-product-type`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {

            setCategories(res.data.data)
            setIsLoading(false)
        });
    }, []);
  return (
    
    <div className='new-pets'>
      <div className='section-heading text-center'>
            <h3>Anything For Your New Pet</h3>
        </div>
        <div className='container-width'>
            {isLoading ? 
              <Row>
                  <div className="text-center pt-4 mb-4">
                      {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                      <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                  </div>
              </Row>
            :
            <Row className='mb-lg-5 mb-0'>
            {
                categories.slice(0,4).map((item, index) => (
                <Col xl='3' lg="4" md='6' className='mb-lg-0 mb-md-4 mb-5'>
                  <a className="pet-text" href={`/catalog?type=${item.name}`}>
                    <div className='new-pets-content text-center'>
                        <div className='new-pets-content-img '>
                            <Image src={item.image} fluid/>
                        </div>
                        
                    </div>
                    <h5 className="text-center">{item.name}</h5>
                  </a>
                </Col>
                ))
              }
            </Row>
            }
            <div className='pets-btn text-center'>
                {/* <Button>Explore By Categories</Button> */}
                <Link to ="/catalog">Explore By Categories</Link>
            </div>
        </div>
      {/* <div className='section-heading text-center'>
            <h3>Top Products</h3>
        </div> */}
        {/* <div className='container-width'>
            {isLoading ? 
              <Row>
                  <div className="text-center pt-4 mb-4">
                      <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                  </div>
              </Row>
            :
            <Row className='mb-lg-5 mb-4'>
                {topProducts.length > 0 ?
                        topProducts.length > 4 ?
                        <SliderProducts products = {topProducts} addToWishlist={addToWishlist} wishlist={wishlist}/>
                        :
                        <NonSliderProducts products = {topProducts} addToWishlist={addToWishlist} wishlist={wishlist}/>
                    : <p className="category-data d-flex justify-content-center">No Product found in this Category</p>
                  }
            </Row>
            }
            <div className='pets-btn text-center'>
                <Button>Explore By Categories</Button>
            </div>
        </div> */}
    </div>
    
  )
}

export default NewPet;

import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import shop from "../../../assets/Furniture/images/shop img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Product =(props)=>{
    const wishlist = props.wishlist;
    const currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : "Rs";
    return(
        <div id="wishlist">
            <Container className="custome-container">
                <div className="table-responsive mb-4">
                    <table className="table list-table">
                        <tr className="add-cart-items">
                            <th>&nbsp;</th>
                            <th>Products</th>
                            <th>Unit Price</th>
                            <th></th>
                        </tr>
                        {
                        wishlist != "" ? (
                            wishlist && wishlist.map((row, key)=>(
                            <tr>
                                <td>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div onClick={() => props.RemoveWishlist(row.id)}>
                                            <FontAwesomeIcon className="cart-active " icon={faXmark} />
                                        </div>
                                        <div className="img-sec ms-lg-5 ms-sm-3 ms-2">
                                            <img src={row.image} alt=""/>
                                        </div>
                                    </div>
                                </td>
                                <td><span className="d-block">{row.product_name}</span></td>
                                <td className="p-color"><span className="currency-font">{currency + " "}</span>{row.price}</td>
                                <td>
                                    <Link to={"/shop/" + row.id}>
                                        <button className="view-btn">
                                            View Product
                                        </button>
                                    </Link>
                                </td>
                            </tr>
                            ))
                        )
                        :<tr>
                        <td colspan="6">
                        <p className="text-center">No Product Found</p>
                        </td>
                        </tr>
                        }
                    </table>
                </div>
            </Container>
        </div>
    )
}
export default Product;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NavDropdown, Nav, Navbar, Dropdown } from "react-bootstrap";
import trucklottie from "../../../assets/Furniture/Lottie/truck.json";
import heartlottie from "../../../assets/Furniture/Lottie/heart.json";
import Lottie from "lottie-react";
import search from "../../../assets/Furniture/images/search.png";
import logo from "../../../assets/Furniture/images/furniture-Logo.png";
import searchlottie from "../../../assets/Furniture/Lottie/search.json";
import cartlottie from "../../../assets/Furniture/Lottie/cart.json";
import { faAngleDoubleDown, faChevronRight, faUser, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import api from "../../helpers/api";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Header = (props) => {
    let query = useQuery();
    const location = useLocation();
    const [siteSettings, setSiteSettings] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const user = localStorage.getItem("isLoggedIn");
    const customer = JSON.parse(localStorage.getItem("userData"));
    const cart_items = JSON.parse(localStorage.getItem("cart"));
    const totalQuantity = cart_items
        ? cart_items.reduce((total, m) => total + m.quantity, 0)
        : "0";
    const wishlist_items = JSON.parse(localStorage.getItem("wishlist"));
    const WishlistCount = wishlist_items ? wishlist_items.length : "0";
    const navigate = useNavigate();
    const onLogoutHandler = (e) => {
        e.preventDefault();
        localStorage.removeItem("userData");
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("isLoggedIn");
        window.location.href = "/";
    };
    useEffect(() => {
        api.get(`/get-product-type`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCategories(res.data.data);
            });
        setIsLoading(true);
        api.get(`/get-site-settings`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setSiteSettings(res.data.data);
                setIsLoading(false);
            });
    }, []);
    const [sticky, setSticky] = useState("");

    // on render, set listener
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 40 ? "is-sticky" : "";
        setSticky(stickyClass);
    };

    const classes = `Header  ${sticky}`;

    const [isActive, setIsActive] = useState(false);

    const handleClick = (event) => {
        // 👇️ toggle isActive state on click
        setIsActive((current) => !current);
    };

    const [search_item, setSearchItem] = useState(query.get("term"));
    useEffect(() => {
        if (search_item != null) {
            setIsActive(true);
        }
    }, []);

    const [HomeSearch, setHomeSearch] = useState("");
    const handleChange = (e) => {
        setHomeSearch(e.target.value);
    };

    const handleSearch = async (e) => {
        e.preventDefault();

        if (HomeSearch) {
            navigate(`/catalog?term=${HomeSearch}`);
        }
    };
    var link = window.location.href.split("/");
    const [displayedCategories, setDisplayedCategories] = useState(3);

    // Load more categories when the "Load More" button is clicked
    const loadMoreCategories = () => {
        setDisplayedCategories(displayedCategories + 3);
    };
    return (
        <div className={classes}>
            <div className="custome-container position-relative">
                <Navbar className="header-color" expand="lg">
                    {isLoading ? (
                        <Link to="/" className="navbar-brand">
                            <div className="header-logo">
                                <img src={logo} />
                            </div>
                        </Link>
                    ) : (
                        <Link to="/" className="navbar-brand">
                            <div className="header-logo">
                                <img src={siteSettings.logo} />
                            </div>
                        </Link>
                    )}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="header ms-auto">
                            {/* <NavDropdown title="Our Stores" className={query.has('type') ? "menu-active-nav header-drop-icon" : "header-drop-icon"} id="basic-nav-dropdown-header-active-color">
                                <div className="header-menu-show">
                                {categories.map((item, index) => (
                                    <a className="dropdown-item"
                                    href={`/catalog?type=${item.name}`}>{item.name}</a>
                                ))}
                                </div>
                            </NavDropdown> */}
                            <a className="item-pointer me-3">
                                <a
                                    className="nav-link w-100 dropdown-toggle"
                                >
                                    Our Stores
                                </a>
                                <div
                                    className="dropdown-menu-custom dropdown-menu-list p-0"
                                    role="menu"
                                    aria-labelledby="dropdownMenu"
                                >
                                    <ul className="dropdown-menu-custom">
                                        {categories?.slice(0, displayedCategories).map((item, index) => (
                                            <li className="submenu-li">
                                                <div className="d-flex w-100 justify-content-between align-items-center submenu-li-x">
                                                    <a
                                                        href={`/catalog?type=${item.name}`}
                                                    >
                                                        {item?.name}
                                                    </a>
                                                    {item?.sub_categories
                                                        ?.length > 0 && (
                                                        <a className="text-black">
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faChevronRight
                                                                }
                                                            />
                                                        </a>
                                                    )}
                                                </div>
                                                {item?.sub_categories?.length >
                                                    0 && (
                                                    <div
                                                        role="menu"
                                                        aria-labelledby="dropdownMenu"
                                                    >
                                                        <ul className="p-0">
                                                            <li className="dropdown-submenu-custom multi-sub-menu">
                                                                <ul className="p-0">
                                                                    {item?.sub_categories.map(
                                                                        (
                                                                            row,
                                                                            index
                                                                        ) => (
                                                                            <li className="px-2 py-1">
                                                                                <a
                                                                                    href={`/catalog?type=${row.name}`}
                                                                                >
                                                                                    {
                                                                                        row?.name
                                                                                    }
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </li>
                                        ))}
                                        {displayedCategories <
                                                categories?.length && (
                                                <li className="submenu-li">
                                                    <a
                                                        style={{cursor:"pointer"}}
                                                        className="d-flex justify-content-center pt-2"
                                                        onClick={
                                                            loadMoreCategories
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            className="text-black"
                                                            icon={
                                                                faAngleDoubleDown
                                                            }
                                                        />
                                                    </a>
                                                </li>
                                            )}
                                    </ul>
                                </div>
                            </a>
                            <a
                                href="/catalog"
                                className={
                                    link[3] == "catalog"
                                        ? "menu-active-nav nav-link"
                                        : "nav-link"
                                }
                            >
                                Catalog
                            </a>
                            {/* <Link className="nav-link" to="/cms/about-us">About Us</Link>
                            <Link className="nav-link" to="/faq">FAQs</Link> */}
                            <NavDropdown
                                title="More"
                                className={
                                    link[3] == "cms" || link[3] == "faq"
                                        ? "menu-active-nav header-drop-icon"
                                        : "header-drop-icon"
                                }
                                id="basic-nav-dropdown-header-active-color"
                            >
                                <NavDropdown.Item
                                    className={
                                        link[4] == "about-us" &&
                                        "menu-active-navdrop "
                                    }
                                    href="/cms/about-us"
                                >
                                    About Us
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    className={
                                        link[3] == "faq" &&
                                        "menu-active-navdrop "
                                    }
                                    as={Link}
                                    to="/faq"
                                >
                                    Faqs
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    <div className="header-icons d-flex align-items-center">
                        {user && (
                            <div className="d-flex align-items-center">
                                <div className="user-profile">
                                    <Dropdown className="d-flex ">
                                        <Dropdown.Toggle
                                            variant="success"
                                            id="dropdown-basic-top-change"
                                        >
                                            <span className="text-flow">
                                                {customer.first_name
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    customer.first_name.slice(
                                                        1
                                                    )}
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="header-down">
                                            <Dropdown.Item href="/myaccount">
                                                My Account
                                            </Dropdown.Item>
                                            <Dropdown.Item href="/myorder">
                                                My Orders
                                            </Dropdown.Item>
                                            <Dropdown.Item href="/wishlist">
                                                My Wishlist
                                            </Dropdown.Item>
                                            <NavDropdown.Item
                                                href="#"
                                                onClick={onLogoutHandler}
                                            >
                                                Logout
                                            </NavDropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        )}
                        {!user && (
                            <Link className="nav-link-header-log" to="/login">
                                <span>Login</span>
                                <div className="header-bg-color">
                                    <FontAwesomeIcon
                                        className="user-icon"
                                        icon={faUser}
                                    />
                                </div>
                            </Link>
                        )}
                        <Link className="header-track-order" to="/track-order">
                            {/* <FontAwesomeIcon className="header-truck"  icon={faTruck} /> */}
                            <div className="header-truck">
                                {/* <img className="img-fluid" src={ani} alt="" /> */}
                                <Lottie
                                    animationData={trucklottie}
                                    loop={true}
                                />
                            </div>
                        </Link>
                        <div className="bottom-header-heart position-relative">
                            <Link className="bottom-header-img" to="/wishlist">
                                {/* <img className="img-fluid" src={heart} alt="" /> */}
                                <div className="header-heart-gif">
                                    {/* <img className="img-fluid" src={heart} alt="" /> */}
                                    <Lottie
                                        animationData={heartlottie}
                                        loop={true}
                                    />
                                </div>
                            </Link>
                            <span>
                                {props.lengthWishlist
                                    ? props.lengthWishlist
                                    : WishlistCount}
                            </span>
                        </div>
                        <div className="header-icon">
                            {isActive == false && (
                                <Lottie
                                    animationData={searchlottie}
                                    loop={true}
                                    onClick={handleClick}
                                />
                            )}
                            {isActive == true && (
                                <FontAwesomeIcon
                                    className="cross-header"
                                    icon={faXmark}
                                    onClick={handleClick}
                                />
                            )}
                        </div>

                        <Link to="/cart" className="nav-link p-lg-0 cart-icon">
                            <div className="header-icon-color">
                                <div className="cart position-relative d-inline-block">
                                    <div className="cart-img">
                                        {/* <img className="img-fluid" src={cart} alt=""/> */}
                                        <Lottie
                                            animationData={cartlottie}
                                            loop={true}
                                        />
                                    </div>
                                    <span>{totalQuantity}</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </Navbar>
                {isActive == true && (
                    <form
                        className="header-form d-flex align-items-center"
                        onSubmit={handleSearch}
                    >
                        <input
                            className={isActive ? "w100" : ""}
                            type="text"
                            placeholder="Search"
                            onChange={
                                location.pathname == "/catalog" ||
                                location.pathname == "/Search"
                                    ? props.handleChange
                                    : handleChange
                            }
                            value={props.search_catalog}
                        />
                        {location.pathname != "/catalog" && (
                            <button
                                className="header-search-button"
                                type="submit"
                            >
                                <img src={search} />
                            </button>
                        )}
                    </form>
                )}
            </div>
        </div>
    );
};
export default Header;

import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Nav,
    NavDropdown,
    Navbar,
    Dropdown,
    Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import catagories from "../../../../assets/HappyShopping/images/catagories.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleDown,
    faChevronDown,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../../helpers/api";
const BottomHeader = () => {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        api.get(`/get-product-type`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCategories(res.data.data);
            });
        const urlHash = window.location.hash;
        if (urlHash.length) {
            const element = document.getElementById(urlHash.substring(1));
            if (element) {
                element.scrollIntoView();
            }
        }
    }, []);
    var link = window.location.href.split("/");

    const [displayedCategories, setDisplayedCategories] = useState(3);

    // Load more categories when the "Load More" button is clicked
    const loadMoreCategories = () => {
        setDisplayedCategories(displayedCategories + 3);
    };
    return (
        <div id="bottom-header">
            <Navbar expand="lg p-0">
                <Container>
                    <Navbar.Brand>
                        <div className="d-flex align-items-center">
                            <div className="bottom-header-img">
                                <img src={catagories} alt="" />
                            </div>
                            <div className="position-relative">
                                <a className="item-pointer">
                                    <div className="d-flex item-pointer">
                                        <a>Categories</a>
                                        <span className="icon-chevron">
                                            <FontAwesomeIcon
                                                className="ps-1 text-white"
                                                icon={faChevronDown}
                                            />
                                        </span>
                                    </div>
                                    <div
                                        className="dropdown-menu-custom dropdown-menu-list p-0"
                                        role="menu"
                                        aria-labelledby="dropdownMenu"
                                    >
                                        <ul className="dropdown-menu-custom">
                                            {categories
                                                ?.slice(0, displayedCategories)
                                                .map((item, index) => (
                                                    <li className="submenu-li">
                                                        <div className="d-flex w-100 justify-content-between align-items-center submenu-li-x">
                                                            <a
                                                                href={`/catalog?type=${item.name}`}
                                                            >
                                                                {item?.name}
                                                            </a>
                                                            {item
                                                                ?.sub_categories
                                                                ?.length >
                                                                0 && (
                                                                <a className="text-black">
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faChevronRight
                                                                        }
                                                                    />
                                                                </a>
                                                            )}
                                                        </div>
                                                        {item?.sub_categories
                                                            ?.length > 0 && (
                                                            <div
                                                                role="menu"
                                                                aria-labelledby="dropdownMenu"
                                                            >
                                                                <ul className="p-0">
                                                                    <li className="dropdown-submenu-custom multi-sub-menu">
                                                                        <ul className="p-0">
                                                                            {item?.sub_categories.map(
                                                                                (
                                                                                    row,
                                                                                    index
                                                                                ) => (
                                                                                    <li className="px-2 py-1">
                                                                                        <a
                                                                                            href={`/catalog?type=${row.name}`}
                                                                                        >
                                                                                            {
                                                                                                row?.name
                                                                                            }
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </li>
                                                ))}
                                            {displayedCategories <
                                                categories?.length && (
                                                <li className="submenu-li">
                                                    <a
                                                    style={{cursor:"pointer"}}
                                                        className="d-flex justify-content-center pt-2"
                                                        onClick={
                                                            loadMoreCategories
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            className="text-black"
                                                            icon={
                                                                faAngleDoubleDown
                                                            }
                                                        />
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link
                                href="/"
                                className={
                                    link[3] == "" && "menu-active-navdrop"
                                }
                            >
                                Home
                            </Nav.Link>
                            <Nav.Link
                                href="/catalog"
                                className={
                                    link[3] == `catalog` &&
                                    "menu-active-navdrop"
                                }
                            >
                                Catalog
                            </Nav.Link>
                            <Nav.Link href="/#new-arrival">
                                New Products
                            </Nav.Link>
                            <Nav.Link href="/#top-product">
                                Top Products
                            </Nav.Link>
                            {/* <Nav.Link href="/#hot-offers">Hot Offers</Nav.Link> */}
                            <Nav.Link
                                href="/cms/about-us"
                                className={
                                    link[4] == `about-us` &&
                                    "menu-active-navdrop"
                                }
                            >
                                About Us
                            </Nav.Link>
                            <Nav.Link
                                href="/faq"
                                className={
                                    link[3] == `faq` && "menu-active-navdrop"
                                }
                            >
                                FAQs
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};
export default BottomHeader;

import React, { useState, useEffect } from "react";
import Banner from "../Banner";
import Header from "../Header/Header";
import TopCategories from "../TopCategories";
import Trend from "../Trend/Trend";
import OurCustomer from "./../OurCustomer";
import Delivery from "./../Delivery/Delivery";
import TopFooter from "../TopFooter";
import Reservation from "../Reservation/Reservation";
import BottomFooter from "../BottomFooter";
import { ToastContainer, toast } from "react-toastify";
import api from "../../helpers/api";
const Home = () => {
    // Get Site Settings detail
    const [siteSettings, setSiteSettings] = useState([]);
    useEffect(() => {
        api.get(`/get-site-settings`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setSiteSettings(res.data.data);
                setIsLoading(false);
            });
    }, []);

    // Get Current Theme Detail
    const NewThemeID = JSON.parse(localStorage.getItem("ThemeID"));
    const [themeId, setThemeId] = useState(NewThemeID ? NewThemeID : []);
    useEffect(() => {
        api.get(`/get-store-theme-details`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setThemeId(res.data.data.store.theme_id);
                localStorage.setItem("ThemeID", res.data.data.store.theme_id);
            });
    }, []);
    // Set Home Page Products
    const [topProducts, settopProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const wishlist_items = JSON.parse(localStorage.getItem("wishlist")); 
    const [wishlist, setWishlist] = useState(wishlist_items);
    if (wishlist == undefined) {
        setWishlist([]);
    }
    useEffect(() => {
        localStorage.setItem("wishlist", JSON.stringify(wishlist));
        api.get(`/get-all-products`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setAllProducts(res.data.data);
            });
        setIsLoading(true);
        api.get(`/get-top-products`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                settopProducts(res.data.data);
                setIsLoading(false);
            });
        setIsLoading(true);
        api.get(`/get-people-loved-products`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setIsLoading(false);
            });
    }, [wishlist]);
    // Add Item to wishlist
    const addToWishlist = (id) => {
        toast.dismiss();
        const check_wishlist = JSON.stringify(
            wishlist.find((item) => item.id === id)
        );
        if (!check_wishlist) {
            const product = allProducts.find((item) => item.id === id);

            setWishlist((current) => [...current, product]);

            toast.success("Wishlist Added Successfully", {
                autoClose: 1000,
            });
        } else {
            let items = JSON.parse(localStorage.getItem("wishlist"));
            items = items.filter((item) => item.id !== id);
            localStorage.setItem("wishlist", JSON.stringify(items));
            if (items.length === 0) {
                localStorage.removeItem("item");
            }
            setWishlist(items);
            toast.error("Item is removed from wishlist", {
                autoClose: 1000,
            });
        }
    };
    return (
        <div className="wrapper">
            <ToastContainer />
            <Header />
            <Banner siteSettings={siteSettings} />
            <Trend
                topProducts={topProducts}
                isLoading={isLoading}
                addToWishlist={addToWishlist}
            />
            <Delivery />
            {/* <OurServices topProducts= {topProducts} isLoading={isLoading} addToWishlist={addToWishlist}/> */}
            <TopCategories addToWishlist={addToWishlist} />
            <Reservation siteSettings={siteSettings} />
            {/* <OurProduct mostLovedProducts= {mostLovedProducts} isLoading={isLoading} addToWishlist={addToWishlist}/> */}
            <OurCustomer />
            <TopFooter siteSettings={siteSettings} />
            <BottomFooter />
        </div>
    );
};
export default Home;

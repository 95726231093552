import React from "react";
import { Container,Row } from "react-bootstrap";
import {Link} from "react-router-dom";

const Shipping =()=>{
    const cart_items = JSON.parse(localStorage.getItem('cart'));
    return(
        <div id="payment">
            <Container>
                <Row>
                  <div className="set-width">
                     <div className="d-lg-flex">
                           <div className="cont1">
                                <div className="nav " id="nav-tab" role="tablist">
                                    {/* <Breadcrumb>
                                      <Breadcrumb.Item  href="#">Cart</Breadcrumb.Item>
                                      <Breadcrumb.Item  href="#">Information</Breadcrumb.Item>
                                      <Breadcrumb.Item  href="#">Shipping</Breadcrumb.Item>
                                      <Breadcrumb.Item className="bread-color" href="#">Payment</Breadcrumb.Item>
                                   </Breadcrumb> */}
                                </div>
                                    <div >
                                        <div className="tab-pane fade show active" id="pills-ship" role="tabpanel" aria-labelledby="pills-ship-tab">
                                            <div className="cont1-inside">
                                                <ul>
                                                    <li className="d-flex">
                                                        <label for="#">Contact</label>
                                                        <span>Your Name (yourname@email.com)</span>
                                                        <Link className="active-change  text-end" to="/">Change</Link>
                                                    </li>
                                                    <li className="d-flex border-0">
                                                        <label for="#">Ship to</label>
                                                        <span>Your complete address with postal code</span>
                                                        <Link className="active-change text-end" to="/">Change</Link>
                                                    </li>
                                                </ul>
                                                <div className="heading">
                                                    <h2>Shipping Method</h2>
                                                </div>
                                                <div className="radio-border ">
                                                    <div className="form-check border-0">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                            id="flexRadioDefault1" />
                                                        <label className="form-check-label lab-color" for="flexRadioDefault1">
                                                            Standard
                                                        </label>
                                                    </div>
                                                </div>
                                              <Link to ="/payment-method" className="endbtn">Continue to Payment</Link>
                                             <button className="endbtn">Return to information</button>

                                                {/* <div className="nav " id="nav-tab" role="tablist">
                                                        <ul className="nav nav-pills " id="pills-tab" role="tablist">
                                                            <li className="nav-item " role="presentation">
                                                                <a className="nav-link " id="pills-ship-tab" data-bs-toggle="pill"
                                                                    data-bs-target="#pills-ship" type="button" role="tab"
                                                                    aria-controls="pills-ship" aria-selected="false"><span className="endbtn">Continue to payment</span>
                                                                </a>
                                                            </li>
                                                            <li className="nav-item  " role="presentation">
                                                                <a className="nav-link ps-3" id="pills-payment-tab" data-bs-toggle="pill"
                                                                    data-bs-target="#pills-payment" type="button" role="tab"
                                                                    aria-controls="pills-ship" aria-selected="false">
                                                                    <span className="endbtn">Return to information</span>
                                                                    </a>
                                                            </li>
                                                    </ul>
                                                </div> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cont2">
                                    <div className="cont2-inside">
                                        {
                                            cart_items != "" ? (
                                                cart_items.map((row, key)=>(
                                                    <div className="d-flex cont2-adj">
                                                        <div className="img-cont">
                                                            <img className="w-100 h-100" src={row.image} alt='' />
                                                        </div>
                                                        <span className="p-width">{row.product_name}</span>
                                                        <span className="text-end price">{row.price}</span>
                                                    </div>
                                                ))
                                            )
                                            : <p> No Product Found</p>
                                        }

                                        <div className="sect1">
                                            <ul className="list-unstyled mb-0">
                                                <li>
                                                    <label for="#">Subtotal</label>
                                                    <span className="price">{cart_items.reduce((prev, cur) => (cur.price * cur.quantity) + prev, 0)}</span>
                                                </li>
                                                <li className="mb-0">
                                                    <label for="#">Shipping</label>
                                                    <span className="color">Calculated at next step</span>
                                                </li>
                                            </ul>
                                            <ul className="list-unstyled border-0 mb-0 pb-0">
                                                <li className="mb-0 ">
                                                    <label for="#">Total</label>
                                                    <span className="price2"><span className="usd">USD</span>{cart_items.reduce((prev, cur) => (cur.price * cur.quantity) + prev, 0)}</span>
                                                </li>
                                            </ul>
                                        </div>
                                   </div>
                           </div>
                      </div>
                  </div>
                </Row>
            </Container>
    </div>
    )
}
export default Shipping;

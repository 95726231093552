import React from "react";
import { Link } from "react-router-dom"
import { Container,Row,Col } from "react-bootstrap";

const Bread =(props)=>{
    return(
        <div id="bread" className="mb-4">
             <Container className="custome-container"> 
             <Row>
                <Col>
                    <div className="top-bar">
                        <div>
                            <span>{props.breadTitle}</span>
                        </div>
                        <div aria-label="breadcrumb ">
                            <ol className="breadcrumb align-items-center mb-0">
                                <li className="breadcrumb-item"><Link to="/" className="text-decorarion">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{props.breadTitle}</li>
                            </ol>
                        </div>
                    </div>
                </Col>
                </Row>
          </Container>
               
       </div>
    )
}
export default Bread;
import React from "react";
import "./PackageExpirePage.css";
const PackageExpirePage = () => {
  return (
    <div className="package-expire-page">
      <div id="clouds">
        <div class="cloud x1"></div>
        <div class="cloud x1_5"></div>
        <div class="cloud x2"></div> 
        <div class="cloud x3"></div>
        <div class="cloud x4"></div>
        <div class="cloud x5"></div>
      </div>
      <div class="c">
        <div class="_404">404</div>
        <div class="_1">
          We are sorry, Currently you have no access your site!
        </div>
        <div class="_2">
          Your package is expired. Contact admin to extend your trial period
        </div>
      </div>
    </div>
  );
};
export default PackageExpirePage;

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import api from "../helpers/api";
const OurCustomer = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/get-testimonials`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setTestimonials(res.data.data);
        setIsLoading(false);
      });
  }, []);
  const settings = {
    centerMode: true,
    initialSlide: 1,
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
    nextArrow: <FontAwesomeIcon icon={faArrowRight} />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          centerPadding: "0%",
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: "0%",
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        }
      }
    ]
  };
  return (
    <div className="our-customer">
      {testimonials && (
        <Container fluid className="custome-container">
          <Row>
            <Col>
              <div className="our-customer-heading">
                <h2>What our customer says?</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Slider className="prod-slider" {...settings}>
                {testimonials &&
                  testimonials.map((row, key) => (
                    <div className="slider-cont">
                      <div className="our-customer-paragraph">
                        <p>{row.description}</p>
                      </div>
                      <div className="ms-2">
                        <div>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="slider-customer-img">
                              <img
                                className="img-fluid"
                                src={row.image}
                                alt=""
                              />
                            </div>
                            <div className="slider-heading">
                              <h2>{row.title}</h2>
                              <span>{row.sub_title}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};
export default OurCustomer;

import React, { useState, useEffect } from "react";
import { Container,Row,Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SpinnerRoundOutlined, SpinnerCircularFixed} from 'spinners-react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { faArrowLeft, faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import SliderProducts from './../SliderComponents/SliderProducts';
import NonSliderProducts from './../SliderComponents/NonSliderProducts';
const Topproduct =(props)=>{

    const wishlist = props.wishlist;
    const products = props.topProducts;
    const isLoading = props.isLoading;
    const addToWishlist = props.addToWishlist;
    return(
        <div id="top-product">
            <div className="banner-margin">
                <Container>
                    <div id="top-pro">
                                <Row>
                                    <Col>
                                        <div>
                                            <h2>Top Products</h2>
                                        </div>
                                    </Col>
                                </Row>
                                {isLoading ?
                                    <Row>
                                        <div className="text-center pt-4 mb-4">
                                            {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                                            <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                                        </div>
                                    </Row> :
                                    <Row>
                                        <Col>
                                        {products.length > 0 ?
                                            products.length > 3 ?
                                            <SliderProducts products = {products} addToWishlist={addToWishlist} wishlist = {wishlist}/>
                                            :
                                            <NonSliderProducts products = {products} addToWishlist={addToWishlist} wishlist = {wishlist}/>
                                        : <p className="category-data d-flex justify-content-center">No Product found in this Category</p>
                                        }
                                        </Col>
                                    </Row>
                                }
                            </div>
                </Container>
            </div>
        </div>
    )
}
export default Topproduct;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderProducts from "../SliderComponents/SliderProducts";
import NonSliderProducts from "../SliderComponents/NonSliderProducts";
import { SpinnerCircularFixed } from "spinners-react";
import banner from "../../../assets/Furniture/images/banner-img.png";
const Newarrival = (props) => {
    const siteSettings = props.siteSettings;
    const newArrivalProducts = props.newArrivalProducts;
    const addToWishlist = props.addToWishlist;
    const wishlist = props.wishlist;
    const isLoading = props.isLoading;
    return (
        <div className="newarrival">
            <div className="arrival-slider">
                <div className="new-arrival-img">
                    <img
                        src={
                            siteSettings ? siteSettings.default_image_1 : banner
                        }
                        alt=""
                    />
                </div>
            </div>
            <Container fluid className="custome-container">
                <div className="new-arrival-slider">
                    {isLoading ? (
                        <Row>
                            <div className="text-center pt-4 mb-4">
                                {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                                <SpinnerCircularFixed
                                    size={50}
                                    thickness={100}
                                    speed={100}
                                    color="#1239A5"
                                    secondaryColor="rgba(0, 0, 0, 0.44)"
                                />
                            </div>
                        </Row>
                    ) : (
                        <Row>
                            <Col className="slider-color box-shade">
                                <div className="new-arrival-heading d-flex justify-content-between align-items-center">
                                    <h2>New Arrivals</h2>
                                    {/* <span>View All</span> */}
                                </div>
                                {newArrivalProducts.length > 0 ? (
                                    newArrivalProducts.length > 4 ? (
                                        <SliderProducts
                                            products={newArrivalProducts}
                                            addToWishlist={addToWishlist}
                                            wishlist={wishlist}
                                        />
                                    ) : (
                                        <NonSliderProducts
                                            products={newArrivalProducts}
                                            addToWishlist={addToWishlist}
                                            wishlist={wishlist}
                                        />
                                    )
                                ) : (
                                    <p className="category-data d-flex justify-content-center">
                                        No Product found in this Category
                                    </p>
                                )}
                            </Col>
                        </Row>
                    )}
                </div>
            </Container>
        </div>
    );
};
export default Newarrival;

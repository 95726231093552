import React, {useState, useEffect} from "react";
import {Container, Row, Col} from 'react-bootstrap'
import { Link } from "react-router-dom"
import { SpinnerCircularFixed} from 'spinners-react';
import api from "../../helpers/api";
const Reservation = (props) => {
    const slug  = 'about-us'
    const [AboutusPages, setAboutusPages] = useState([]);
    const [content, setContent] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const siteSettings = props.siteSettings;
    useEffect(() => {
        setIsLoading(true)
        api.get(`/cms-page/${slug}`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {

            setAboutusPages(res.data)
            setContent(res.data.content.substring(0,200)+"...")
            setIsLoading(false)
        });

    }, []);
    return(
        <>
            <section>
                <div className='reservation'>
                    <Container fluid className='custome-container'>
                    {isLoading ?
                        <Row>
                            <div className="text-center pt-4 mb-4">
                                {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                                <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                            </div>
                        </Row>
                        :
                        <Row>
                            <Col md={6} className='mb-md-0 mb-3'>
                                <div className='wrap-reserve-text'>
                                    <div className='reservation-heading'>
                                        <h2>{AboutusPages.title}</h2>
                                        <p>{content}</p>
                                    </div>
                                    <div>
                                        <Link to='/cms/about-us' className='purple-btn'>About US</Link>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="reservation-img">
                                    <img src={siteSettings.default_image_1} alt='make reservation'></img>
                                </div>
                            </Col>
                        </Row>
                    }
                    </Container>
                </div>
            </section>
        </>
    )
}
export default Reservation;
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import logo from "../../../assets/Furniture/images/footer-logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useValidator from "../../hooks/useValidator";
import { Link } from "react-router-dom";
import api from "../../helpers/api";
function ShowErrorMessage(props) {
    const messages = props.message;
    const listItems = Object.keys(messages).map((key) => {
        return <li>{props.message[key]}</li>;
    });
    return <ul>{listItems}</ul>;
}
const Middlefooter = () => {
    const [email, setEmail] = useState();
    const [CmsPages, setCmsPages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [validator, showValidationMessage] = useValidator();
    const [errorMsg, setErrorMsg] = useState();
    const [successMsg, setSuccessMsg] = useState();
    const [siteSettings, setSiteSettings] = useState([]);
    useEffect(() => {
        setTimeout(() => {
            // After 3 seconds set the show value to false
            setErrorMsg("");
            setSuccessMsg("");
        }, 3000);
    }, [errorMsg, successMsg]);
    useEffect(() => {
        api.get(`/get-site-settings`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setSiteSettings(res.data.data);
                setIsLoading(false);
            });
    }, []);
    useEffect(() => {
        api.get(`/fetch_cms_pages`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCmsPages(res.data.data);
                setIsLoading(false);
            });
    }, []);
    const submitHandler = async (e) => {
        e.preventDefault();

        await api.post(
                `/subscribe`,
                {
                    email,
                },
                {
                    headers: {
                        "POS-API-KEY": "jd54elkrtlh",
                    },
                }
            )
            .then((res) => {
                if (res.data.status == 0) {
                    if (typeof res.data.message === "object") {
                        setErrorMsg(
                            <ShowErrorMessage message={res.data.message} />
                        );
                    } else {
                        setErrorMsg(res.data.message);
                    }
                    // toast.info(res.data.message);
                } else {
                    setErrorMsg("");
                    setEmail("");
                    setSuccessMsg(res.data.message);
                }
            })
            .catch((res) => {
                toast.error("Something went wrong");
            });
    };

    // Replace the site settings copyright with current year
    const originalString = siteSettings?.site_copyright;
    const currentYear = new Date().getFullYear();
    const copyright = originalString?.replace(/\d{4}/, currentYear);
    return (
        <div className="middle-footer">
            <Container fluid className="custome-container">
                <Row>
                    <Col lg={5} sm={6}>
                        <div className="sec1">
                            <strong>
                                <a href="/">
                                    <img
                                        className="img-fluid"
                                        src={logo}
                                        alt=""
                                    />
                                </a>
                            </strong>
                            {errorMsg && (
                                <div className="alert alert-danger alert-dismissible">
                                    {errorMsg}
                                </div>
                            )}
                            {successMsg && (
                                <div className="alert alert-success">
                                    {successMsg}
                                </div>
                            )}
                            <Form method="post" onSubmit={submitHandler}>
                                <Form.Group
                                    className="middle-form"
                                    controlId="formBasicEmailmiddle-form"
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <div className="midddle-footer-btn">
                                    <button type="submit">Subscribe</button>
                                </div>
                            </Form>
                            {/* <div className="footer-arrow">
                                <img src={footer} alt="" />
                            </div> */}
                        </div>
                    </Col>
                    <Col lg={4} sm={6}>
                        <div className="sec2">
                            <span>Our Service</span>
                            <ul className="list-unstyled mb-0">
                                {CmsPages != ""
                                    ? CmsPages.map((row, key) => (
                                          <li className="pt-0">
                                              <Link to={"/cms/" + row.slug}>
                                                  {row.title}
                                              </Link>
                                          </li>
                                      ))
                                    : ""}
                            </ul>
                        </div>
                    </Col>
                    <Col lg={3} sm={6}>
                        <div className="sec2">
                            <span>Catalog</span>
                            <ul className="list-unstyled mb-0">
                                <li className="pt-0">
                                    <a href="/catalog">All Brands </a>
                                </li>
                                <li className="pt-0">
                                    <a href="/catalog">All Product Types </a>
                                </li>
                                <li className="pt-0">
                                    <a href="/catalog">All Product Tags </a>
                                </li>
                                {/* <li><a href="/">Newsletter </a></li> */}
                                {/* <li className="pb-0"><a href="/">News &amp; Press Releases</a></li> */}
                            </ul>
                        </div>
                    </Col>
                    {/* <Col lg={2} sm={6}>
                        <div className="sec2">
                            <span>Helping you save</span>
                            <ul className="list-unstyled mb-0">
                                <li className="pt-0"><a href="/">Extended Warranty Retail </a></li>
                                <li><a href="/">Gift Card </a></li>
                                <li><a href="/"to ="/">Exclusive Discounts</a></li>
                                <li className="pb-0"><a href="/">Offers</a></li>
                            </ul>
                        </div>
                    </Col> */}
                </Row>
                <Row>
                    <Col>
                        <div className="bottom-footer">
                            <span className="bottom-footer-heading">
                                {copyright}
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default Middlefooter;

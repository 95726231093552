import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import api from "../../../helpers/api";
const Vocher = () => {
    const { id } = useParams()

    const [OrderProducts, setOrderProducts] = useState([])
    const [invoice_id, setInvoiceID] = useState([])
    const [invoice_date, setInvoiceDate] = useState([])
    const [data, setData] = useState([]);

    const sub_total = OrderProducts.reduce((prev, cur) => ((cur.vairant_id != 0 ? ( cur.price_per_unit - cur.product_variants[0].discounted_amount) : cur.discounted_amount ? cur.price_per_unit - cur.discounted_amount : cur.price_per_unit) * cur.qty) + prev, 0);
    const tax_rate = OrderProducts.reduce((prev, cur) => ((cur.tax_rate/2) * cur.qty) + prev, 0);

    useEffect(()=>{
        fetchOrderProducts()
    },[])

    const fetchOrderProducts = async () => {

        const customer = JSON.parse(localStorage.getItem('userData'));

        api.get(`/get-order-products/${id}`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {

            setOrderProducts(res.data.data)
            setInvoiceID(res.data.invoice_id)
            setInvoiceDate(res.data.invoice_date)
            setData(res.data.meta)
        });
    };
    return (
        <div className="vocher">
            <Container>
                <Row>
                    <Col>
                        <div className="vocher-heading">
                            <h3>Thank you for order</h3>
                        </div>
                        <div className="table-responsive mb-4">
                            <table className="table list-table">
                                <tr>
                                    <th></th>
                                    <th>Product Name</th>
                                    <th></th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Discounted Price</th>
                                    <th></th>
                                    <th>Total</th>
                                </tr>
                                {
                                OrderProducts.length > 0 ? (
                                    OrderProducts.map((row, key)=>(

                                        <tr key={key}>
                                        <td>
                                        <Link to={`/shop/${row.product_id}`}>
                                            <div className="img-sec ms-lg-5 ms-sm-3 ms-2">
                                                <img src={row.image} />
                                            </div>
                                        </Link>
                                        </td>
                                        <td><Link to={`/shop/${row.product_id}`}>{row.product_name}</Link></td>
                                        <td></td>
                                        <td>{"Quantity :" + row.qty}</td>
                                        <td className="s-color">
                                            {
                                                row.vairant_id != 0 ?
                                                <div>
                                                    { row.product_variants[0].display_name ?
                                                    <div className="d-flex justify-contnet-between align-items-center">
                                                    <FontAwesomeIcon className="payment-icon mb-3 me-2" icon={faTag} />

                                                        <span className="price-details-disc mb-3">{row.product_variants[0].display_name }<span className="me-2"> | {row.price_per_unit}</span></span>


                                                    </div>
                                                    :
                                                    <span className="me-2"> {row.price_per_unit}</span>
                                                    }
                                                </div>
                                                :
                                                row.display_name?
                                                <div>
                                                    <div className="d-flex justify-contnet-between align-items-center">
                                                    <FontAwesomeIcon className="payment-icon mb-3 me-2" icon={faTag} />
                                                        <span className="price-details-disc mb-3">{row.display_name }<span className="me-2"> | {row.price_per_unit}</span></span>
                                                    </div>
                                                </div>
                                                :
                                                row.price_per_unit
                                            }
                                        </td>
                                        <td className="s-color">

                                            {
                                             row.vairant_id != 0 ?
                                             row.product_variants[0].discounted_amount?
                                             parseFloat(row.price_per_unit - row.product_variants[0].discounted_amount).toFixed(2)
                                             : ""
                                             : parseFloat(row.discounted_amount).toFixed(2)?
                                              parseFloat(row.price_per_unit - row.discounted_amount).toFixed(2) : ""
                                            }
                                        </td>
                                        <td className="s-color">

                                        </td>
                                        <td className="s-color">
                                            {row.vairant_id != 0 ?
                                               parseFloat((row.price_per_unit - row.product_variants[0].discounted_amount) * row.qty).toFixed(2)

                                            : row.discounted_amount ?
                                            parseFloat((row.price_per_unit - row.discounted_amount) * row.qty).toFixed(2)
                                            :
                                            parseFloat(row.price_per_unit * row.qty).toFixed(2)}
                                        </td>
                                        </tr>
                                    ))
                                    ): <p>No Product Found</p>
                                }

                            </table>
                        </div>
                        <div className="vocher-price d-flex justify-content-between">
                            <div className="">
                                <h3>Order details</h3>
                                <br />
                                <span className="vocher-color mb-1 d-flex ">
                                    {"Invoice Number:"+ " " + invoice_id}
                                </span>
                                <br />
                                <span className="vocher-color mb-1 d-flex">
                                    {"Invoice Date:" + " " + invoice_date}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="vocher-total">
                                    <span className="vocher-color mb-1 me-3 d-flex">
                                        Sub total
                                    </span>
                                    <span className="vocher-color mb-1 me-3 d-flex">
                                        GST Tax
                                    </span>
                                    <span className="vocher-color mb-1 me-3 d-flex">
                                        Delivery charge
                                    </span>
                                    <span className="vocher-color mb-1 me-3 d-flex">
                                        Total:
                                    </span>
                                </div>
                                <div className="vocher-total">
                                    <span className="vocher-color mb-1 ms-2 d-flex">
                                        {sub_total}
                                    </span>
                                    <span className="vocher-color mb-1 ms-2 d-flex">
                                        {tax_rate}
                                    </span>
                                    <span className="vocher-color mb-1 ms-2 d-flex">
                                        80
                                    </span>

                                    <span className="vocher-color mb-1 me-3 d-flex">
                                        {sub_total + tax_rate + 80}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="vocher-payment-total d-flex"></div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default Vocher;

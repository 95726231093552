import React from "react";
import "./LoadingSpinner.css";
const LoadingSpinner = () => {
    return (
        // <div className="spinner-container">
        //   <div className="loading-spinner">
        //   </div>
        // </div>
        <div className="loader-overlay">
            <div className="lds-ripple"><div></div><div></div></div>
        </div>
      );
}
export default LoadingSpinner;

import React, { useState, useEffect } from "react";
import { Container,Row,Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { SpinnerCircularFixed} from 'spinners-react';
import SliderProducts from '../SliderComponents/SliderProducts';
import NonSliderProducts from '../SliderComponents/NonSliderProducts';
const Trend =(props)=>{
    const topProducts= props.topProducts;
    const addToWishlist = props.addToWishlist;
    const wishlist = props.wishlist;
    const isLoading = props.isLoading;
    
    return(
        <div className="trend">
            <Container fluid className="custome-container">
                    <Row>
                        <Col>
                            <div className="trend-heading">
                             <h2>Popular Dishes</h2>
                            </div>
                            {isLoading ? 
                            <Row>
                                <div className="text-center pt-4 mb-4">
                                    {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                                    <SpinnerCircularFixed size={50} thickness={100} speed={100} color="#1239A5" secondaryColor="rgba(0, 0, 0, 0.44)" />
                                </div>
                            </Row>
                            :
                                topProducts.length > 0 ?
                                    topProducts.length > 4 ?
                                    <SliderProducts products = {topProducts} addToWishlist={addToWishlist} wishlist={wishlist}/>
                                    :
                                    <NonSliderProducts products = {topProducts} addToWishlist={addToWishlist} wishlist={wishlist}/>
                                : <p className="category-data d-flex justify-content-center">No Product found in this Category</p>
                            }
                            
                       </Col>
                    </Row>
            </Container>
      </div>

    )
}
export default Trend;
import React, { useState, useEffect } from "react";
import { Container,Row,Col } from "react-bootstrap";
import api from "../../helpers/api";
const Banefit =()=>{
    const [Eservices, setEservices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.get(`/get-services`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setEservices(res.data.data)
            setIsLoading(false)
        });
  
    }, []);

    return(
      <div className="Banefit">
          <Container fluid className="custome-container">
            <Row>
              <Col>
              <div className="position-relative">
                  {/* <div className="border-banefit"></div> */}
                  <div className="benefit-heading">
                    <h2>Benefit you get when Using our services</h2>
                  </div>
              </div>
              </Col>
            </Row>
            {
              Eservices &&
                Eservices != "" &&
                <Row>
                    <Col md={4}>
                      <div className="best-quality d-flex ">
                        <div className="quality-icon">
                          <div className="quality-img">
                            <img src={Eservices[0].image} alt="/" />
                          </div>
                        
                        </div>
                        <div className="banefit-heading">
                          <h2>{Eservices[0].title}</h2>
                          <p className="banefit-paragraph" >{Eservices[0].description}</p>
                        </div>
                        
                      </div>
                    </Col>
                    <Col md={4}>
                    <div className="free-shipping d-flex">
                          <div className="shipping-icon">
                            <div className="quality-img">
                            <img src={Eservices[1].image} alt="/" />
                            </div>
                          
                          </div>
                          <div className="banefit-heading">
                            <h2>{Eservices[1].title}</h2>
                            <p className="banefit-paragraph">{Eservices[1].description}</p>
                          </div>
                        </div>
                    </Col>
                    <Col  md={4}>
                    <div className="Warranty d-flex ">
                      <div className="warrenty-icon">
                      <div className="quality-img">
                      <img src={Eservices[2].image} alt="/" />
                      </div>
                        
                      </div>
                        <div className="banefit-heading">
                          <h2>{Eservices[2].title}</h2>
                          <p className="banefit-paragraph">{Eservices[2].description}</p>
                        </div>
                    </div>
                    </Col>
                </Row>
            }
          </Container>
      </div>   
    )
}
export default Banefit;
import React from "react";
import './Jewelry-selection2.css'
import Container from 'react-bootstrap/Container';
import SliderProducts from '../../SliderComponents/SliderProducts';
import NonSliderProducts from '../../SliderComponents/NonSliderProducts';
import { SpinnerDotted } from 'spinners-react';
function JewelrySelection2(props) {
  const mostLovedProducts= props.mostLovedProducts;
  const addToWishlist = props.addToWishlist;
  const wishlist = props.wishlist;
  const isLoading = props.isLoading;
  return(
    <>
      <div id="People-loved-jewellery">
        <div className='section'>
          <div className='jewelry-selection jewelry-selection2'>
            <Container fluid className='custom_container'>
              {/* <div className='wrap-text text-center'>
                  <h2 className='pb-0'>People Loved in Jewelry Selection</h2>
              </div> */}
              {isLoading ? 
                <div className='row'>
                    <div className="text-center pt-4 mb-4">
                        {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                        <SpinnerDotted size={50} thickness={100} speed={100} color="#930011" secondaryColor="rgba(0, 0, 0, 0.44)" />
                    </div>
                </div>
                :
              <div className='row'>
                  {/* <div> */}
                  {mostLovedProducts.length > 0 ?
                    mostLovedProducts.length > 4 ?
                    <SliderProducts products = {mostLovedProducts} addToWishlist={addToWishlist} wishlist={wishlist}/>
                    :
                    
                    <NonSliderProducts products = {mostLovedProducts} addToWishlist={addToWishlist} wishlist={wishlist}/>
                : <p className="category-data d-flex justify-content-center">No Product found in this Category</p>
                }
                  {/* </div> */}

               
              </div>
               }
            </Container>
          </div>
        </div>
      </div>
    </>
  )
}

export default JewelrySelection2;
import React, {useState, useEffect} from "react";
import { Container,Row,Col } from "react-bootstrap";
import smart from "../../../../assets/HairBeauty/images/smart-line.png";
import perfume from "../../../../assets/HairBeauty/images/perfume.png";
import { Link } from "react-router-dom"
import api from "../../../helpers/api";
const Smartadd =()=>{
    const [siteSettings, setSiteSettings] = useState([]);
    const [Banner, setBanner] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.get(`/get-site-settings`, {
            headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
            }
        }).then(res => {
            setSiteSettings(res.data.data)
            setIsLoading(false)
        });
        api.get(`/fetch_banners`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh',
            }
          }).then(res => {
            setBanner(res.data.data[1])
            setIsLoading(false)
        });
    }, []);
   
    return(
        <div className="banner-margin">
            <Container>
                <div id="smt-ad">
                    {Banner != null &&
                    <Row>
                        <Col>
                            <div className="ad-bg">
                                <h2>{Banner != null && Banner.title}</h2>
                                <span>{Banner != null && Banner.description}</span>
                                <Link to={`/catalog?type=Beauty Products`} className="btn" >{Banner != null && Banner.button_text}</Link>
                                <div className="position-relative">
                                <div className="img-line">
                                    <img src={smart}/>
                                </div>
                                <div className="perfume-img">
                                <img src={siteSettings.default_image_1}/>
                                </div>
                                </div>
                                
                            </div>
                        </Col>
                    </Row>
                    }
                </div>
            </Container>
        </div>

    )
}
export default Smartadd;
import React, { useState, useEffect } from "react";
import {
    Container,
    Nav,
    Navbar,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import icons from "../../../assets/BearKid/images/header-icons.png";
import {
    faAngleDoubleDown,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../helpers/api";
const BottomHeader = () => {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        api.get(`/get-product-type`, {
                headers: {
                    "POS-API-KEY": "jd54elkrtlh",
                },
            })
            .then((res) => {
                setCategories(res.data.data);
            });
        const urlHash = window.location.hash;
        if (urlHash.length) {
            const element = document.getElementById(urlHash.substring(1));
            if (element) {
                element.scrollIntoView();
            }
        }
    }, []);
    var locationPath = window.location.href.split("/");

    const [displayedCategories, setDisplayedCategories] = useState(3);

    // Load more categories when the "Load More" button is clicked
    const loadMoreCategories = () => {
        setDisplayedCategories(displayedCategories + 3);
    };
    
    return (
        <div id="bottom-header">
            <div
                className={
                    locationPath[3] != "" &&
                    locationPath[3] != "#top-product" &&
                    locationPath[3] != "#new-arrival"
                        ? "bottom-header-color"
                        : ""
                }
            >
                <Navbar expand="lg p-0">
                    <Container>
                        <Navbar.Brand>
                            <div className="d-flex align-items-center">
                                <div className="bottom-header-img">
                                    <img src={icons} alt="" />
                                </div>
                                {/* <NavDropdown className="bottom-header" title="All Categories" id="basic-nav-dropdown-header">
                                    {categories.map((item, index) => (
                                    <NavDropdown.Item href={`/catalog?type=${item.name}`}>{item.name}</NavDropdown.Item>
                                    ))}
                                </NavDropdown> */}
                                <div className="position-relative">
                                    <a className="item-pointer me-2">
                                        <a
                                        style={{cursor:"pointer"}}
                                            className="nav-link dropdown-toggle"
                                        >
                                            Categories
                                        </a>
                                        <div
                                            className="dropdown-menu-custom dropdown-menu-list p-0"
                                            role="menu"
                                            aria-labelledby="dropdownMenu"
                                        >
                                            <ul className="dropdown-menu-custom">
                                                {categories
                                                    ?.slice(
                                                        0,
                                                        displayedCategories
                                                    )
                                                    .map((item, index) => (
                                                        <li className="submenu-li">
                                                            <div className="d-flex w-100 justify-content-between align-items-center submenu-li-x">
                                                                <a
                                                                    href={`/catalog?type=${item.name}`}
                                                                >
                                                                    {item?.name}
                                                                </a>
                                                                {item
                                                                    ?.sub_categories
                                                                    ?.length >
                                                                    0 && (
                                                                    <a className="text-black">
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faChevronRight
                                                                            }
                                                                        />
                                                                    </a>
                                                                )}
                                                            </div>
                                                            {item
                                                                ?.sub_categories
                                                                ?.length >
                                                                0 && (
                                                                <div
                                                                    role="menu"
                                                                    aria-labelledby="dropdownMenu"
                                                                >
                                                                    <ul className="p-0">
                                                                        <li className="dropdown-submenu-custom multi-sub-menu">
                                                                            <ul className="p-0">
                                                                                {item?.sub_categories.map(
                                                                                    (
                                                                                        row,
                                                                                        index
                                                                                    ) => (
                                                                                        <li className="px-2 py-1">
                                                                                            <a
                                                                                                href={`/catalog?type=${row.name}`}
                                                                                            >
                                                                                                {
                                                                                                    row?.name
                                                                                                }
                                                                                            </a>
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </li>
                                                    ))}
                                                {displayedCategories <
                                                    categories?.length && (
                                                    <li className="submenu-li">
                                                        <a
                                                            className="d-flex justify-content-center pt-2"
                                                            style={{cursor:"pointer"}}
                                                            onClick={
                                                                loadMoreCategories
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                className="text-black"
                                                                icon={
                                                                    faAngleDoubleDown
                                                                }
                                                            />
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/catalog">Catalog</Nav.Link>
                                <Nav.Link href="/#new-arrival">
                                    New Products
                                </Nav.Link>
                                <Nav.Link href="/#top-product">
                                    Top Products
                                </Nav.Link>
                                <Nav.Link href="/cms/about-us">
                                    About Us
                                </Nav.Link>
                                <Nav.Link as={Link} to="/faq">
                                    FAQs
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </div>
    );
};
export default BottomHeader;

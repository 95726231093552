import React, { useState, useEffect } from "react";
import { Row, Col, Image,Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom";
import api from "../../../helpers/api";
const ShopCategory = () => {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        api.get(`/get-product-type`, {
            headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
            }
        }).then(res => {
            setCategories(res.data.data)
            setIsLoading(false)
        });
    }, []);
    return (
        <div className='shop-category'>
            <div className='section-heading text-center'>
                <h3>Shop by category</h3>
            </div>
            <div className='shop-category-top'>
                <div className='container-width'>
                    <Row>
                    {
                        categories.slice(0,4).map((item, index) => (
                        <Col lg="3" md="4">
                            <Link to={`/catalog?type=${item.name}`}>
                            <div className='shop-category-content position-relative' data-aos="flip-left" data-aos-duration="1500" data-aos-easing="ease-out-cubic">
                                <div className='shop-category-content-image'>
                                    <Image src={item.image} fluid />
                                </div>
                                <Tooltip title={item.name}>
                                   <h4>{item.name}</h4>
                               </Tooltip>
                                <h4>{item.name}</h4>
                            </div>
                            </Link>
                        </Col>
                        ))
                    }
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default ShopCategory;

import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { SpinnerCircularFixed } from "spinners-react";
import Select from "react-select";
import api from "../../../../helpers/api";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Sidebar = (props) => {
  const navigate = useNavigate();
  let query = useQuery();
  const currency = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "Rs";
  let category_type = query.get("type");
  const [type_name, setTypeName] = useState(query.get("type"));
  const [brands, setBrands] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productTags, setProductTags] = useState([]);
  const [products, setProducts] = useState([]);
  const [data, setData] = useState([]);

  const [pageNumber, setPageNumber] = useState([]);

  const [brandID, setBrandID] = useState("");
  const [productTypesID, setProductTypesID] = useState("");
  const [productTagID, setProductTagsID] = useState("");
  const [myValue, setMyValue] = useState([]);
  const wishlist_items = JSON.parse(localStorage.getItem("wishlist"));

  const wishlist =
    props.wishlist == undefined ? wishlist_items : props.wishlist;

  const { links, activeLink } = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBrand, setIsLoadingBrand] = useState(false);
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);
  const [isLoadingTag, setIsLoadingTag] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const sortingOptions = [
    { value: "default", label: "Default Sorting" },
    { value: "name", label: "Name" },
    { value: "PHL", label: "Price(Higher to Lower)" },
    { value: "PLH", label: "Price(Lower to Higher)" }
  ];
  useEffect(() => {
    setIsLoadingBrand(true);
    api
      .get(`/get-brand-type`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setBrands(res.data.data);
        setIsLoadingBrand(false);
      });
    setIsLoadingCategory(true);
    api
      .get(`/get-product-type`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setProductTypes(res.data.data);
        setIsLoadingCategory(false);
      });
    setIsLoadingTag(true);
    api
      .get(`/get-product-tag`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setProductTags(res.data.data);
        setIsLoadingTag(false);
      });
  }, []);
  const catalogRef = useRef(null);
  const [counter, setCounter] = useState(1);
  const [search_item, setSearchItem] = useState(query.get("term"));
  const searchName = props.searchName;
  const search_catalog = props.search_catalog;

  useEffect(() => {
    productsByTypes();
  }, [searchName, search_catalog, search_item]);

  const category = productTypes?.filter((type) => {
    // Check if productTypesID exists in any of the subcategories of this type
    return type?.sub_categories?.some(
      (subcategory) =>
        subcategory?.id === productTypesID ||
        subcategory?.name === category_type
    );
  });

  useEffect(() => {}, [productTypesID, category_type]);
  // Search & Product By Types, Brands & Tags
  const productsByTypes = async (
    brand_id = "",
    product_type_id,
    tag_id = "",
    order_by = "",
    pageNumber = 1
  ) => {
    setSelectedOption(order_by);
    if (
      query.has("type") &&
      product_type_id != null &&
      brand_id != null &&
      tag_id != null
    ) {
      setIsLoading(true);
      navigate("");
    }
    if (searchName) {
      if (query.has("term")) {
        navigate("");
      }
      setSearchItem(searchName);
    } else if (searchName == "" && search_catalog == "") {
      if (query.has("term")) {
        navigate("");
      }
      setSearchItem(null);
    }
    setIsLoading(true);
    api
      .get(
        `/get-product-by-type?brand_id=${brand_id}&product_type_id=${
          brand_id || tag_id
            ? ""
            : type_name
            ? type_name
            : product_type_id
            ? product_type_id
            : productTypesID
        }&tag_id=${tag_id}&search_name=${
          search_item ? search_item : searchName
        }&order_by=${order_by?.value ? order_by.value : ""}&page=${pageNumber}`,
        {
          headers: {
            "POS-API-KEY": "jd54elkrtlh"
          }
        }
      )
      .then((res) => {
        catalogRef.current?.scrollIntoView({ behavior: "smooth" });
        setTypeName(null);
        setProducts(res.data.data);
        setBrandID(brand_id);
        setProductTypesID(
          product_type_id
            ? product_type_id
            : brand_id || tag_id
            ? ""
            : productTypesID
        );
        setProductTagsID(tag_id);
        setMyValue(order_by);
        setData(res.data.meta);
        setIsLoading(false);
        setCounter(counter + 1);
      });
  };

  return (
    <div id="catagory">
      <div className="catagory-cont">
        <Container>
          <Row>
            <Col md={3}>
              <div className="side-bar">
                {brands.length > 0 && (
                  <div>
                    <h2>Brand Type</h2>
                    {isLoadingBrand ? (
                      <ul className="py-2">
                        <SpinnerCircularFixed
                          size={30}
                          thickness={100}
                          speed={100}
                          color="#1239A5"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      </ul>
                    ) : (
                      <ul className="ps-0">
                        {brands.map((item, index) => (
                          <li>
                            <a
                              className={
                                item.id == brandID
                                  ? " side-barr-text active"
                                  : "side-barr-text"
                              }
                              onClick={() =>
                                productsByTypes(item.id, "", "", pageNumber)
                              }
                            >
                              {item.brand_name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
                {productTypes.length > 0 && (
                  <div>
                    <h2>Product Type</h2>
                    {isLoadingCategory ? (
                      <ul className="py-2">
                        <SpinnerCircularFixed
                          size={30}
                          thickness={100}
                          speed={100}
                          color="#1239A5"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                        {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                      </ul>
                    ) : (
                      <ul className="ps-0">
                        {productTypes.map((item, index) => (
                          <li>
                            <a
                              className={`side-barr-text ${
                                item.name === query.get("type") ||
                                item.id === productTypesID ||
                                (category && category[0]?.id === item?.id)
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                productsByTypes("", item.id, "", pageNumber)
                              }
                            >
                              {item.name}
                            </a>
                            {item?.sub_categories?.length > 0 && (
                              <ul
                                className={
                                  item.name === query.get("type") ||
                                  productTypesID === item.id ||
                                  (category && category[0]?.id === item?.id)
                                    ? "menu-open mb-0"
                                    : "menu-closed mb-0"
                                }
                              >
                                {item?.sub_categories.map((row, index) => (
                                  <li>
                                    <a
                                      className={`side-barr-text ${
                                        row.name === query.get("type") ||
                                        row.id === productTypesID
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        productsByTypes(
                                          "",
                                          row.id,
                                          "",
                                          pageNumber
                                        )
                                      }
                                    >
                                      {row?.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
                {productTags.length > 0 && (
                  <div>
                    <h2>Product Tags</h2>
                    {isLoadingTag ? (
                      <ul className="py-2">
                        <SpinnerCircularFixed
                          size={30}
                          thickness={100}
                          speed={100}
                          color="#1239A5"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                        {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                      </ul>
                    ) : (
                      <ul className="ps-0">
                        {productTags.map((item, index) => (
                          <li>
                            <a
                              className={
                                item.id == productTagID
                                  ? " side-barr-text active"
                                  : "side-barr-text"
                              }
                              onClick={() =>
                                productsByTypes("", "", item.id, pageNumber)
                              }
                            >
                              {item.tag_name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </Col>

            <Col md={9}>
              <Col>
                <div className="d-flex icon-mar">
                  <div className="ms-auto ">
                    <Select
                      className="border-color-account catalog-select"
                      defaultValue={{
                        label: "Default Sorting",
                        value: "default"
                      }}
                      onChange={(e) =>
                        productsByTypes(
                          brandID,
                          productTypesID,
                          productTagID,
                          e,
                          pageNumber
                        )
                      }
                      value={selectedOption}
                      options={sortingOptions.map((item, index) => {
                        return {
                          value: item.value,
                          label: item.label
                        };
                      })}
                    />
                  </div>
                </div>
              </Col>
              {isLoading ? (
                <Row>
                  <div className="text-center py-5">
                    <SpinnerCircularFixed
                      size={50}
                      thickness={100}
                      speed={100}
                      color="#1239A5"
                      secondaryColor="rgba(0, 0, 0, 0.44)"
                    />
                  </div>
                </Row>
              ) : (
                <div>
                  {products.length > 0 ? (
                    <Row>
                      {products.map((item, index) => (
                        <Col xxl={4} xl={4} lg={6} sm={6}>
                          <div className="slider-cont">
                            <Link to={`/shop/${item.id}`}>
                              <div className="image">
                                <img src={item.image} alt="" />
                              </div>
                            </Link>
                            <div>
                              {item.display_name !== null ? (
                                <div className="discount-tag">
                                  <span>{item.display_name}</span>
                                </div>
                              ) : (
                                ""
                              )}
                              <div
                                className="heart-icon"
                                key={index}
                                data-id={item.id}
                                onClick={() => props.addToWishlist(item.id)}
                              >
                                {JSON.stringify(
                                  wishlist.find((row) => row.id === item.id)
                                ) ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="top-product-svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 27 23"
                                  >
                                    <path
                                      id="Icon_feather-heart"
                                      data-name="Icon feather-heart"
                                      d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                      transform="translate(-0.823 -2.997)"
                                      fill="none"
                                      stroke="#3f5b6c"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="3"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 27 23"
                                  >
                                    <path
                                      id="Icon_feather-heart"
                                      data-name="Icon feather-heart"
                                      d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                                      transform="translate(-0.823 -2.997)"
                                      fill="none"
                                      stroke="#3f5b6c"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="3"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                            <Link to={`/shop/${item.id}`}>
                              <div>
                                {/* {item.product_category == 1 ?
                                                            <div className="arritical-tag">
                                                                <span>variants</span>
                                                            </div>
                                                    : ""} */}
                                <div className="side-bar-active">
                                  <h3 title={item.product_name}>
                                    {item.product_name}
                                  </h3>
                                  {item.discount_offer_id != null ? (
                                    <div className="price">
                                      <span className="d-price">
                                        <del>{currency + " " + item.price}</del>{" "}
                                        |{" "}
                                      </span>
                                      <span className="r-price">
                                        {currency +
                                          " " +
                                          (item.price - item.discounted_amount)}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="price">
                                      <span className="r-price">
                                        {currency + " " + item.price}
                                      </span>
                                    </div>
                                  )}
                                  <div className="people-loved-link">
                                    <Link
                                      to={`/shop/${item.id}`}
                                      className="top-product-cart"
                                    >
                                      ADD TO CART{" "}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <p className="text-center">No Product found</p>
                  )}
                  <Col>
                    {products.length > 0 && (
                      <Pagination
                        activePage={data?.current_page ? data?.current_page : 0}
                        itemsCountPerPage={data?.per_page ? data?.per_page : 0}
                        totalItemsCount={data?.total ? data?.total : 0}
                        onChange={(pageNumber) => {
                          productsByTypes(
                            brandID,
                            productTypesID,
                            productTagID,
                            myValue,
                            pageNumber
                          );
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First Page"
                        lastPageText="Last Page"
                      />
                    )}
                  </Col>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default Sidebar;

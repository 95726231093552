import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const Product = () => {
    const wishlist_items = JSON.parse(localStorage.getItem("wishlist"));
    const currency = localStorage.getItem("currency")
        ? localStorage.getItem("currency")
        : "Rs";
    const [wishlist, setWishlist] = useState(wishlist_items);
    const RemoveWishlist = (id) => {
        const check_index = wishlist.findIndex((item) => item.id === id);
        wishlist.splice(check_index, 1);
        setWishlist(wishlist);
        localStorage.setItem("wishlist", JSON.stringify(wishlist));
        window.location.href = "/wishlist";
    };

    return (
        <div id="wishlist">
            <Container>
                <div className="table-responsive mb-4">
                    <table className="table list-table">
                        <tr>
                            <th></th>
                            <th>Products</th>
                            <th>Unit Price</th>
                            <th className="ms-2"></th>
                        </tr>
                        {wishlist_items != "" ? (
                            wishlist_items.map((row, key) => (
                                <tr key={key}>
                                    <td>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div
                                                onClick={() =>
                                                    RemoveWishlist(row.id)
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    className="cart-active"
                                                    icon={faXmark}
                                                />
                                            </div>
                                            <div className="img-sec ms-lg-5 ms-sm-3 ms-2">
                                                <img src={row.image} alt="" />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="d-block">
                                            {row.product_name}
                                        </span>
                                    </td>

                                    <td className="p-color">
                                        {currency + " " + row.price}
                                    </td>

                                    {/* <td className="s-color">{row.available_stock && row.available_stock != 0 ? "In Stock" : "Out of Stock" }</td> */}

                                    <td>
                                        <Link to={"/shop/" + row.id}>
                                            <button className="view-btn">
                                                View Product
                                            </button>
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colspan="6">
                                    <p className="text-center">
                                        No Product Found
                                    </p>
                                </td>
                            </tr>
                        )}
                    </table>
                </div>
            </Container>
        </div>
    );
};
export default Product;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {faStar} from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom";
const SliderProducts = (props) => {
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : "Rs";
    const wishlist = props.wishlist == undefined ? wishlist_items : props.wishlist;
    function StarRating({count, value, 
        inactiveColor='#ddd',
        size=20,
        activeColor='#f00', onChange}) {
    
      // short trick 
      const stars = Array.from({length: count}, () => '🟊')
  
      return (
        <div>
          {stars.map((s, index) => {
            let style = inactiveColor;
            if (index < value) {
              style=activeColor;
            }
            return (
                <FontAwesomeIcon icon={faStar} style={{color: style, width:size, height:size, fontSize: size, marginLeft: "-6px"}}/>
            )
          })}
        </div>
      )
    }
    // const wishlist = props.wishlist;
    const settings = {
        dots: false,
        infinite: true,
        centerMode: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
        nextArrow: <FontAwesomeIcon icon={faArrowRight} />,
        responsive: [


            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false

                }
            },
            {
                breakpoint: 1650,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false

                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false

                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <Slider className="prod-slider" {...settings}>
            {
            props.products.map((row, index) => (
            <div className="slider-cont">
                <Link className="product-text" to={`/shop/${row.id}`}>
                <div className="new-image-single">
                    <img src={row.image} alt=""/>
                </div>
                </Link>
                { row.display_name !== null ?
                <div className="discount-tag">
                    <span>{row.display_name}</span>
                </div>
                :
                ''
                }
                <div className="heart-icon" key={index} data-id={row.id} onClick={() => { props.addToWishlist(row.id); }}>
                {
                        (JSON.stringify(wishlist.find((item) => item.id === row.id))) ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="top-product-svg" width="20" height="20"
                        viewBox="0 0 27 23">
                        <path id="Icon_feather-heart" data-name="Icon feather-heart"
                            d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                            transform="translate(-0.823 -2.997)" fill="none"
                            stroke="#3f5b6c" strokeLinecap="round" strokeLinejoin="round"
                            strokeWidth="3" />
                    </svg>

                        : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                        viewBox="0 0 27 23">
                        <path id="Icon_feather-heart" data-name="Icon feather-heart"
                            d="M24.472,6.265a6.525,6.525,0,0,0-8.933,0L14.323,7.428,13.106,6.265a6.527,6.527,0,0,0-8.933,0,5.846,5.846,0,0,0,0,8.535L5.39,15.963,14.323,24.5l8.933-8.535L24.472,14.8a5.845,5.845,0,0,0,0-8.535Z"
                            transform="translate(-0.823 -2.997)" fill="none"
                            stroke="#3f5b6c" strokeLinecap="round" strokeLinejoin="round"
                            strokeWidth="3" />
                    </svg>

                    }
                </div>
                <Link className="product-text" to={`/shop/${row.id}`}>
                <div className="top-text-active">
                    <div className="shop-items-product">
                        {/* <Tooltip title={row.product_name}>
                           <h3>{row.product_name}</h3>
                        </Tooltip> */}
                        <h3 className="heading-newarival-slider" title={row.product_name}>{row.product_name}</h3>
                          {/* <Tooltip title={row.product_name}>
                           <h3>{row.product_name}</h3>
                        </Tooltip> */}
                        <div className="d-flex star-mar mb-2">
                        <StarRating 
                        count={5}
                        size={20}
                        value={row.product_reviews}
                        activeColor ={'#FEBE37'}
                        inactiveColor={'#ddd'}/>         
                        </div>
                        {
                            row.discount_offer_id != null ?
                            <div className="newarrival-slider-price">
                            <span className="d-price"><del><span className="currency-font">{currency + " "}</span>{row.price}</del> | </span>
                            <span className="r-price"><span className="currency-font">{currency + " "}</span>{(row.price - row.discounted_amount)}</span>
                            </div>
                            :
                            <div className="newarrival-slider-price">
                                <span className="currency-font">{currency + " "}</span>
                                <span>{row.price}</span>
                            </div>
                        }
                    </div>
                </div>
                </Link>
            </div>
            ))}
        </Slider>
    )
}
export default SliderProducts;

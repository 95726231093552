import React, {useState, useEffect} from "react";
import { Container,Row,Col } from "react-bootstrap";
import heart from "../../assets/ShopLove/images/heart.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import customer from "../../assets/CafeFood/images/customer.png";
import ReactStars from "react-rating-stars-component";
import api from "../helpers/api";
const Banner =(props)=>{
    const ratingChanged = (newRating) => {
        // console.log(newRating);
    };
    const [Banner, setBanner] = useState([]);
    const siteSettings = props.siteSettings;
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        api.get(`/fetch_banners`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh',
            }
          }).then(res => {
            setBanner(res.data.data[0])
            setIsLoading(false)
        });

    }, []);
    return(
        <div className="banner">
            <Container fluid className="custome-container">
                <Row>
                    {/* <Col>
                    <div className="bg-img">
                        <div className="banner-paragraph">
                            <span>Trending collection</span>
                            <h2>Enjoy the fashing <div className="d-block">weak with our story</div></h2>
                            <p>Phasellus risus turpis, pretium sit amet magna non, molestie ultricies enim. Nullam pulvinar felis at metus malesuada, nec convallis lacus commodo. Duis blandit neque purus, nec auctor mi sollicitudin nec.</p>
                            <div className="d-flex align-items-center">
                                <button className="banner-btn d-flex align-items-center me-2">
                                    <span>Start Shopping</span>
                                    <div className="icon">
                                        <FontAwesomeIcon className="banner-icon" icon={faAngleRight} />
                                    </div> 
                                </button>
                                <button className="banner-button d-flex align-items-center ">
                                    <img src={button} alt="" />
                                    <span>How to order</span>
                                </button>
                            </div>
                            
                        </div>

                    </div>
                       
                    </Col> */}
                    <Col lg={6}>
                    <div className="border-banner-left"></div>
                        <div className="banner-paragraph">
                            <span>{Banner.small_heading}</span>
                            <h2>{Banner.title}</h2>
                            <p>{Banner.description}</p>
                            <div className="banner-block d-flex align-items-center">
                                <a className="banner-text" href="/catalog">
                                    <button className="banner-btn d-flex align-items-center me-2">
                                        <span>Start Shopping</span>
                                        <div className="icon">
                                          <FontAwesomeIcon className="banner-icon" icon={faAngleRight} />
                                       </div> 
                                    </button>
                                </a>
                                {/* <button className="banner-button d-flex align-items-center ">
                                    <img src={button} alt="" />
                                        <div className="banner-border-icon">
                                         <FontAwesomeIcon className="banner-play" icon={faPlay} />
                                        </div>
                                        <span>How to order</span>
                                </button> */}
                            </div> 
                            <div className='happy-customer d-flex align-items-center'>
                                <div className="banner-rating-img  me-3" >
                                    <img className='img-fluid ml-2 customer-img' src={customer} alt='Cafe Food'></img>
                                </div>
                                <div>
                                    <span className='order-font'>Our happy customers</span>
                                    <div className='d-flex align-items-center'>
                                        <ReactStars
                                            count={1}
                                            onChange={ratingChanged}
                                            size={24}
                                            color="#FFA934"
                                            activeColor="#FFA934"
                                        />
                                        <span className='tiny-font rating me-1'> {Banner.product_review} </span>
                                        <span className='tiny-font'>{"("+Banner.review_count+" "+ "Reviews)"}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="img-heart">
                                <img className="img-fluid" src={heart} alt="fluid" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="top-banner-img position-relative">
                            <img className="banner-right-img" src={siteSettings.banner_image}  />
                            {/* <div className="banner-review">
                                <p>Our happy customers</p>
                                <span>
                                    <span className="rating">
                                    <FontAwesomeIcon className="banner-star" icon={faStar} />
                                    <span className="raitng-banner"> 4.8 </span>
                                    </span>
                                    <span className="rating-color"> (12.5K Reviews) </span>
                                </span>
                            </div> */}
                            <div className="banner-heart-img">
                                <img className="img-fluid" src={heart} alt="" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    )
}
export default Banner;

import React, { useState, useEffect } from "react";
import { Container,Row,Col } from "react-bootstrap";
import banner from "../../../assets/Furniture/images/banner-img.png";
const Woodfurtinure =(props)=> {
    const siteSettings = props.siteSettings;
    return(
        <div className="wood-furtinure">
            <div className="custome-container">
                <Row>
                    <Col>
                        <span className="furtinure-heading">Share your setups with us on instagram</span>
                        <h2 className="medorawood-furtinure">#medorawood furniture </h2>
                    </Col>
                </Row>
            </div>
            <div className="wood-furtinure-img">
                <img src={siteSettings? siteSettings.default_image_2 : banner} alt="" />
            </div>
        </div>
      
    )
};
export default Woodfurtinure;
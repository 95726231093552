import React, {useState, useEffect} from "react";
import AboutUs from './AboutUs/AboutUs';
import Banner from './Banner/Banner';
import BannerBox from './BannerBox/BannerBox';
import BestSeller from './BestSeller/BestSeller';
import DealDay from './DealDay/DealDay';
import FlowerShop from './FlowerShop/FlowerShop';
import Header from './Header/Header';
import ShopCategory from './ShopCategory/ShopCategory';
import MiddleFooter from "./MiddleFooter/MiddleFooter";
import Footer from './Footer/Footer';
import Testimonials from './Testimonials/Testimonials';
import { ToastContainer, toast } from 'react-toastify';
import api from "../../helpers/api";
const HomePage = () => {
	// Banner Site Settings
	const [siteSettings, setSiteSettings] = useState([]);
  useEffect(() => {
      api.get(`/get-site-settings`, {
        headers: {
          'POS-API-KEY' : 'jd54elkrtlh'
        }
      }).then(res => {
        setSiteSettings(res.data.data)
        setIsLoading(false)
    });
  }, []);
	const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
	const [wishlist, setWishlist] = useState(wishlist_items);
  if(wishlist == undefined)
  {
      setWishlist([]);
  }
	const [topProducts, settopProducts] = useState([]);
	const [mostLovedProducts, setmostLovedProducts] = useState([]);
	const [allProducts, setAllProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const NewThemeID = JSON.parse(localStorage.getItem('ThemeID'));
  const [themeId, setThemeId] = useState(NewThemeID?NewThemeID:[]);
  useEffect(() => {
      api.get(`/get-store-theme-details`, {
          headers: {
            'POS-API-KEY' : 'jd54elkrtlh'
          }
        }).then(res => {
          setThemeId(res.data.data.store.theme_id)
          localStorage.setItem('ThemeID', res.data.data.store.theme_id);
      });
      
  }, []);

  useEffect(() => {
    localStorage.setItem('wishlist', JSON.stringify(wishlist));
    api.get(`/get-all-products`, {
      headers: {
        'POS-API-KEY' : 'jd54elkrtlh'
      }
    }).then(res => {
      setAllProducts(res.data.data)
    });
    setIsLoading(true)
    api.get(`/get-top-products`, {
        headers: {
          'POS-API-KEY' : 'jd54elkrtlh'
        }
    }).then(res => {
        settopProducts(res.data.data)
        setIsLoading(false)
    });
    setIsLoading(true)
    api.get(`/get-people-loved-products`, {
        headers: {
          'POS-API-KEY' : 'jd54elkrtlh'
        }
      }).then(res => {
        setmostLovedProducts(res.data.data)
        setIsLoading(false)
    });
  }, [wishlist]);
  const addToWishlist = (id) => {
      toast.dismiss();
      const check_wishlist = JSON.stringify(wishlist.find((item) =>
      item.id === id
      ));
    
      if (!check_wishlist) {
          const product = allProducts.find((item) => item.id === id);

          setWishlist(current  => [...current , product]);

          toast.success("Wishlist Added Successfully", {
            autoClose: 1000
          });
      } else {
          let items =JSON.parse(localStorage.getItem("wishlist"));
          items = items.filter((item) => item.id !== id);
          localStorage.setItem("wishlist", JSON.stringify(items));
          if (items.length === 0) {
            localStorage.removeItem("item");
          }
          setWishlist(items);
          toast.error("Item is removed from wishlist", {
            autoClose: 1000
          });
      }

  };
	return (
		<div className='wrapper'>
			<div className='bg'>
				<ToastContainer/>
				<Header />
				<Banner siteSettings={siteSettings}/>
				<BannerBox/>
				<DealDay topProducts= {topProducts} isLoading={isLoading} addToWishlist={addToWishlist}/>
				<AboutUs siteSettings={siteSettings}/>
				<BestSeller addToWishlist={addToWishlist}/>
				<ShopCategory/>
        <Testimonials/>
				<FlowerShop mostLovedProducts= {mostLovedProducts} isLoading={isLoading} addToWishlist={addToWishlist} siteSettings={siteSettings}/>		
        <Footer/>
				<MiddleFooter/>
			</div>
		</div>
	)
}

export default HomePage;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, NavDropdown, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from '../../../../assets/HappyShopping/images/happy-logo.svg';
import heart from "../../../../assets/HappyShopping/images/heart-icon.svg";
import shopping from "../../../../assets/HappyShopping/images/shopping-cart.svg";
import search from "../../../../assets/HappyShopping/images/search-icon.svg";
import profile from "../../../../assets/HappyShopping/images/avatar.png";
import { useNavigate, useLocation } from "react-router-dom";
import capitilizeFirst from '../../../hooks/capitilizeFirst';
import api from "../../../helpers/api";
function useQuery(props) {
    return new URLSearchParams(useLocation().search);
}
const MiddleHeader = (props) => {
    const location = useLocation();
    const user = localStorage.getItem("isLoggedIn");
    const customer = JSON.parse(localStorage.getItem('userData'));
    const cart_items = JSON.parse(localStorage.getItem('cart'));
    const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
    const WishlistCount = wishlist_items ? wishlist_items.length : '0' ;
    const totalQuantity = cart_items ? props.showContent : "0";
    const [isLoading, setIsLoading] = useState(false);
    const [siteSettings, setSiteSettings] = useState([]);
    useEffect(() => {
        setIsLoading(true)
        api.get(`/get-site-settings`, {
            headers: {
              'POS-API-KEY' : 'jd54elkrtlh'
            }
          }).then(res => {
            setSiteSettings(res.data.data)
            setIsLoading(false)
        });
    }, []);
    
    const onLogoutHandler = (e) => {
        e.preventDefault();
        localStorage.removeItem("userData");
        localStorage.removeItem("jwt_token");
        localStorage.removeItem("isLoggedIn");
        window.location.href = '/';
    };
    let query = useQuery();
    const searchName = query.get("term") ? query.get("term") : ""
    const [HomeSearch, setHomeSearch] = useState();
    const navigate = useNavigate();
    const handleChange = (e) => {
        
        setHomeSearch(e.target.value);
    }

    const handleSearch = (e) => {
        
        if(HomeSearch)
        {
            navigate(`/catalog?term=${HomeSearch}`);
        }
    
    }
    var link = window.location.href.split('/');
    
    return (

        <div id="middle-header">

            <Container>
                <Row>
                    <Col xl={3} lg={3} md={3} sm={12}>
                        <strong>
                            <Link to="/" className="middle-header-logo d-inline-block ">
                            {isLoading ? <img className="img-fluid" src={logo} alt="" />:
                                <img className="img-fluid" src={siteSettings.logo} alt="" />
                            }
                            </Link>
                        </strong>
                    </Col>
                    <Col xl={5} lg={5} md={4} sm={12}>
                        <div className="wrap position-relative">
                            <div className="search">
                                <input type="text" className="searchTerm" placeholder="What are you looking for?" onChange={location.pathname == "/catalog" ? props.handleChange : handleChange} value={props.search_catalog}/>
                            </div>
                            <button type="submit" className="searchButton" onClick={handleSearch}>
                                <i className="fa fa-search"></i>Search 
                            </button>
                            <div className="search-icons">
                              <img src={search}/>
                            </div>
                           
                        </div>
                    </Col>
                    <Col xl={4} lg={4} md={5} sm={12}>
                        <div className="heart-sec d-flex align-items-center justify-content-end ms-lg-2">
                            <div className="middle-header-heart-icon d-flex me-4">
                                <Link className="bottom-header-img" to="/wishlist">
                                    <div className="middle-header-heart position-relative">
                                        <img className="img-fluid" src={heart} alt="" />
                                        <span>{props.lengthWishlist ? props.lengthWishlist : WishlistCount}</span>
                                    </div>
                                </Link>
                            </div>
                            <Link to="/cart">
                                <div className="header-cart d-flex align-items-center justify-content-between">
                                    <div className="cart position-relative d-inline-block">
                                        <div className="bottom-header-truck">
                                            <img className="img-fluid" src={shopping} alt=""/>
                                        </div>
                                        <span>{totalQuantity}</span>
                                    </div>
                                    <div className="my-cart">
                                        <span className="header-color">My Cart</span>
                                        <span className="color">Rs.{cart_items?parseFloat(cart_items.reduce((prev, cur) => ((cur.discountedAmount ? cur.price - cur.discountedAmount: cur.price) * cur.quantity) + prev, 0)).toFixed(2):'0.00'}</span>
                                    </div>
                                </div>
                            </Link>

                            {!user && <div className="d-flex align-items-center">
                                <div className="top-header-login me-auto">
                                    <Link to="/login">
                                        <span>Login</span>
                                    </Link>
                                </div>
                            </div>}
                            {user && <div className="d-flex align-items-center">
                                <div className="user-profile">
                                    <Dropdown className="d-flex ">
                                            <div className="me-2">
                                                <img className="img-fluid" src={profile} style={{width: "38px"}}/>
                                            </div>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic-top-change">
                                            <span className="text-flow">
                                                {props.profileName ? capitilizeFirst(props.profileName) : capitilizeFirst(customer.first_name)}
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="/myaccount">My Account</Dropdown.Item>
                                            <Dropdown.Item href="/myorder">My Orders</Dropdown.Item>
                                            <Dropdown.Item href="/wishlist">My Wishlist</Dropdown.Item>
                                            <NavDropdown.Item href="#" onClick={onLogoutHandler}>Logout</NavDropdown.Item>
                                        </Dropdown.Menu>
                                     </Dropdown>
                                </div>
                            </div>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )


}
export default MiddleHeader;


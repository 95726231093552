
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav, NavDropdown, Navbar,Dropdown } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import catagories from "../../../assets/HairBeauty/images/catagories.svg";
import truck from "../../../assets/HairBeauty/images/truck.svg";
import logo from "../../../assets/HairBeauty/images/beauty-logo.png";
import login from "../../../assets/HairBeauty/images/user.svg";
import heart from "../../../assets/HairBeauty/images/heart-icon.svg";
import shopping from "../../../assets/HairBeauty/images/shopping-cart.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faUser } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import api from "../../helpers/api";
// import Aboutus from '../../others/Aboutus/Aboutus';
const BottomHeader = (props) => {
  const navigate = useNavigate();
  const [siteSettings, setSiteSettings] = useState([]);
  const [categories, setCategories] = useState([]);
  const cart_items = JSON.parse(localStorage.getItem('cart'));
  const totalQuantity = cart_items ? cart_items.reduce((total,m) => total + m.quantity,0) : '0';
  const wishlist_items = JSON.parse(localStorage.getItem('wishlist'));
  const WishlistCount = wishlist_items ? wishlist_items.length : '0' ;
  const user = localStorage.getItem("isLoggedIn");
  const customer = JSON.parse(localStorage.getItem('userData'));
  const [isLoading, setIsLoading] = useState([]);
  useEffect(() => {
    setIsLoading(true)
    api.get(`/get-site-settings`, {
      headers: {
      'POS-API-KEY' : 'jd54elkrtlh'
      }
  }).then(res => {
      setSiteSettings(res.data.data)
      setIsLoading(false)
  });
    api.get(`/get-product-type`, {
      headers: {
        'POS-API-KEY' : 'jd54elkrtlh'
      }
    }).then(res => {

      setCategories(res.data.data)
      setIsLoading(false)

    });
    const urlHash = window.location.hash;
    if (urlHash.length) {
      const element = document.getElementById(urlHash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);
  const onLogoutHandler = (e) => {
    e.preventDefault();
    localStorage.removeItem("userData");
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("isLoggedIn");
    navigate("/")
    toast.success("You logout successfully");
  };
  var link = window.location.href.split('/');

  return (

    <div id="bottom-header">

      <Navbar expand="lg p-0">
        <Container>
        {isLoading ? <img className="img-fluid" src={logo} alt="" />:
          <Navbar.Brand as={Link} to="/">
            <img className='img-fluid' src={siteSettings.logo}></img>
          </Navbar.Brand>
            }
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/" className={link[3] == "" && "menu-active-nav"}>Home</Nav.Link>
              <Nav.Link href="/catalog" className={link[3] == "catalog" && "menu-active-nav"}>Catalog</Nav.Link>
              <Nav.Link as={Link} to="/cms/about-us" className={link[4] == "about-us" && "menu-active-nav"}>About Us</Nav.Link>
              <Nav.Link as={Link} to="/faq" className={link[3] == "faq" && "menu-active-nav"}>FAQs</Nav.Link>
                
              
            </Nav>
          </Navbar.Collapse>
          <div className="bottom-header-btn d-flex justify-content-between align-items-center">
                <Nav.Link className="bottom-header-img" href="/track-order"> 
                <FontAwesomeIcon  icon={faTruck} /> 
                </Nav.Link>
                <div className="bottom-header-cart position-relative">
                  <Link className="bottom-header-img" to="/wishlist">
                        <img className="img-fluid" src={heart} alt="" />
                    </Link>
                    <span>{props.lengthWishlist ? props.lengthWishlist : WishlistCount}</span>
                </div>
                 
                <Nav.Link as={Link} to="/cart">
                    <div className="cart position-relative d-inline-block">
                        <div className="bottom-header-img"><img className="img-fluid" src={shopping} alt="" /></div>
                        <span>{totalQuantity}</span>
                    </div>
                </Nav.Link>
              {!user && <Nav.Link as={Link} to="/login">
                {/* <FontAwesomeIcon className="bottom-header-icon" icon={faUser} /> */}
                <div className="bottom-user-header">
                  <img className='img-fluid' src={login} ></img>
                </div>
              </Nav.Link>}
              {user && <div className="user-profile">
                <Dropdown className="d-flex ">
                    {/* <div className="me-2">
                    </div> */}
                    <Dropdown.Toggle variant="none" className="bottom-header-button" id="dropdown-basic-top-change">
                      <span className="text-flow">{customer.first_name.charAt(0).toUpperCase() + customer.first_name.slice(1)}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="header-down">
                        <Dropdown.Item as={Link} to="/myaccount">My Account</Dropdown.Item>
                        <Dropdown.Item as={Link} to="/myorder">My Orders</Dropdown.Item>
                        <Dropdown.Item as={Link} to="/wishlist">My Wishlist</Dropdown.Item>
                        <NavDropdown.Item href="#" onClick={onLogoutHandler}>Logout</NavDropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>}
              </div>
        </Container>
      </Navbar>

    </div>
  );
};
export default BottomHeader;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import Middlefooter from "../Middlefooter/Middlefooter";
import Description from "./Description";
import Relatedproduct from "./Relatedproduct";
import ShopItems from "./ShopItem";
import Shopborder from "./Shopborder";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../helpers/LoadingSpinner/LoadingSpinner";
import PetStoreHeader from "../../PetStore/HomePage/Header/Header";
import NewUpdates from "../../PetStore/HomePage/NewUpdates/NewUpdates";
import PetStoreFooter from "../../PetStore/HomePage/Footer/Footer";
import JewelleryHeader from "../../Jewellery/Header/Header";
import JewelleryTopFooter from "../../Jewellery/Home-Page/Subscriber/Subscriber";
import JewelleryFooter from "../../Jewellery/Footer/Footer";
import ShopHeader from "../../ShopLove/Header/Header";
import ShopBottomFooter from "../../ShopLove/BottomFooter";
import FlowerHeader from "../../FlowerShop/HomePage/Header/Header";
import FlowerFooter from "../../FlowerShop/HomePage/Footer/Footer";
import FlowerMidFooter from "../../FlowerShop/HomePage/MiddleFooter/MiddleFooter";
import CafeHeader from "../../CafeFood/Header/Header";
import CafeFooter from "../../CafeFood/BottomFooter";
import api from "../../helpers/api";
const Shop = () => {
  const { id } = useParams();
  const [productDetail, setProductDetail] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const wishlist_items = JSON.parse(localStorage.getItem("wishlist"));
  const [wishlist, setWishlist] = useState(wishlist_items);
  const lengthWishlist = wishlist ? wishlist.length : 0;
  if (wishlist == undefined) {
    setWishlist([]);
  }
  const [BrandProducts, setBrandsProducts] = useState([]);
  const [imageGal, setImageGal] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [showContent, setShowContent] = useState(0);
  const [productCategory, setproductCategory] = useState(null);
  const [prooductId, setProductId] = useState(null);
  const [productName, setProductName] = useState(null);
  const [productStock, setProductStock] = useState(null);
  const [firstVariantImage, setFirstVariantImage] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [VariantImage, setVariantImage] = useState(null);
  const [variantName, setVaraintName] = useState(null);
  const [price, setPrice] = useState(null);
  const [product_price, setProductPrice] = useState(null);
  const [TaxRate, setTaxRate] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState([]);
  // Discount Offer States
  const [discountOfferId, setDiscountOfferId] = useState(null);
  const [discountOfferName, setDiscountName] = useState(null);
  const [discountSubType, setDiscountSubType] = useState(null);
  const [discountedAmount, seDiscountedAmount] = useState(null);
  const [discountedPercentage, seDiscountedPercentage] = useState(null);
  const [discountCase, seDiscountCase] = useState(null);
  const NewThemeID = JSON.parse(localStorage.getItem("ThemeID"));
  const [themeId, setThemeId] = useState(NewThemeID ? NewThemeID : []);
  useEffect(() => {
    api
      .get(`/get-store-theme-details`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setThemeId(res.data.data.store.theme_id);
        localStorage.setItem("ThemeID", res.data.data.store.theme_id);
      });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // window.scrollTo(0, 0)
    setIsLoading(true);
    api
      .get(`/get-product-detail/${id}`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setProductDetail(res.data.data);
        setRelatedProducts(res.data.data.related_products);
        setAllProducts(res.data.data.related_products);
        setBrandsProducts(res.data.data.product_brands);
        setImageGal(res.data.data.product_variants_Images);
        setIsLoading(false);
        setFirstVariantImage(res.data.data.first_variant_image);
      })
      .catch(({ response: res }) => {});
  }, [id]);

  const images = [
    {
      src: productDetail.image
    }
  ];

  images.push.apply(images, imageGal);

  const imagesProduct = images.map((number) => ({
    src: VariantImage || number.src,
    thumbnail: number.src
  }));
  useEffect(() => {
    const TotalQty = cart
      ? cart.reduce((total, m) => total + m.quantity, 0)
      : "0";
    setShowContent(TotalQty);
  }, [cart]);
  useEffect(() => {
    setproductCategory(productDetail.product_category);
    setProductId(productDetail.id);
    setProductName(productDetail.product_name);
    setVariantId(productDetail.first_variant_id);
    setVaraintName(productDetail.first_variant_combination_name);
    setPrice(productDetail.price);
    setProductPrice(productDetail.product_price);
    setProductStock(productDetail.available_stock);
    setImage(productDetail.image);
    if (productDetail.first_variant_discount_id == null) {
      setDiscountOfferId(productDetail.discount_offer_id);
      setDiscountName(productDetail.display_name);
      setDiscountSubType(productDetail.discount_sub_type);
      seDiscountedAmount(productDetail.discounted_amount);
      seDiscountedPercentage(productDetail.get_discount_percentage);
    } else {
      setDiscountOfferId(productDetail.first_variant_discount_id);
      setDiscountName(productDetail.first_variant_discount_name);
      setDiscountSubType(productDetail.first_variant_discount_type);
      seDiscountedAmount(productDetail.first_variant_discount_amount);
      seDiscountedPercentage(productDetail.first_variant_discount_pecentage);
    }
    seDiscountCase(productDetail.discount_case);
    setTaxRate(productDetail.tax_rate);
    if (localStorage.getItem("cart")) {
      setCart(JSON.parse(localStorage.getItem("cart")));
    }
  }, [productDetail]);

  const onOptionClicked = (
    variant_id,
    variant_name,
    price,
    image,
    dicount_offer_id,
    display_name,
    discount_sub_type,
    discounted_amount,
    get_discount_percentage,
    discount_case,
    variant_taxRate
  ) => {
    setVariantId(variant_id);
    setVaraintName(variant_name);
    setPrice(price);
    setImage(image);
    setVariantImage(image);
    setFirstVariantImage(null);
    // Discount Offer States

    setDiscountOfferId(dicount_offer_id);
    setDiscountName(display_name);
    setDiscountSubType(discount_sub_type);
    seDiscountedAmount(discounted_amount);
    seDiscountedPercentage(get_discount_percentage);
    seDiscountCase(discount_case);
    setTaxRate(variant_taxRate);
  };

  // Add product to wishlist
  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  const addToWishlist = (id) => {
    toast.dismiss();
    const check_wishlist = JSON.stringify(
      wishlist.find((item) => item.id === id)
    );

    if (!check_wishlist) {
      const product = allProducts.find((item) => item.id === id);

      setWishlist((current) => [...current, product]);
      toast.success("Wishlist Added Successfully", {
        autoClose: 1000
      });
    } else {
      let items = JSON.parse(localStorage.getItem("wishlist"));
      items = items.filter((item) => item.id !== id);
      localStorage.setItem("wishlist", JSON.stringify(items));
      if (items.length === 0) {
        localStorage.removeItem("item");
      }
      setWishlist(items);
      toast.error("Item is removed from wishlist", {
        autoClose: 1000
      });
    }
  };

  // Add product to cart
  const addToCart = () => {
    const check_index = cart.findIndex((item) =>
      item.variant_id == ""
        ? item.prooduct_id === id
        : item.variant_id === variantId
    );

    const new_index = cart.find((item) =>
      item.variant_id != "" ? item.variant_id === id : item.prooduct_id === id
    );
    if (check_index !== -1) {
      if (new_index?.productStock > 0 && quantity <= new_index?.productStock) {
        const newList = cart.map((item) => {
          if (
            item.variant_id == ""
              ? item.prooduct_id === id
              : item.variant_id === variantId
          ) {
            const updatedItem = {
              ...item,
              quantity: item.quantity + quantity,
              productStock: item.productStock - quantity
            };

            return updatedItem;
          }

          return item;
        });

        setCart(newList);
        toast.success("Cart Added Successfully");
      } else {
        toast.error(
          "You cannot add more items of this product in cart. Available stock is full",
          {
            autoClose: 1000
          }
        );
      }
    } else {
      if (quantity <= productStock) {
        setCart((current) => [
          ...current,
          {
            product_category: productCategory,
            prooduct_id: prooductId,
            product_name: productName,
            first_variant_image: firstVariantImage,
            variant_id: variantId,
            variant_name: variantName,
            price: price,
            productPrice: product_price,
            TaxRate: TaxRate,
            image: image,
            quantity: quantity,
            discountOfferId: discountOfferId,
            discountOfferName: discountOfferName,
            discountSubType: discountSubType,
            discountedAmount: discountedAmount,
            discountedPercentage: discountedPercentage,
            discountCase: discountCase,
            themeId: themeId,
            productStock: productStock - quantity
          }
        ]);
        toast.success("Cart Added Successfully");
      } else {
        toast.error(
          "You cannot add more items of this product in cart. Available stock is full",
          {
            autoClose: 1000
          }
        );
      }
    }
  };
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  // increase & decrease the products quantity
  const IncreaseChange = () => {
    const new_test = quantity + 1;
    setQuantity(new_test);
  };
  const DecreaseChange = () => {
    if (quantity > 1) {
      const new_test = quantity - 1;
      setQuantity(new_test);
    }
  };

  const newchangeCourasel = () => {
    setVariantImage(null);
  };
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="wrapper">
          {themeId == 1 && (
            <FlowerHeader
              showContent={showContent}
              lengthWishlist={lengthWishlist}
            />
          )}
          {themeId == 2 && (
            <ShopHeader
              showContent={showContent}
              lengthWishlist={lengthWishlist}
            />
          )}
          {themeId == 3 && (
            <Header showContent={showContent} lengthWishlist={lengthWishlist} />
          )}
          {themeId == 4 && (
            <CafeHeader
              showContent={showContent}
              lengthWishlist={lengthWishlist}
            />
          )}
          {themeId == 8 && (
            <PetStoreHeader
              showContent={showContent}
              lengthWishlist={lengthWishlist}
            />
          )}
          {themeId == 9 && (
            <JewelleryHeader
              showContent={showContent}
              wishlist={wishlist}
              lengthWishlist={lengthWishlist}
            />
          )}
          <ToastContainer />
          <ShopItems productDetail={productDetail} />
          <Shopborder
            productDetail={productDetail}
            discountSubType={discountSubType}
            discountOfferName={discountOfferName}
            discountOfferId={discountOfferId}
            TaxRate={TaxRate}
            discountedAmount={discountedAmount}
            discountedPercentage={discountedPercentage}
            discountCase={discountCase}
            images={imagesProduct}
            variantName={variantName}
            variantId={variantId}
            image={image}
            price={price}
            addToCart={addToCart}
            onOptionClicked={onOptionClicked}
            IncreaseChange={IncreaseChange}
            DecreaseChange={DecreaseChange}
            quantity={quantity}
            addToWishlist={addToWishlist}
            wishlist={wishlist}
            newchangeCourasel={newchangeCourasel}
          />
          <Description productDetail={productDetail} />
          <Relatedproduct
            relatedProducts={relatedProducts}
            addToWishlist={addToWishlist}
            wishlist={wishlist}
          />
          {themeId == 1 && <FlowerFooter />}
          {themeId == 1 && <FlowerMidFooter />}
          {themeId == 2 && <ShopBottomFooter />}
          {/* {themeId == 3 && <Topfooter/>} */}
          {themeId == 3 && <Middlefooter />}
          {themeId == 4 && <CafeFooter />}
          {themeId == 8 && <NewUpdates />}
          {themeId == 8 && <PetStoreFooter />}
          {themeId == 9 && <JewelleryTopFooter />}
          {themeId == 9 && <JewelleryFooter />}
        </div>
      )}
    </>
  );
};
export default Shop;

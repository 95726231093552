import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../helpers/api";
function ShowErrorMessage(props) {
  const messages = props.message;
  const listItems = Object.keys(messages).map((key) => {
    return <li>{props.message[key]}</li>;
  });
  return <ul>{listItems}</ul>;
}
const Footer = () => {
  const [email, setEmail] = useState();
  const [CmsPages, setCmsPages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [siteSettings, setSiteSettings] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setErrorMsg("");
      setSuccessMsg("");
    }, 3000);
  }, [errorMsg, successMsg]);
  useEffect(() => {
    api
      .get(`/fetch_cms_pages`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setCmsPages(res.data.data);
        setIsLoading(false);
      });
    api
      .get(`/get-site-settings`, {
        headers: {
          "POS-API-KEY": "jd54elkrtlh"
        }
      })
      .then((res) => {
        setSiteSettings(res.data.data);
        setIsLoading(false);
      });
  }, []);
  const submitHandler = async (e) => {
    e.preventDefault();

    await api
      .post(
        `/subscribe`,
        {
          email
        },
        {
          headers: {
            "POS-API-KEY": "jd54elkrtlh"
          }
        }
      )
      .then((res) => {
        if (res.data.status == 0) {
          if (typeof res.data.message === "object") {
            setSuccessMsg("");
            setErrorMsg(<ShowErrorMessage message={res.data.message} />);
          } else {
            setSuccessMsg("");
            setErrorMsg(res.data.message);
          }
          // toast.info(res.data.message);
        } else {
          setErrorMsg("");
          setEmail("");
          setSuccessMsg(res.data.message);
        }
      })
      .catch((res) => {
        toast.error("Something went wrong");
      });
  };
  // Replace the site settings copyright with current year
  const originalString = siteSettings?.site_copyright;
  const currentYear = new Date().getFullYear();
  const copyright = originalString?.replace(/\d{4}/, currentYear);
  return (
    <div className="footer">
      <div className="footer-one">
        <div className="container-width">
          <Row>
            <Col lg="4" md="8">
              {errorMsg && (
                <div className="alert alert-danger alert-dismissible footer">
                  {errorMsg}
                </div>
              )}
              {successMsg && (
                <div className="alert alert-success footer">{successMsg}</div>
              )}
              <Form
                className="footer-content pe-xl-5 pe-4 mb-lg-0 mb-5"
                method="post"
                onSubmit={submitHandler}
              >
                <Form.Group
                  className="input-wrapper mb-xl-5 mb-4"
                  controlId="formBasicEmail"
                >
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Group>
                <Button type="submit">Subscribe</Button>
              </Form>
            </Col>
            <Col lg="8">
              <Row>
                <Col lg={2} offset-1></Col>
                <Col lg="3" md="4">
                  <div className="footer-content">
                    <h5 className="mb-4">Our Services</h5>
                    <ul className="p-0">
                      {CmsPages != ""
                        ? CmsPages.map((row, key) => (
                            <li>
                              <Link to={"/cms/" + row.slug}>{row.title}</Link>
                            </li>
                          ))
                        : ""}
                    </ul>
                  </div>
                </Col>
                <Col lg={2} offset-1></Col>
                <Col lg="3" md="4">
                  <div className="footer-content">
                    <h5 className="mb-4">Catalog</h5>
                    <ul className="p-0">
                      <li>
                        <a href="/catalog">All Brands</a>
                      </li>
                      <li>
                        <a href="/catalog">All Product Types</a>
                      </li>
                      <li>
                        <a href="/catalog">All Product Tags</a>
                      </li>
                    </ul>
                  </div>
                </Col>
                {/* <Col lg="4" md='4'>
                                    <div className='footer-content'>
                                        <h5 className='mb-4'>Helping you save</h5>
                                        <ul className='p-0'>
                                            <li><a href='/'>Extended Warranty Retail</a></li>
                                            <li><a href='/'>Gift Card</a></li>
                                            <li><a href='/'>Exclusive Discounts</a></li>
                                            <li><a href='/'>Offers</a></li>
                                        </ul>
                                    </div>
                                </Col> */}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer-two">
        <div className="container-width">
          <div className="copyright text-center">
            <p className="mb-0">{copyright}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

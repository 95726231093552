import React from "react";
import { Container,Row,Col } from "react-bootstrap";
import { Link } from "react-router-dom";


const breadaccount =()=>{
    return(
        <div id="bread" className="mb-4">
            <Container>
                <Row>
                    <Col>
                        <div className="top-bar">
                            <div>
                                <span>Account</span>
                            </div>
                            <div aria-label="breadcrumb ">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link className="text-decorarion" to="/">Home</Link></li>
                                    <li className="breadcrumb-item active products" aria-current="page">Account</li>
                                </ol>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
     </div>
    )
}
export default breadaccount;
import React from "react";
import { Row } from "react-bootstrap";
import { SpinnerCircularFixed } from "spinners-react";
import SliderProducts from "./../SliderComponents/SliderProducts";
import NonSliderProducts from "./../SliderComponents/NonSliderProducts";
const Features = (props) => {
    const mostLovedProducts = props.mostLovedProducts;
    const addToWishlist = props.addToWishlist;
    const wishlist = props.wishlist;
    const isLoading = props.isLoading;
    return (
        <div className="features">
            <div className="section-heading text-center">
                <h6>Real, Good Food and items Experts</h6>
                <h3>People Loved It</h3>
            </div>
            <div className="container-width">
                {isLoading ? (
                    <Row>
                        <div className="text-center pt-4 mb-4">
                            {/* <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1239A5" /> */}
                            <SpinnerCircularFixed
                                size={50}
                                thickness={100}
                                speed={100}
                                color="#1239A5"
                                secondaryColor="rgba(0, 0, 0, 0.44)"
                            />
                        </div>
                    </Row>
                ) : mostLovedProducts.length > 0 ? (
                    mostLovedProducts.length > 4 ? (
                        <SliderProducts
                            products={mostLovedProducts}
                            addToWishlist={addToWishlist}
                            wishlist={wishlist}
                        />
                    ) : (
                        <NonSliderProducts
                            products={mostLovedProducts}
                            addToWishlist={addToWishlist}
                            wishlist={wishlist}
                        />
                    )
                ) : (
                    <p className="category-data d-flex justify-content-center">
                        No Product found in this Category
                    </p>
                )}
            </div>
        </div>
    );
};

export default Features;
